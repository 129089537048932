import {translate} from 'vue-gettext'

const {gettext: $gettext} = translate

export default [
    {
        _name: 'CSidebarNavTitle',
        _children: [$gettext('Configuration')],
        perms: [
            'dashboard.view_feature_shortener',
            'admin.role.list',
            'admin.template.list',
            'admin.vertical.list',
            'admin.plan.list',
            'admin.region.list',
            'admin.price_list.list'
        ]
    },
    // {
    //     _name: 'CSidebarNavItem',
    //     name: $gettext('Faq'),
    //     to: {name: 'faqCategory.list'},
    //     icon: 'cip-annotation-question',
    //     perms: ['dashboard.view_feature_faq']
    // },
    // {
    //     _name: 'CSidebarNavItem',
    //     name: $gettext('Tag'),
    //     to: {name: 'tag_category.list'},
    //     icon: 'cip-tag',
    //     perms: ['dashboard.view_feature_tag']
    // },
    {
        _name: 'CSidebarNavItem',
        name: $gettext('Shortener'),
        to: {name: 'shortener.list'},
        icon: 'cip-link-horizontal',
        perms: ['dashboard.view_feature_shortener']
    },
    {
        _name: "CSidebarNavItem",
        name: $gettext("Roles"),
        to: {name: 'roles.list'},
        icon: "cip-add-user",
        perms: ["admin.role.list"],
    },
    {
        _name: 'CSidebarNavItem',
        name: $gettext('Templates'),
        to: {name: 'eventTemplate.list'},
        icon: 'cip-window-code',
        perms: ['admin.template.list']
    },
    {
        _name: "CSidebarNavItem",
        name: $gettext("Verticals"),
        to: {name: 'verticalConfiguration.list'},
        icon: "cip-window-code",
        perms: ["admin.vertical.list"],
    },
    {
        _name: 'CSidebarNavItem',
        name: $gettext('Plans'),
        to: {name: 'payments.plan.list'},
        icon: 'cip-list',
        perms: ['admin.plan.list']
    },
    {
        _name: 'CSidebarNavItem',
        name: $gettext('Regions'),
        to: {name: 'region.list'},
        icon: 'cip-doc',
        perms: ['admin.region.list']
    },
    {
        _name: 'CSidebarNavItem',
        name: $gettext('Price List Template'),
        to: {name: 'priceListTemplate.list'},
        icon: 'cip-doc',
        perms: ['admin.price_list.list']
    },
]