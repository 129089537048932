<template>
  <CModal :title="$pgettext('contract.create','Create Contract')" :show="show" @update:show="close" size="lg"
          centered>
    <div v-if="show">
      <PFRow :label="$pgettext('contract.create.label','Name')" size="12x12">
        <CInput v-model="form.name" placeholder="Name"/>
      </PFRow>
      <PFRow :label="$pgettext('contract.create.label','Qty')" size="12x12">
        <CInput v-model="form.qty" placeholder="Qty"/>
      </PFRow>
      <PFRow :label="$pgettext('contract.create.label','Template')" size="12x12">
        <PSelectKeyValue :options="searchTemplate" :value.sync="form.template" placeholder="Select Template"/>
      </PFRow>
      <PFRow :label="$pgettext('contract.create.label','Vertical')" size="12x12">
        <PSelectKeyValue :options="searchVertical" :value.sync="form.vertical" placeholder="Select Vertical"/>
      </PFRow>
      <PFRow :label="$pgettext('contract.create.label','Terzo Dominio ( ex Event Owner )')" size="12x12">
        <PSelectKeyValue :options="searchEventOwner" :value.sync="form.event_owner" placeholder="Select Event owner"/>
      </PFRow>
      <PFRow :label="$pgettext('contract_manager.label', 'Is Subscription')">
        <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.is_subscription"/>
      </PFRow>
      <template v-if="!form.is_subscription">
        <PFRow :label="$pgettext('base_plan.create.label','Clone from Plan')" size="12x12">
          <PlanModalSelector @input="onSelectPlan" ref="planModalSelector"/>
          <CButton @click="openPlanModalSelector" v-translate translate-context="base_plan.create.actions">
            Search Plan
          </CButton>
        </PFRow>
        <PFRow :label="$pgettext('contract_manager.label', 'Num Photo Included')">
          <CInput v-model="form.plan.num_photo_included" type="number"/>
        </PFRow>
        <PFRow :label="$pgettext('contract_manager.label', 'Num Max Photo')">
          <CInput v-model="form.plan.num_max_photo" type="number"/>
        </PFRow>
        <PFRow :label="$pgettext('contract_manager.label', 'Max Participants')">
          <CInput v-model="form.plan.max_participants" type="number"/>
        </PFRow>
        <PFRow :label="$pgettext('contract_manager.label', 'Extra Photo Price')">
          <CInput v-model="form.plan.extra_photo_price" type="number"/>
        </PFRow>
        <PFRow :label="$pgettext('contract_manager.label', 'Is Promo')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.plan.is_promo"/>
        </PFRow>
      </template>
      <template v-else>
        <PFRow :label="$pgettext('contract_manager.label', 'Num Photo Included')">
          <CInput v-model="form.settings.num_photo_included" type="number"/>
        </PFRow>
        <PFRow :label="$pgettext('contract_manager.label', 'Num Max Photo')">
          <CInput v-model="form.settings.num_max_photo" type="number"/>
        </PFRow>
        <PFRow :label="$pgettext('contract_manager.label', 'Is Promo')">
          <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.settings.is_promo"/>
        </PFRow>
      </template>
    </div>
    <template #footer>
      <CButton @click="close" color="secondary" v-translate>Close</CButton>
      <PButton class="btn btn-primary"
               :loading="loading"
               @click="create"
               color="primary"
               :disabled="v$.$invalid"
               translate-context="econtract.create.modal" v-translate>
        Create
      </PButton>
    </template>
  </CModal>
</template>

<script>

import {useVuelidate} from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import {mapActions, mapGetters} from "vuex";
import PButton from "@/components/PButton.vue";
import PFRow from "@/domain/core/components/PFRow.vue";
import PSelectKeyValue from "@/domain/core/components/PSelectKeyValue.vue";
import PlanModalSelector from "@/domain/plans/components/PlanModalSelector.vue";

export default {
  name: "CreateContractModal",
  components: {PlanModalSelector, PSelectKeyValue, PFRow, PButton},
  emits: ['sent'],
  data() {
    return {
      form: {
        name: null,
        qty: 0,
        event_owner: null,
        template: null,
        vertical: null,
        plan: {
          num_photo_included: 0,
          extra_photo_price: 0,
          num_max_photo: 0,
          max_participants: 0,
          is_promo: false
        },
        settings: {
          num_photo_included: null,
          num_max_photo: null,
          is_promo: null,
        },
      },
      role_detail: null,
      show: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters('invitations', [
      'roles'
    ]),
  },
  setup() {
    return {v$: useVuelidate()}
  },
  validations() {
    return {
      form: {
        name: {},
        qty: {},
        plan: {required},
        event_owner: {required},
        template: {required},
        vertical: {required}
      },
    }
  },
  methods: {
    ...mapActions('contractManager', [
      'searchPlan',
      'searchEventOwner',
      'searchTemplate',
      'searchVertical',
      'createContract'
    ]),
    open() {
      this.form.name = null
      this.form.qty = null
      this.form.vertical = null
      this.form.plan = {
        num_photo_included: 0,
        extra_photo_price: 0,
        num_max_photo: 0,
        max_participants: 0,
        is_promo: false
      }
      this.form.event_owner = 34 // hardcode "GetPica", this field will be removed in the future and changed with a more appropriate "third level domain" or similar
      this.show = true
    },
    async create() {
      this.loading = true
      await this.createContract(this.form)
      await this.notifySuccess(this.$pgettext('contract.create.notification', 'Invitation sent'))
      this.$emit('sent')
      this.loading = false
      this.close()
    },
    close() {
      this.show = false
    },
    openPlanModalSelector() {
      this.$refs.planModalSelector.open()
    },
    onSelectPlan(plan) {
      this.form.plan = plan
    }
  }
}
</script>