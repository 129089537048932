import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

import routes from "./routes"

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: 'active',
    scrollBehavior: () => ({y: 0}),
    routes: routes
})

router.beforeEach((to, from, next) => {
    /*
    * Ogni route di default richiede autenticazione;
    * se una route non richiede esplicitamente l'autenticazione (signup, password recovery, ...),
    * deve avere meta.requiresNotAuth
    * */
    const isAuthenticated = store.getters['login/isAuthenticated']
    const requiresNotAuth = to.matched.some(record => record.meta.requiresNotAuth)
    const allowsUnAuthorized = to.matched.some(record => record.meta.allowsUnAuth)
    if (allowsUnAuthorized) {
        next()
        return
    }

    if (isAuthenticated && requiresNotAuth) {
        // sono autenticato ma la route richiesta NON vuole autenticazione --> vado alla home
        if (to.name !== 'Maintenance') {
            next('/')
        } else {
            next()
        }
    } else if (isAuthenticated) {
        // se sono in una nelle route dell'evento, eseguo il fetch del picaservice
        if (!('picaServiceId' in to.params)) {
            store.commit('event/UNSET_PICASERVICE')
        }
        next()
    } else if (isAuthenticated === false) {
        if (requiresNotAuth === false) {
            // non sono autenticato ed è richiesta autenticazione --> vai al login

            // temporary fix for iframe, need to set mata to iframe route
            if (!to.path.includes('iframe')) {
                next('/login')
            }
        } else {
            next()
        }
    }
})


router.beforeEach((to, from, next) => {
    /*
    * Verifica se lo user ha il permesso di accedere alla route richiesta
    * */

    // const requiredPerms = to.matched.some(record => record.meta.perms)

    let requiredPerms = false
    to.matched.forEach((record) => {
        if (record.meta.perms) {
            requiredPerms = record.meta.perms
            return true
        }
    })

    if (requiredPerms) {
        const hasPerms = store.getters['login/hasPerms'](requiredPerms)
        if (hasPerms) {
            // console.log('Ho il permesso', hasPerms)
            next()
        } else {
            // console.log('NON ho il permesso')
            next({'name': 'Page403'})
        }
    } else {
        // se non sono specificati permessi vuol dire che la route è accessibile a chiunque
        next()
    }
})


router.afterEach((to, from) => {
    /*
    * Valorizzazione del tag <title>
    * */
    let documentTitle = 'Pica Dashboard'

    if (to.meta && to.meta.title) {
        document.title = `${to.meta.title} | ${documentTitle}`
    } else
        document.title = documentTitle
})

export default router



