import {ApiException} from "@/domain/core/exception/exceptions";
import {emptyListResponse} from "@/domain/core/utils/django";


const state = {
    invoices: {...emptyListResponse},
    invoice: null,
    creditNotes: {...emptyListResponse},
    creditNote: null,
}

export const getters = {
    invoices: state => state.invoices,
    invoice: state => state.invoice,
    creditNotes: state => state.creditNotes,
    creditNote: state => state.creditNote
}


export const mutations = {
    invoices(state, invoices) {
        state.invoices = invoices
    },
    invoice(state, invoice) {
        state.invoice = invoice
        state.invoices.results = state.invoices.results.map(i => {
            if (i.id === invoice.id)
                return invoice
            return i
        })
    },
    creditNotes(state, creditNotes) {
        state.creditNotes = creditNotes
    },
    creditNote(state, creditNote) {
        state.creditNote = creditNote
        state.creditNotes.results = state.creditNotes.results.map(i => {
            if (i.id === creditNote.id)
                return creditNote
            return i
        })
    }
}


export const actions = service => ({
    async fetchInvoiceList({commit}, {filters, pagination, sorting}) {
        try {
            const invoices = await service.fetchInvoiceList(filters, pagination, sorting)
            commit('invoices', invoices)
        } catch (e) {
            if (e instanceof ApiException)
                commit('invoices', emptyListResponse)
            throw e
        }
    },
    async fetchInvoice({commit}, id) {
        commit('invoice', await service.fetchInvoice(id))
    },
    async confirmInvoice({commit, getters}, data) {
        commit('invoice', await service.confirmInvoice(getters['invoice'].id, data))
    },
    async fetchCreditNoteList({commit}, {filters, pagination, sorting}) {
        try {
            const creditNotes = await service.fetchCreditNoteList(filters, pagination, sorting)
            commit('creditNotes', creditNotes)
        } catch (e) {
            if (e instanceof ApiException)
                commit('creditNotes', emptyListResponse)
            throw e
        }
    },
    async fetchCreditNote({commit}, id) {
        commit('creditNote', await service.fetchCreditNote(id))
    },
    async confirmCreditNote({commit, getters}, data) {
        commit('creditNote', await service.confirmCreditNote(getters['creditNote'].id, data))
    }
})

export default service => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(service)
})

