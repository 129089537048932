import {translate} from 'vue-gettext'

const {gettext: $gettext} = translate

export default [
    {
        _name: 'CSidebarNavTitle',
        _children: [$gettext('Reports')],
        perms: ['dashboard.view_feature_stats_multi_events']
    },
    {
        _name: 'CSidebarNavItem',
        name: $gettext('Sales report'),
        to: {name: 'salesReport'},
        icon: 'cip-file-03',
        perms: ['dashboard.view_feature_stats_sales']
    },
    {
        _name: 'CSidebarNavItem',
        name: $gettext('Events analytics'),
        to: {name: 'statsEvents'},
        icon: 'cip-stats',
        perms: ['dashboard.view_feature_stats_multi_events']
    },
]