import axios from "axios";

const instance = axios.create({
    baseURL: process.env.VUE_APP_PICA_METRICS_BACKEND,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
})
const jwt = localStorage.getItem('jwt')
if (jwt) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${jwt}`
}
export default instance