<template>
  <div v-if="!isDraft">
    <CSidebarNavItem
        :name="$gettext('Overview')"
        :to="{name: 'eventWizard.overview', params: {picaServiceId: picaServiceId}}"
        icon="cip-overview"
    />
    <CSidebarNavItem
        :name="$gettext('Activity')"
        :to="{name: 'activityList', params: {picaServiceId: picaServiceId}}"
        icon="cip-clock-check"
        v-if="hasAnyPerms('dashboard.view_feature_operation_log')"
    />
    <CSidebarNavItem
        :name="$gettext('Utility')"
        :to="{name: 'utility', params: {picaServiceId: picaServiceId}}"
        icon="cip-tool"
        v-if="hasAnyPerms('dashboard.view_feature_picaservice_utility')"
    />
    <CSidebarNavItem
        :name="$gettext('Pica Codes (old)')"
        :to="{name: 'picacodeReleaseList', params: {picaServiceId: picaServiceId}}"
        v-if="hasAnyPerms('pica.view_picacoderelease')"
        icon="cip-code"
    />
    <CSidebarNavItem
        :name="$gettext('Promo')"
        :to="{name: 'eventPromo', params: {picaServiceId: picaServiceId}}"
        icon="cipPromo"
        v-if="hasAnyPerms([
            'pica.view_eventpromocode',
            'pica.change_eventpromocode',
            'pica.view_eventautocheckincode',
            'pica.change_eventautocheckincode'
        ])"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'OverviewNav',
  computed: {
    ...mapGetters('event', [
      'picaServiceId',
      'isDraft'
    ]),
    ...mapGetters('event', [
      'requiresPicaCode',
    ]),
  }
}
</script>
