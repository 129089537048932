import ApiClient from "@/domain/core/utils/apiClient";
import client_core from "@/services/clients/client_core";
const client = new ApiClient(client_core)

const endpoints = {
    category_list: '/dashboard/v2/faq_category/',
    category_detail: '/dashboard/v2/faq_category/{id}/',
    question_list: '/dashboard/v2/faq_question/',
    question_detail: '/dashboard/v2/faq_question/{id}/',
}

export default {
    async fetchCategoryList(filters, pagination, sorting) {
        let result = await client.get(endpoints.category_list, {
            ...filters,
            page_size: pagination.page_size ?? 20,
            page: pagination.page ?? 1,
            ordering: sorting.asc ? `-${sorting.column}` : sorting.column ?? ''
        })
        return {
            results: result.results,
            pagination: {
                num_pages: result.pagination.num_pages,
                page_size: result.pagination.page_size,
                page: result.pagination.page_number,
                total: result.pagination.total,
            }
        }
    },
    async deleteCategory(id) {
        await client.delete(endpoints.category_detail.replace('{id}',id))
    },
    async fetchCategory(id) {
        return await client.get(endpoints.category_detail.replace('{id}',id))
    },
    async updateCategory(id, data) {
        return await client.patch(endpoints.category_detail.replace('{id}',id), data)
    },
    async createCategory(data) {
        return await client.post(endpoints.category_list,data)
    },
    async fetchQuestionList(category, filters, pagination, sorting) {
        let result = await client.get(endpoints.question_list, {
            category: category,
            ...filters,
            page_size: pagination.page_size ?? 20,
            page: pagination.page ?? 1,
            ordering: sorting.asc ? `-${sorting.column}` : sorting.column ?? ''
        })
        return {
            results: result.results,
            pagination: {
                num_pages: result.pagination.num_pages,
                page_size: result.pagination.page_size,
                page: result.pagination.page_number,
                total: result.pagination.total,
            }
        }
    },
    async deleteQuestion(id) {
        await client.delete(endpoints.question_detail.replace('{id}',id))
    },
    async fetchQuestion(id) {
        return await client.get(endpoints.question_detail.replace('{id}',id))
    },
    async updateQuestion(id, data) {
        return await client.patch(endpoints.question_detail.replace('{id}',id), data)
    },
    async createQuestion(data) {
        return await client.post(endpoints.question_list,data)
    }
}
