import {ApiException} from "@/domain/core/exception/exceptions";
import {emptyListResponse} from "@/domain/core/utils/django";



const state = {
    roles: {...emptyListResponse},
    role: {},
    permissions: {},
}

export const getters = {
    roles: state => state.roles,
    role: state => state.role,
    permissions: state => state.permissions,
}


export const mutations = {
    roles(state, roles) {
        state.roles = roles
    },
    role(state, role) {
        state.role = role
    },
    deleteRole(state, id) {
        state.roles.results = state.roles.results.filter(role => role.id !== id)
    },
    permissions(state, permissions) {
        state.permissions = permissions
    }
}


export const actions = rolesService => ({
    async fetchRoleList({commit}, {filters, pagination, sorting}) {
        try {
            const roles = await rolesService.fetchRoleList(filters, pagination, sorting)
            commit('roles', roles)
        } catch (e) {
            if (e instanceof ApiException)
                commit('roles', emptyListResponse)
            throw e
        }
    },
    async deleteRole({commit}, id) {
        await rolesService.deleteRole(id)
        commit('deleteRole', id)
    },
    async fetchRole({commit}, id) {
        const role = await rolesService.fetchRole(id)
        commit('role', role)
    },
    async updateRole({commit}, {id, data}) {
        commit('role', await rolesService.updateRole(id, data))
    },
    async createRole({commit}, data) {
        commit('role', await rolesService.createRole(data))
    },
    async fetchPermissions({commit}) {
        commit('permissions', await rolesService.fetchPermissions())
    }
})

export default rolesService => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(rolesService)
})

