import axios from "axios";

const instance = axios.create({
    baseURL: process.env.VUE_APP_PICA_SHORTENER_BACKEND,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
})

export default instance