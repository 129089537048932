import ApiClient from "@/domain/core/utils/apiClient";
import client_core from "@/services/clients/client_core";

const client = new ApiClient(client_core)

const endpoints = {
    setup: '/dashboard/v2/events/setup/', // POST
    create_config: '/dashboard/v2/events/create_config/', // GET
    categories: '/dashboard/v2/events/regions/{region}/categories/', // GET
}

export default {
    async createNewEvent(data) {
        return await client.post(endpoints.setup, data)
    },
    async fetchCreateConfig({region, plan}) {
        let params = {}
        if (region && region.length > 0) params.region = region
        if (plan && plan.length > 0) params.plan = plan

        return await client.get(endpoints.create_config, params)
    },
    async fetchCategories(region) {
        return await client.get(endpoints.categories.replace('{region}', region))
    },
}

