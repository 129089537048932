import ApiClient from "@/domain/core/utils/apiClient";
import client_core from "@/services/clients/client_core";
const client = new ApiClient(client_core)

const endpoints = {
    role_list: '/dashboard/v2/role/',
    role_detail: '/dashboard/v2/role/{id}/',
    role_permissions: '/dashboard/v2/role/permissions/',
}

export default {
    async fetchRoleList(filters, pagination, sorting) {
        if (!filters) filters = {}
        if (!pagination) pagination = {}
        if (!sorting) sorting = {}

        let result = await client.get(endpoints.role_list, {
            ...filters,
            page_size: pagination.page_size ?? 20,
            page: pagination.page ?? 1,
            ordering: sorting.asc ? `-${sorting.column}` : sorting.column ?? ''
        })
        return {
            results: result.results,
            pagination: {
                num_pages: result.pagination.num_pages,
                page_size: result.pagination.page_size,
                page: result.pagination.page_number,
                total: result.pagination.total,
            }
        }
    },
    async deleteRole(id) {
        await client.delete(endpoints.role_detail.replace('{id}',id))
    },
    async fetchRole(id) {
        return await client.get(endpoints.role_detail.replace('{id}',id))
    },
    async updateRole(id, data) {
        return await client.patch(endpoints.role_detail.replace('{id}',id), data)
    },
    async createRole(data) {
        return await client.post(endpoints.role_list,data)
    },
    async fetchPermissions() {
        return await client.get(endpoints.role_permissions)
    },
}
