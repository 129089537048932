const ListCategoryTag = () => import('./ListCategoryTag.vue')
const NewCategoryTag = () => import('./NewCategoryTag.vue')
const EditCategoryTag = () => import('./EditCategoryTag.vue')
const ListTag = () => import('./ListTag.vue')
const NewTag = () => import('./NewTag.vue')
const EditTag = () => import('./EditTag.vue')
const TheContainer = () => import('@/containers/TheContainer')

export default [
    {
        path: '/tag',
        name: 'tag',
        component: TheContainer,
        meta: {perms: ['dashboard.view_feature_faq']},
        children: [
            {
                path: 'category/list',
                name: 'tag_category.list',
                component: ListCategoryTag,
                meta: {label: 'List Tags Categories',},
            },
            {
                path: 'category/new',
                name: 'tag_category.new',
                component: NewCategoryTag,
                meta: {label: 'Create new Tag Category',},
            },
            {
                path: 'category/:id',
                name: 'tag_category.edit',
                component: EditCategoryTag,
                meta: {label: 'Edit Tag Category',},
            },
            {
                path: ':category/list',
                name: 'tag.list',
                component: ListTag,
                meta: {label: 'List Tags',},
            },
            {
                path: ':category/new',
                name: 'tag.new',
                component: NewTag,
                meta: {label: 'Create new Faq Question',},
            },
            {
                path: ':category/:id',
                name: 'tag.edit',
                component: EditTag,
                meta: {label: 'Edit Faq Question',},
            },
        ]
    }
]