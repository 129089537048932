const ActivityList = () => import('@/views_pica/events/ActivityList.vue')
const EventUtility = () => import('@/views_pica/events/EventUtility.vue')

export default [
    {
        path: 'activity',
        name: 'activityList',
        component: ActivityList,
        meta: {
            title: 'Activity',
            label: 'Activity',
            perms: ['pica_activity_log.view_activity','dashboard.view_feature_operation_log']
        },
    },
    {
        path: 'utility',
        name: 'utility',
        component: EventUtility,
        meta: {
            title: 'Utility',
            label: 'Utility',
            perms: ['dashboard.view_feature_picaservice_utility']
        },
    },
]