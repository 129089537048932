import ApiClient from "@/domain/core/utils/apiClient";
import client_core from "@/services/clients/client_core";

const client = new ApiClient(client_core)

const endpoints = {
    search: '/dashboard/v2/customer_success/customer/search/',
    user: '/dashboard/v2/customer_success/customer/{id}/user/',
    reactivate: '/dashboard/v2/customer_success/customer/{id}/reactivate/',
    change_password: '/dashboard/v2/customer_success/customer/{id}/change_password/',
    enable_dashboard: '/dashboard/v2/customer_success/customer/{id}/enable_dashboard/',
}

export default {
    async searchUsers(filters) {
        if (!filters.user_id || filters.user_id.length === 0) delete filters.user_id
        if (!filters.email || filters.email.length === 0) delete filters.email
        if (!filters.pica_code || filters.pica_code.length === 0) delete filters.pica_code
        if (Object.keys(filters).length <= 0) return []
        window.log(filters)

        return await client.post(endpoints.search, {
            ...filters,
        })
    },
    async fetchUser(id) {
        return await client.get(endpoints.user.replace('{id}', id))
    },
    async reactivate(id) {
        return await client.post(endpoints.reactivate.replace('{id}', id))
    },
    async changePassword(id, password) {
        return await client.post(endpoints.change_password.replace('{id}', id), {
            password: password
        })
    },
    async enableDashboard(id) {
        return await client.post(endpoints.enable_dashboard.replace('{id}', id))
    },
}
