import client_core from "@/services/clients/client_core";

const PicaServiceService = {
    getPicaServices(params = {}) {
        return client_core.get('/dashboard/v1/pica-services/', { params })
    },

    getPicaService(picaServiceId) {
        return client_core.get(`/dashboard/v1/pica-services/${picaServiceId}/`)
    },

    getPicaServiceEmptyObject() {
        return client_core.get('/dashboard/v1/pica-services/new/')
    },

    createPicaService(params) {
        return client_core.post('/dashboard/v1/pica-services/', params)
    },

    updatePicaService(picaServiceId, params) {
        return client_core.patch(`/dashboard/v1/pica-services/${picaServiceId}/`, params)
    },

    uploadPicaServiceImages(picaServiceId, formData, axiosConfig = {}, endpoint='images') {
        return client_core.post(`/dashboard/v1/pica-services/${picaServiceId}/${endpoint}/`, formData, axiosConfig)
    },

    updateTagStatus(picaServiceId, isTagCompleted) {
        return client_core.patch(`/dashboard/v1/pica-services/${picaServiceId}/set-tagging-completion/`, {
            is_tagging_completed: isTagCompleted
        })
    },

    updateUploadStatus(picaServiceId) {
        return client_core.patch(`/dashboard/v1/pica-services/${picaServiceId}/set-upload-completion/`, {})
    },

    getEventCategories() {
        return client_core.get('/dashboard/v1/event-categories/')
    },

    getPicacodeReleased(picaServiceId, searchParams = {}) {
        let params = {}
        if ('picacode' in searchParams)
            params['picacode'] = searchParams.picacode
        if ('raceNumber' in searchParams)
            params['race_number'] = searchParams.raceNumber
        return client_core.get(`/dashboard/v1/pica-services/${picaServiceId}/picacodes-released/`, { params })
    },

    getPromoCodes(picaServiceId) {
        return client_core.get(`/dashboard/v1/pica-services/${picaServiceId}/promo-codes/`)
    },

    setPromoCodes(picaServiceId, params) {
        return client_core.put(`/dashboard/v1/pica-services/${picaServiceId}/promo-codes/`, params)
    },

    getEventNotes(params) {
        return client_core.get(`/dashboard/v1/pica-service-notes/`, {params})
    },

    createEventNote(params) {
        return client_core.post(`/dashboard/v1/pica-service-notes/`, params)
    },

    updateEventNote(params) {
        return client_core.patch(`/dashboard/v1/pica-service-notes/${params.id}/`, {
            pica_service: params.pica_service,
            text: params.text
        })
    },

    deleteEventNote(params) {
        return client_core.delete(`/dashboard/v1/pica-service-notes/${params.id}/`, {params: {
            pica_service: params.pica_service
        }})
    },

    getAutoCheckinCodes(picaServiceId) {
        return client_core.get(`/dashboard/v1/pica-services/${picaServiceId}/autocheckin-codes/`)
    },

    setAutoCheckinCodes(picaServiceId, params) {
        return client_core.put(`/dashboard/v1/pica-services/${picaServiceId}/autocheckin-codes/`, params)
    },

    getDraft(jwt, params) {
        return client_core.get(`/dashboard/v1/pica-services/${jwt}/draft/`, {params})
    },

    createOrUpdateDraft(jwt, payload, params) {
        return client_core.post(`/dashboard/v1/pica-services/${jwt}/draft/`, payload, {params})
    },

    publishDraft(jwt) {
        return client_core.patch(`/dashboard/v1/pica-services/${jwt}/publish-draft/`)
    },

    getPicaServiceForDataEntry(params) {
        return client_core.get(`/dashboard/picaservice-data-entry/`, {params})
    },
    patchPicaServiceForDataEntry(id, params) {
        return client_core.patch(`/dashboard/picaservice-data-entry/${id}/`, params)
    },
}

export default PicaServiceService
