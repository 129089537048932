<template>
  <CModal :title="$pgettext('event.modal.create_new_event.title','New Event')" :show="show" @update:show="close"
          size="lg"
          centered>

    <PFRow :label="$pgettext('event.modal.create_new_event.label','Event Name')">
      <CInput
          v-model="form.name"
          :placeholder="$pgettext('event.modal.create_new_event.placeholder','Event Name')"
      />
    </PFRow>
    <PFRow :label="$pgettext('event.modal.create_new_event.label','Time Zone')">
      <TimezoneSelect v-model="form.timezone"/>
    </PFRow>
    <PFRow :label="$pgettext('event.modal.create_new_event.label','Currency')" v-if="!contract.is_promo">
      <CurrencySelect v-model="form.currency"/>
    </PFRow>
    <template #footer>
      <CButton @click="close" color="secondary" v-translate>Close</CButton>
      <PButton class="btn btn-primary"
               :loading="loading"
               @click="create"
               color="primary"
               :disabled="v$.$invalid"
               translate-context="econtract.create.modal" v-translate>
        Create
      </PButton>
    </template>
  </CModal>
</template>

<script>
import {mapActions} from "vuex";
import PFRow from "@/domain/core/components/PFRow.vue";
import TimezoneSelect from "@/domain/core/components/TimezoneSelect.vue";
import CurrencySelect from "@/domain/core/components/CurrencySelect.vue";
import PButton from "@/components/PButton.vue";
import {useVuelidate} from "@vuelidate/core";
import {maxLength, minLength, required} from "@vuelidate/validators";

export default {
  name: "CreateEventModal",
  components: {PButton, CurrencySelect, TimezoneSelect, PFRow},
  emits: ['sent'],
  setup() {
    return {v$: useVuelidate()}
  },
  validations() {
    return {
      form: {
        name: {required, minLength: minLength(5), maxLength: maxLength(100)},
        timezone: {required},
        currency: {required},
      },
    }
  },
  data() {
    return {
      empty: {
        name: '',
        timezone: '',
        currency: 'EUR'
      },
      contract: {
        id: null,
        name: '',
      },
      form: {...this.empty,},
      show: false,
      loading: false,
    }
  },
  methods: {
    ...mapActions('contractManager', [
      'createEvent',
    ]),
    open(contract) {
      this.form = {...this.empty}
      this.contract = contract
      this.show = true
    },
    async create() {
      this.loading = true
      const id = await this.createEvent({
        contractId: this.contract.id,
        data: this.form
      })
      await this.$router.push({name: 'eventWizard.date_and_name', params: {picaServiceId: id}})
      this.loading = false
      this.close()
    },
    close() {
      this.show = false
    },
  }
}
</script>