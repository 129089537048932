<template>
  <div v-if="isImpersonating">
    <a href="" @click.prevent="returnYourself" class="badge badge-warning text-uppercase" v-translate>Back to yourself</a>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
  name: 'ImpersonateBadge',
  computed: {
    ...mapGetters('impersonate', [
      'isImpersonating',
    ])
  },
  methods: {
    ...mapActions('impersonate', [
      'returnYourself',
    ]),
  }
}
</script>