import Vue from 'vue'


const state = {
    autoHide: 4000,
    messages: {},
    nextId: 0,
    modal: null
}

const getters = {
    confirmModal: state => state.modal,
    messages: state => state.messages,
    autoHide: state => state.autoHide
}

const mutations = {
    ADD_MESSAGE(state, message) {
        Vue.set(state.messages, state.nextId++, message)
    },
    REMOVE_MESSAGE(state, id) {
        delete state.messages[id]
    },
    CLEAR_MESSAGES(state) {
        state.messages = {}
    },
    SET_CONFIRM_MODAL(state, modal){
        state.modal = modal
    }
}

const actions = {
    addMessage({ commit }, message) {
        commit('ADD_MESSAGE', message)
    },
    clearMessages({ commit }) {
        commit('CLEAR_MESSAGES')
    },
    success({ commit }, message) {
        commit('ADD_MESSAGE', {
            text: message,
            messageType: 'success'
        })
    },
    error({ commit }, message) {
        commit('ADD_MESSAGE', {
            text: message,
            messageType: 'danger'
        })
    },
    setConfirmModal({ commit }, modal){
        commit('SET_CONFIRM_MODAL', modal)
    },
    confirm({getters}, {title, message, color, icon, cb_confirm, cb_decline}){
        getters['confirmModal'].open(title, message, color, icon, cb_confirm, cb_decline)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}