import TheContainer from "@/containers/TheContainer.vue";
import ListRefund from "@/domain/refund/ListRefund.vue";
import NewRefund from "@/domain/refund/NewRefund.vue";
import RefundDetail from "@/domain/refund/RefundDetail.vue";

export default [
    {
        path: '/refund',
        name: 'refund',
        component: TheContainer,
        meta: {perms: ['orders.refunds.list']},
        children: [
            {
                path: 'list',
                name: 'refund.list',
                component: ListRefund,
                meta: {label: 'List Order Item Refund',},
            },
            {
                path: 'new',
                name: 'refund.new',
                component: NewRefund,
                meta: {label: 'Create new Refund',},
            },
            {
                path: ':id',
                name: 'refund.detail',
                component: RefundDetail,
                meta: {label: 'Show Refund',},
            },
        ]
    }
]