const TheContainer = () => import('@/containers/TheContainer')
const SalesReport = () => import('@/views_pica/sales_report/SalesReport')
const Stats = () => import('@/views_pica/stats/Stats')

export default [
    {
        path: '/reports',
        redirect: '/reports/sales-report',
        component: TheContainer,
        children: [
            {
                path: 'sales-report',
                name: 'salesReport',
                component: SalesReport,
                meta: {
                    title: 'Sales report',
                    label: 'Sales report',
                    perms: ['dashboard.view_feature_stats_sales']
                },
            },
            {
                path: 'stats',
                name: 'statsEvents',
                component: Stats,
                props: {isMultiEvents: true},
                meta: {
                    title: 'Events analytics',
                    label: 'Events analytics',
                    perms: ['dashboard.view_feature_stats_multi_events']
                },
            },
        ]
    },


]

