export default [
    "7 32",
    '<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\n' +
    '        <g transform="translate(-121.000000, -223.000000)" fill="#878791">\n' +
    '            <g id="Group-4" transform="translate(121.342500, 223.703500)">\n' +
    '                <path d="M3.248,-1.0658141e-14 C5.042,-1.0658141e-14 6.496,1.454 6.496,3.248 C6.496,5.042 5.042,6.496 3.248,6.496 C1.454,6.496 4.79616347e-14,5.042 4.79616347e-14,3.248 C4.79616347e-14,1.454 1.454,-1.0658141e-14 3.248,-1.0658141e-14"></path>\n' +
    '                <path d="M3.248,12.3427 C5.042,12.3427 6.496,13.7967 6.496,15.5907 C6.496,17.3847 5.042,18.8387 3.248,18.8387 C1.454,18.8387 4.79616347e-14,17.3847 4.79616347e-14,15.5907 C4.79616347e-14,13.7967 1.454,12.3427 3.248,12.3427"></path>\n' +
    '                <path d="M3.248,24.685 C5.042,24.685 6.496,26.139 6.496,27.933 C6.496,29.727 5.042,31.181 3.248,31.181 C1.454,31.181 4.79616347e-14,29.727 4.79616347e-14,27.933 C4.79616347e-14,26.139 1.454,24.685 3.248,24.685"></path>\n' +
    '            </g>\n' +
    '        </g>\n' +
    '    </g>'
]