import MqttClient from "@/domain/core/utils/mqttClient";


class MessageManager {
    constructor() {
        this.debug = false
        this.client = MqttClient
        this.callbacks = []
        this.topics = []
        this.params = {}
    }

    setParams(params) {
        if (params['userId']) this.client.setUserId(params['userId'])
        this.params = {...this.params, ...params}
        this._refreshSubscriptions()
    }

    on(topic, callback, key) {
        if (!topic) {
            console.error('Topic is required', topic, callback, key)
            return
        }
        const index = this.callbacks.findIndex(sub => sub.pattern === topic && sub.key === key)
        this.log('on - ' + topic + ' - ' + key)
        if (index === -1)
            this.callbacks.push({pattern: topic, callback, key})
        else
            this.callbacks[index].callback = callback
        this._refreshSubscriptions()
    }

    off(key) {
        this.log('off - ' + (key ?? 'all'))
        if (key) this.callbacks = this.callbacks.filter(sub => sub.key !== key)
        else this.callbacks = []
        this._refreshSubscriptions()
    }

    _on_connect() {
        this.log('On connect')
        this.topics = []
        this._refreshSubscriptions()
    }

    _refreshSubscriptions() {
        this.log('refreshing subscriptions')
        if (this.callbacks.length === 0) {
            if (this.client.isConnected()) {
                this.client.disconnect()
                return this.log('no subscriptions - disconnecting')
            }
            return this.log('no subscriptions')
        }
        if (!this.client.isInitialized()) {
            this.client.init(this._on_connect.bind(this), null, this._handleMessage.bind(this))
            return this.log('client not initialized - initializing')
        }
        if (!this.client.isConnected())
            return this.log('client not connected - waiting')

        this.callbacks.map(cb => {
            cb.topic = cb.pattern.replace(/{([^}]+)}/g, (_, key) => this.params[key])
            return cb
        })

        const newTopics = [...new Set(this.callbacks.map(cb => cb.topic))]

        // Unsubscribe dai topic non più necessari
        this.topics.forEach(topic => {
            if (!newTopics.includes(topic)) {
                this.client.unsubscribe(topic)
                this.log('unsubscribed from ' + topic)
            }
        })

        // Subscribe ai nuovi topic
        newTopics.forEach(topic => {
            if (this.topics.includes(topic)) return
            this.client.subscribe(topic)
            this.log('subscribed to ' + topic)
        })

        // Aggiorna this.topics con le nuove informazioni
        this.topics = newTopics
    }

    _handleMessage(topic, message) {
        const payload = JSON.parse(message)

        this.callbacks.filter(sub => {
            const regex = new RegExp('^' + sub.topic.replace(/\*/g, '.*') + '$')
            return regex.test(topic)
        }).forEach(({callback}) => {
            this.log('callback for ' + topic + ' : ' + message)
            try {
                callback(payload)
            } catch (e) {
                console.error('MessageManager - Error in callback', e)
            }
        })
    }

    log(message) {
        if (typeof console !== 'undefined' && this.debug) console.log('MessageManager ' + message)
    }
}

export default new MessageManager()