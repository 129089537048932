import Vue from 'vue'
import GetTextPlugin from 'vue-gettext'
import translations from './translations.json'

Vue.use(GetTextPlugin, {
    availableLanguages: {
        en: 'English',
        it: 'Italiano',
    },
    defaultLanguage: 'en',
    languageVmMixin: {
        computed: {
            currentKebabCase: function () {
                return this.current.toLowerCase().replace('_', '-')
            },
        },

        methods: {
            _: function (string, context) {
                const msgId = this.$gettext(string)
                if (context)
                    return this.$gettextInterpolate(msgId, context)
                return msgId
            }
        },
    },
    translations: translations,
    silent: true,
    autoAddKeyAttributes: true
})