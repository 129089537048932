<template>
  <div class="mb-3">
    <hr class="mt-1 mb-4">
    <div class="d-flex justify-content-center mb-2">
      <template v-for="option in customPriceListTypeOptions">
        <CButton :color="selectedType === option.id ? 'primary' : ''" :key="option.id"
                 @click="onReset(option.id)">
          {{ option.name }}
        </CButton>
      </template>
    </div>
    <CDataTable
        class="table-price-list"
        :items="rows"
        :fields="fields"
        :hover="false"
        :striped="false"
        :bordered="true"
        :fixed="false"
        :key="tableKey"
        add-table-classes="mb-0"
        v-if="rows.length > 0"
    >
      <template #from_qty="{ item }">
        <td>
          <CInput type="number" v-model="item.from_qty" @change="onUpdate" @input="onUpdate"/>
        </td>
      </template>
      <template #to_qty="{ item }">
        <td>
          <CInput type="number" v-model="item.to_qty" @change="onUpdate"  @input="onUpdate"/>
        </td>
      </template>
      <template #qty="{ item }">
        <td v-if="!item.qty">
          <CInput type="number" v-model="item.qty" @change="onUpdate" @input="onUpdate"/>
        </td>
        <td v-else>
          <CInput type="number" readonly disabled v-model="item.qty" />
        </td>
      </template>
      <template #unit_price="{ item }">
        <td v-if="!hasUnitPrice && !hasBlockPrice">
          <PCurrencyInput v-model="item.unit_price" :currency="currency" @change="onUpdate"  @input="onUpdate"/>
        </td>
        <td v-else-if="hasUnitPrice">
          <PCurrencyInput v-model="item.unit_price" :currency="currency" @change="onUpdate"  @input="onUpdate"/>
        </td>
        <td v-else>
          <PCurrencyInput :value="getUnitPrice(item)" :currency="currency" readonly disabled/>
        </td>
      </template>
      <template #price="{ item }">
        <td v-if="!hasUnitPrice && !hasBlockPrice">
          <PCurrencyInput v-model="item.price" :currency="currency" @change="onUpdate"  @input="onUpdate"/>
        </td>
        <td v-else-if="hasBlockPrice">
          <PCurrencyInput v-model="item.price" :currency="currency" @change="onUpdate"  @input="onUpdate"/>
        </td>
        <td v-else>
          <PCurrencyInput :value="getTotalPrice(item)" :currency="currency" readonly disabled/>
        </td>
      </template>
      <template #actions="{ item, index }">
        <td class="text-right">
          <CButton @click="deleteLastItem()"
                   class="btn-circle"
                   color="dark"
                   variant="outline"
                   v-if="isLastRow(index)">
            <CIcon name="cipTrashFull"/>
          </CButton>
        </td>
      </template>
    </CDataTable>
  </div>
</template>

<script>

import {customPriceListType} from "@/domain/core/constant/priceListType";
import PCurrencyInput from "@/domain/core/components/PCurrencyInput.vue";
import {mapGetters} from "vuex";

export default {
  name: "CustomPriceList",
  components: {PCurrencyInput},
  emits: ['update'],
  props: {
    currency: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      rows: [],
      tableKey: 0,
      emptyRow: {
        from_qty: '',
        to_qty: '',
        qty: '',
        unit_price: '',
        price: '',
      },
      selectedType: null
    }
  },
  computed: {
    ...mapGetters('event', ['eventCurrency']),
    customPriceListType() {
      return customPriceListType
    },
    customPriceListTypeOptions() {
      return this.customPriceListType.options()
    },
    hasUnitPrice() {
      if (!this.rows.length)
        return false
      if (this.hasRangeQty)
        return true

      return this.rows[0].unit_price && parseFloat(this.rows[0].unit_price) > 0
    },
    hasBlockPrice() {
      if (!this.rows.length)
        return false
      if (this.hasRangeQty)
        return false

      return this.rows[0].price && parseFloat(this.rows[0].price) > 0
    },
    hasRangeQty() {
      if (!this.rows.length)
        return false

      return this.rows[0].from_qty && this.rows[0].to_qty
    },
    hasSingleQty() {
      if (!this.rows.length)
        return false

      return this.rows[0].qty
    },
    fields() {
      let fields = []
      if (this.hasRangeQty) {
        fields.push({key: 'from_qty', label: this.$pgettext('priceList.prices', 'From')})
        fields.push({key: 'to_qty', label: this.$pgettext('priceList.prices', 'To')})
      } else if (this.hasSingleQty) {
        fields.push({key: 'qty', label: this.$pgettext('priceList.prices', 'Qty')})
      } else {
        fields.push({key: 'from_qty', label: this.$pgettext('priceList.prices', 'From')})
        fields.push({key: 'to_qty', label: this.$pgettext('priceList.prices', 'To')})
        fields.push({key: 'qty', label: this.$pgettext('priceList.prices', 'Qty')})
      }
      fields.push({key: 'unit_price', label: this.$pgettext('priceList.prices', 'Price (per item)')})
      if (!this.hasRangeQty)
        fields.push({key: 'price', label: this.$pgettext('priceList.prices', 'Price (total)')})
      fields.push({key: 'actions', label: ''})
      return fields
    }
  },

  methods: {
    init({items}) {
      this.rows = items.sort((a, b) => a.from_qty - b.from_qty)
      if (this.rows.length === 0)
        this.rows.push({...this.emptyRow})
      this.manageEmptyRows()
      // Set the default type
      this.selectedType = this.hasRangeQty ? customPriceListType.range : customPriceListType.unit
      this.tableKey++
    },
    getPriceList() {
      return {items: this.getCleanedPriceList(), configuration: {}}
    },
    addItem(rows) {
      if (!rows.length) {
        rows.push({...this.emptyRow})
        return rows
      }
      const lastRow = rows[rows.length - 1]
      let newRow = {}
      if (this.hasSingleQty)
        newRow.qty = parseInt(lastRow.qty) + 1
      else {
        const increment = lastRow.to_qty - lastRow.from_qty
        newRow.from_qty = parseInt(lastRow.to_qty) + 1
        newRow.to_qty = parseInt(lastRow.to_qty) + increment
      }
      rows.push(newRow)
      return rows
    },
    async onUpdate() {
      console.log('on update?')
      await this.$nextTick()
      this.manageEmptyRows()
      this.$emit('update', {items: this.getCleanedPriceList(), configuration: {}})
    }, manageEmptyRows() {
      const lastNonEmptyIndex = this.rows.findLastIndex(row => this.itemHasPrice(row))
      if (this.rows.length > lastNonEmptyIndex +2)
        this.rows = this.rows.slice(0, lastNonEmptyIndex + 1)
      if (this.itemHasPrice(this.rows[this.rows.length-1]))
        this.rows = this.addItem(this.rows)
    }, getCleanedPriceList() {
      let cleaned = []
      for (let i = 0; i < this.rows.length; i++) {
        const row = this.rows[i]
        if (!this.isValidRow(row))
          break
        cleaned.push(row)
      }
      return cleaned
    },
    isValidRow(item) {
      // Note: we should check the 'prev row' to ensure consistency ( ex: new qty > prev qty)
      // we are just doing a basic check here for now
      return this.itemHasPrice(item) && this.itemHasQty(item)
    },
    getUnitPrice(item) {
      if (!item.price || parseInt(item.qty) <= 0) return ''
      return Math.round((parseFloat(item.price) / parseInt(item.qty)) * 10) / 10
    },
    getTotalPrice(item) {
      if (item.from_qty && item.to_qty) return ''
      if (!item.unit_price || parseInt(item.qty) <= 0) return ''
      return Math.round(parseFloat(item.unit_price) * parseInt(item.qty) * 10) / 10
    },
    itemHasPrice(item) {
      return parseFloat(item.price) > 0 || parseFloat(item.unit_price) > 0
    },
    itemHasQty(item) {
      return item.qty > 0 || (item.from_qty > 0 && item.to_qty > 0)
    },
    isLastRow(index) {
      return index === this.rows.length - 2
    }, deleteLastItem() {
      let deletedCount = this.rows.length === 2 ? 1 : 2
      this.rows.splice(this.rows.length - 2, deletedCount)
      this.onUpdate()
    }, onReset(type) {
      this.selectedType = type
      if (this.selectedType === customPriceListType.range) {
        this.rows = [
          {
            from_qty: 1,
            to_qty: 5,
          }
        ]
      } else {
        this.rows = [
          {
            qty: 1,
          }
        ]
      }
      this.onUpdate()
    }
  }
}
</script>