export default [
    "18 18",
    '<path d="M1.5 1.5H4.22727V4.22727H1.5V1.5Z" fill="var(--ci-primary-color, currentColor)"  />\n' +
    '<path d="M5.5909 1.5H8.31817V4.22727H5.5909V1.5Z" fill="var(--ci-primary-color, currentColor)"  />\n' +
    '<path d="M9.68184 1.5H12.4091V4.22727H9.68184V1.5Z" fill="var(--ci-primary-color, currentColor)"  />\n' +
    '<path d="M13.7727 1.5H16.5V4.22727H13.7727V1.5Z" fill="var(--ci-primary-color, currentColor)"  />\n' +
    '<path d="M1.5 5.59091H4.22727V8.31818H1.5V5.59091Z" fill="var(--ci-primary-color, currentColor)"  />\n' +
    '<path d="M5.5909 5.59091H8.31817V8.31818H5.5909V5.59091Z" fill="var(--ci-primary-color, currentColor)"  />\n' +
    '<path d="M9.68184 5.59091H12.4091V8.31818H9.68184V5.59091Z" fill="var(--ci-primary-color, currentColor)"  />\n' +
    '<path d="M13.7727 5.59091H16.5V8.31818H13.7727V5.59091Z" fill="var(--ci-primary-color, currentColor)"  />\n' +
    '<path d="M1.5 9.68182H4.22727V12.4091H1.5V9.68182Z" fill="var(--ci-primary-color, currentColor)"  />\n' +
    '<path d="M5.5909 9.68182H8.31817V12.4091H5.5909V9.68182Z" fill="var(--ci-primary-color, currentColor)"  />\n' +
    '<path d="M9.68184 9.68182H12.4091V12.4091H9.68184V9.68182Z" fill="var(--ci-primary-color, currentColor)"  />\n' +
    '<path d="M13.7727 9.68182H16.5V12.4091H13.7727V9.68182Z" fill="var(--ci-primary-color, currentColor)"  />\n' +
    '<path d="M1.5 13.7727H4.22727V16.5H1.5V13.7727Z" fill="var(--ci-primary-color, currentColor)"  />\n' +
    '<path d="M5.5909 13.7727H8.31817V16.5H5.5909V13.7727Z" fill="var(--ci-primary-color, currentColor)"  />\n' +
    '<path d="M9.68184 13.7727H12.4091V16.5H9.68184V13.7727Z" fill="var(--ci-primary-color, currentColor)"  />\n' +
    '<path d="M13.7727 13.7727H16.5V16.5H13.7727V13.7727Z" fill="var(--ci-primary-color, currentColor)"  />'
]

