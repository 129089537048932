import moment from "moment";
import GallerySorting from "@/domain/core/constant/gallerySorting";

class MediaCluster {
    static BY_MINUTE = 'minute'
    static BY_10MINUTE = '10minute'
    static BY_HOUR = 'hour'
    static BY_DAY = 'day'
    static NO_CLUSTER = 'none'

    constructor(clusterBy, sorting) {
        this.clusterBy = clusterBy
        this.sorting = sorting
    }

    /**
     * Cluster the photos using getClusterKey method, and cluster them
     * It expects an already sorted list of media
     *
     * The resulting object will be a list of objects, each object will contain
     * - the media array,
     * - the cluster key
     * - human-readable name
     * - complete flag that indicate if the cluster has all the media available
     *
     * We assume media contains all the photos fetched so far
     * Every photo should already contain a moment(shot_at)
     *
     * TimeLine is used as a reference to determine if a cluster is complete or not
     *
     * ex:
     * [{
     *      media: [photo1, photo2],
     *      key: '202101011200',
     *      complete: true,
     * },
     * {
     *      media: [photo3],
     *      key: '202101011210',
     *      complete: false
     * }]
     *
     * hasBefore and hasAfter are used to know if we have more media to fetch before/after the already fetched media
     * In this way we know if the first and last cluster are complete or not
     *
     * @param sorted
     * @param timeLine
     * @return array
     */
    getClusters(sorted, timeLine) {
        if (this.clusterBy === MediaCluster.NO_CLUSTER) {
            return [{
                media: sorted,
                key: 'all',
                complete: false,
            }]
        }

        let keys = []
        let clustered = sorted.reduce((acc, photo) => {
            let key = this.getClusterKey(photo.shot_at)
            if (!acc[key]) {
                acc[key] = {
                    media: [],
                    key: key,
                    date: photo.shot_at,
                    total: timeLine.find(item => item.key === key)?.num
                }
                keys.push(key)
            }
            acc[key].media.push(photo)
            return acc
        }, {})

        // keys will be already sorted since we fill them in the order we find them

        // If we have a previous key, then the token must be complete, same for the last token
        // The only exception is when we have no new media at the start or at the end
        return keys.map(key => {
            const cluster = clustered[key]
            return {
                ...cluster,
                complete: cluster.total === clustered[key].media.length,
            }
        })
    }

    /**
     * This method is used to sort all the media in the right order
     *
     * @param media
     * @return array
     */
    getSortedMedia(media) {
        return Object.values(media).sort(this.sortMedia.bind(this))
    }


    /**
     * This is the key used for clustering, must be
     * - unique
     * - without space or special characters
     * - sortable ( used for complete tokens and display )
     * - reversible ( from the key one must be able to get the from/to date give the clusterBy )
     * It's not necessary to be human-readable
     * @param date
     * @return string
     */
    getClusterKey(date) {
        if (this.clusterBy === MediaCluster.NO_CLUSTER)
            return 'all'
        if (this.clusterBy === MediaCluster.BY_MINUTE)
            return date.format('YYYYMMDDHHmm')
        if (this.clusterBy === MediaCluster.BY_10MINUTE)
            return moment(date).minute(Math.floor(date.minute() / 10) * 10).format('YYYYMMDDHHmm')
        if (this.clusterBy === MediaCluster.BY_HOUR)
            return date.format('YYYYMMDDHH')
        if (this.clusterBy === MediaCluster.BY_DAY)
            return date.format('YYYYMMDD')
        throw new Error('Invalid cluster by')
    }

    /**
     * Since we defined the key as reversible, we can get the date from the key
     * It's used when we want to go to understand the time-limit for a cluster
     * It returns an object with from/to date with moment objects
     *
     * ex: {
     *     from: moment(),
     *     to: moment().add(1, 'minute')
     * }
     *
     * @param key
     * @param timeZone
     * @return object
     */
    getDateRangeForKey(key, timeZone) {
        if (this.clusterBy === MediaCluster.BY_MINUTE) {
            const from = moment.tz(key + ' 00', 'YYYYMMDDHHmm ss', timeZone)
            return {
                from: from,
                to: from.clone().add(1, 'minute')
            }
        }
        if (this.clusterBy === MediaCluster.BY_10MINUTE) {
            const from = moment.tz(key + ' 00', 'YYYYMMDDHHmm ss', timeZone)
            return {
                from: from,
                to: from.clone().add(10, 'minute')
            }
        }
        if (this.clusterBy === MediaCluster.BY_HOUR) {
            const from = moment.tz(key + ' 00:00', 'YYYYMMDDHH mm:ss', timeZone)
            return {
                from: from,
                to: from.clone().add(1, 'hour')
            }
        }
        if (this.clusterBy === MediaCluster.BY_DAY) {
            const from = moment.tz(key + ' 00:00:00', 'YYYYMMDD hh:mm:ss', timeZone)
            return {
                from: from,
                to: from.clone().add(1, 'day')
            }
        }
        throw new Error('Invalid cluster by')
    }

    /**
     * Sort function for media inside a cluster, internal use only
     * @param a
     * @param b
     * @return {number}
     */
    sortMedia(a, b) {
        if (!a || !b) return 0
        let diff = 0
        if (this.sorting === GallerySorting.custom)
            diff = a.order - b.order
        if (this.sorting === GallerySorting.shot_at_asc)
            diff = a.shot_at - b.shot_at
        if (this.sorting === GallerySorting.shot_at_desc)
            diff = b.shot_at - a.shot_at
        if (this.sorting === GallerySorting.upload_asc)
            diff = a.id - b.id
        if (this.sorting === GallerySorting.upload_desc)
            diff = b.id - a.id
        if (diff !== 0) return diff
        return a.id - b.id
    }

    isMediaBefore(photo, first) {
        return this.sortMedia(photo, first) < 0
    }

    isMediaAfter(photo, last) {
        return this.sortMedia(photo, last) > 0
    }
}

export default MediaCluster;