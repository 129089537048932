import Vue from "vue";

const state = {
    picaServiceId: null,
    layouts: {},
    tasks: {},
    layoutOptions: [],
    release: null,
    galleries: [],
}

const mapToLabelValue = (items) => items.map(item => ({
    label: item.label,
    value: item.id
}))

export const getters = {
    picaServiceId: (state) => state.picaServiceId,
    layout: (state) => (id) => state.layouts[id] ?? null,
    task: (state) => (id) => state.tasks[id] ?? null,
    layoutOptions: state => state.layoutOptions,
    release: state => state.release,
    galleries: state => mapToLabelValue(state.galleries),
}


export const mutations = {
    picaServiceId(state, id) {
        state.picaServiceId = id
        state.layouts = {}
        state.tasks = {}
        state.layoutOptions = []
        state.galleries = []
        state.release = null
    },
    layout(state, layout) {
        Vue.set(state.layouts, layout.id, layout)
    },
    task(state, task) {
        Vue.set(state.tasks, task.id, task)
    },
    layoutOptions(state, options) {
        state.layoutOptions = options
    },
    release(state, release) {
        state.release = release
    },
    galleries(state, galleries) {
        state.galleries = galleries
    }
}


export const actions = service => ({
    setPicaServiceId({commit}, id) {
        commit('picaServiceId', id)
    },
    async fetchLayouts({commit, getters}, ids) {
        const toFetch = ids.filter(id => !getters.layout(id))
        if (toFetch.length === 0) return

        const layouts = await service.fetchLayouts(getters['picaServiceId'], toFetch)
        layouts.forEach(layout => commit('layout', layout))
    },
    async fetchLayoutOptions({commit, getters}) {
        if (getters.layoutOptions.length) return
        const options = await service.fetchLayoutOptions(getters['picaServiceId'])
        commit('layoutOptions', options)
    },
    async fetchTasks({commit, getters}, ids) {
        if (ids.length === 0) return
        const tasks = await service.fetchTasks(getters['picaServiceId'], ids)
        tasks.forEach(task => commit('task', task))
        return tasks
    },
    async deleteRelease({getters}, id) {
        return service.deleteRelease(getters['picaServiceId'], id)
    },
    async downloadReleases({getters}, ids) {
        return service.downloadReleases(getters['picaServiceId'], ids)
    },
    async downloadLabels({getters}, ids) {
        return service.downloadLabels(getters['picaServiceId'], ids)
    },
    async generateLabels({getters}, {ids, layout, format}) {
        return service.generateLabels(getters['picaServiceId'], ids, layout, format)
    },
    async fetchRelease({commit, getters}, id) {
        commit('release', null)
        const release = await service.fetchRelease(getters['picaServiceId'], id)
        commit('release', release)
    },
    async fetchGalleries({commit, getters}, forceRefresh = false) {
        if (getters.galleries.length && !forceRefresh) return
        const galleries = await service.fetchGalleries(getters['picaServiceId'])
        commit('galleries', galleries)
    },
    async setGallery({getters, commit}, gallery) {
        const release = await service.setGallery(getters['picaServiceId'], getters['release'].id, gallery)
        commit('release', release)
    },
    async setCodec({getters, commit}, codec) {
        const release = await service.setCodec(getters['picaServiceId'], getters['release'].id, codec)
        commit('release', release)
    }
})

export default service => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(service)
})

