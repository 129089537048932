<template>
  <div>
    <UploadWidget
        v-if="hasPerm('stats.left_widget') && !isDraft"
        :planName="planName"
        :intents_count="eventStats.intents_count"
        :process_limit="eventStats.process_limit"
        :pay_as_you_go_count="eventStats.pay_as_you_go_count"
        :pay_as_you_go_paid="eventStats.pay_as_you_go_paid"
        :upload_limit="eventStats.upload_limit"
        :uploaded_count="eventStats.uploaded_count"
        :received_count="eventStats.received_count"
        :processed_count="eventStats.processed_count"
        @upload="$emit('upload')"
        @seePhotos="$emit('seePhotos')"
        @deliver="onDeliver"/>
    <div class="upload-sessions" v-if="sessions.length>0">
      <div class="summary d-flex align-items-center">
        <div>
          <CIcon name="cipUpload2" size="custom-size" height="16"/>
        </div>
        <SessionProgress class="m-2"
                         :processed_count="sessionSummary.processed_count"
                         :received_count="sessionSummary.received_count"
                         :uploaded_count="sessionSummary.uploaded_count"
                         :intents_count="sessionSummary.intents_count"
                         :total_size="sessionSummary.total_size"
                         :uploaded_size="sessionSummary.uploaded_size"
        />
        <div class="perc">
          {{ uploadedProgressPerc }}%
        </div>
        <div>
          <CButton class="btn-circle btn-circle--xs" color="dark" size="sm" variant="outline"
                   @click="collapsed=!collapsed">
            <CIcon :name="collapsed ? 'cipCaretUp':'cipCaretDown'"/>
          </CButton>
        </div>

      </div>

      <div v-if="!collapsed" class="sessions-list">
        <div class="title" v-translate translate-context="globalUploader.sessions">Uploads</div>
        <hr>
        <div class="content">
          <div v-for="session in sessions" :key="session.id">
            <UploadSession
                :session="session"
                @close="onCloseSession(session)"
                @confirm="onConfirmSession(session)"
            >
              <template #default>
                <template v-if="session.status === uploadSessionStatus.pending">
                  <template v-if="session.files">
                    {{ session.uploaded_size | formatSize(getOptimalSize(session.total_size)) }} <span
                      v-translate>on</span>
                    {{ session.total_size | formatSize }} -
                  </template>
                  {{ session.uploaded_count + session.received_count }}/{{ session.intents_count }} files
                </template>
                <template v-else-if="session.status === uploadSessionStatus.inProgress">
                  <template v-if="session.files">
                    {{ session.uploaded_size | formatSize(getOptimalSize(session.total_size)) }} <span
                      v-translate>on</span>
                    {{ session.total_size | formatSize }} -
                  </template>
                  {{ session.uploaded_count + session.received_count }}/{{ session.intents_count }} files
                </template>
                <template v-else-if="session.status === uploadSessionStatus.uploaded">
                  <template v-if="session.files">
                    {{ session.total_size | formatSize }} -
                  </template>
                  {{ session.intents_count }} files uploaded
                </template>
                <template v-else-if="session.status === uploadSessionStatus.received">
                  <template v-if="session.files">
                    {{ session.total_size | formatSize }} -
                  </template>
                  {{ session.intents_count }} files uploaded
                </template>
                <template v-else-if="session.status === uploadSessionStatus.processed">
                  <translate :translate-n="session.processed_count"
                             translate-plural="%{ session.processed_count } files processed">%{
                    session.processed_count } file processed
                  </translate>
                </template>
                <template v-else-if="session.status === uploadSessionStatus.empty">
                  All files uploaded
                </template>
              </template>
              <template v-if="session.files && session.files.failed.length > 0" v-slot:errors>
                <UploadErrors :files="session.files.failed"/>
              </template>
            </UploadSession>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import uploadSessionStatus from "@/domain/core/constant/uploadSessionStatus";
import MessageManager from "@/domain/core/utils/messageManager";
import UploadErrors from "@/domain/uploader/components/UploadErrors.vue";
import UploadSession from "@/domain/uploader/components/UploadSession.vue";
import mqttTopics from "@/domain/core/constant/mqttTopics";
import SessionProgress from "@/domain/uploader/components/SessionProgress.vue";
import Filters from "../../core/utils/filters";
import UploadWidget from "@/domain/uploader/components/UploadWidget.vue";
import eventBus from "@/domain/core/constant/eventBus";

export default {
  name: 'UploadStatus',
  emits: ['deliver', 'upload', 'seePhotos'],
  components: {
    UploadWidget, SessionProgress, UploadSession, UploadErrors
  },
  data() {
    return {
      collapsed: false,
      showTooltip: false,
      autoCollapseTimeout: null,
    }
  },
  async created() {
    this.registerEvent(eventBus.event.changed, this.onEventChange)
    this.registerEvent(eventBus.event.published, this.onEventChange)
    this.registerEvent(eventBus.event.pay_as_you_go, this.onEventChange)
    await this.onEventChange()
  },
  beforeDestroy() {
    MessageManager.off('uploadStatus')
    this.unregisterEvent(eventBus.event.changed, this.onEventChange)
    this.unregisterEvent(eventBus.event.published, this.onEventChange)
    this.unregisterEvent(eventBus.event.pay_as_you_go, this.onEventChange)
    if (this.autoCollapseTimeout) clearTimeout(this.autoCollapseTimeout)
  },
  watch: {
    sessions(newSessions, oldSessions) {
      if (newSessions.length > oldSessions.length)
        this.collapsed = false
    },
    hasActiveUpload() {
      this.checkAutoCollapse()
    }
  },
  computed: {
    ...mapGetters('uploader', [
      'sessions',
      'sessionSummary',
      'eventStats',
      'planName',
    ]),
    ...mapGetters('event', [
      'isDraft'
    ]),
    uploadSessionStatus() {
      return uploadSessionStatus
    },
    uploadedProgressPerc() {
      if (this.sessionSummary.total_size && this.sessionSummary.total_size > 0) {
        return Math.floor((this.sessionSummary.uploaded_size / this.sessionSummary.total_size) * 100)
      }
      return Math.floor((this.sessionSummary.uploaded_count / this.sessionSummary.intents_count) * 100)
    },
    hasActiveUpload() {
      return this.sessions.some(session => [uploadSessionStatus.empty, uploadSessionStatus.inProgress].includes(session.status))
    }
  },
  methods: {
    ...mapActions('uploader', [
      'stopSession',
      'deleteSession',
      'fetchEventStats',
      'sessionUpdate',
      'eventUpdate',
      'confirmSession',
      'fetchEventStats',
      'fetchGalleryStats'
    ]),
    async onEventChange() {
      if (!this.hasPerm('stats.counters')) {
        MessageManager.off('uploadStatus')
        return
      }

      await Promise.all([
        this.fetchEventStats(),
        this.fetchGalleryStats()
      ])

      this.checkAutoCollapse()

      MessageManager.on(mqttTopics.upload.event, (data) => {
        this.eventUpdate({
          intents: data.data.metrics.intents ?? null,
          uploaded: data.data.metrics.uploaded ?? null,
          received: data.data.metrics.received ?? null,
          processed: data.data.metrics.processed ?? null
        })
      }, 'uploadStatus')

      MessageManager.on(mqttTopics.upload.user_tasks, (data) => {
        this.sessionUpdate({
          id: data.data.task_id,
          intents: data.data.metrics.intents ?? null,
          uploaded: data.data.metrics.uploaded ?? null,
          received: data.data.metrics.received ?? null,
          processed: data.data.metrics.processed ?? null
        })
      }, 'uploadStatus')
    },
    onCloseSession(session) {
      this.confirm({
        title: this.$gettext('Stop Upload'),
        message: this.$gettext('Are you sure you want to cancel uploading this gallery? The action is irreversible'),
        color: 'danger',
        cb_confirm: async () => {
          if (session.status === uploadSessionStatus.empty || session.status === uploadSessionStatus.pending) {
            await this.deleteSession(session.id)
          } else if (session.status === uploadSessionStatus.inProgress) {
            await this.stopSession(session.id)
          }
        }
      })
    }, getOptimalSize(size) {
      return Filters.optimalSizeMeasure(size)
    }, onConfirmSession(session) {
      this.confirmSession(session.id)
    }, onDeliver() {
      this.$emit('deliver')
    }, checkAutoCollapse() {
      if (this.hasActiveUpload) {
        if (!this.autoCollapseTimeout) return
        clearTimeout(this.autoCollapseTimeout)
      } else {
        if (this.collapsed) return
        this.autoCollapseTimeout = setTimeout(() => {
          this.collapsed = true
        }, 5000)
      }
    }
  }
}
</script>