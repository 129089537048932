import event_overview from '@/domain/eventManage/routes/overview'
import event_configuration from '@/domain/eventManage/routes/configuration'
import event_media from '@/domain/eventManage/routes/media'
import event_administration from '@/domain/eventManage/routes/administration'
import event_report from '@/domain/eventManage/routes/report'
import event_wizard from '@/domain/eventWizard/routes'
import event_gallery from '@/domain/eventGallery/routes'
import EventManager from "@/domain/eventManage/EventManager.vue";
import photo_search from "@/domain/photoSearch/routes";
import photo_map from '@/domain/photoMap/routes'
import invitations from '@/domain/eventInvitations/routes'
import checkin from '@/domain/checkin/routes'

export default [
    {
        path: '/events/:picaServiceId([0-9]+)',
        redirect: '/events/:picaServiceId([0-9]+)/overview',
        name: 'eventIndex',
        component: EventManager,
        meta: {},
        children: [
            ...event_overview,
            ...event_configuration,
            ...event_media,
            ...event_administration,
            ...event_report,
            ...event_wizard,
            ...event_gallery,
            ...photo_search,
            ...photo_map,
            ...invitations,
            ...checkin,

        ]
    },
]
