import ApiClient from "@/domain/core/utils/apiClient";
import client_core from "@/services/clients/client_core";
import Downloader from "@/domain/core/utils/downloader";

const client = new ApiClient(client_core)

const endpoints = {
    by_year: '/dashboard/v2/consolidate/{id}/consolidate/by-year/{year}/', // GET
    csv_orders: '/dashboard/v2/consolidate/{id}/consolidate/csv/orders/?consolidate_id={consolidation}', // GET
    csv_refunds: '/dashboard/v2/consolidate/{id}/consolidate/csv/refunds/?consolidate_id={consolidation}', // GET
    csv_summary: '/dashboard/v2/consolidate/{id}/consolidate/csv/summary/?consolidate_id={consolidation}', // GET
    csv_month_orders: '/dashboard/v2/consolidate/{id}/consolidate/csv/month_orders/?month={month}&year={year}', // GET
    csv_month_refunds: '/dashboard/v2/consolidate/{id}/consolidate/csv/month_refunds/?month={month}&year={year}', // GET
    csv_month_summary: '/dashboard/v2/consolidate/{id}/consolidate/csv/month_summary/?month={month}&year={year}', // GET
    consolidate: '/dashboard/v2/consolidate/{id}/consolidate/consolidate/', // POST
    configuration: '/dashboard/v2/consolidate/{id}/consolidate/configuration/', // GET
    delete: '/dashboard/v2/consolidate/{id}/consolidate/delete/?consolidate_id={consolidation}', // DELETE

}

export default {
    async fetchConfiguration(id) {
        return await client.get(endpoints.configuration.replace('{id}', id))
    },
    async fetchForYear(id, year) {
        return await client.get(endpoints.by_year.replace('{id}', id).replace('{year}', year))
    },
    async downloadOrders(id, consolidation) {
        const url = endpoints.csv_orders.replace('{id}', id).replace('{consolidation}', consolidation)
        await Downloader.download(client, url)
    },
    async downloadRefunds(id, consolidation) {
        const url = endpoints.csv_refunds.replace('{id}', id).replace('{consolidation}', consolidation)
        await Downloader.download(client, url)
    },
    async downloadSummary(id, consolidation) {
        const url = endpoints.csv_summary.replace('{id}', id).replace('{consolidation}', consolidation)
        await Downloader.download(client, url)
    },
    async downloadMonthOrders(id, month, year) {
        const url = endpoints.csv_month_orders.replace('{id}', id).replace('{month}', month).replace('{year}', year)
        await Downloader.download(client, url)
    },
    async downloadMonthRefunds(id, month, year) {
        const url = endpoints.csv_month_refunds.replace('{id}', id).replace('{month}', month).replace('{year}', year)
        await Downloader.download(client, url)
    },
    async downloadMonthSummary(id, month, year) {
        const url = endpoints.csv_month_summary.replace('{id}', id).replace('{month}', month).replace('{year}', year)
        await Downloader.download(client, url)
    },
    async deleteConsolidation(id, consolidation) {
        await client.delete(endpoints.delete.replace('{id}', id).replace('{consolidation}', consolidation))
    },
    async consolidate(id, from, to) {
        await client.post(endpoints.consolidate.replace('{id}', id), {
            date_from: from,
            date_to: to
        })
    },
}
