const ListRole = () => import('./ListRole.vue')
const EditRole = () => import('./EditRole.vue')
const NewRole = () => import('./NewRole.vue')
const TheContainer = () => import('@/containers/TheContainer')

export default [
    {
        path: '/roles',
        name: 'roles',
        component: TheContainer,
        children: [
            {
                path: 'list',
                name: 'roles.list',
                component: ListRole,
                meta: {label: 'List Roles',},
            },
            {
                path: 'new',
                name: 'roles.new',
                component: NewRole,
                meta: {label: 'Create new Role',},
            },
            {
                path: ':id',
                name: 'roles.edit',
                component: EditRole,
                meta: {label: 'Edit Role',},
            },
        ]
    }
]