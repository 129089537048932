export default {
    chat: {
        enable: 'chat.enable',
        disable: 'chat.disable',
        contexts: {
            event: 'event'
        }
    },
    event: {
        published: 'event.published',
        pay_as_you_go: 'event.pay_as_you_go',
        changed: 'event.changed',
    }
}