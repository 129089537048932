import {translate} from "vue-gettext";

const {gettext: $gettext} = translate;

export default {
    paid: 'paid',
    presentation: 'presentation',
    free_public: 'free_public',
    free_private: 'free_private',
    release: 'release',
    custom: 'custom',
    options(release = false, advanced = false) {
        let options = [
            {
                id: this.paid,
                name: $gettext('Media a pagamento')
            },
            {
                id: this.presentation,
                name: $gettext('Gallery di Presentazione')
            },
            {
                id: this.free_public,
                name: $gettext('Media pubblici gratuiti')
            },
            {
                id: this.free_private,
                name: $gettext('Media privati gratuiti')
            }
        ]
        if (release) {
            options.push({
                id: this.release,
                name: $gettext('Release Endurance')
            })
        }
        if (advanced) {
            options.push({
                id: this.custom,
                name: $gettext('Custom')
            })
        }
        return options
    },

    default() {
        return this.paid
    }

}