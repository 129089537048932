import {translate} from 'vue-gettext';

const {gettext: $gettext, pgettext: $pgettext} = translate;

export default class Translate {
    static context(context, key, value) {
        let translated = $pgettext(context, key)
        if (translated !== key)
            return translated
        return value
    }
}