
export const filterAmountType = {
    EQUAL: '=',
    GTE: '>',
    LTE: '<'
}

const defaultCustomFilter = () => ({
    filterCreatedAtRange: {},
    filterPaidAtRange: {},
    filterPaymentMethod: null,
    filterOrderStatus: null,
    filterOrderType: null,
    filterTotalAmountType: filterAmountType.EQUAL,
    filterTotalAmount: null,
    filterAmountNetPhotoServiceType: filterAmountType.EQUAL,
    filterAmountNetPhotoService: null,
})


const state = {
    columnFilterValue: {},
    customFilter: defaultCustomFilter(),
    activePage: 1,
}

export const getters = {
    columnFilterValue: state => state.columnFilterValue,
    activePage: state => state.activePage,
    customFilter: state => state.customFilter,
}

export const mutations = {
    setColumnFilterValue(state, value) {
        state.columnFilterValue = value
    },
    setActivePage(state, value) {
        state.activePage = value
    },
    setCustomFilter(state, value) {
        state.customFilter = value
    },
}

export const actions = service => ({
    updateColumnFilterValue({commit}, value) {
        commit('setColumnFilterValue', value)
    },
    updateActivePage({commit}, value) {
        commit('setActivePage', value)
    },
    updateCustomFilter({commit}, value) {
        if (!value){
          value = defaultCustomFilter()
        }
        commit('setCustomFilter', value)
    },
})

export default orderService => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(orderService)
})

