<template>
  <CModal :title="titleLabel" :show.sync="showModal" :size="size">
    <slot></slot>
    <template #footer>
      <CButton @click="onDismiss" color="secondary">
        {{ cancelButtonLabel }}
      </CButton>
      <CButton @click="onConfirm" color="primary">
        {{ confirmButtonLabel }}
      </CButton>
    </template>
  </CModal>
</template>

<script>


export default {
  name: "PConfirm",

  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: false
    },

    confirmButton: {
      type: String,
      required: false
    },
    cancelButton: {
      type: String,
      required: false
    },
    size: {
      type: String,
      required: false,
    }
  },
  computed: {
    titleLabel() {
      return this.title || this.$pgettext('modal.confirm', 'Confirm')
    },
    confirmButtonLabel() {
      return this.confirmButton || this.$pgettext('modal.confirm', 'Confirm')
    },
    cancelButtonLabel() {
      return this.cancelButton || this.$pgettext('modal.confirm', 'Cancel')
    }
  },
  methods: {
    onConfirm() {
      this.$emit('confirm')
      this.onDismiss()
    },
    onDismiss() {
      this.$emit('update:showModal', false);
      this.$emit('dismiss')
    }
  }

}
</script>


<style scoped>

</style>