import {ApiException} from "@/domain/core/exception/exceptions";
import {emptyListResponse} from "@/domain/core/utils/django";


const state = {
    templates: {...emptyListResponse},
    template: {},
}

export const getters = {
    templates: state => state.templates,
    template: state => state.template,
}


export const mutations = {
    templates(state, templates) {
        state.templates = templates
    },
    template(state, template) {
        state.template = template
    },
    deleteTemplate(state, id) {
        state.templates.results = state.templates.results.filter(template => template.id !== id)
    },
}


export const actions = eventWizardService => ({
    async fetchTemplateList({commit}, {filters, pagination, sorting}) {
        try {
            const templates = await eventWizardService.fetchTemplateList(filters, pagination, sorting)
            commit('templates', templates)
        } catch (e) {
            if (e instanceof ApiException)
                commit('templates', emptyListResponse)
            throw e
        }
    },
    async deleteTemplate({commit}, id) {
        await eventWizardService.deleteTemplate(id)
        commit('deleteTemplate', id)
    },
    async fetchTemplate({commit}, id) {
        const template = await eventWizardService.fetchTemplate(id)
        commit('template', template)
    },
    async updateTemplate({commit}, {id, data}) {
        const template = await eventWizardService.updateTemplate(id, data)
        commit('template', template)
    },
    async createTemplate({commit}, data) {
        const template = await eventWizardService.createTemplate(data)
        commit('template', template)
    },

})

export default eventTemplateService => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(eventTemplateService)
})

