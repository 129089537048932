const state = {
    picaServiceId: null,
    clusterTypeId: null,
    clusterId: null,
    types: null,
    clusters: null,
    media: null,
    tags: null,
    stats: null,
}

export const getters = {
    picaServiceId: state => state.picaServiceId,
    clusterTypeId: state => state.clusterTypeId,
    clusterId: state => state.clusterId,
    types: state => state.types,
    clusters: state => state.clusters,
    media: state => state.media,
    tags: state => state.tags,
    stats: state => state.stats,
    isGenerated: state => state.types && state.types.length > 0
}


export const mutations = {
    setPicaServiceId(state, id) {
        state.picaServiceId = id
    },
    setClusterTypeId(state, id) {
        state.clusterTypeId = id
    },
    setClusterId(state, id) {
        state.clusterId = id
    },
    setTypes(state,types) {
        state.types = types
    },
    setClusters(state, clusters) {
        state.clusters = clusters
    },
    setMedia(state, media) {
        state.media = media
    },
    setTags(state, tags) {
        state.tags = tags
    },
    setStats(state, stats) {
        state.stats = stats
    },
    resetClusters(state) {
        state.clusters = null
        state.clusterTypeId = null
    },
    resetCluster(state) {
        state.clusterId = null
        state.media = null
        state.tags = null
        state.stats = null
    }
}


export const actions = photoMapService => ({
    setPicaServiceId({commit}, id) {
        commit('resetClusters')
        commit('resetCluster')
        commit('setPicaServiceId', id)
        commit('setTypes', null)
    },
    setClusterTypeId({commit}, id) {
        commit('resetClusters')
        commit('resetCluster')
        commit('setClusterTypeId', id)
    },
    setClusterId({commit}, id) {
        commit('resetCluster')
        commit('setClusterId', id)
    },
    async forceGeneration({getters, dispatch, commit}) {
        commit('resetClusters')
        commit('resetCluster')
        await photoMapService.forceGeneration(getters.picaServiceId)
        await dispatch('fetchTypes')
    },
    async fetchTypes({commit,getters}) {
        const types = await photoMapService.fetchTypes(getters.picaServiceId)
        commit('setTypes', types)
    },
    async fetchClusters({commit,getters}) {
        const clusters = await photoMapService.fetchClusters(getters.picaServiceId, getters.clusterTypeId)
        commit('setClusters', clusters)
    },
    async fetchMedia({commit,getters}) {
        const media = await photoMapService.fetchMedia(getters.picaServiceId, getters.clusterId)
        commit('setMedia', media)
    },
    async fetchTags({commit,getters}) {
        const tags = await photoMapService.fetchTags(getters.picaServiceId, getters.clusterId)
        commit('setTags', tags)
    },
    async fetchStats({commit,getters}) {
        const stats = await photoMapService.fetchStats(getters.picaServiceId, getters.clusterId)
        commit('setStats', stats)
    },
    async updateTags({commit,getters}, tags) {
        console.log('id cluster',getters.clusterId)
        await photoMapService.updateTags(getters.picaServiceId, getters.clusterId, tags)
        commit('setTags', tags)
    },
})

export default photoMapService => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(photoMapService)
})

