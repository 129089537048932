<template>
  <div>
    <PFRow :label="$pgettext('event_wizard.gallery.modal.label','Name')" size="12x12">
      <CInput v-model="form.name" :placeholder="$pgettext('event_wizard.gallery.modal.placeholder','Gallery name')"
              @input="onChange"/>
    </PFRow>
    <PFRow size="7x5" :label="$pgettext('event_gallery.label', 'Are video present?')">
      <CSwitch class="mx-1" color="primary" variant="3d" size="sm"
               :checked.sync="form.has_video" @input="onChange"/>
    </PFRow>
    <PFRow size="12x12" :label="$pgettext('event_gallery.label', 'Photo Price')">
      <CompactPriceSelector @update="onUpdatePhotoPrice" release/>
    </PFRow>
    <PFRow size="12x12" :label="$pgettext('event_gallery.label', 'Video Price')" v-if="form.has_video">
      <CompactPriceSelector @update="onUpdateVideoPrice" release/>
    </PFRow>
  </div>
</template>

<script>

import PFRow from "@/domain/core/components/PFRow.vue";
import CompactPriceSelector from "@/domain/eventGallery/tab/priceList/CompactPriceSelector.vue";

export default {
  name: "GalleryRelease",
  components: {CompactPriceSelector, PFRow},
  emits: ['save'],
  data() {
    return {
      form: {
        name: null,
        has_video: false,
        price_media: null,
        price_video: null
      },
    }
  },
  props: {
    params: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    params: {
      handler(val) {
        this.form = {
          name: this.form.name,
          has_video: this.form.has_video,
          price_media: this.form.price_media,
          price_video: this.form.price_video,
          ...val
        }
      },
      deep: true
    }
  },
  mounted() {
    this.form = {
      name: '',
      has_video: false,
      price_media: null,
      price_video: null,
      ...this.params
    }
  },
  methods: {
    onChange() {
      this.$emit('save', this.form);
    }, onUpdatePhotoPrice(data) {
      this.form.price_media = data
      this.onChange()
    }, onUpdateVideoPrice(data) {
      this.form.price_video = data
      this.onChange()
    }
  }
}
</script>
