import {translate} from 'vue-gettext'

const {gettext: $gettext} = translate;
const EventGallery = () => import('@/domain/eventGallery/EventGallery')

export default [
    {
        path: 'gallery/:galleryId',
        redirect: 'gallery/:galleryId/setting',
        name: 'gallery',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        meta: {
            label: $gettext('Gallery'),
            title: 'Gallery'
        },
        children: [
            {
                path: 'detail',
                name: 'eventWizard.gallery.detail',
                component: EventGallery,
                meta: {label: $gettext('Detail'),},
                props: {tab: null}
            },
            {
                path: 'setting',
                name: 'eventWizard.gallery.setting',
                component: EventGallery,
                meta: {label: $gettext('Setting'),},
                props: {tab: 'setting'}
            },
            {
                path: 'logo_on_photo',
                name: 'eventWizard.gallery.logo_on_photo',
                component: EventGallery,
                meta: {label: $gettext('Logo on Photo'),},
                props: {tab: 'logo_on_photo'}
            },
            {
                path: 'browse_photo',
                name: 'eventWizard.gallery.browse_photo',
                component: EventGallery,
                meta: {label: $gettext('Browse Photo'),},
                props: {tab: 'browse_photo'}
            },
            {
                path: 'price_list',
                name: 'eventWizard.gallery.price_list',
                component: EventGallery,
                meta: {label: $gettext('Price List'),},
                props: {tab: 'price_list'}
            },
            {
                path: 'utility',
                name: 'eventWizard.gallery.utility',
                component: EventGallery,
                meta: {label: $gettext('Utility'),},
                props: {tab: 'utility'}
            },
        ]
    }


]




