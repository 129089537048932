const state = {
    regions: null,
    contracts: null,
    categories: null,
    region: null,
    columnFilterValue: {},
    activePage: 1,
}


export const getters = {
    regions: state => state.regions,
    region: state => state.region,
    contracts: state => state.contracts,
    categories: state => state.categories,
    urlSessionParams: () => {
        const memory = sessionStorage.getItem('event_url')
        if (memory) return JSON.parse(memory)
        return {}
    },
    columnFilterValue: state => state.columnFilterValue,
    activePage: state => state.activePage,

}


export const mutations = {
    regions(state, regions) {
        state.regions = regions
    },
    region(state, region) {
        state.region = region
        state.base_plans = null
    },
    contracts(state, contracts) {
        state.contracts = contracts
    },
    categories(state, categories) {
        state.categories = categories
    },
    rememberUrl(state, {region, plan}) {
        if (!region && !plan) {
            sessionStorage.removeItem('event_url')
            return
        }

        const memory = {
            region: region,
            plan: plan
        }
        sessionStorage.setItem('event_url', JSON.stringify(memory))
    },
    setColumnFilterValue(state, value) {
        state.columnFilterValue = value
    },
    setActivePage(state, value) {
        state.activePage = value
    }
}


export const actions = service => ({
    // eslint-disable-next-line no-unused-vars
    async createNewEvent({commit}, data) {
        const newEvent = await service.createNewEvent(data)
        commit('rememberUrl', {})
        return newEvent
    },
    async fetchCreateConfig({commit, getters}) {
        const config = await service.fetchCreateConfig(getters['urlSessionParams'])
        if (config['region']) {
            commit('regions', null)
            commit('region', config['region'])
            commit('categories', config['categories'])
            commit('contracts', config['contracts'])
        } else {
            commit('region', null)
            commit('regions', config['regions'])
            commit('contracts', null)
            commit('categories', null)
        }
    },
    async setRegion({commit}, region) {
        commit('region', region)
    },
    async fetchCategories({commit, getters}) {
        commit('categories', await service.fetchCategories(getters['region'].id))
    },
    rememberUrl({commit}, {region, plan}) {
        commit('rememberUrl', {region, plan})
    },
    updateColumnFilterValue({commit}, value) {
        commit('setColumnFilterValue', value);
    },
    updateActivePage({commit}, value) {
        commit('setActivePage', value);
    }
})

export default eventService => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(eventService)
})

