<template>
  <CModal :show.sync="show" :title="$pgettext('plan.modal.search_plan.title', 'New Event')" centered>
    <template #header>
      <div>
        <div class="d-flex">
          <CIcon name="cipWallet02" size="custom-size" width="20" class="mr-3 icon-circle icon-circle--primary"/>
          <div>
            <h5 class="modal-title" v-translate translate-context="plan.modal.search_plan.title">Select a
              plan</h5>
            <div v-translate translate-context="plan.modal.search_plan">All the plans options will be cloned</div>
          </div>
        </div>
      </div>
      <CButtonClose @click="show = false"/>
    </template>
    <PFRow :label="$pgettext('plan.modal.search_plan.label','Plan')" size="12x12">
      <PSelectKeyValue :options="searchPlan" @input="onSelectPlan" placeholder="Select Plan"/>
    </PFRow>
    <PComponentLoader :loading="loading" v-if="planId">
      <template v-if="plan && plan.settings">
        <h3>{{ plan.name }}</h3>
        <PFRow :label="$pgettext('plan.label','Included')">
          {{ plan.settings.num_photo_included }}
        </PFRow>
        <PFRow :label="$pgettext('plan.label','Price for extra media')">
          {{ plan.settings.extra_photo_price }}
        </PFRow>
        <PFRow :label="$pgettext('plan.label','Max uploadable media')">
          {{ plan.settings.num_max_photo }}
        </PFRow>
        <PFRow :label="$pgettext('plan.label','Max participants (for plan choice)')">
          {{ plan.settings.max_participants }}
        </PFRow>
        <PFRow :label="$pgettext('plan.label','Is Promo')">
          {{ plan.settings.is_promo }}
        </PFRow>
      </template>
    </PComponentLoader>
    <template #footer>
      <CButton color="secondary" size="sm" @click="show = false" v-translate>Close</CButton>
      <PButton
          color="primary"
          v-translate translate-context="'plan.modal.search_plan.actions"
          class="mr-3"
          :disabled="!plan"
          @click="confirm"
      >Confirm
      </PButton>
    </template>
  </CModal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PButton from "@/components/PButton.vue";
import PSelectKeyValue from "@/domain/core/components/PSelectKeyValue.vue";
import PFRow from "@/domain/core/components/PFRow.vue";
import PComponentLoader from "@/domain/core/components/PComponentLoader.vue";

export default {
  name: "PlanModalSelector",
  components: {
    PComponentLoader,
    PFRow,
    PSelectKeyValue,
    PButton,
  },
  emits: ['input'],
  data() {
    return {
      show: false,
      loading: false,
      planId: null,
    }
  },
  computed: {
    ...mapGetters('plan', [
      'plan',
    ]),
  },
  methods: {
    ...mapActions('plan', [
      'searchPlan',
      'fetchPlan'
    ]),
    async open() {
      this.show = true
      this.planId = null
      await this.fetchPlan(null)
    },
    async onSelectPlan(plan) {
      this.planId = plan.value
      this.loading = true
      await this.fetchPlan(this.planId)
      this.loading = false
    },
    async confirm() {
      this.$emit('input',this.plan.settings)
      this.show = false
    }
  }
}
</script>