<template>
  <div>
    <UploadErrorAlert
      v-if="alreadyPresent.length > 0"
      :files="alreadyPresent"
      color="warning"
      icon="cipAlertTriangle"
      :message="$pgettext('globalUploader.errors', 'already present')"
    />
    <UploadErrorAlert
      v-if="invalidFile.length > 0"
      :files="invalidFile"
      color="danger"
      icon="cipXCircle"
      :message="$pgettext('globalUploader.errors', 'invalid files')"
    />
    <UploadErrorAlert
      v-if="uploadLimit.length > 0"
      :files="uploadLimit"
      color="info"
      icon="cipInfo"
      :message="$pgettext('globalUploader.errors', 'upload limit reached')"
    />
    <UploadErrorAlert
      v-if="userCancelled.length > 0"
      :files="userCancelled"
      color="info"
      icon="cipInfo"
      :message="$pgettext('globalUploader.errors', 'files stopped by the user')"
    />
  </div>
</template>

<script>
import uploadErrors from "@/domain/core/constant/uploadErrors";
import UploadErrorAlert from "@/domain/uploader/components/UploadErrorAlert.vue";


export default {
  name: 'UploadErrors',
  components: {UploadErrorAlert},
  props: {
    files: {
      type: Array,
      required: true
    }
  },
  computed: {
    alreadyPresent() {
      return this.files.filter(file => file.error === uploadErrors.alreadyPresent)
    },
    invalidFile() {
      return this.files.filter(file => file.error === uploadErrors.invalidFile)
    },
    uploadLimit() {
      return this.files.filter(file => file.error === uploadErrors.uploadLimit)
    },
    userCancelled() {
      return this.files.filter(file => file.error === uploadErrors.userCancelled)
    },
    uploadProblem() {
      return this.files.filter(file => file.error === uploadErrors.uploadProblem)
    },
    uploadErrors() {
      return uploadErrors
    }
  }
}
</script>