import {ApiException} from "@/domain/core/exception/exceptions";
import {emptyListResponse} from "@/domain/core/utils/django";

const state = {
    plans: {...emptyListResponse},
    plan: {},
}

export const getters = {
    plans: state => state.plans,
    plan: state => state.plan,
}


export const mutations = {
    plans(state, plans) {
        state.plans = plans
    },
    plan(state, plan) {
        state.plan = plan
    },
    deletePlan(state, id) {
        state.plans.results = state.plans.results.filter(plan => plan.id !== id)
    }
}


export const actions = service => ({
    async fetchPlanList({commit}, {filters, pagination, sorting}) {
        try {
            const plans = await service.fetchPlanList(filters, pagination, sorting)
            commit('plans', plans)
        } catch (e) {
            if (e instanceof ApiException)
                commit('plans', emptyListResponse)
            throw e
        }
    },
    async deletePlan({commit}, id) {
        await service.deletePlan(id)
        commit('deletePlan', id)
    },
    async fetchPlan({commit}, id) {
        if (id === null) {
            commit('plan', {})
            return
        }
        const plan = await service.fetchPlan(id)
        commit('plan', plan)
    },
    async updatePlan({commit}, {id, data}) {
        const plan = await service.updatePlan(id, data)
        commit('plan', plan)
    },
    async createPlan({commit}, data) {
        const plan = await service.createPlan(data)
        commit('plan', plan)
    },
    // eslint-disable-next-line no-unused-vars
    async searchPlan({commit}, query) {
        return await service.searchPlan(query)
    },
})

export default service => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(service)
})

