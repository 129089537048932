import ApiClient from "@/domain/core/utils/apiClient";
import client_shortener from "@/services/clients/client_shortener";
const client = new ApiClient(client_shortener)

const endpoints = {
    list: '/admin/list/', // GET
    create: '/admin/url/', // POST
    detail: '/admin/url/{id}/', // GET
    update: '/admin/url/{id}/', // PUT
    delete: '/admin/url/{id}/', // DELETE
    free_url: '/admin/free_url/', // GET
}

export default {
    async fetchList(filters, next = null) {
        const params = {
            limit: 20,
            next: next ?? null,
            id: filters.id ?? null,
            long: filters.long_url ?? null,
        }
        const result = await client.get(endpoints.list, params)
        return {
            results: result.data,
            pagination: {
                num_per_page: params.limit,
                next: result.next,
            }
        }
    },
     async create(data) {
        return await client.post(endpoints.create, data)
    },
    async get(id) {
        return await client.get(endpoints.detail.replace('{id}',id),)
    },
    async delete(id) {
        await client.delete(endpoints.delete.replace('{id}',id))
    },
    async update(id, data) {
        return await client.put(endpoints.update.replace('{id}',id), data)
    },
    async getFreeUrl() {
        const result = await client.get(endpoints.free_url)
        return result.short_url
    },
}
