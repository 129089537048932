const state = {
    regions: [],
    region: null,
    categories: [],
}

export const getters = {
    regions: state => state.regions,
    region: state => state.region,
    categories: state => state.categories,
}


export const mutations = {
    regions(state, regions) {
        state.regions = regions
    },
    region(state, region) {
        state.region = region
    },
    categories(state, categories) {
        state.categories = categories
    },

}


export const actions = service => ({
    async fetchRegions({commit}) {
        commit('regions', await service.fetchRegions())
    },
    async fetchRegion({commit}, idRegion) {
        commit('region', await service.fetchRegion(idRegion))
    },
    async fetchCategories({commit}, idRegion) {
        commit('categories', await service.fetchCategories(idRegion))
    },
})

export default service => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(service)
})

