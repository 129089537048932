<template>
  <PSelectKeyValue
      :options="currencies"
      :value="value"
      label="label"
      track-by="value"
      :placeholder="$pgettext('core.label','Select Currency')"
      @input="onInput"
  />
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PSelectKeyValue from "@/domain/core/components/PSelectKeyValue.vue";

export default {
  name: "CurrencySelect",
  components: {PSelectKeyValue},
  data() {
    return {
      loading: true,
    }
  },
  props: {
    value: {
      type: String,
      required: false,
    },
  },
  emits: ['update:value'],
  async mounted() {
    await this.fetchCurrencies()
    this.loading = false
  },

  computed: {
    ...mapGetters('core', [
      'currencies'
    ]),
  },

  methods: {
    ...mapActions('core', [
      'fetchCurrencies',
    ]),
    onInput(val) {
      this.$emit('input', val.value)
    }
  },

}
</script>