import {translate} from 'vue-gettext'

const {gettext: $gettext} = translate

export default [
    {
        _name: 'CSidebarNavTitle',
        _children: [$gettext('My photo service')],
        perms: ['dashboard.view_feature_photoservice_photographers']
    },
    {
        _name: 'CSidebarNavItem',
        name: $gettext('Photographers'),
        to: '/my-photo-service/photographers',
        icon: 'cip-photographer',
        perms: ['dashboard.view_feature_photoservice_photographers']
    },
]