const MediaSearch = () => import('@/views_pica/photos/MediaSearch.vue')
const EventTagging = () => import('@/views_pica/events/EventTagging.vue')


export default [
    {
        path: 'search-photos',
        name: 'MediaSearch',
        component: MediaSearch,
        meta: {
            title: 'Search photos',
            label: 'Search photos',
            perms: ['dashboard.view_feature_search_photos']
        }
    },
    {
        path: 'tagging',
        name: 'EventTagging',
        component: EventTagging,
        meta: {
            title: 'Manual tag',
            label: 'Manual tag',
            perms: ['dashboard.view_feature_tag_photos']
        }
    },
]