import TheContainer from "@/containers/TheContainer.vue";
import Invoices from "@/domain/invoice/Invoices.vue";

export default [
    {
        path: '/invoices',
        name: 'invoice',
        component: TheContainer,
        meta: {perms: ['orders.invoices.list']},
        children: [
            {
                path: 'invoice',
                name: 'invoice.invoice',
                component: Invoices,
                meta: {label: 'Invoices',},
                props: {tab: 'invoice'}
            },
             {
                path: 'credit_note',
                name: 'invoice.credit_note',
                component: Invoices,
                meta: {label: 'Credit Notes',},
                props: {tab: 'credit_note'}
            },
        ]
    }
]