// colorGenerator.js

class ColorGenerator {
  constructor(name) {
    this.name = name;
    this.colors = this._generateColors();
  }

  _generateColors() {
    const charCode = this.name.charCodeAt(0);
    const pastelHexColor = this._getColor(charCode);
    const isBright = this._luminance(pastelHexColor) > 0.7;
    const textColor = isBright ? '#000000' : '#FFFFFF';

    return { background: pastelHexColor, text: textColor };
  }

  _luminance(hexColor) {
    const rgb = this._hexToRgb(hexColor);
    return (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255;
  }

  _hexToRgb(hexColor) {
    const bigint = parseInt(hexColor.substring(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
  }

  _getColor(value) {
    const baseColor = (value * 123456 % 0xFFFFFF).toString(16);
    const saturation = 0.6; // Set the desired saturation level, 1 for fully saturated colors

    const hsl = this._hexToHsl(`#${baseColor}`);
    hsl[1] *= saturation;

    return this._hslToHex(hsl);
  }

  _hexToHsl(hexColor) {
    const rgb = this._hexToRgb(hexColor);
    const r = rgb.r / 255;
    const g = rgb.g / 255;
    const b = rgb.b / 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);

    let h, s, l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
      }
      h /= 6;
    }

    return [h, s, l];
  }

  _hslToHex([h, s, l]) {
    const hueToRgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    const r = hueToRgb(p, q, h + 1 / 3);
    const g = hueToRgb(p, q, h);
    const b = hueToRgb(p, q, h - 1 / 3);

    return `#${Math.round(r * 255).toString(16)}${Math.round(g * 255).toString(16)}${Math.round(b * 255).toString(16)}`;
  }
}

export default ColorGenerator;