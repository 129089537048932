import {freeSet} from "@coreui/icons";
import {logo} from './logo'
import {logoMinimized} from './logo-minimized'

import {cipIcons} from './cipIcons'

export const iconsSet = {
    logo,
    logoMinimized,
    ...freeSet,
    ...cipIcons
};