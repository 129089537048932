import moment from 'moment'
import store from "@/store";

export default class Filters {
    static formatDate(value, format = 'LL', timezone) {
        if (value) {
            if (timezone)
                return moment.tz(value, timezone).format(format)
            return moment(value).format(format)
        }
    }

    static formatDateTimeTZ(value, timezone, format = 'LLL Z') {
        return moment.tz(value, timezone).format(format)
    }

    static isoStartOfDayFromStringDate(value, timezone) {
        console.assert(value.length === 10, 'value must be YYYY-MM-DD')
        return moment.tz(value + "T00:00:00.000", timezone).toISOString()
    }

    static isoEndOfDayFromStringDate(value, timezone) {
        console.assert(value.length === 10, 'value must be YYYY-MM-DD')
        return moment.tz(value + "T23:59:59.999", timezone).toISOString()
    }

    static formatDateTime(value, format = 'LLL', timezone) {
        if (!value) return ''
        if (!timezone) timezone = moment.tz.guess()
        return moment(value).tz(timezone).format(format)
    }

    static truncate(text, length, clamp) {
        const clampStr = clamp || '...'
        if (text.length > length)
            return text.slice(0, length) + clampStr
        return text
    }


    static formatSize(size, optimalSize) {
        if (size === undefined || size === null )
            return ''
        if (size < 0)
            size = 0

        if (!optimalSize)
            optimalSize = Filters.optimalSizeMeasure(size)

        if (optimalSize === 'TB')
            return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB';
        else if (optimalSize === 'GB')
            return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB';
        else if (optimalSize === 'MB')
            return (size / 1024 / 1024).toFixed(2) + ' MB';
        else if (optimalSize === 'KB')
            return (size / 1024).toFixed(2) + ' KB';
        return size.toFixed(2) + ' B';
    }

    static optimalSizeMeasure(size) {
        if (size > 1024 * 1024 * 1024 * 1024)
            return 'TB';
        else if (size > 1024 * 1024 * 1024)
            return 'GB';
        else if (size > 1024 * 1024)
            return 'MB';
        else if (size > 1024)
            return 'KB';
        return ' B';
    }

    static formatDuration(duration, format = 'mm:ss') {
        const durationObject = moment.duration(duration, 'seconds')
        return moment.utc(durationObject.asMilliseconds()).format(format)
    }

    static currency(value, currency) {
        if (!value) value = 0.0
        if (currency === undefined) {
            console.log('currency is undefined for ' + value)
            return ''
        }
        try {
            return new Intl.NumberFormat(store.getters['login/userLanguage'], {
                style: 'currency',
                currency: currency
            }).format(value)
        } catch (e) {
            return ''
        }
    }

    static number(value, fractionDigits = 1) {
        return new Intl.NumberFormat(store.getters['login/userLanguage'], {
            maximumFractionDigits: fractionDigits
        }).format(value)
    }

    static upper(value) {
        if (!value) return ''
        return value.toString().toUpperCase()
    }

    static percent(num, denominator = null, fractionDigits = 1) {
        if (denominator !== null) {
            if (denominator === 0) return 'N/A'
            num = num / denominator
        }
        return new Intl.NumberFormat(store.getters['login/userLanguage'], {
            style: 'percent',
            maximumFractionDigits: fractionDigits
        }).format(num)
    }
}

