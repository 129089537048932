export default [
    "24 24",
    '<g clip-path="url(#clip0_801_2182)">\n' +
    '<path fill="none" d="M3 6H3.01919M3.01919 6H9M3.01919 6C3 6.31438 3 6.70191 3 7.2002V16.8002C3 17.9203 3 18.4796 3.21799 18.9074C3.40973 19.2837 3.71547 19.5905 4.0918 19.7822C4.51921 20 5.079 20 6.19694 20L9 20M3.01919 6C3.04314 5.60768 3.09697 5.3293 3.21799 5.0918C3.40973 4.71547 3.71547 4.40973 4.0918 4.21799C4.51962 4 5.08009 4 6.2002 4H17.8002C18.9203 4 19.4796 4 19.9074 4.21799C20.2837 4.40973 20.5905 4.71547 20.7822 5.0918C20.9032 5.3293 20.957 5.60768 20.9809 6M9 6H20.9809M9 6V20M20.9809 6H21M20.9809 6C21 6.31368 21 6.70021 21 7.19691L21 16.8031C21 17.921 21 18.48 20.7822 18.9074C20.5905 19.2837 20.2837 19.5905 19.9074 19.7822C19.48 20 18.921 20 17.8031 20H9" class="stroke-over " stroke="var(--ci-primary-color, currentColor)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '</g>\n' +
    '<defs>\n' +
    '<clipPath id="clip0_801_2182">\n' +
    '<rect width="24" height="24" fill="none"/>\n' +
    '</clipPath>\n' +
    '</defs>\n' +
    '</svg>'
]

