const state = {
    picaServiceId: null,
    ordersSummary: null,
    checkinsSummary: null,
    mediaSummary: null,
    engagementSummary: null,
}

export const getters = {
    picaServiceId: state => state.picaServiceId,
    ordersSummary: state => state.ordersSummary,
    checkinsSummary: state => state.checkinsSummary,
    mediaSummary: state => state.mediaSummary,
    engagementSummary: state => state.engagementSummary,
}


export const mutations = {
    picaServiceId(state, picaServiceId) {
        if (state.picaServiceId === picaServiceId) return
        state.picaServiceId = picaServiceId
        state.ordersSummary = null
        state.checkinsSummary = null
        state.mediaSummary = null
        state.engagementSummary = null
    },
    ordersSummary(state, ordersSummary) {
        state.ordersSummary = ordersSummary
    },
    checkinsSummary(state, checkinsSummary) {
        state.checkinsSummary = checkinsSummary
    },
    mediaSummary(state, mediaSummary) {
        state.mediaSummary = mediaSummary
    },
    engagementSummary(state, engagementSummary) {
        state.engagementSummary = engagementSummary
    },
}


export const actions = service => ({
    setPicaServiceId({commit}, picaServiceId) {
        commit('picaServiceId', picaServiceId)
    },
    async fetchOrdersSummary({commit, getters}) {
        if (getters['ordersSummary'] !== null) return
        commit('ordersSummary', await service.fetchOrderSummary(getters['picaServiceId']))
    },
    async fetchSummaryCheckins({commit, getters}) {
        if (getters['checkinsSummary'] !== null) return
        commit('checkinsSummary', await service.fetchCheckinsSummary(getters['picaServiceId']))
    },
    async fetchSummaryMedia({commit, getters}) {
        if (getters['mediaSummary'] !== null) return
        commit('mediaSummary', await service.fetchMediaSummary(getters['picaServiceId']))
    },
    async fetchSummaryEngagement({commit, getters}) {
        if (getters['engagementSummary'] !== null) return
        commit('engagementSummary', await service.fetchEngagementSummary(getters['picaServiceId']))
    },
})

export default service => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(service)
})

