<template>
  <div v-if="refund">
    <EditReasonModal :refund="refund" ref="editReasonModal"/>
    <h1>
      <translate translate-context="refund" :translate-params="{id: refund.id}">Refund #%{id}</translate>
    </h1>
    <CContainer fluid class="grid-stripped">
      <PFRow :label="$pgettext('refund.label','Order Item ID')">
        {{refund.order_item}}
      </PFRow>
      <PFRow :label="$pgettext('refund.label','Amount')">
        {{refund.amount}}
      </PFRow>
      <PFRow :label="$pgettext('refund.label','Reason')">
        <CButton size="sm" variant="ghost" color="primary" class="mr-2"
                 @click="editReason"
                 v-if="hasPerm('orders.refunds.update_reason')"
        >
          <CIcon name="cipPencil" size="sm" />
        </CButton>
        {{ refund.reason }}
      </PFRow>
    </CContainer>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PFRow from "@/domain/core/components/PFRow.vue";
import EditReasonModal from "@/domain/refund/components/EditReasonModal.vue";

export default {
  name: 'RefundDetail',
  components: {EditReasonModal, PFRow},
  computed: {
    ...mapGetters('refund', [
      'refund',
    ]),
  },
  async mounted() {
    await this.fetchRefund(this.$route.params.id)
  },
  methods: {
    ...mapActions('refund', [
      'fetchRefund',
    ]),
    editReason() {
      this.$refs.editReasonModal.open()
    }
  }
}
</script>

<style scoped>

</style>