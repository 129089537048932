<template>
  <div>
    <transition name="fade" v-if="loading">
      <div class="component-loader" :class="{'full-screen': fullScreen}">
        <CSpinner :color="spinnerColor" :size="spinnerSize"/>
      </div>
    </transition>
    <slot v-else/>
  </div>
</template>

<script>
export default {
  name: "PComponentLoader",
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    fullScreen: {
      type: Boolean,
      default: true
    },
    spinnerColor: {
      type: String,
      default: 'primary'
    },
    spinnerSize: {
      type: String,
      default: 'lg'
    }
  }
}
</script>
