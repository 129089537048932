import Compressor from "compressorjs";
import {translate} from 'vue-gettext';
import ApiClient from "@/domain/core/utils/apiClient";
import s3_client from "@/services/clients/s3_client";

// import WebkitFileExtractor from "@/domain/core/utils/files/webkit";
import FileSystemExtractor from "@/domain/core/utils/files/filesystem";
import BasicExtractor from "@/domain/core/utils/files/basic";

const upload_client = new ApiClient(s3_client)

const {gettext: $gettext, gettextInterpolate: $gettextInterpolate} = translate;


export default {
    async getFilesFromDropEvent(e) {
        if (FileSystemExtractor.isSupported())
            return FileSystemExtractor.extract([...e.dataTransfer.items])
        // if (WebkitFileExtractor.isSupported())
        //     return WebkitFileExtractor.extract([...e.dataTransfer.items])
        return BasicExtractor.extract(e.dataTransfer.files)
    },
    compress(file, options = {}) {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: 0.9,
                convertSize: 8000000, // 8MB
                mimeType: 'image/jpeg',
                ...options,
                success: (result) => {
                    resolve(result)
                },
                error: () => {
                    reject($gettext('Invalid image'))
                }
            })
        })
    }, formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes'

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    }, async validateFileExtension(allowed_extensions, file) {
        if (allowed_extensions.length <= 0) return true

        const extension = file.name.split('.').pop().toLowerCase()
        if (allowed_extensions.includes(extension)) return true

        throw new Error($gettextInterpolate(
            $gettext('Incorrect file format (allowed: %{acceptedFormats}) - %{fileType}'),
            {acceptedFormats: allowed_extensions, fileType: extension}
        ))
    }, validateFileSize(max_file_size, file) {
        if (max_file_size === null) return true
        if (file.size <= max_file_size) return true
        throw new Error($gettextInterpolate(
            $gettext('File size exceeds the limit of %{maxFileSize} - %{fileSize}'),
            {maxFileSize: this.formatBytes(max_file_size), fileSize: this.formatBytes(file.size)}
        ))
    }, async validateImageSize(sizes, file) {
        if (sizes.length <= 0) return false
        if (!this.isImage(file)) return false

        const img = await this.getImage(file)

        if (sizes.minWidth && img.width < sizes.minWidth) {
            throw new Error($gettextInterpolate(
                $gettext('Min width of the image must be %{minWidth} px - %{width} px'),
                {minWidth: sizes.minWidth, width: img.width})
            )
        }
        if (sizes.minHeight && img.height < sizes.minHeight) {
            throw new Error($gettextInterpolate(
                $gettext('Min height of the image must be %{minHeight} px - %{height} px'),
                {minHeight: sizes.minHeight, height: img.height}
            ))
        }
        if (sizes.maxWidth && img.width < sizes.maxWidth) {
            throw new Error($gettextInterpolate(
                $gettext('Max width of the image must be %{maxWidth} px - %{width} px'),
                {maxWidth: sizes.maxWidth, width: img.width}
            ))
        }
        if (sizes.maxHeight && img.height < sizes.maxHeight) {
            throw new Error($gettextInterpolate(
                $gettext('Max height of the image must be %{maxHeight} px - %{height} px'),
                {maxHeight: sizes.maxHeight, height: img.height}
            ))
        }
        if (sizes.aspectRatio && img.width / img.height !== sizes.aspectRatio) {
            throw new Error($gettextInterpolate(
                $gettext('Wrong image proportions'),
                {aspectRatio: sizes.aspectRatio, ratio: Math.round((img.width / img.height) * 10) / 10}
            ))
        }
        return true
    }, isVideoPlayable(file) {
        return new Promise((resolve, reject) => {
            let obj = document.createElement("video");

            if (file.type !== '' && obj.canPlayType(file.type) !== 'maybe') {
                return reject($gettextInterpolate(
                    $gettext('Video format not playable in the browser %{format}'),
                    {format: file.type}
                ))
            }

            obj.onloadeddata = () => resolve(true)
            obj.onerror = () => reject($gettext('Unable to decode video'))

            // We are waiting for the video to load for 10 seconds, if it fails to load, we can't be sure if it's playable or not
            // to avoid problem with user with slow-pc we decided to accept the file as valid, this can cause problem down the road
            // since we tell the user that the file is ok and then it could be deleted after the upload
            setTimeout(() => resolve(true), 10000)

            obj.setAttribute('src', window.URL.createObjectURL(file))

        })
    }, async uploadToS3({file, intent, onProgress, signal}) {
        const fd = new FormData()
        for (let key in intent.fields) {
            fd.append(key, intent.fields[key])
        }

        fd.append('file', file, file.name)
        return upload_client.upload(intent.url, fd, onProgress, signal)
    }, isVideo(file) {
        return VIDEO_EXTENSIONS.indexOf(this._getExtension(file)) >= 0
    }, isImage(file) {
        return IMAGE_EXTENSIONS.indexOf(this._getExtension(file)) >= 0
    }, _getExtension(file) {
        if (file instanceof File)
            return file.name.split('.').pop().toLowerCase()
        else {
            return file.split('?').shift().split('.').pop().toLowerCase()
        }
    }, async validate(file, rules) {
        for (let rule of rules['all'])
            await rule(file)

        if (this.isImage(file)) {
            for (let rule of rules['image'])
                await rule(file)
        } else if (this.isVideo(file)) {
            for (let rule of rules['video'])
                await rule(file)
        } else {
            for (let rule of rules['generic'])
                await rule(file)
        }
    }, getValidationRules(params) {
        let rules = {
            'all': [],
            'image': [],
            'video': [],
            'generic': []
        }
        if (params.allowed_extensions && params.allowed_extensions.length) rules['all'].push(this.validateFileExtension.bind(this, params.allowed_extensions))
        if (params.max_generic_size) rules['generic'].push(this.validateFileSize.bind(this, params.max_generic_size))

        if (params.max_image_size) rules['image'].push(this.validateFileSize.bind(this, params.max_image_size))
        if (params.input_image_size) rules['image'].push(this.validateImageSize.bind(this, params.input_image_size))

        rules['video'].push(this.isVideoPlayable.bind(this))
        if (params.max_video_size) rules['video'].push(this.validateFileSize.bind(this, params.max_video_size))


        return rules
    },
    async getResolution(file) {
        if (!this.isImage(file)) throw new Error($gettext('File is not an image'))
        const image = await this.getImage(file)
        return {
            width: image.naturalWidth,
            height: image.naturalHeight,
            resolution: `${image.naturalWidth}x${image.naturalHeight}`,
            mp: (Math.round((image.naturalWidth * image.naturalHeight) / 100000)/10)+'MP'
        }
    },
    async getImage(file, timeout = 500) {
        if (file.image && file.image.complete) return file.image
        return new Promise((resolve, reject) => {
            if (!file.url) file.url = window.URL.createObjectURL(file)
            if (!file.image) {
                file.image = new Image()
                file.image.src = file.url
            }
            file.image.onload = () => resolve(file.image)
            file.image.onerror = () => reject($gettext('Unable to decode image'))
            if (timeout > 0) setTimeout(() => reject($gettext('Unable to decode image - timeout')), 5000)
        })
    },
    forceDownload(url, fileName) {
        if (!fileName) fileName = url.split('/').pop().split('?').shift()
        const downloadLink = document.createElement("a");
        downloadLink.href = url
        downloadLink.download = fileName
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
    }
}

const VIDEO_EXTENSIONS = ['mp4', 'avi', 'mkv', 'mov', 'm4v', 'flv', 'wmv', 'webm']
const IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'bmp', 'webp']