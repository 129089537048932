import {translate} from 'vue-gettext'

const {gettext: $gettext} = translate;
const ListVerticalConfiguration = () => import('./ListVerticalConfiguration.vue')
const EditVerticalConfiguration = () => import('./EditVerticalConfiguration.vue')
const NewVerticalConfiguration = () => import('./NewVerticalConfiguration.vue')
const TheContainer = () => import('@/containers/TheContainer')

export default [
    {
        path: '/vertical',
        name: 'verticalConfiguration',
        component: TheContainer,
        meta: {perms: ['admin.vertical.list']},
        children: [
            {
                path: 'list',
                name: 'verticalConfiguration.list',
                component: ListVerticalConfiguration,
                meta: {label: $gettext('Vertical Configuration'),},
            },
            {
                path: 'new',
                name: 'verticalConfiguration.new',
                component: NewVerticalConfiguration,
                meta: {label: $gettext('New Vertical Configuration'),},
            },
            {
                path: ':id',
                name: 'verticalConfiguration.edit',
                component: EditVerticalConfiguration,
                meta: {label: $gettext('Edit Vertical Configuration'),},
            },
        ]
    }
]