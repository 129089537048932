<template>
    <div class="c-app">
        <TheSidebar :nav="nav"/>
        <CWrapper>
            <TheHeader/>
            <Notifier/>
            <div class="c-body">
                <main class="c-main">
                    <CContainer fluid>
                        <router-view></router-view>
                    </CContainer>
                </main>
<!--                <TheFooter/>-->
            </div>
        </CWrapper>
    </div>
</template>

<script>
    import TheSidebar from './TheSidebar'
    import TheHeader from './TheHeader'
    import Notifier from "../components/Notifier"
    import navDeafault from './_nav'

    export default {
        name: 'TheContainer',
        components: {
            TheSidebar,
            TheHeader,
            Notifier
        },
      created() {
          this.nav = navDeafault
      }
    }
</script>

