const ListEventTemplate = () => import('./ListEventTemplate.vue')
const EditEventTemplate = () => import('./EditEventTemplate.vue')
const NewEventTemplate = () => import('./NewEventTemplate.vue')
const TheContainer = () => import('@/containers/TheContainer')

export default [
    {
        path: '/template',
        name: 'eventTemplate',
        component: TheContainer,
        meta: {perms: ['admin.template.list']},
        children: [
            {
                path: 'list',
                name: 'eventTemplate.list',
                component: ListEventTemplate,
                meta: {label: 'List Event Templates',},
            },
            {
                path: 'new',
                name: 'eventTemplate.new',
                component: NewEventTemplate,
                meta: {label: 'Create new Event Template',},
            },
            {
                path: ':id',
                name: 'eventTemplate.edit',
                component: EditEventTemplate,
                meta: {label: 'Edit Event Template',},
            },
        ]
    }
]