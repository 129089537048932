import PicaServiceService from "../../services/pica_service.service"
import moment from "moment"

const state = {
    picaService: null,
    picacodeRelease: null,
}

const RESORT_CATEGORY_ID = 24
const IGD_ID = 7

const getters = {

    picaServiceId: state => state.picaService && state.picaService.id ? state.picaService.id : null,
    eventCurrency: state => state.picaService && state.picaService.currency ? state.picaService.currency : null,
    isInEvent: state => state.picaService !== null,
    currentEventName: state => state.picaService ? state.picaService.event.name : null,
    currentEventSubtitle: state => state.picaService ? state.picaService.event.photo_service : null,
    currentEventStartDate: state => state.picaService ? state.picaService.event.start_at : null,
    currentEventCategoryId: state => state.picaService ? state.picaService.event.category.id : null,
    thirdLevelDomain: state => state.picaService && state.picaService.event_owner ? state.picaService.event_owner.third_level_domain : null,
    codec: state => state.picaService ? state.picaService.codec : null,
    usesFaceRecognition: state => state.picaService ? state.picaService.codec.is_face_recognition : null,
    isPromo: state => state.picaService ? state.picaService.is_promo : null,
    isUserStatsViewer: state => state.picaService ? state.picaService.is_user_stats_viewer : null,
    hasMediaOriginal: state => state.picaService ? state.picaService.has_media_original : null,
    allowsUploadFromMobile: state => state.picaService ? !state.picaService.is_tagging_completed && !state.picaService.is_upload_completed && state.picaService.allows_upload_from_mobile : null,
    allowsUploadHd: state => state.picaService ? !state.picaService.is_tagging_completed && state.picaService.allows_upload_hd : false,
    allowVideoUpload: state => state.picaService ? state.picaService.allows_upload_video : null,
    isEventStarted: state => Date.parse(state.picaService.event.start_at) < Date.now(),
    isMagicTagEnabled: state => state.picaService ? state.picaService.is_magictag_enabled : null,
    isDraft: state => state.picaService ? state.picaService.event.is_draft : null,
    isResort: state => state.picaService ? state.picaService.event.category.id === RESORT_CATEGORY_ID : false,
    isOwnerIGD: state => state.picaService && state.picaService.event_owner ? state.picaService.event_owner.id === IGD_ID : false,
    resortCategoryId: state => RESORT_CATEGORY_ID,
    timeZone: state => state.picaService ? state.picaService.event.timezone : null,
    industry: state => state.picaService ? state.picaService.event.category.industry : null,
    askFaceValidityStart:  state => state.picaService ? state.picaService.checkin_ask_face_validity_start : false,
    askFaceValidityEnd:  state => state.picaService ? state.picaService.checkin_ask_face_validity_end : false,
    thirdPartyTool: state => state.picaService ? state.picaService.third_party_tool : null,
    requiresPicaCode: state => state.picaService ? state.picaService.codec.is_face_recognition : null,
    assignsTransactionCostsToPica: state => state.picaService ? state.picaService.assigns_transaction_costs_to_pica : null,
    isMultyDay: state => state.picaService ? ['only-end','start-end'].includes(state.picaService.checkin_stay_mode) : null,
}

const mutations = {
    SET_PICASERVICE(state, {picaService, partialUpdate = false}) {
        // for compatibility with the datetime widget
        if (picaService.event && picaService.event.start_at !== null && picaService.event.start_at) {
            picaService.event.start_at = moment(picaService.event.start_at).local().format('YYYY-MM-DD HH:mm')
        }
        if (picaService.event && picaService.event.end_at !== null && picaService.event.end_at) {
            picaService.event.end_at = moment(picaService.event.end_at).local().format('YYYY-MM-DD HH:mm')
        }
        if (picaService.event && picaService.hd_available_from !== null && picaService.hd_available_from) {
            picaService.hd_available_from = moment(picaService.hd_available_from).local().format('YYYY-MM-DD HH:mm')
        }

        if (!partialUpdate || state.picaService === null) {
            state.picaService = picaService
        } else {
            state.picaService = {...state.picaService, ...picaService};
        }
    },

    UNSET_PICASERVICE(state) {
        state.picaService = null
    },

    SET_PICACODE_RELEASE(state, {picacodeRelease, partialUpdate = false}) {
        if (!partialUpdate || state.picacodeRelease === null) {
            state.picacodeRelease = picacodeRelease
        } else {
            state.picacodeRelease = {...state.picacodeRelease, ...picacodeRelease};
        }
    },

    UNSET_PICACODE_RELEASE(state) {
        state.picacodeRelease = null
    },


    SET_UNLOCKED_STEP(state, unlocked_step) {
        state.unlockedStep = unlocked_step
    },

    RESET(state) {
        state.picaService = null
        state.picacodeRelease = null
    },

    setAsPublished(state) {
        state.picaService.event.is_draft = false
    }
}

const actions = {
    fetchPicaService({getters, commit}, picaServiceId) {
        if (picaServiceId === undefined) {
            picaServiceId = getters.picaServiceId
        }
        return PicaServiceService.getPicaService(picaServiceId)
            .then((response) => {
                // console.log('Fetch PicaService')
                // console.info(response.data)
                commit('SET_PICASERVICE', {picaService: response.data})
            })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
