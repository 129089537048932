<template>
  <div>
    <PFRow :label="$pgettext('event_wizard.gallery.modal.label','Name')" size="12x12">
      <CInput v-model="form.name" :placeholder="$pgettext('event_wizard.gallery.modal.placeholder','Gallery name')" @input="onChange"/>
    </PFRow>
    <PFRow size="7x5" :label="$pgettext('event_gallery.label', 'Are video available?')">
      <CSwitch class="mx-1" color="primary" variant="3d" size="sm"
               :checked.sync="form.has_video"  @input="onChange"/>
    </PFRow>
    <PFRow size="12x12" :label="$pgettext('event_gallery.label', 'Photo Price')">
      <CompactPriceSelector @update="onUpdatePhotoPrice"/>
    </PFRow>
    <PFRow size="12x12" :label="$pgettext('event_gallery.label', 'Video Price')" v-if="form.has_video">
      <CompactPriceSelector @update="onUpdateVideoPrice"/>
    </PFRow>
  </div>
</template>

<script>

import PFRow from "@/domain/core/components/PFRow.vue";
import CompactPriceSelector from "@/domain/eventGallery/tab/priceList/CompactPriceSelector.vue";

export default {
  name: "GalleryPaid",
  components: {CompactPriceSelector, PFRow},
  emits: ['save'],
  data() {
    return {
      form: {
        name: null,
        has_video: false,
        price_media: null,
        price_video: null
      },
    }
  },
  methods: {
    onChange() {
      this.$emit('save', this.form);
    }, onUpdatePhotoPrice(data) {
      this.form.price_media = data
      this.onChange()
    }, onUpdateVideoPrice(data) {
      this.form.price_video = data
      this.onChange()
    }
  }
}
</script>
