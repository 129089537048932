<template>
  <CBreadcrumb v-bind="props">
    <slot></slot>
  </CBreadcrumb>
</template>

<script>
export default {
  name: 'PBreadcrumbRouter',
  props: {
    addClasses: [String, Array, Object],
    addLinkClasses: [String, Array, Object],
    addLastItemClasses: [String, Array, Object]
  },
  computed: {
    items() {

      const routes = this.$route.matched.filter(route => {
        const isDefaultPath = route.parent && route.path === route.parent.path + '/'
        return (!isDefaultPath && route.name !== 'dashboard') && (route.name || (route.meta && route.meta.label))
      })
      return routes.map(route => {
        const meta = route.meta || {}
        const to = route
        let text
        if (route.name === 'eventIndex') {
          text = this.$store.getters['event/currentEventName']
        } else if (route.name === 'gallery') {
          text = this.$store.getters['eventGallery/gallery']?.name
        } else {
          text = this.$gettext(meta.label)
        }
        return {to, text}
      })
    },
    props() {
      return {
        items: this.items,
        addClasses: this.addClasses,
        addLinkClasses: this.addLinkClasses,
        addLastItemClasses: this.addLastItemClasses
      }
    }
  }
}
</script>
