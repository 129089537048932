import ApiClient from "@/domain/core/utils/apiClient";
import client_core from "@/services/clients/client_core";
import client_metrics from "@/services/clients/client_metrics";
import TotalHelper from "@/domain/core/utils/chart/TotalHelper";
import {METRIC_TYPES, SHARE_TYPES} from "@/domain/eventMetric/consts";

const core = new ApiClient(client_core)
const metrics = new ApiClient(client_metrics)


const metrics_endpoints = {
    share_link_by_share_type: '/stats/{id}/shareLinkByShareType',
    group_by_type: '/stats/{id}/groupByType',
}

const core_endpoints = {
    orders_summary: '/dashboard/v3/stats/{id}/orders_summary',
    checkins_summary: '/dashboard/v3/stats/{id}/checkins_summary',
    media_summary: '/dashboard/v3/stats/{id}/media_summary',
}

export default {
    async fetchOrderSummary(id) {
        return await core.get(core_endpoints.orders_summary.replace('{id}', id))
    },
    async fetchCheckinsSummary(id) {
        return await core.get(core_endpoints.checkins_summary.replace('{id}', id))
    },
    async fetchMediaSummary(id) {
        return await core.get(core_endpoints.media_summary.replace('{id}', id))
    },
    async fetchEngagementSummary(id) {

        const [byShare, byType] = await Promise.all([
            metrics.get(metrics_endpoints.share_link_by_share_type.replace('{id}', id), {
                from: '2021-01-01',
                to: '2030-01-01',
                tz: 'UTC',
                countBy: 'month',
            }),
            metrics.get(metrics_endpoints.group_by_type.replace('{id}', id), {
                from: '2021-01-01',
                to: '2030-01-01',
                tz: 'UTC',
                countBy: 'month',
                types: [METRIC_TYPES.MEDIA_DOWNLOADED]
            })
        ])

        const totalHelper = new TotalHelper()

        let social = 0
        Object.values(SHARE_TYPES).forEach((key) => {
            social += totalHelper.getTotal(byShare[key])
        })

        const download = totalHelper.getTotal(byType[METRIC_TYPES.MEDIA_DOWNLOADED])

        return {
            total: social + download,
            social: social,
            download: download
        }
    },
}
