import ApiClient from "@/domain/core/utils/apiClient";
import client_core from "@/services/clients/client_core";
const client = new ApiClient(client_core)

const endpoints = {
    vertical_list: '/dashboard/v2/vertical-configuration/verticals/',
    vertical_detail: '/dashboard/v2/vertical-configuration/verticals/{id}/',
}

export default {
    async fetchVerticalList(filters, pagination, sorting) {
        let result = await client.get(endpoints.vertical_list, {
            ...filters,
            page_size: pagination.page_size ?? 20,
            page: pagination.page ?? 1,
            ordering: sorting.asc ? `-${sorting.column}` : sorting.column ?? ''
        })
        return {
            results: result.results,
            pagination: {
                num_pages: result.pagination.num_pages,
                page_size: result.pagination.page_size,
                page: result.pagination.page_number,
                total: result.pagination.total,
            }
        }
    },
    async deleteVertical(id) {
        await client.delete(endpoints.vertical_detail.replace('{id}',id))
    },
    async fetchVertical(id) {
        return await client.get(endpoints.vertical_detail.replace('{id}',id))
    },
    async updateVertical(id, data) {
        return await client.patch(endpoints.vertical_detail.replace('{id}',id), data)
    },
    async createVertical(data) {
        return await client.post(endpoints.vertical_list,data)
    },
}
