import {ApiException} from "@/domain/core/exception/exceptions";
import {emptyListResponse} from "@/domain/core/utils/django";


const state = {
    verticals: {...emptyListResponse},
    vertical: {},
}

export const getters = {
    verticals: state => state.verticals,
    vertical: state => state.vertical,
}


export const mutations = {
    verticals(state, verticals) {
        state.verticals = verticals
    },
    vertical(state, vertical) {
        state.vertical = vertical
    },
    deleteVertical(state, id) {
        state.verticals.results = state.verticals.results.filter(vertical => vertical.id !== id)
    },
}


export const actions = service => ({
    async fetchVerticalList({commit}, {filters, pagination, sorting}) {
        try {
            const verticals = await service.fetchVerticalList(filters, pagination, sorting)
            commit('verticals', verticals)
        } catch (e) {
            if (e instanceof ApiException)
                commit('verticals', emptyListResponse)
            throw e
        }
    },
    async deleteVertical({commit}, id) {
        await service.deleteVertical(id)
        commit('deleteVertical', id)
    },
    async fetchVertical({commit}, id) {
        commit('vertical', await service.fetchVertical(id))
    },
    async updateVertical({commit}, {id, data}) {
        commit('vertical', await service.updateVertical(id, data))
    },
    async createVertical({commit}, data) {
        commit('vertical', await service.createVertical(data))
    },
})

export default service => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(service)
})

