import axiosRetry from 'axios-retry'
import axios from "axios";


const instance = axios.create()

// Exponential back-off retry delay between requests, 2^N secs
axiosRetry(instance, {
    retries: 4,
    retryDelay: (retryNumber) => Math.pow(2, retryNumber) * 1000,
    retryCondition: axiosRetry.isNetworkOrIdempotentRequestError
})

export default instance
