import client_core from "@/services/clients/client_core";
import ApiClient from "@/domain/core/utils/apiClient";

const client = new ApiClient(client_core)

const endpoints = {
    orders_summary: 'dashboard/v2/stats/pica-services/{picaServiceId}/orders/summary/',
    orders_summary_events: 'dashboard/v2/stats/pica-services/orders/summary/',
    orders_count_by_date: 'dashboard/v2/stats/pica-services/{picaServiceId}/orders/counts-by-date/',
    orders_count_by_date_events: 'dashboard/v2/stats/pica-services/orders/counts-by-date/',
    orders_bought_photos: 'dashboard/v2/stats/pica-services/{picaServiceId}/orders/bought-photos/',
    orders_bought_photos_events: 'dashboard/v2/stats/pica-services/orders/bought-photos/',
    orders_campaigns: 'dashboard/v2/stats/pica-services/{picaServiceId}/orders/campaign/',

    checkins_group_by: 'dashboard/v2/stats/pica-services/{picaServiceId}/checkins/group-by/',
    checkins_group_by_events: 'dashboard/v2/stats/pica-services/checkins/group-by/',
    checkins_summary: 'dashboard/v2/stats/pica-services/{picaServiceId}/checkins/summary/',
    checkins_summary_events: 'dashboard/v2/stats/pica-services/checkins/summary/',
    checkins_count_by_date: 'dashboard/v2/stats/pica-services/{picaServiceId}/checkins/counts-by-date/',
    checkins_count_by_date_events: 'dashboard/v2/stats/pica-services/checkins/counts-by-date/',
    checkins_tags_summary: 'dashboard/v2/stats/pica-services/{picaServiceId}/checkins/tags/summary/',

    uploads_summary: 'dashboard/v2/stats/pica-services/{picaServiceId}/uploads/summary/',
    amounts_by_photographer: 'dashboard/v2/stats/pica-services/{picaServiceId}/orders/amounts-by-photographer/',
    amounts_by_photographer_events: 'dashboard/v2/stats/pica-services/orders/amounts-by-photographer/',
    uploads_count_by_photographer: 'dashboard/v2/stats/pica-services/{picaServiceId}/uploads/counts-by-photographer/',
    uploads_count_by_photographer_events: 'dashboard/v2/stats/pica-services/uploads/counts-by-photographer/',
    uploads_count_by_upload_task: 'dashboard/v2/stats/pica-services/{picaServiceId}/uploads/counts-by-upload-task/',
    uploads_count_by_upload_task_events: 'dashboard/v2/stats/pica-services/uploads/counts-by-upload-task/',

    releases: 'dashboard/v2/stats/pica-services/{picaServiceId}/releases/'
}

export default {
    async getOrdersSummary(picaServiceId, params, bodyParams) {
        if (picaServiceId) {
            return client.get(endpoints.orders_summary.replace('{picaServiceId}', picaServiceId), params)
        } else {
            return client.post(endpoints.orders_summary_events, bodyParams, params)
        }
    },
    async getOrdersCountByDate(picaServiceId, params, bodyParams) {
        if (picaServiceId) {
            return client.get(endpoints.orders_count_by_date.replace('{picaServiceId}', picaServiceId), params)
        } else {
            return client.post(endpoints.orders_count_by_date_events, bodyParams, params)
        }
    },
    async getBoughtPhotos(picaServiceId, params, bodyParams) {
        if (picaServiceId) {
            return client.get(endpoints.orders_bought_photos.replace('{picaServiceId}', picaServiceId), params)
        } else {
            return client.post(endpoints.orders_bought_photos_events, bodyParams, params)
        }
    },
    async getOrdersCampaigns(picaServiceId, params) {
        return client.get(endpoints.orders_campaigns.replace('{picaServiceId}', picaServiceId), params)
    },
    async getCheckinsCountByDate(picaServiceId, params, bodyParams) {
        if (picaServiceId) {
            return client.get(endpoints.checkins_count_by_date.replace('{picaServiceId}', picaServiceId), params)
        } else {
            return client.post(endpoints.checkins_count_by_date_events, bodyParams, params)
        }
    },
    async getCheckinsSummary(picaServiceId, params, bodyParams) {
        if (picaServiceId) {
            return client.get(endpoints.checkins_summary.replace('{picaServiceId}', picaServiceId), params)
        } else {
            return client.post(endpoints.checkins_summary_events, bodyParams, params)
        }
    },
    async getCheckinsGroupBy(picaServiceId, params, bodyParams) {
        if (picaServiceId) {
            return client.get(endpoints.checkins_group_by.replace('{picaServiceId}', picaServiceId), params)
        } else {
            return client.post(endpoints.checkins_group_by_events, bodyParams, params)
        }
    },
    async getCheckinsTagsSummary(picaServiceId, params) {
        return client.get(endpoints.checkins_tags_summary.replace('{picaServiceId}', picaServiceId), params)
    },
    async getUploadsSummary(picaServiceId, params) {
        return client.get(endpoints.uploads_summary.replace('{picaServiceId}', picaServiceId), params)
    },
    async getOrdersAmountByPhotographer(picaServiceId, params, bodyParams) {
        if (picaServiceId) {
            return client.get(endpoints.amounts_by_photographer.replace('{picaServiceId}', picaServiceId), params)
        } else {
            return client.post(endpoints.amounts_by_photographer_events, bodyParams, params)
        }
    },
    async getUploadsCountByPhotographer(picaServiceId, params, bodyParams) {
        if (picaServiceId) {
            return client.get(endpoints.uploads_count_by_photographer.replace('{picaServiceId}', picaServiceId), params)
        } else {
            return client.post(endpoints.uploads_count_by_photographer, bodyParams, params)
        }
    },
    async getUploadsCountByUploadTask(picaServiceId, params, bodyParams) {
        if (picaServiceId) {
            return client.get(endpoints.uploads_count_by_upload_task.replace('{picaServiceId}', picaServiceId), params)
        } else {
            return client.post(endpoints.uploads_count_by_upload_task_events, bodyParams, params)
        }
    },
    async getReleases(picaServiceId, params) {
        return client.get(endpoints.releases.replace('{picaServiceId}', picaServiceId), params)
    }
}
