<template>
  <div v-if="!isDraft && hasAtLeastOneSection">
    <CSidebarNavTitle v-translate>Insights</CSidebarNavTitle>
    <template v-if="canSeeOrders">
      <CSidebarNavItem
          :name="$gettext('Orders')"
          :to="{name: 'eventOrderList', params: {picaServiceId: picaServiceId}}"
          icon="cip-order"/>
    </template>
    <template v-if="canSeeConsolidate">
    <CSidebarNavItem
        :name="$gettext('Closing Balance')"
        :to="{name: 'yearlyConsolidation', params: {picaServiceId: picaServiceId}}"
        icon="cip-calendar"/>
    </template>
    <template v-if="hasPerm('stats.analytics')">
      <CSidebarNavItem
          :name="$gettext('Analytics')"
          :to="{name: 'stats', params: {picaServiceId: picaServiceId}}"
          icon="cip-stats"
      />
    </template>
    <template v-if="hasPerm('checkin.list')">
      <CSidebarNavItem
          :name="$gettext('Check-ins')"
          :to="{name: 'checkin.list', params: {picaServiceId: picaServiceId}}"
          icon="cip-user-square"
      />
    </template>
  </div>
</template>

<script>


import {mapGetters} from "vuex";

export default {
  name: 'InsightNav',
  computed: {
    ...mapGetters('event', [
      'picaServiceId',
      'isDraft',
      'isResort',
      'industry',
    ]),
    canSeeConsolidate() {
      return this.hasPerm('dashboard.view_feature_order_consolidation')
    },
    canSeeOrders() {
      return this.hasPerm('event_wizard.see_orders')
    },
    hasAtLeastOneSection() {
      return this.canSeeOrders
          || this.canSeeConsolidate
          || this.hasPerm('stats.analytics')
          || this.hasPerm('checkin.list')
    }
  }
}
</script>
