export default [
    "20 20",
    '<g clip-path="url(#clip0_2434_635)">\n' +
    '<path d="M4.99961 16.6671L12.3905 9.27622C12.7205 8.94621 12.8855 8.7812 13.0758 8.71938C13.2431 8.665 13.4234 8.665 13.5908 8.71938C13.7811 8.7812 13.9461 8.94621 14.2761 9.27622L17.8377 12.8378M8.74996 7.08341C8.74996 8.00389 8.00377 8.75008 7.08329 8.75008C6.16282 8.75008 5.41663 8.00389 5.41663 7.08341C5.41663 6.16294 6.16282 5.41675 7.08329 5.41675C8.00377 5.41675 8.74996 6.16294 8.74996 7.08341ZM18.3333 10.0001C18.3333 14.6025 14.6023 18.3334 9.99996 18.3334C5.39759 18.3334 1.66663 14.6025 1.66663 10.0001C1.66663 5.39771 5.39759 1.66675 9.99996 1.66675C14.6023 1.66675 18.3333 5.39771 18.3333 10.0001Z"  stroke="var(--ci-primary-color, currentColor)" fill="none" class="stroke-over" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '</g>\n' +
    '<defs>\n' +
    '<clipPath id="clip0_2434_635">\n' +
    '<rect width="20" height="20" fill="white"/>\n' +
    '</clipPath>\n' +
    '</defs>']

