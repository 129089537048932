import {ApiException} from "@/domain/core/exception/exceptions";
import {emptyListResponse} from "@/domain/core/utils/django";


const state = {
    priceLists: {...emptyListResponse},
    priceList: {},
}

export const getters = {
    priceLists: state => state.priceLists,
    priceList: state => state.priceList,
}


export const mutations = {
    priceLists(state, priceLists) {
        state.priceLists = priceLists
    },
    priceList(state, priceList) {
        state.priceList = priceList
    },
    deletePriceList(state, id) {
        state.priceLists.results = state.priceLists.results.filter(priceList => priceList.id !== id)
    },
}


export const actions = service => ({
    async fetchPriceListList({commit}, {filters, pagination, sorting}) {
        try {
            const priceLists = await service.fetchPriceListList(filters, pagination, sorting)
            commit('priceLists', priceLists)
        } catch (e) {
            if (e instanceof ApiException)
                commit('priceLists', emptyListResponse)
            throw e
        }
    },
    async deletePriceList({commit}, id) {
        await service.deletePriceList(id)
        commit('deletePriceList', id)
    },
    async fetchPriceList({commit}, id) {
        commit('priceList', await service.fetchPriceList(id))
    },
    async updatePriceList({commit}, {id, data}) {
        commit('priceList', await service.updatePriceList(id, data))
    },
    async createPriceList({commit}, data) {
        commit('priceList', await service.createPriceList(data))
    }
})

export default service => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(service)
})

