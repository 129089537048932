<template>
  <CSidebar
      :class="sidebarClasses"
      color-scheme="light"
      fixed
      :minimize="minimize"
      :show="show"
      @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <div class="d-flex flex-row align-items-center">
        <CIcon
            class="c-sidebar-brand-full"
            name="logo"
            size="custom-size"
            :height="32"
            viewBox="0 0 136 34"
        />
      </div>
    </CSidebarBrand>

    <slot name="header-nav" />
    <slot>
      <CRenderFunction flat :content-to-render="getNav"/>
    </slot>
  </CSidebar>
</template>

<script>
import sidebar from "@coreui/coreui/js/src/sidebar";
import {mapGetters} from "vuex";

export default {
  name: 'TheSidebar',

  props: {
    nav: {
      type: Array,
      required: false
    },
  },

  computed: {
    ...mapGetters('event', [
      'isInEvent',
    ]),
    sidebar() {
      return sidebar
    },
    show() {
      return this.$store.state.sidebarShow
    },
    minimize() {
      return this.$store.state.sidebarMinimize
    },

    sidebarClasses() {
      let suffix = 'index'
      if (this.isInEvent) {
        suffix = 'in-event'
      }
      return [`sidebar--${suffix}`]
    }, getNav() {
      return [
        {
          _name: 'CSidebarNav',
          _children: this.nav.filter((navItem) => {
            if ('perms' in navItem && !this.hasAnyPerms(navItem.perms)) return false
            if ('canShow' in navItem && !navItem.canShow()) return false
            return true
          }).map((navItem) => {
            // the url needs to be resolved with store dynamic params
            if (navItem.resolveTo instanceof Function) navItem.to = navItem.resolveTo()
            return navItem
          })
        }
      ]
    }
  },
}
</script>
