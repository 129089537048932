<template>
  <div class="upload-footer">
    <template v-if="currentState === stateWidgetUpload.payAsYouGo">
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <CIcon name="cipDot" size="custom-size" width="8" style="color: #FDB022" class="mr-1"/>
          {{ pay_as_you_go_count }} <span class="ml-1" v-translate>files</span>
        </div>
        <div>
          <CLink @click="$emit('deliver')">
            <translate translate-context="globalUploader.sessions">Deliver now</translate>
            <CIcon class="text-primary ml-1" name="cipArrowNarrowRight" size="custom-size" width="15"/>
          </CLink>
        </div>
      </div>
    </template>
    <template v-else-if="currentState === stateWidgetUpload.noFilesUploaded">
      <div class="d-flex align-items-center justify-content-end">
        <div>
          <CLink @click="$emit('upload')">
            <translate translate-context="globalUploader.sessions">Upload photos</translate>
            <CIcon class="text-primary ml-1" name="cipArrowNarrowRight" size="custom-size" width="15"/>
          </CLink>
        </div>
      </div>
    </template>
    <template v-else>
       <div class="d-flex align-items-center justify-content-end">
        <div>
          <CLink @click="$emit('seePhotos')">
            <translate translate-context="globalUploader.sessions">See photos</translate>
            <CIcon class="text-primary ml-1" name="cipArrowNarrowRight" size="custom-size" width="15"/>
          </CLink>
        </div>
      </div>
    </template>
  </div>
</template>


<script>
import {stateWidgetUpload} from "@/constants";

export default {
  name: "UploadsProgressFooter",
  emits: ['deliver', 'upload', 'seePhotos'],
  props: {
    intents_count: {
      type: Number,
      default: 0
    },
    process_limit: {
      type: Number,
      default: 0
    },
    pay_as_you_go_count: {
      type: Number,
      default: 0
    },
    currentState: {
      type: String,
      required: true
    }
  },
  computed: {
    stateWidgetUpload(){
      return stateWidgetUpload
    }
  }
}
</script>