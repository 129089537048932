<template>
  <div>
    <CSidebarNavTitle v-translate v-if="hasAnyPerms('invitation.list')">Administration</CSidebarNavTitle>
    <template>
      <CSidebarNavItem
          :name="$gettext('Roles')"
          :to="{name: 'event.invitations.list', params: {picaServiceId: picaServiceId}}"
          icon="cip-photographer"
          v-if="hasPerm('invitation.list')"
      />
    </template>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'InvitationsNav',
  computed: {
    ...mapGetters('event', [
      'picaServiceId'
    ]),
  }
}
</script>
  