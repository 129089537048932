const BasicExtractor = {
    extract(files) {
        const ret = []
        // ListFiles is not iterable with filter/map/etc so we convert it to an array in a way to harmonize it with the rest of the code
        for (let i = 0; i < files.length; i++) {
            // A file with type '' is most probably a folder
            if (files[i].type === '') continue
            ret.push(files[i])
        }
        return ret
    }
}

export default BasicExtractor