<template>
  <CModal :show.sync="show" :title="$pgettext('invoice.modal.detail.title', 'Invoice detail')" centered>
    <template v-if="invoice">
      <PFRow :label="$pgettext('invoice.modal.detail.label','Name')" size="12x12">
        <CInput readonly size="sm" :value="invoice.name"/>
      </PFRow>
      <PFRow :label="$pgettext('invoice.modal.detail.label','Address')" size="12x12">
        <CInput readonly size="sm" :value="completeAddress"/>
      </PFRow>
      <PFRow :label="$pgettext('invoice.modal.detail.label','VAT/Fiscal code')" size="12x12">
        <CInput readonly size="sm" :value="invoice.vat_number"/>
      </PFRow>
      <PFRow :label="$pgettext('invoice.modal.detail.label','Total amount paid')" size="12x12">
        <CInput readonly size="sm" :value="invoice.price_grandtotal|currency(invoice.currency)"/>
      </PFRow>
      <PFRow :label="$pgettext('invoice.modal.detail.label','Total tax')" size="12x12">
        <CInput readonly size="sm" :value="invoice.amount_tax|currency(invoice.currency)"/>
      </PFRow>
    </template>
    <PFRow :label="$pgettext('invoice.modal.detail.label','Invoice number')" size="12x12">
      <CInput size="sm" v-model="form.number"/>
    </PFRow>
    <PFRow :label="$pgettext('invoice.modal.detail.label','Sent at')" size="12x12">
      <VueCtkDateTimePicker
          no-label
          v-model="form.sent_at"
          format="YYYY-MM-DD"
          formatted="LL"
          :input-size="`sm`"
      />
    </PFRow>
    <PFRow :label="$pgettext('invoice.modal.detail.label','Created at')" size="12x12">
      <VueCtkDateTimePicker
          no-label
          v-model="form.created_at"
          format="YYYY-MM-DD"
          formatted="LL"
          :input-size="`sm`"
      />
    </PFRow>
    <template #footer>
      <CButton color="secondary" size="sm" @click="show = false" v-translate>Close</CButton>
      <PButton
          v-if="hasPerm('orders.invoices.confirm')"
          color="primary"
          v-translate translate-context="invoice.modal.detail.actions"
          :loading="saving"
          @click="confirm">Confirm
      </PButton>
    </template>
  </CModal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PFRow from "@/domain/core/components/PFRow.vue";
import PButton from "@/components/PButton.vue";

export default {
  name: "InvoiceDetailModal",
  components: {PButton, PFRow},
  data() {
    return {
      show: false,
      saving: false,
      form: {
        number: null,
        sent_at: null,
        created_at: null,
      },
    }
  },
  computed: {
    ...mapGetters('invoice', [
      'invoice',
    ]),
    completeAddress() {
      return `${this.invoice.street}, ${this.invoice.zip_code}, ${this.invoice.city}, ${this.invoice.country}`
    }
  },
  methods: {
    ...mapActions('invoice', [
      'fetchInvoice',
      'confirmInvoice',
    ]),
    async open(id) {
      this.saving = false
      this.loading = true
      await this.fetchInvoice(id)
      this.form.number = this.invoice.number
      this.form.sent_at = this.invoice.sent_at
      this.form.created_at = this.invoice.created_at
      this.show = true
      this.loading = false
    },
    async confirm() {
      this.saving = true
      await this.confirmInvoice({
        number: this.form.number,
        sent_at: this.form.sent_at,
        created_at: this.form.created_at,
      })
      this.show = false
    }
  }
}
</script>