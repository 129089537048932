import ApiClient from "@/domain/core/utils/apiClient";
import client_core from "@/services/clients/client_core";

const client = new ApiClient(client_core)

const endpoints = {
    refund_list: '/dashboard/v2/refunds/', // GET
    refund_new: '/dashboard/v2/refunds/', // POST
    refund_delete: '/dashboard/v2/refunds/{id}/', // DELETE
    refund_search: '/dashboard/v2/refunds/search/', // GET, params: [picacode, email, order_number]
    refund_export: '/dashboard/v2/refunds/export/', // GET
    refund_detail: '/dashboard/v2/refunds/{id}/', // GET
    refund_update_reason: '/dashboard/v2/refunds/{id}/reason/', // PATCH
}

export default {
    async fetchRefundList(filters, pagination, sorting) {
        let result = await client.get(endpoints.refund_list, {
            ...filters,
            page_size: pagination.page_size ?? 20,
            page: pagination.page ?? 1,
            ordering: sorting.asc ? `-${sorting.column}` : sorting.column ?? ''
        })
        return {
            results: result.results,
            pagination: {
                num_pages: result.pagination.num_pages,
                page_size: result.pagination.page_size,
                page: result.pagination.page_number,
                total: result.pagination.total,
            }
        }
    },
    async deleteRefund(id) {
        await client.delete(endpoints.refund_delete.replace('{id}', id))
    },
    async fetchRefund(id) {
        return await client.get(endpoints.refund_detail.replace('{id}', id))
    },
    async createRefund(data) {
        return await client.post(endpoints.refund_new, data)
    },
    async searchOrderItem({picacode, email, order_number}) {
        let params = {}
        if (picacode)
            params.picacode = picacode
        if (email)
            params.email = email
        if (order_number)
            params.order_number = order_number

        return await client.get(endpoints.refund_search, params)
    },
    async exportRefunds(filters) {
        return await client.get(endpoints.refund_export, filters)
    },
    async updateReason(id, data) {
        return await client.patch(endpoints.refund_update_reason.replace('{id}', id),  {
            reason: data.reason
        })
    }
}
