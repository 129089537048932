import ApiClient from "@/domain/core/utils/apiClient";
import client_core from "@/services/clients/client_core";
const client = new ApiClient(client_core)

const endpoints = {
    invitation_list: '/dashboard/v2/event-wizard/{id}/invitation/',   // GET
    delete: '/dashboard/v2/event-wizard/{id}/invitation/{invitation}/',    // DELETE
    resend: '/dashboard/v2/event-wizard/{id}/invitation/{invitation}/resend/',    // POST
    send_invitation: '/dashboard/v2/event-wizard/{id}/invitation/invite/',  // POST
    send_multiple_invitations: '/dashboard/v2/event-wizard/{id}/invitation/invite_multiple/',  // POST
    roles: '/dashboard/v2/event-wizard/{id}/invitation/roles/',  // GET
    user_id_by_email: '/dashboard/v2/event-wizard/{id}/invitation/user_id_by_email/',  // GET
    contract_manager_users: '/dashboard/v2/event-wizard/{id}/invitation/contract_manager_users/',  // GET
    change_password: '/dashboard/v2/event-wizard/{id}/invitation/change_password/',  // POST

    photographer_manager_info: '/dashboard/v2/photographers/{id}/photographer_manager/info/',   // GET
    set_owner_as_photographer_manager: '/dashboard/v2/photographers/{id}/photographer_manager/set_owner_as_photographer_manager/',  // POST
    send_photographer_manager_invitation: '/dashboard/v2/photographers/{id}/photographer_manager/invite/',  // POST
}

export default {
    async fetchInvitationList(id, filters, pagination, sorting) {
        let result = await client.get(endpoints.invitation_list.replace('{id}',id), {
            ...filters,
            page_size: pagination.page_size ?? 20,
            page: pagination.page ?? 1,
            ordering: sorting.asc ? '' : '-' + sorting.column ?? '',
        })
        return {
            results: result.results,
            pagination: {
                num_pages: result.pagination.num_pages,
                page_size: result.pagination.page_size,
                page: result.pagination.page_number,
                total: result.pagination.total,
            }
        }
    },
    async deleteInvitation(id, invitationId) {
        await client.delete(endpoints.delete.replace('{id}',id).replace('{invitation}', invitationId))
    },
    async resendInvitation(id, invitationId) {
        await client.post(endpoints.resend.replace('{id}',id).replace('{invitation}', invitationId))
    },
    async sendInvitation(id, {email, role, message, require_accept, password}) {
        return await client.post(endpoints.send_invitation.replace('{id}',id), {email, role, message, require_accept, password})
    },
    async fetchRoles(id) {
        return await client.get(endpoints.roles.replace('{id}',id))
    },

    async fetchPhotographerManagerInfo(id) {
        return await client.get(endpoints.photographer_manager_info.replace('{id}',id))
    },
    async setOwnerAsPhotographerManager(id) {
        return await client.post(endpoints.set_owner_as_photographer_manager.replace('{id}',id))
    },
    async sendPhotographerManagerInvitation(id, email) {
        return await client.post(endpoints.send_photographer_manager_invitation.replace('{id}',id), {email})
    },
    async fetchUserIdByEmail(id, email) {
        return await client.get(endpoints.user_id_by_email.replace('{id}',id), {email})
    },
    async fetchContractManagerUsers(id) {
        return await client.get(endpoints.contract_manager_users.replace('{id}',id))
    },
    async sendMultipleInvitations(id, userIds, role) {
        return await client.post(endpoints.send_multiple_invitations.replace('{id}',id), {
            ids: userIds, role: role
        })
    },
    async changePassword(id, userId, password) {
        return await client.post(endpoints.change_password.replace('{id}',id), {
            user: userId,
            password: password
        })
    }
}
