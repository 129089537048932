import {ApiException, PermissionDenied} from "@/domain/core/exception/exceptions";
import client_core from "@/services/clients/client_core";
import client_metrics from "@/services/clients/client_metrics";
import client_shortener from "@/services/clients/client_shortener";
import {setLocale} from '@/utils/i18n'
import Vue from "vue";

const state = {
    token: null,
    jwt: null,
    remember: false,
    user: null,
    invitation: null,
    config: {},
    configFetched: {},
}

   
function isJwtExpired(token) {
    if (!token || token === 'undefined') return true
    const payload = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString())
    return payload.exp < Date.now() / 1000
}


export const getters = {
    isAuthenticated: state => !!state.token,
    invitation: state => state.invitation,
    email: state => state.user?.email,
    isConfigFetched: (state) => (type,id) => {
        if (!state.configFetched[type]) return false
        return String(state.configFetched[type]['id']) === String(id)
    },
    hasPerm: (state) => (perm) => {
        if (!state.user || !state.user.perms) return false
        if (state.config && perm in state.config && state.config[perm]) return true
        return state.user.perms.includes(perm)
    },
    hasPerms: (state) => (perms) => {
        if (!state.user || !state.user.perms) return false
        for (const perm of perms) {
            if (state.config && perm in state.config && state.config[perm]) continue
            if (!state.user.perms.includes(perm))
                return false
        }
        return true
    },
    hasAnyPerms: (state) => (perms) => {
        if (!state.user || !state.user.perms) return false
        if (typeof perms === 'string') perms = [perms]

        for (const perm of perms) {
            if (state.config && perm in state.config && state.config[perm])
                return true
            if (state.user.perms.includes(perm))
                return true
        }
        return false
    },
    userCompactName: (state) => {
        if (!state.user) return ''
        if (!state.user.first_name || !state.user.last_name) return state.user.email
        return `${state.user.first_name[0]}. ${state.user.last_name}`
    },
    userFullName: (state) => {
        if (!state.user) return ''
        return `${state.user.first_name} ${state.user.last_name}`
    },
    userIdeogramName: (state) => {
        if (!state.user) return ''
        if (state.user.first_name) {
            return `${state.user.first_name[0]}`.toLowerCase()
        } else if (state.user.email) {
            return `${state.user.email[0]}`.toLowerCase()
        }
    },
    isInGroup: (state) => (group) => state.user?.groups.indexOf(group) !== -1,
    isPicaManager: (state) => {
        return state.user?.groups.includes('pica-managers')
    },
    photoService: (state) => {
        return state.user.photo_service || null
    },
    user: state => state.user,
    userTimeZone: (state) => {
        if (!state.user) return 'UTC'
        return state.user.timezone
    },
    jwt: state => state.jwt,
    token: state => state.token,
    userLanguage: (state) => {
        if (!state.user) {
            const availableLanguages = ['en', 'it']
            const browserLanguage = navigator.language.includes('-') ? navigator.language.split('-')[0] : navigator.language
            if (availableLanguages.includes(browserLanguage))
                return browserLanguage
            else
                return 'en'

        }
        return state.user.language
    },
}

export const mutations = {
    remember(state, remember) {
        state.remember = remember
        if (remember) localStorage.setItem('remember', remember)
        else localStorage.removeItem('remember')
    },
    setToken(state, token) {
        state.token = token
        if (token === null) {
            if (state.remember)
                localStorage.removeItem('token')
            delete client_core.defaults.headers.common['Authorization']
        } else {
            if (state.remember)
                localStorage.setItem('token', JSON.stringify(token))
            client_core.defaults.headers.common['Authorization'] = `${token.type} ${token.token}`
        }
    },
    setJwt(state, jwt) {
        state.jwt = jwt
        if (jwt === null) {
            if (state.remember)
                localStorage.removeItem('jwt')
            delete client_metrics.defaults.headers.common['Authorization']
            delete client_shortener.defaults.headers.common['Authorization']

        } else {
            if (state.remember)
                localStorage.setItem('jwt', jwt)
            client_metrics.defaults.headers.common['Authorization'] = `Bearer ${jwt}`
            client_shortener.defaults.headers.common['Authorization'] = `Bearer ${jwt}`
        }
    },
    setUser(state, user) {
        if (user === null) {
            state.user = null
            localStorage.removeItem('user')
            return
        }

        state.user = user
        if (state.remember)
            localStorage.setItem('user', JSON.stringify(user))
        setLocale(user.language, user.timezone)
    },
    logout(state) {
        state.token = null
        state.jwt = null
        state.user = null
        state.remember = false
        state.config = false
        localStorage.removeItem('token')
        localStorage.removeItem('jwt')
        localStorage.removeItem('user')
        localStorage.removeItem('remember')
        localStorage.removeItem('configFetched')
        localStorage.removeItem('tours')

        delete client_core.defaults.headers.common['Authorization']
    },
    invitation(state, invitation) {
        state.invitation = invitation
    },
    config(state, {config, type, id}) {
        if (!type) {
            state.configFetched = {}
            state.config = {}
            return;
        }

        state.configFetched[type] = {
            id: id,
            config: config
        }

        state.config = {}
        for (const type in state.configFetched)
            Object.assign(state.config, state.configFetched[type].config)

        if (state.remember)
            localStorage.setItem('configFetched', JSON.stringify(state.configFetched))
    }
}

export const actions = service => ({
    // eslint-disable-next-line no-unused-vars
    async createUser({commit, getters}, {email, password}) {
        const language = getters['userLanguage']
        await service.createUser(email, password, language)
    },
    async authenticate({commit, dispatch}, {email, password, allowRegister, remember}) {
        if (remember !== undefined)
            commit('remember', remember)
        try {
            const result = await service.getAuthToken(email, password, allowRegister)
            commit('setToken', {token: result.token, type: 'Token'})
            commit('setJwt', result.jwt)
        } catch (e) {
            if (e instanceof ApiException)
                commit('logout')
            throw e
        }
        await dispatch('getCurrentUser')
    },
    async getCurrentUser({commit, dispatch}) {
        commit('setUser', await service.getUserData())
        commit('config', {config: null, type: null})
        await dispatch('fetchConfig', {type:'globals'})
    },
    async socialLogin({commit, dispatch, getters}, data) {
        commit('remember', true)
        try {
            data['language'] = getters['userLanguage']
            const result = await service.convertAuthToken(data)
            commit('setToken', {token: result.access_token, type: result.token_type})
            await dispatch('refreshJwt')
            await dispatch('getCurrentUser')
        } catch (e) {
            if (e instanceof ApiException)
                commit('logout')
            throw e
        }
    },
    logout({commit}) {
        commit('logout')
        commit('core/resetCache', null, {root: true})
    },
    async changePassword({getters, dispatch}, params) {
        await service.changePassword(params.password, params.passwordRepeat)
        const email = getters['email']
        dispatch('logout')
        await dispatch('authenticate', {email, password: params.password})
    },
    async refreshJwt({getters, commit}) {
        if (!getters['user']) return
        if (!isJwtExpired(getters['jwt'])) return
        commit('setJwt', await service.getJwt())
    },
    async changeUserData({dispatch}, params) {
        await service.changeUserData({
            first_name: params.first_name,
            last_name: params.last_name,
            language: params.language,
            timezone: params.timezone
        })
        await dispatch('getCurrentUser')
    },
    // eslint-disable-next-line no-unused-vars
    async validateInvitation({commit}, {code, type}) {
        try {
            commit('invitation', await service.validateInvitation(code, type))
            return true
        }
        catch (e) {
            commit('invitation', null)
            if (e instanceof PermissionDenied)
                return false
            else
                throw e
        }
    },
    async fetchConfig({commit, getters}, {type, id}) {
        if (id === undefined) id = 0
        if (getters['isConfigFetched'](type, id)) return
        commit('config', {
            config: await service.getConfig(type,id),
            type: type,
            id: id
        })
    },
    setPermissions({commit}, {type, permissions, id}) {
        if (id === undefined) id = 0
        commit('config', {
            config: permissions,
            type: type,
            id: id
        })
    },
    async acceptInvitation({commit, getters, dispatch}) {
        const invitation = getters['invitation']
        await service.acceptInvitation(invitation.code, invitation.type)
        commit('invitation', null)

        // We need to refresh the user data after accepting the invitation since it can have new permissions
        await dispatch('getCurrentUser')

        return invitation
    },
    async rememberFromStorage({commit, dispatch}) {
        commit('remember', localStorage.getItem('remember') || false)
        const version = localStorage.getItem('version') || null
        // If the data was saved with a different version, don't load it
        if (process.env.VUE_APP_VERSION !== version) {
            localStorage.clear()
            sessionStorage.clear()
            localStorage.setItem('version', process.env.VUE_APP_VERSION)
            return
        }
        commit('invitation', JSON.parse(sessionStorage.getItem('invitation')) || null)
        commit('setToken', JSON.parse(localStorage.getItem('token')) || null)
        commit('setJwt', localStorage.getItem('jwt') || null)
        commit('setUser', JSON.parse(localStorage.getItem('user')) || null)

        const configs = JSON.parse(localStorage.getItem('configFetched') || '{}')
        if (Object.keys(configs).length === 0)
            commit('logout')

        for (const type in configs) {
            const config = configs[type]
            commit('config', {config: config.config, type: type, id: config.id})
        }
        dispatch('refreshJwt')
    },
    // eslint-disable-next-line no-unused-vars
    async resendValidationCode({getters}, {email}) {
        await service.resendValidationCode(email)
    },
    async validateCode({commit, dispatch}, {email, code}) {
        const result = await service.validateCode(email, code)
        commit('remember', true)
        commit('setToken', {token: result.token, type: 'Token'})
        commit('setJwt', result.jwt)
        await dispatch('getCurrentUser')
    }
})

export default loginService => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(loginService)
})

