import Vue from "vue";

const emptyData = {
    setting: null,
    logo_on_photo: null,
    price_list: null,
}

const state = {
    galleries: [],
    gallery: null,
    tags: null,
    picaServiceId: null,
    data: {...emptyData},
}

export const getters = {
    picaServiceId: state => state.picaServiceId,
    gallery: state => state.gallery,
    galleries: state => state.galleries,
    tags: state => state.tags,
    data: state => state.data,
    step_setting: state => state.data.setting,
    step_logo_on_photo: state => state.data.logo_on_photo,
    step_price_list: state => state.data.price_list,
}

export const mutations = {
    picaServiceId(state, id) {
        if (state.picaServiceId === id) return
        state.picaServiceId = id
        state.tags = null
        state.data = {...emptyData}
    },
    galleries(state, galleries) {
        state.galleries = galleries
    },
    gallery(state, gallery) {
        state.gallery = gallery
        state.data = {...emptyData}
    },
    deleteGallery(state, gallery) {
        state.galleries = state.galleries.map(g => {
            if (gallery.parent_gallery === g.id) {
                g.subs = g.subs.filter(s => s.id !== gallery.id)
                return g
            }
            if (g.id === gallery.id) {
                return null
            }
            return g
        }).filter(g => g !== null)
    },
    addGallery(state, gallery) {
        gallery.subs = []
        if (!gallery.parent_gallery) {
            state.galleries = [...state.galleries, gallery]
            return
        }
        state.galleries.map(g => {
            if (g.id === gallery.parent_gallery) {
                g.subs.push(gallery)
                return g
            }
            return g
        })
    },
    sortGalleries(state, ids) {
        state.galleries = ids.map(id => state.galleries.find(g => g.id === id))
    },
    sortSubGalleries(state, {galleryId, ids}) {
        state.galleries = state.galleries.map(gallery => {
            if (gallery.id !== galleryId) return gallery
            return {
                ...gallery,
                sub: ids.map(id => gallery.sub.find(g => g.id === id))
            }
        })
    },
    tags(state, tags) {
        state.tags = tags
    },
    data(state, {tab, data}) {
        Vue.set(state.data, tab, data)
    },
}

export const actions = service => ({
    setPicaServiceId({commit}, id) {
        commit('picaServiceId', id)
    },
    async fetchGallery({commit, getters}, id) {
        const gallery = await service.fetchGallery(getters.picaServiceId, id)
        const perm = gallery.permissions
        commit('gallery', gallery)

        const config = {}
        if (perm.delete) config['gallery.delete'] = perm.delete
        if (perm.settings) config['gallery.settings'] = perm.settings
        if (perm.logo_on_photo) config['gallery.logo_on_photo'] = perm.logo_on_photo
        if (perm.price_list) config['gallery.price_list'] = perm.price_list
        if (perm.edit_price_list) config['gallery.edit_price_list'] = perm.edit_price_list
        if (perm.browse_photo) config['gallery.browse_photo'] = perm.browse_photo
        if (perm.utility) config['gallery.utility'] = perm.utility

        commit('login/config', {
            config: config,
            type: 'gallery',
            id: getters.picaServiceId
        }, {root: true})
    },
    async deleteGallery({commit, getters}, gallery) {
        await service.deleteGallery(getters.picaServiceId, gallery.id)
        commit('deleteGallery', gallery)
    },
    async createGallery({getters}, data) {
        await service.createGallery(getters.picaServiceId, data)
    },
    async updateSorting({commit, getters}, ids) {
        await service.updateSorting(getters.picaServiceId, ids)
        commit('sortGalleries', ids)
    },
    async updateSubSorting({commit, getters}, {galleryId, ids}) {
        await service.updateSorting(getters.picaServiceId, ids)
        commit('sortSubGalleries', {galleryId, ids})
    },
    async fetchTags({commit, getters}) {
        if (getters['tags']) return
        commit('tags', await service.fetchTags(getters.picaServiceId, getters.gallery.id))
    },
    async fetchTabData({commit, getters}, tab) {
        if (tab === 'browse_photo') return null
        if (tab === 'utility') return null
        let tabData = getters.data[tab]
        if (tabData) return tabData
        tabData = await service.fetchData(getters.picaServiceId, getters.gallery.id, tab)
        commit('data', {tab, data: tabData})
    },
    async updateTabData({commit, getters}, {tab, data}) {
        const result = await service.updateData(getters.picaServiceId, getters.gallery.id, tab, data)
        commit('data', {
            tab,
            data: result
        })
    },
    async searchPriceList({getters}, params) {
        return await service.searchPriceList(getters.picaServiceId, params)
    },
})

export default service => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(service)
})

