<template>
  <div>
    <PTabs :tabs="tabs" :selected="tab" @select="goToTab" pills/>
    <ListInvoice v-if="tab === 'invoice'"/>
    <ListCreditNote v-if="tab === 'credit_note'"/>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import ListInvoice from "@/domain/invoice/ListInvoice.vue";
import ListCreditNote from "@/domain/invoice/ListCreditNote.vue";
import PTabs from "@/domain/core/components/PTabs.vue";

export default {
  name: 'Invoices',
  components: {PTabs, ListCreditNote, ListInvoice},
  props: {
    tab: {
      type: String,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    tabs() {
      return {
        invoice: {name: 'invoice', label: 'Invoices'},
        credit_note: {name: 'credit_note', label: 'Credit Notes'},
      }
    }
  },
  async mounted() {
    await this.fetchConfig({type: 'invoice'})
  },
  methods: {
    ...mapActions('login', [
      'fetchConfig',
    ]),
    async goToTab(tab) {
      await this.$router.push({name: `invoice.${tab}`})
    },
  }
}
</script>

<style scoped>

</style>