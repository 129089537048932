const ListRegion = () => import('./region/ListRegion.vue')
const EditRegion = () => import('./region/EditRegion.vue')
const NewRegion = () => import('./region/NewRegion.vue')

const ListPlanCategory = () => import('./category/ListPlanCategory.vue')
const EditPlanCategory = () => import('./category/EditPlanCategory.vue')
const NewPlanCategory = () => import('./category/NewPlanCategory.vue')

const ListBasePlan = () => import('./basePlan/ListBasePlan.vue')
const EditBasePlan = () => import('./basePlan/EditBasePlan.vue')
const NewBasePlan = () => import('./basePlan/NewBasePlan.vue')

const TheContainer = () => import('@/containers/TheContainer')

export default [
    {
        path: '/region',
        name: 'region',
        component: TheContainer,
        meta: {perms: ['admin.region.list']},
        children: [
            {
                path: 'list',
                name: 'region.list',
                component: ListRegion,
                meta: {label: 'List Regions',},
            },
            {
                path: 'new',
                name: 'region.new',
                component: NewRegion,
                meta: {label: 'Create new Region',},
            },
            {
                path: ':id',
                name: 'region.edit',
                component: EditRegion,
                meta: {label: 'Edit Region',},
            },
            {
                path: '/region/:region/category',
                name: 'plan_category',
                redirect: ':region/category/list',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                meta: {label: 'Category', perms: ['admin.plan_category.list']},
                children: [
                    {
                        path: 'list',
                        name: 'plan_category.list',
                        component: ListPlanCategory,
                        meta: {label: 'List Category for Region',},
                    },
                    {
                        path: 'new',
                        name: 'plan_category.new',
                        component: NewPlanCategory,
                        meta: {label: 'Create new Category for Region',},
                    },
                    {
                        path: ':id',
                        name: 'plan_category.edit',
                        component: EditPlanCategory,
                        meta: {label: 'Edit Category for Region',},
                    },
                    {
                        path: '/region/:region/category/:category/base_plan',
                        name: 'base_plan',
                        redirect: ':region/category/:category/base_plan/list',
                        component: {
                            render(c) {
                                return c('router-view')
                            }
                        },
                        meta: {label: 'Base Plan', perms: ['admin.base_plan.list']},
                        children: [
                            {
                                path: 'list',
                                name: 'base_plan.list',
                                component: ListBasePlan,
                                meta: {label: 'List Base Plan for Region',},
                            },
                            {
                                path: 'new',
                                name: 'base_plan.new',
                                component: NewBasePlan,
                                meta: {label: 'Create new Base Plan for Region',},
                            },
                            {
                                path: ':id',
                                name: 'base_plan.edit',
                                component: EditBasePlan,
                                meta: {label: 'Edit Base Plan for Region',},
                            },
                        ]
                    },
                ]
            },
        ]
    }
]