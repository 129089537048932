<template>
  <CModal :title="$pgettext('contract.create','Create Role')" :show="show" @update:show="close" size="lg"
          centered>

    <Form @save="onSave" :save_text="$pgettext('form','Update')" :data="role" v-if="role"/>
    <template #footer>
      <CButton @click="close" color="secondary" v-translate>Close</CButton>
    </template>
  </CModal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Form from "@/domain/roles/components/Form.vue";

export default {
  name: "EditRoleModal",
  components: {Form},
  data() {
    return {
      show: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters('contractManager', [
      'role'
    ]),
  },
  methods: {
    ...mapActions('contractManager', [
      'fetchRole',
      'updateRole'
    ]),
    async open(id) {
      this.loading = true
      await this.fetchRole(id)
      this.show = true
    },
    async onSave(data) {
      this.loading = true
      await this.updateRole(data)
      await this.notifySuccess(this.$pgettext('role.update.notification', 'Role updated'))
      this.loading = false
      this.close()
    },
    close() {
      this.show = false
    },
  }
}
</script>


<style scoped>

</style>