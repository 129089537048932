var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-3"},[_c('hr',{staticClass:"mt-1 mb-4"}),_c('div',{staticClass:"d-flex justify-content-center mb-2"},[_vm._l((_vm.customPriceListTypeOptions),function(option){return [_c('CButton',{key:option.id,attrs:{"color":_vm.selectedType === option.id ? 'primary' : ''},on:{"click":function($event){return _vm.onReset(option.id)}}},[_vm._v(" "+_vm._s(option.name)+" ")])]})],2),(_vm.rows.length > 0)?_c('CDataTable',{key:_vm.tableKey,staticClass:"table-price-list",attrs:{"items":_vm.rows,"fields":_vm.fields,"hover":false,"striped":false,"bordered":true,"fixed":false,"add-table-classes":"mb-0"},scopedSlots:_vm._u([{key:"from_qty",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CInput',{attrs:{"type":"number"},on:{"change":_vm.onUpdate,"input":_vm.onUpdate},model:{value:(item.from_qty),callback:function ($$v) {_vm.$set(item, "from_qty", $$v)},expression:"item.from_qty"}})],1)]}},{key:"to_qty",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CInput',{attrs:{"type":"number"},on:{"change":_vm.onUpdate,"input":_vm.onUpdate},model:{value:(item.to_qty),callback:function ($$v) {_vm.$set(item, "to_qty", $$v)},expression:"item.to_qty"}})],1)]}},{key:"qty",fn:function(ref){
var item = ref.item;
return [(!item.qty)?_c('td',[_c('CInput',{attrs:{"type":"number"},on:{"change":_vm.onUpdate,"input":_vm.onUpdate},model:{value:(item.qty),callback:function ($$v) {_vm.$set(item, "qty", $$v)},expression:"item.qty"}})],1):_c('td',[_c('CInput',{attrs:{"type":"number","readonly":"","disabled":""},model:{value:(item.qty),callback:function ($$v) {_vm.$set(item, "qty", $$v)},expression:"item.qty"}})],1)]}},{key:"unit_price",fn:function(ref){
var item = ref.item;
return [(!_vm.hasUnitPrice && !_vm.hasBlockPrice)?_c('td',[_c('PCurrencyInput',{attrs:{"currency":_vm.currency},on:{"change":_vm.onUpdate,"input":_vm.onUpdate},model:{value:(item.unit_price),callback:function ($$v) {_vm.$set(item, "unit_price", $$v)},expression:"item.unit_price"}})],1):(_vm.hasUnitPrice)?_c('td',[_c('PCurrencyInput',{attrs:{"currency":_vm.currency},on:{"change":_vm.onUpdate,"input":_vm.onUpdate},model:{value:(item.unit_price),callback:function ($$v) {_vm.$set(item, "unit_price", $$v)},expression:"item.unit_price"}})],1):_c('td',[_c('PCurrencyInput',{attrs:{"value":_vm.getUnitPrice(item),"currency":_vm.currency,"readonly":"","disabled":""}})],1)]}},{key:"price",fn:function(ref){
var item = ref.item;
return [(!_vm.hasUnitPrice && !_vm.hasBlockPrice)?_c('td',[_c('PCurrencyInput',{attrs:{"currency":_vm.currency},on:{"change":_vm.onUpdate,"input":_vm.onUpdate},model:{value:(item.price),callback:function ($$v) {_vm.$set(item, "price", $$v)},expression:"item.price"}})],1):(_vm.hasBlockPrice)?_c('td',[_c('PCurrencyInput',{attrs:{"currency":_vm.currency},on:{"change":_vm.onUpdate,"input":_vm.onUpdate},model:{value:(item.price),callback:function ($$v) {_vm.$set(item, "price", $$v)},expression:"item.price"}})],1):_c('td',[_c('PCurrencyInput',{attrs:{"value":_vm.getTotalPrice(item),"currency":_vm.currency,"readonly":"","disabled":""}})],1)]}},{key:"actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{staticClass:"text-right"},[(_vm.isLastRow(index))?_c('CButton',{staticClass:"btn-circle",attrs:{"color":"dark","variant":"outline"},on:{"click":function($event){return _vm.deleteLastItem()}}},[_c('CIcon',{attrs:{"name":"cipTrashFull"}})],1):_vm._e()],1)]}}],null,false,592908717)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }