import ApiClient from "@/domain/core/utils/apiClient";
import client_core from "@/services/clients/client_core";
const client = new ApiClient(client_core)

const endpoints = {
    priceList_list: '/dashboard/v2/payment/price_list/',
    priceList_detail: '/dashboard/v2/payment/price_list/{id}/',
    priceList_search: '/dashboard/v2/payment/price_list/search/',
}

export default {
    async fetchPriceListList(filters, pagination, sorting) {
        let result = await client.get(endpoints.priceList_list, {
            ...filters,
            page_size: pagination.page_size ?? 20,
            page: pagination.page ?? 1,
            ordering: sorting.asc ? `-${sorting.column}` : sorting.column ?? ''
        })
        return {
            results: result.results,
            pagination: {
                num_pages: result.pagination.num_pages,
                page_size: result.pagination.page_size,
                page: result.pagination.page_number,
                total: result.pagination.total,
            }
        }
    },
    async deletePriceList(id) {
        await client.delete(endpoints.priceList_detail.replace('{id}',id))
    },
    async fetchPriceList(id) {
        return await client.get(endpoints.priceList_detail.replace('{id}',id))
    },
    async updatePriceList(id, data) {
        return await client.patch(endpoints.priceList_detail.replace('{id}',id), data)
    },
    async createPriceList(data) {
        return await client.post(endpoints.priceList_list,data)
    }
}
