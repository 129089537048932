import {ApiException} from "@/domain/core/exception/exceptions";
import {emptyListResponse} from "@/domain/core/utils/django";


const state = {
    regions: {...emptyListResponse},
    region: {},
    regionId: null,
    categories: {...emptyListResponse},
    category: {},
    categoryId: null,
    basePlans: {...emptyListResponse},
    basePlan: {},
}

export const getters = {
    regions: state => state.regions,
    region: state => state.region,
    regionId: state => state.regionId,
    categories: state => state.categories,
    category: state => state.category,
    categoryId: state => state.categoryId,
    basePlans: state => state.basePlans,
    basePlan: state => state.basePlan,
}


export const mutations = {
    regions(state, regions) {
        state.regions = regions
    },
    region(state, region) {
        state.region = region
    },
    deleteRegion(state, id) {
        state.regions.results = state.regions.results.filter(region => region.id !== id)
    },
    regionId(state, id) {
        state.regionId = id
    },
    categories(state, categories) {
        state.categories = categories
    },
    category(state, category) {
        state.category = category
    },
    deleteCategory(state, id) {
        state.categories.results = state.categories.results.filter(category => category.id !== id)
    },
    categoryId(state, id) {
        state.categoryId = id
    },
    basePlans(state, basePlans) {
        state.basePlans = basePlans
    },
    basePlan(state, basePlan) {
        state.basePlan = basePlan
    },
    deleteBasePlan(state, id) {
        state.basePlans.results = state.basePlans.results.filter(basePlan => basePlan.id !== id)
    },
}


export const actions = service => ({
    async fetchRegionList({commit}, {filters, pagination, sorting}) {
        try {
            const regions = await service.fetchRegionList(filters, pagination, sorting)
            commit('regions', regions)
        } catch (e) {
            if (e instanceof ApiException)
                commit('regions', emptyListResponse)
            throw e
        }
    },
    async deleteRegion({commit}, id) {
        await service.deleteRegion(id)
        commit('deleteRegion', id)
    },
    async fetchRegion({commit}, id) {
        commit('region', await service.fetchRegion(id))
    },
    async updateRegion({commit}, {id, data}) {
        commit('region', await service.updateRegion(id, data))
    },
    async createRegion({commit}, data) {
        commit('region', await service.createRegion(data))
    },
    setRegionId({commit}, id) {
        commit('regionId', id)
    },
    async fetchCategoryList({commit,getters}, {filters, pagination, sorting}) {
        try {
            const categories = await service.fetchCategoryList(getters.regionId, filters, pagination, sorting)
            commit('categories', categories)
        } catch (e) {
            if (e instanceof ApiException)
                commit('categories', emptyListResponse)
            throw e
        }
    },
    async deleteCategory({commit, getters}, id) {
        await service.deleteCategory(getters.regionId, id)
        commit('deleteCategory', id)
    },
    async fetchCategory({commit, getters}, id) {
        commit('category', await service.fetchCategory(getters.regionId, id))
    },
    async updateCategory({commit, getters}, {id, data}) {
        commit('category', await service.updateCategory(getters.regionId, id, data))
    },
    async createCategory({commit, getters}, data) {
        commit('category', await service.createCategory(getters.regionId, data))
    },
    setCategoryId({commit}, id) {
        commit('categoryId', id)
    },
    async fetchBasePlanList({commit,getters}, {filters, pagination, sorting}) {
        try {
            const basePlans = await service.fetchBasePlanList(getters.regionId,getters.categoryId, filters, pagination, sorting)
            commit('basePlans', basePlans)
        } catch (e) {
            if (e instanceof ApiException)
                commit('basePlans', emptyListResponse)
            throw e
        }
    },
    async deleteBasePlan({commit, getters}, id) {
        await service.deleteBasePlan(getters.regionId,getters.categoryId, id)
        commit('deleteBasePlan', id)
    },
    async fetchBasePlan({commit, getters}, id) {
        commit('basePlan', await service.fetchBasePlan(getters.regionId,getters.categoryId, id))
    },
    async updateBasePlan({commit, getters}, {id, data}) {
        commit('basePlan', await service.updateBasePlan(getters.regionId,getters.categoryId, id, data))
    },
    async createBasePlan({commit, getters}, data) {
        commit('basePlan', await service.createBasePlan(getters.regionId,getters.categoryId, data))
    },
    // eslint-disable-next-line no-unused-vars
    async searchTemplate({commit}, query) {
        return await service.searchTemplate(query)
    },
    // eslint-disable-next-line no-unused-vars
    async searchVertical({commit}, query) {
        return await service.searchVertical(query)
    },
})

export default service => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(service)
})

