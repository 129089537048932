<template>
  <CModal :show.sync="show" centered add-content-classes="modal-confirm">
    <template #header-wrapper>
      <div class="d-none"></div>
    </template>
    <div>
      <div class="d-flex align-items-start">
        <CIcon :name="iconName" size="custom-size"
               width="36" height="36"
               class="mr-3 icon-circle" :class="`icon-circle--${color}`"
               v-if="iconName"/>
        <div>
          <h5 class="modal-title">{{ titleLabel }}</h5>
          <p v-html="messageLabel"></p>
        </div>
        <div class="flex-grow-1 mt-1 ml-4"><CButtonClose @click="onDecline"/></div>
      </div>
    </div>
    <template #footer>
      <PButton @click="onDecline" color="secondary">
        {{ cancelButtonLabel }}
      </PButton>
      <PButton @click="onConfirm" :color="color">
        {{ confirmButtonLabel }}
      </PButton>
    </template>
  </CModal>
</template>

<script>

import PButton from "@/components/PButton.vue";

export default {
  name: "ConfirmModal",
  components: {PButton},
  data() {
    return {
      show: false,
      loading: false,
      title: null,
      message: null,
      color: 'primary',
      icon: null,
      cb_confirm: null,
      cb_decline: null
    }
  },
  computed: {
    titleLabel() {
      return this.title || this.$pgettext('modal.confirm', 'Confirm')
    },
    messageLabel() {
      return this.message || this.$pgettext('modal.confirm', 'Do you confirm?')
    },
    confirmButtonLabel() {
      return this.$pgettext('modal.confirm', 'Confirm')
    },
    cancelButtonLabel() {
      return this.$pgettext('modal.confirm', 'Cancel')
    },
    iconName() {
      if (this.icon) return this.icon
      if (this.color === 'primary') return 'cipAlertTriangle'
      if (this.color === 'danger') return 'cipTrashFull'
      if (this.color === 'info') return 'cipInfo'
      return null
    }
  },
  methods: {
    open(title, message, color='primary', icon, cb_confirm, cb_decline) {
      this.show = true
      this.title = title
      this.message = message
      this.color = color
      this.icon = icon
      this.cb_confirm = cb_confirm
      this.cb_decline = cb_decline
    },
    async onConfirm() {
      this.loading = true
      try {
        await this.cb_confirm()
        this.loading = false
        this.show = false
      } catch (e) {
        this.loading = false
      }
    },
    async onDecline() {
      this.loading = true
      try {
        await this.cb_decline()
        this.loading = false
        this.show = false
      } catch (e) {
        this.loading = false
        this.show = false
      }
    },
  }
}
</script>

<style scoped>

</style>