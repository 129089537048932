export default {
    upload: {
        // Global event stats, for user that has access to all media
        event: 'pica/upload/{picaServiceId}/global/',

        // Global event stats, for user with access only to the media uploaded by him
        user: 'pica/upload/{picaServiceId}/user/{userId}/global/',

        // Gallery browse photo, we need to know when a new media is created or is processed
        gallery: 'pica/upload/{picaServiceId}/gallery/{galleryId}/global/',

        // Global browse photo, we need to know when a new media is created or is processed
        media: 'pica/upload/{picaServiceId}/gallery/*/user/*/task/*/',

        // Upload task relative to a user, or a gallery ( depend on patterns substitution )
        new_media_gallery: 'pica/upload/{picaServiceId}/gallery/{galleryId}/user/*/task/*/',

        // Keep visible upload task in sync
        user_tasks: 'pica/upload/{picaServiceId}/gallery/*/user/{userId}/task/*/',
    }
}