import ApiClient from "@/domain/core/utils/apiClient";
import client_core from "@/services/clients/client_core";

const client = new ApiClient(client_core)

const endpoints = {
    types: '/dashboard/v2/clusters/{id}/clusters/types/', // GET
    clusters: '/dashboard/v2/clusters/{id}/clusters/clusters/?photographer_id={photographer}', // GET
    media: '/dashboard/v2/clusters/{id}/cluster/{cluster}/media/', // GET
    stats: '/dashboard/v2/clusters/{id}/cluster/{cluster}/stats/', // GET
    forceGeneration: '/dashboard/v2/clusters/{id}/clusters/force_generation/', // POST
    get_tags: '/dashboard/v2/clusters/{id}/cluster/{cluster}/get_tags/', // GET
    update_tags: '/dashboard/v2/clusters/{id}/cluster/{cluster}/update_tags/', // POST
}

export default {
    async fetchTypes(picaServiceId) {
        return await client.get(endpoints.types.replace('{id}', picaServiceId))
    },
    async fetchClusters(picaServiceId, photographerId) {
        if (photographerId === undefined || photographerId === null)
            photographerId = ''

        return await client.get(endpoints.clusters.replace('{id}', picaServiceId).replace('{photographer}', photographerId))
    },
    async forceGeneration(picaServiceId) {
        await client.post(endpoints.forceGeneration.replace('{id}', picaServiceId))
    },
    async fetchMedia(picaServiceId, clusterId) {
        return await client.get(endpoints.media.replace('{id}', picaServiceId).replace('{cluster}', clusterId))
    },
     async fetchStats(picaServiceId, clusterId) {
        return await client.get(endpoints.stats.replace('{id}', picaServiceId).replace('{cluster}', clusterId))
    },
    async fetchTags(picaServiceId, clusterId) {
        return await client.get(endpoints.get_tags.replace('{id}', picaServiceId).replace('{cluster}', clusterId))
    },
    async updateTags(picaServiceId, clusterId, tags) {
        await client.post(
            endpoints.update_tags.replace('{id}', picaServiceId).replace('{cluster}', clusterId),
            {tags: tags}
        )
    },
}
