import ApiClient from "@/domain/core/utils/apiClient";
import client_core from "@/services/clients/client_core";
import {chunk} from "lodash";

const client = new ApiClient(client_core)

const endpoints = {
    stats: '/dashboard/v2/uploader/{id}/stats/', // POST
    existing_files: '/dashboard/v2/uploader/{id}/existing_files/?gallery_id={galleryId}', // POST
    create_task: '/dashboard/v2/uploader/{id}/task/?gallery_id={galleryId}', // POST
    cancel_task: '/dashboard/v2/uploader/{id}/task/{uploadTaskId}/cancel/', // POST
    check_new_media: '/dashboard/v2/photo-search/{id}/check_new_media/', // POST
}




export default {
    async existingFiles({picaServiceId, galleryId}, files) {
        const promises = chunk(files, 100).map(files => {
            return client.post(endpoints.existing_files.replace('{id}', picaServiceId).replace('{galleryId}',galleryId), {
                'files': files.map(file => file.md5)
            })
        })
        const results = await Promise.all(promises)
        return results.reduce((acc, result) => {
            acc.push(...result.files)
            return acc
        }, [])
    },
    async createTask({picaServiceId, galleryId}, files) {
        return client.post(endpoints.create_task.replace('{id}', picaServiceId).replace('{galleryId}',galleryId), {
            files: files.map(file => ({
                md5: file.md5,
                src: file.name,
            }))
        })
    },
    async cancelTask({picaServiceId, galleryId}, uploadTaskId) {
        return client.post(endpoints.cancel_task.replace('{id}', picaServiceId).replace('{galleryId}',galleryId).replace('{uploadTaskId}', uploadTaskId))
    },
    async fetchEventStats(picaServiceId, galleryId) {
        const params = galleryId ? { gallery_id: galleryId } : {};
        return client.get(endpoints.stats.replace('{id}', picaServiceId),params)
    }
}
