import ApiClient from "@/domain/core/utils/apiClient";
import client_core from "@/services/clients/client_core";
const client = new ApiClient(client_core)

const endpoints = {
    service_order_list: '/dashboard/v2/payment/service_orders/',
    service_order_download_invoice: '/dashboard/v2/payment/service_orders/{id}/download_invoice',
}

export default {
    async fetchServiceOrderList(filters, pagination, sorting) {
        if (!filters) filters = {}
        if (!pagination) pagination = {}
        if (!sorting) sorting = {}

        let result = await client.get(endpoints.service_order_list, {
            ...filters,
            page_size: pagination.page_size ?? 20,
            page: pagination.page ?? 1,
            ordering: sorting.asc ? `-${sorting.column}` : sorting.column ?? ''
        })
        return {
            results: result.results,
            pagination: {
                num_pages: result.pagination.num_pages,
                page_size: result.pagination.page_size,
                page: result.pagination.page_number,
                total: result.pagination.total,
            }
        }
    },
    async downloadInvoice(id) {
        return await client.download(endpoints.service_order_download_invoice.replace('{id}',id))
    },
}
