import ApiClient from "@/domain/core/utils/apiClient";
import client_core from "@/services/clients/client_core";

const client = new ApiClient(client_core)

const endpoints = {
    plan_list: '/dashboard/v2/payment/plan/',
    plan_detail: '/dashboard/v2/payment/plan/{id}/',
    search_plan: '/dashboard/v2/payment/plan/search/',
}

export default {
    async fetchPlanList(filters, pagination, sorting) {
        let result = await client.get(endpoints.plan_list, {
            ...filters,
            page_size: pagination.page_size ?? 20,
            page: pagination.page ?? 1,
            ordering: sorting.asc ? `-${sorting.column}` : sorting.column ?? ''
        })
        return {
            results: result.results,
            pagination: {
                num_pages: result.pagination.num_pages,
                page_size: result.pagination.page_size,
                page: result.pagination.page_number,
                total: result.pagination.total,
            }
        }
    },
    async deletePlan(id) {
        await client.delete(endpoints.plan_detail.replace('{id}', id))
    },
    async fetchPlan(id) {
        return await client.get(endpoints.plan_detail.replace('{id}', id))
    },
    async updatePlan(id, data) {
        return await client.patch(endpoints.plan_detail.replace('{id}', id), data)
    },
    async createPlan(data) {
        return await client.post(endpoints.plan_list, data)
    },
    async searchPlan({id, query}) {
        const params = {}
        if (id) params.id = id
        if (query) params.search = query
        return await client.get(endpoints.search_plan, params)
    },
}
