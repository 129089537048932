<template>
  <div>
    <CRow>
      <CCol lg="12">
        <h1 class="main-header" v-translate translate-context="invoice">
          Invoices
        </h1>
        <CCard class="no-borders">
          <CCardBody class="px-0">
            <CDataTable
                :hover="false"
                :bordered="true"
                :items="invoices.results"
                :fields="fields"
                :column-filter="{external: true,lazy:true}"
                :column-filter-value="filters"
                :sorter="{external: true, resetable:true}"
                :loading="loading"
                :sorter-value="sorting"
                striped
                @update:column-filter-value="updateFilters"
                @update:sorter-value="updateSorting"
                class="custom-table"
            >
              <template #no-items-view>
                <div class="text-center">
                  <h6 v-translate>No items found</h6>
                </div>
              </template>
              <template #sorting-icon="{ state, classes }">
                <div :class="classes">
                  <CIcon name="cipCaretUp" size="lg" v-if="state === 'asc' || state === 0"></CIcon>
                  <CIcon name="cipCaretUp" size="lg" v-else></CIcon>
                </div>
              </template>
              <template #order_number="{item}">
                <td>
                  <a href="#" @click.prevent="openOrderPage(item)" class="font-weight-semi-bold">{{
                      item.order_number
                    }}</a>
                </td>
              </template>
              <template #price_grandtotal="{item}">
                <td class="">
                  {{ item.price_grandtotal|currency(item.currency) }}
                </td>
              </template>
              <template #amount_tax="{item}">
                <td class="">
                  {{ item.amount_tax|currency(item.currency) }}
                </td>
              </template>
              <template #number="{item}">
                <td class="">
                  <template v-if="item.number">
                    {{ item.number }}
                  </template>
                </td>
              </template>
              <template #vat_number="{item}">
                <td class="">
                  <div class="d-flex align-items-center text-nowrap">
                    {{ item.vat_number }}
                    <CLink class="ml-2"
                           @click="copyInClipboard(item.vat_number, $gettext('VAT/Fiscal code'))">
                      <CIcon name="cipCopy" size="custom-size" :height="18"/>
                    </CLink>
                  </div>
                </td>
              </template>

              <template #status="{item}">
                <td class="">
                  <CBadge color="light" class="badge-outline">
                    <div class="d-flex align-items-center">
                      <CIcon :name="item.sent_at ? 'cipDotGreen' : 'cipDotOrange'" size="custom-size" :height="8"
                             class="mr-2"/>
                      <template v-if="item.sent_at">
                        <span v-translate translate-context="invoice.status" class="font-weight-normal">Completed</span>
                      </template>
                      <template v-else>
                        <span v-translate translate-context="invoice.status" class="font-weight-normal">Pending</span>
                      </template>
                    </div>
                  </CBadge>
                </td>
              </template>
              <template #actions="{item}">
                <td class="text-right">
                  <CButton color="dark" class="btn-circle btn-circle--sm" variant="outline" size="sm"
                           @click="openDetail(item.id)"
                           v-if="hasPerm('orders.invoices.confirm')">
                    <CIcon name="cipPencilLine" size="sm"></CIcon>
                  </CButton>
                </td>
              </template>
              <template #under-table>
                <CPagination
                    v-show="invoices.pagination.num_pages > 1"
                    :activePage="invoices.pagination.page"
                    @update:activePage="updatePage"
                    :pages="invoices.pagination.num_pages"
                />
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>


    </CRow>
    <InvoiceDetailModal ref="invoiceDetailModal"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import InvoiceDetailModal from "@/domain/invoice/components/InvoiceDetailModal.vue";

export default {
  name: 'ListInvoice',
  components: {InvoiceDetailModal},

  data() {
    return {
      loading: true,
      exporting: false,
      pagination: {
        page_size: 20,
        page: 1,
      },
      sorting: {
        column: null,
        asc: false
      },
      filters: {},
    }
  },
  async mounted() {
    await this.fetchList()
  },
  computed: {
    ...mapGetters('invoice', [
      'invoices',
    ]),
    fields() {
      let fields = []
      fields.push(
          {key: 'order_number', label: this.$pgettext('invoice.list', 'Order number'), sorter: false},
          {key: 'name', label: this.$pgettext('invoice.list', 'Name'), sorter: false},
          {key: 'vat_number', label: this.$pgettext('invoice.list', 'VAT/Fiscal code'), sorter: false},
          {key: 'price_grandtotal', label: this.$pgettext('invoice.list', 'Amount'), sorter: false, filter: false},
          {key: 'amount_tax', label: this.$pgettext('invoice.list', 'Tax'), sorter: false, filter: false},
          {key: 'number', label: this.$pgettext('invoice.list', 'Invoice number'), sorter: false},
          {key: 'status', label: '', sorter: false, filter: false},
          {key: 'actions', label: '', sorter: false, filter: false}
      )
      return fields
    }
  },
  methods: {
    ...mapActions('invoice', [
      'fetchInvoiceList',
    ]),
    async fetchList() {
      this.loading = true
      await this.fetchInvoiceList({
        filters: this.filters,
        pagination: this.pagination,
        sorting: this.sorting
      })
      this.pagination.page = this.invoices.pagination.page
      this.loading = false
    },
    updateFilters(filters) {
      this.filters = filters
      this.pagination.page = 1
      this.fetchList()
    },
    updateSorting(sorting) {
      this.sorting = sorting
      this.fetchList()
    },
    updatePage(page) {
      this.pagination.page = page
      this.fetchList()
    },
    openDetail(id) {
      this.$refs.invoiceDetailModal.open(id)
    },
    openOrderPage(item) {
      if (!this.hasPerm('pica_ecommerce.view_order_detail'))
        return

      const url = this.$router.resolve({
        name: 'orderDetail',
        params: {
          orderId: item.order_id
        }
      })
      window.open(url.href, '_blank')
    }
  }
}
</script>

<style scoped>

</style>