<template>
  <CModal :show.sync="show" centered add-content-classes="modal-420">
    <template #header-wrapper>
      <div class="d-none"></div>
    </template>
    <div class="d-flex flex-column justify-content-center align-items-center published-event-modal">
      <div class="emoji">&#127881;</div>
      <div class="text-center">
        <div class="title" v-translate translate-context="event.modal.publish.title">
          Event activated successfully!
        </div>
        <p class="text" v-translate translate-context="event.modal.publish.description">
          Complete the customization, upload photos and tell participants how to access their album
        </p>
      </div>
      <PConfetti ref="confetti" :origin="{'y': 1}" />
    </div>
    <template #footer>
      <div class="d-flex justify-content-center w-100">
        <PButton block color="primary" @click="onConfirm" v-translate>Continue</PButton>
      </div>
    </template>
  </CModal>
</template>

<script>
import PConfetti from "@/domain/core/components/PConfetti.vue";
import PButton from "@/components/PButton.vue";
export default {
  name: "PublishedEventModal",
  components: {PButton, PConfetti},
  emits: ['confirm'],
  data() {
    return {
      show: false
    }
  },
  methods: {
    async open() {
      this.show = true
      await this.$nextTick()
      await this.delay(500)
      this.$refs.confetti.start()
    },
    close() {
      this.show = false
    },
    async onConfirm() {
      await this.emitPromised('confirm')
    }
  }
}
</script>