<template>
  <div>
    <CRow>
      <CCol col="12" class="mb-3">
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <h1 class="main-header my-0 mr-3" v-translate translate-context="yearly_consolidation">Closing balance</h1>
            <PDropDown show-item-selector
                       :font-weight="'font-weight-semi-bold'"
                       :reset-on-select="false"
                       :items="availableYearsItems" @change="selectYear"
                       v-if="availableYears && availableYearsItems.length>0"/>
          </div>
          <div v-if="permissions.canConsolidateOrders">
            <CButton @click="openConsolidation" color="primary" class="mb-3" v-translate
                     translate-context="consolidation.yearly_orders">Create new +
            </CButton>
          </div>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <PComponentLoader :loading="!availableYears">
          <div v-if="availableYears">
            <div v-if="availableYears.length > 0">
              <PComponentLoader :loading="consolidationsForYear === null">
                <YearTable
                    :data="consolidationsForYear"
                    :canDownloadOrderExports="permissions.canDownloadOrderExports"
                    :canSeeTotals="permissions.canSeeTotals"
                    :canSeeRwValues="permissions.canSeeRwValues"
                    :canDeleteConsolidation="permissions.canDeleteConsolidation"
                    :canSeeGrossValues="permissions.canSeeGrossValues"
                    :canDownloadSummary="permissions.canDownloadSummary"
                    :currencyCode="currency"
                    :timezone="timeZone"
                    :deletableId="deletableId"
                    @download-orders="onDownloadOrders"
                    @download-refunds="onDownloadRefunds"
                    @download-summary="onDownloadSummary"
                    @download-month-orders="onDownloadMonthOrders"
                    @download-month-refunds="onDownloadMonthRefunds"
                    @download-month-summary="onDownloadMonthSummary"
                    @delete-consolidation="onDeleteConsolidation"
                />
              </PComponentLoader>
            </div>
            <div v-else>
              <div class="alert alert-info my-5" role="alert">
                No consolidations available
              </div>
            </div>
          </div>
        </PComponentLoader>
      </CCol>
    </CRow>
    <CreateNewConsolidation
        ref="create-new-consolidation"
        :timezone="timeZone"
        @make="consolidate"
        v-if="permissions.canConsolidateOrders"
    />
    <PConfirm :show-modal="showConfirmDeletion" @confirm="onConfirmDeletion" @dismiss="showConfirmDeletion=false"
              v-if="consolidationToDelete">
      <p>
        <translate>Are you sure you want to delete this consolidation?</translate>
        <br><br>
        <span class="font-weight-semi-bold" v-translate>From</span>:
        {{ consolidationToDelete.date_begin|formatDateTime('L LT', timeZone) }}
        <span class="font-weight-semi-bold" v-translate>To</span>:
        {{ consolidationToDelete.date_end|formatDateTime('L LT', timeZone) }}

      </p>
    </PConfirm>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PComponentLoader from "@/domain/core/components/PComponentLoader.vue";
import YearTable from "@/domain/ordersConsolidation/components/YearTable.vue";
import CreateNewConsolidation from "@/domain/ordersConsolidation/components/CreateNewConsolidation.vue";
import PConfirm from "@/domain/core/components/PConfirm.vue";
import PDropDown from "@/domain/core/components/PDropDown.vue";

export default {
  name: 'YearlyOrderConsolidation',
  components: {PDropDown, PConfirm, CreateNewConsolidation, YearTable, PComponentLoader},
  data() {
    return {
      showConfirmDeletion: false,
      consolidationIdToDelete: null,
    }
  },
  async mounted() {
    await this.setPicaServiceId(this.picaServiceId)
    await this.fetchConfiguration()
    if (this.availableYears.length <= 0) return
    await this.fetchConsolidationsForYear()
  },
  computed: {
    ...mapGetters('event', [
      'picaServiceId',
      'timeZone'
    ]),
    ...mapGetters('ordersConsolidation', [
      'availableYears',
      'consolidationsForYear',
      'lastConsolidation',
      'permissions',
      'currency',
      'year',
      'deletableId'
    ]),
    availableYearsItems() {
      return this.availableYears.map(year => {
        return {label: year, value: year}
      }).reverse()
    },
    consolidationToDelete() {
      if (!this.consolidationIdToDelete) return null
      const flattenedData = Object.values(this.consolidationsForYear).flat()
      return flattenedData.find(consolidation => consolidation.id === this.consolidationIdToDelete)
    }
  },
  methods: {
    ...mapActions('ordersConsolidation', [
      'setPicaServiceId',
      'fetchConfiguration',
      'fetchConsolidationsForYear',
      'downloadOrders',
      'downloadRefunds',
      'downloadSummary',
      'downloadMonthOrders',
      'downloadMonthRefunds',
      'downloadMonthSummary',
      'consolidate',
      'deleteConsolidation',
      'setYear'
    ]),
    async selectYear(year) {
      if (year === this.year) return
      this.loading = true
      await this.setYear(year)
      await this.fetchConsolidationsForYear()
      this.loading = false
    },
    async onDownloadOrders(id) {
      await this.downloadOrders(id)
    },
    async onDownloadRefunds(id) {
      await this.downloadRefunds(id)
    },
    async onDownloadSummary(id) {
      await this.downloadSummary(id)
    },
    async onDownloadMonthOrders(month) {
      await this.downloadMonthOrders({year:this.year, month:month})
    },
    async onDownloadMonthRefunds(month) {
      await this.downloadMonthRefunds({year:this.year, month:month})
    },
    async onDownloadMonthSummary(month) {
      await this.downloadMonthSummary({year:this.year, month:month})
    },
    onDeleteConsolidation(id) {
      this.consolidationIdToDelete = id
      this.showConfirmDeletion = true
    },
    openConsolidation() {
      this.$refs['create-new-consolidation'].open(this.lastConsolidation)
    },
    async onConfirmDeletion() {
      this.showConfirmDeletion = false
      await this.deleteConsolidation(this.consolidationIdToDelete)
    }
  }
}
</script>