<template>
  <PComponentLoader :loading="loading">
   <div class="c-app">
    <EventManagerSidebar @createGallery="openCreateGallery"/>
    <CWrapper>
      <TheHeader/>
      <Notifier/>
      <CreateNewGalleryModal ref="createNewGalleryModal"/>
      <PublishEventModal ref="publishEventModal" @published="onPublished" @edit="onEventEdit" @goToPayment="onPayment"/>
      <PublishedEventModal ref="publishedEventModal" @confirm="onConfirm"/>
      <PayOnDemandModal ref="payOnDemandModal" @published="onPublished"/>
      <PayAsYouGoModal ref="payAsYouGoModal" @payed="onPayAsYouGo"/>
      <PaidEventModal ref="paidEventModal"/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <router-view></router-view>
          </CContainer>
        </main>
      </div>
    </CWrapper>
  </div>
  </PComponentLoader>
</template>

<script>
import TheHeader from '../../containers/TheHeader.vue'
import Notifier from "../../components/Notifier.vue"
import {mapActions, mapGetters} from "vuex";
import EventManagerSidebar from "@/domain/eventManage/EventManagerSidebar.vue";
import MessageManager from "@/domain/core/utils/messageManager";
import CreateNewGalleryModal from "@/domain/eventGallery/components/CreateNewGalleryModal.vue";
import PayAsYouGoModal from "@/domain/events/components/PayAsYouGoModal.vue";
import PaidEventModal from "@/domain/events/components/PaidEventModal.vue";
import PublishedEventModal from "@/domain/events/components/PublishedEventModal.vue";
import PayOnDemandModal from "@/domain/events/components/PayOnDemandModal.vue";
import PublishEventModal from "@/domain/events/components/PublishEventModal.vue";
import PComponentLoader from "@/domain/core/components/PComponentLoader.vue";
import eventBus from "@/domain/core/constant/eventBus";


export default {
  name: 'EventManager',
  components: {
    PComponentLoader,
    PublishEventModal, PayOnDemandModal, PublishedEventModal,
    PaidEventModal, PayAsYouGoModal,
    CreateNewGalleryModal,
    EventManagerSidebar,
    TheHeader,
    Notifier,
  },

  data() {
    return {
      loading: true,
    }
  },
  async beforeRouteUpdate(to, from, next) {
    await this.fetchEventData()
    next()
  },
  async beforeMount() {
    await this.fetchEventData()
  },
  beforeDestroy() {
    MessageManager.off()
    this.emitEvent(eventBus.chat.disable, eventBus.chat.contexts.event)
  },
  computed: {
    ...mapGetters('event', [
      'isUserStatsViewer',
      'picaServiceId'
    ]),
    ...mapGetters('login', [
      'user',
    ]),
  },
  methods: {
    ...mapActions('event', [
      'fetchPicaService',
    ]),
    ...mapActions('login', [
      'fetchConfig',
    ]),
    ...mapActions('eventWizard', [
      'setEventId',
      'fetchEventConfiguration',
      'fetchFormData',
      'setStepAsActive',
      'fetchStepData',
    ]),
    ...mapActions('logoSet', {
      logoSet_setPicaServiceId: 'setPicaServiceId'
    }),
    ...mapActions('picaCode', {
      picaCode_setPicaServiceId: 'setPicaServiceId'
    }),
    ...mapActions('nStats', {
      stats_setPicaServiceId: 'setPicaServiceId',
    }),
    ...mapActions('eventGallery', {
      gallery_setPicaServiceId: 'setPicaServiceId'
    }),
    ...mapActions('uploader', {
      uploader_setDestination: 'setDestination',
      uploader_fetchEventStats: 'fetchEventStats',
      uploader_fetchGalleryStats: 'fetchGalleryStats',
    }),
    openCreateGallery(params) {
      if (!params) params = {}
      this.$refs.createNewGalleryModal.open(params)
    },
    async fetchEventData() {
      if (this.$route.params.picaServiceId === this.picaServiceId) return

      this.loading = true
      this.setEventId(this.$route.params.picaServiceId)
      MessageManager.setParams({
        picaServiceId: this.$route.params.picaServiceId,
        userId: this.user?.id
      })

      await this.logoSet_setPicaServiceId(this.$route.params.picaServiceId)
      await this.picaCode_setPicaServiceId(this.$route.params.picaServiceId)
      await this.stats_setPicaServiceId(this.$route.params.picaServiceId)
      await this.gallery_setPicaServiceId(this.$route.params.picaServiceId)
      await this.uploader_setDestination({
        picaServiceId: this.$route.params.picaServiceId,
        galleryId: null,
      })

      await Promise.all([
        this.fetchPicaService(this.$route.params.picaServiceId),
        this.fetchEventConfiguration(),
        this.fetchConfig({
          type: 'pica_service',
          id: this.$route.params.picaServiceId
        }),
      ])
      await this.fetchFormData()
      await this.emitEvent(eventBus.event.changed, this.$route.params.picaServiceId)
      await this.emitEvent(this.hasPerm('feature.chat') ? eventBus.chat.enable : eventBus.chat.disable, eventBus.chat.contexts.event);
      this.loading = false
    },
    openPayAsYouGo() {
      this.$refs.payAsYouGoModal.open()
    },
    openPublishEvent() {
      this.$refs.publishEventModal.open()
    },
    async onPayAsYouGo() {
      await this.$refs.paidEventModal.open()
      await this.fetchEventConfiguration()
      await Promise.all([
        this.fetchFormData(),
        this.fetchPicaService(this.picaServiceId),
        await this.emitEvent(eventBus.event.pay_as_you_go, this.picaServiceId),
      ])
    },
    async onPublished() {
      await this.$refs.publishedEventModal.open()
      await this.fetchEventConfiguration()
      await Promise.all([
        this.fetchFormData(),
        this.fetchPicaService(this.picaServiceId),
        await this.emitEvent(eventBus.event.published, this.picaServiceId),
      ])
    },
    async routeToOverview() {
      if (this.$route.name === 'eventWizard.overview') return
      await this.$router.push({name: 'eventWizard.overview', params: {picaServiceId: this.picaServiceId}})
    },
    onEventEdit() {
      if (this.$route.name === 'eventWizard.date_and_name') return
      this.fetchStepData('date_and_name')
      this.$router.push({name: 'eventWizard.date_and_name', params: {picaServiceId: this.picaServiceId}})
    },
    onPayment({planId, createInvoice}) {
      this.$refs.payOnDemandModal.planOptionId = planId
      this.$refs.payOnDemandModal.createInvoice = createInvoice
      this.$refs.payOnDemandModal.open()
    },
    async onConfirm() {
      await this.$nextTick()
      await this.delay(500)
      await this.routeToOverview()
      this.$refs.publishedEventModal.close()
    }
  }
}
</script>
