<template>
  <div>
    <PAlert icon="cipAlertTriangle" :text="stripeError.message" class="mb-3" color="danger" v-if="hasStripeError"/>
    <slot></slot>
    <div v-if="enablePaymentMock">
      <CButton color="primary" @click="simulatePayment">Mock Payment</CButton>
    </div>
    <div v-else>
      <div id="payment-element" class="mb-3"></div>
      <div id="address-element"></div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {getStripeArgs, paymentAppearance} from "@/domain/core/constant/stripe";
import {orderStatus} from "@/constants";
import PAlert from "@/components/PAlert.vue";

export default {
  name: "StripeElements",
  components: {PAlert},
  emits: ['paid'],
  data() {
    return {
      elements: null,
      stripe: null,
      paymentElement: null,
      clientSecret: null,
      addressElement: null,
      stripeError: {},
      pollingTimeout: null,
    }
  },
  props: {
    currency: {
      type: String,
      required: true,
    },
    price: {
      required: true,
    },
    customer_secret: {
      type: String,
      required: true,
    },
    client_secret: {
      type: String,
      required: true,
    }
  },
  computed: {
    hasStripeError() {
      return Object.keys(this.stripeError).length > 0
    },
    enablePaymentMock() {
      return process.env.VUE_APP_PICA_ENABLE_PAYMENT_MOCK === '1'
    }
  },
  methods: {
    ...mapActions('eventWizard', [
      'setOrderStatus',
      'getOrderStatus'
    ]),
    async setup() {
      if (this.enablePaymentMock) return

      await this.$nextTick()
      this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY)
      const options = {
        mode: 'payment',
        currency: this.currency.toLowerCase(),
        amount: Math.round(this.price * 100),
        paymentMethodTypes: ['card'],
        customerSessionClientSecret: this.customer_secret,
        ...paymentAppearance,
      }
      this.elements = this.stripe.elements(options)
      await this.stripe_setupPaymentElement()
      await this.stripe_setupAddressElement()
    },
    async stripe_setupPaymentElement() {
      this.paymentElement = this.elements.create("payment", {
        fields: {
          billingDetails: {
            name: 'never',
          }
        }
      })
      this.paymentElement.mount("#payment-element")
    },
    async stripe_setupAddressElement() {
      this.addressElement = this.elements.create("address", {
        mode: "billing",
      })
      this.addressElement.mount("#address-element")
    },
    async pay() {
      if (this.enablePaymentMock) {
        await this.simulatePayment()
        return
      }
      this.stripeError = {}
      const validationResult = await this.elements.submit()
      if (validationResult.error)
        return false
      await this.stripe_processPayment()
    },
    async stripe_processPayment() {
      try {
        const args = getStripeArgs(this.client_secret, this.elements)
        const result = await this.stripe.confirmPayment(args)
        if (result.error) {
          this.stripeError = result.error
        } else {
          await this.setOrderStatus({status: orderStatus.PENDING})
          await this.poolOrderStatus()
        }
      } catch (error) {
        this.stripeError = error
      }
    },
    async poolOrderStatus() {
      let response = await this.getOrderStatus()
      if (response.status === orderStatus.PAID) {
        if (this.pollingTimeout) clearTimeout(this.pollingTimeout)
        this.$emit('paid')
      } else {
        this.pollingTimeout = setTimeout(() => this.poolOrderStatus(), 1000)
      }
    },
    async simulatePayment() {
      await this.setOrderStatus({status: orderStatus.PAID})
      await this.poolOrderStatus()
    }
  }
}
</script>



