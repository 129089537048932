// import {translate} from 'vue-gettext'

// const {gettext: $gettext} = translate

const PicacodeReleaseList = () => import('@/views_pica/picacode_releases/PicacodeReleaseList.vue')
const EventPromo = () => import('@/views_pica/events/EventPromo.vue')

export default [
    {
        path: 'picacodes',
        name: 'picacodeReleaseList',
        component: PicacodeReleaseList,
        meta: {
            title: 'Pica Codes',
            label: 'Pica Codes',
            perms: ['pica.view_picacoderelease']
        },
    },
    {
        path: 'promo',
        name: 'eventPromo',
        component: EventPromo,
        meta: {
            title: 'Promo',
            label: 'Promo',
            perms: [
                // we assume only users that can change will access this view
                //'pica.view_eventpromocode',
                'pica.change_eventpromocode',
                //'pica.view_eventautocheckincode',
                'pica.change_eventautocheckincode'
            ]
        },
    },
]