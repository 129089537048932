import {translate} from 'vue-gettext'

const {gettext: $gettext} = translate

export default [
    {
        _name: 'CSidebarNavTitle',
        _children: [$gettext('Payments')],
        perms: [
            'orders.refunds.list',
            'orders.invoices.list',
        ]
    },
    {
        _name: 'CSidebarNavItem',
        name: $gettext('Refunds'),
        to: {name: 'refund.list'},
        icon: 'cip-coins-swap',
        perms: ['orders.refunds.list']
    },
    {
        _name: "CSidebarNavItem",
        name: $gettext("Invoices"),
        to: {name: 'invoice.invoice'},
        icon: "cip-doc",
        perms: ["orders.invoices.list"],
    },
]