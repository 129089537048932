import ApiClient from "@/domain/core/utils/apiClient";
import client_core from "@/services/clients/client_core";
import Downloader from "@/domain/core/utils/downloader";

const client = new ApiClient(client_core)

const endpoints = {
    layout_list: '/dashboard/v2/event-wizard/{picaService}/pica-code/layout/?ids={ids}',
    layout_options: '/dashboard/v2/event-wizard/{picaService}/pica-code/layout_options/',
    task_list: '/dashboard/v2/event-wizard/{picaService}/pica-code/task/?ids={ids}',
    export_releases: '/dashboard/v2/event-wizard/{picaService}/pica-code/export_releases/?ids={ids}',
    export_labels: '/dashboard/v2/event-wizard/{picaService}/pica-code/export_labels/?ids={ids}',
    delete_release: '/dashboard/v2/event-wizard/{picaService}/pica-code/{id}/',
    fetch_release: '/dashboard/v2/event-wizard/{picaService}/pica-code/{id}/',
    generate_labels: '/dashboard/v2/event-wizard/{picaService}/pica-code/generate_labels/?ids={ids}',
    fetch_galleries: '/dashboard/v2/event-wizard/{picaService}/pica-code/galleries/',
    set_gallery: '/dashboard/v2/event-wizard/{picaService}/pica-code/{id}/set_gallery/',
    set_codec: '/dashboard/v2/event-wizard/{picaService}/pica-code/{id}/set_codec/',
}

export default {
    async fetchLayouts(picaServiceId, ids) {
        return await client.get(
            endpoints.layout_list
                .replace('{picaService}', picaServiceId)
                .replace('{ids}', ids.join(','))
        )
    },
    async fetchLayoutOptions(picaServiceId) {
        return await client.get(endpoints.layout_options.replace('{picaService}', picaServiceId))
    },
    async fetchTasks(picaServiceId, ids) {
        return await client.get(
            endpoints.task_list
                .replace('{picaService}', picaServiceId)
                .replace('{ids}', ids.join(','))
        )
    },
    async deleteRelease(picaServiceId, id) {
        return await client.delete(
            endpoints.delete_release
                .replace('{picaService}', picaServiceId)
                .replace('{id}', id)
        )
    },
    async downloadReleases(picaServiceId, ids) {
        await Downloader.download(client, endpoints.export_releases
            .replace('{picaService}', picaServiceId)
            .replace('{ids}', ids.join(','))
        )
    },
    async downloadLabels(picaServiceId, ids) {
        await Downloader.download(client, endpoints.export_labels
            .replace('{picaService}', picaServiceId)
            .replace('{ids}', ids.join(','))
        )
    },
    async generateLabels(picaServiceId, ids, layout, format) {
        return await client.post(
            endpoints.generate_labels
                .replace('{picaService}', picaServiceId)
                .replace('{ids}', ids.join(',')),
            {
                layout: layout,
                file_format: format
            }
        )
    },
    async fetchRelease(picaServiceId, id) {
        return await client.get(
            endpoints.fetch_release
                .replace('{picaService}', picaServiceId)
                .replace('{id}', id)
        )
    },
    async fetchGalleries(picaServiceId) {
        return await client.get(endpoints.fetch_galleries.replace('{picaService}', picaServiceId))
    },
    async setGallery(picaServiceId, id, gallery) {
        return await client.post(
            endpoints.set_gallery
                .replace('{picaService}', picaServiceId)
                .replace('{id}', id)
        ,{gallery: gallery})
    },
    async setCodec(picaServiceId, id, codec) {
        return await client.post(
            endpoints.set_codec
                .replace('{picaService}', picaServiceId)
                .replace('{id}', id)
        ,{codec: codec})
    }
}
