const ListFaqCategory = () => import('./ListFaqCategory.vue')
const NewFaqCategory = () => import('./NewFaqCategory.vue')
const EditFaqCategory = () => import('./EditFaqCategory.vue')
const ListFaqQuestion = () => import('./ListFaqQuestion.vue')
const NewFaqQuestion = () => import('./NewFaqQuestion.vue')
const EditFaqQuestion = () => import('./EditFaqQuestion.vue')

const TheContainer = () => import('@/containers/TheContainer')

export default [
    {
        path: '/faq',
        name: 'faq',
        component: TheContainer,
        meta: {perms: ['dashboard.view_feature_faq']},
        children: [
            {
                path: 'category/list',
                name: 'faqCategory.list',
                component: ListFaqCategory,
                meta: {label: 'List Faq Categories',},
            },
            {
                path: 'new',
                name: 'faqCategory.new',
                component: NewFaqCategory,
                meta: {label: 'Create new Faq Category',},
            },
            {
                path: ':id',
                name: 'faqCategory.edit',
                component: EditFaqCategory,
                meta: {label: 'Edit Faq Category',},
            },
            {
                path: ':category/question/list',
                name: 'faqQuestion.list',
                component: ListFaqQuestion,
                meta: {label: 'List Faq Questions',},
            },
            {
                path: ':category/question/new',
                name: 'faqQuestion.new',
                component: NewFaqQuestion,
                meta: {label: 'Create new Faq Question',},
            },
            {
                path: ':category/question/:id',
                name: 'faqQuestion.edit',
                component: EditFaqQuestion,
                meta: {label: 'Edit Faq Question',},
            },
        ]
    }
]