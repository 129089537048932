import ApiClient from "@/domain/core/utils/apiClient";
import client_core from "@/services/clients/client_core";
const client = new ApiClient(client_core)

const endpoints = {
    tag_list: '/dashboard/v2/tags/',
    tag_detail: '/dashboard/v2/tags/{id}/',
    tag_autocomplete: '/dashboard/v2/tags/autocomplete/?q={search}&category={category}',
    category_list: '/dashboard/v2/tags_category/',
    category_detail: '/dashboard/v2/tags_category/{id}/',
}

export default {
    async fetchTagList(category, filters, pagination, sorting) {
        let result = await client.get(endpoints.tag_list, {
            ...filters,
            page_size: pagination.page_size ?? 20,
            page: pagination.page ?? 1,
            ordering: sorting.asc ? `-${sorting.column}` : sorting.column ?? '',
            category: category
        })
        return {
            results: result.results,
            pagination: {
                num_pages: result.pagination.num_pages,
                page_size: result.pagination.page_size,
                page: result.pagination.page_number,
                total: result.pagination.total,
            }
        }
    },
    async deleteTag(id) {
        await client.delete(endpoints.tag_detail.replace('{id}',id))
    },
    async fetchTag(id) {
        return await client.get(endpoints.tag_detail.replace('{id}',id))
    },
    async updateTag(id, data) {
        return await client.patch(endpoints.tag_detail.replace('{id}',id), data)
    },
    async createTag(data) {
        return await client.post(endpoints.tag_list,data)
    },
    async autocompleteTags(search, category) {
        if (!category) category = ''
        const url = endpoints.tag_autocomplete
            .replace('{search}', search)
            .replace('{category}', category)
        return await client.get(url)
    },
    async fetchCategoryList(filters, pagination, sorting) {
        let result = await client.get(endpoints.category_list, {
            ...filters,
            page_size: pagination.page_size ?? 20,
            page: pagination.page ?? 1,
            ordering: sorting.asc ? `-${sorting.column}` : sorting.column ?? ''
        })
        return {
            results: result.results,
            pagination: {
                num_pages: result.pagination.num_pages,
                page_size: result.pagination.page_size,
                page: result.pagination.page_number,
                total: result.pagination.total,
            }
        }
    },
    async deleteCategory(id) {
        await client.delete(endpoints.category_detail.replace('{id}',id))
    },
    async fetchCategory(id) {
        return await client.get(endpoints.category_detail.replace('{id}',id))
    },
    async updateCategory(id, data) {
        return await client.patch(endpoints.category_detail.replace('{id}',id), data)
    },
    async createCategory(data) {
        return await client.post(endpoints.category_list,data)
    },
}
