import {translate} from 'vue-gettext'

const {pgettext: $pgettext} = translate
export default {
    USER_GROUP_PICA_MANAGERS: 'pica-managers',
    USER_GROUP_LEGAL_REPRESENTATIVES: 'legal-representatives',
    USER_GROUP_PHOTOGRAPHERS_MANAGERS: 'photographers-managers',
    USER_GROUP_PHOTOGRAPHERS: 'photographers',

    ORDER_PAYMENT_METHOD_BANK_TRANSFER: 'bank-transfer',

    ORDER_TYPE_PHOTOLIST: 'photolist',
    ORDER_TYPE_PHOTOPACKAGE: 'photopackage',
    ORDER_TYPE_PHOTOPACKAGE_AND_VIDEO: 'photopackage+video'
}

export const orderStatus = {
    PAID: 'paid',
    CREATED: 'created',
    PENDING: 'pending',
    SHIPPED: 'shipped',
    GIFTED: 'gifted',
    GIFTED_PENDING: 'gifted_pending',
    REFUNDED: 'refunded'
}


export const  currencyCodes = {
    EUR: 'EUR'
}

export const  thirdPartyTool = {
    IMAGO: 'imago'
}

export const stateWidgetUpload = {
  noFilesUploaded: 'noFilesUploaded',
  filesUploaded: 'upload',
  payAsYouGo: 'payAsYouGo',
  payAsYouGoPaid: 'payAsYouGoPaid',
}