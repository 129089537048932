import ApiClient from "@/domain/core/utils/apiClient";
import client_core from "@/services/clients/client_core";
const client = new ApiClient(client_core)

const endpoints = {
    event_owner: '/dashboard/v2/event-template/event-owner/',

    event_template_list: '/dashboard/v2/event-template/templates/',
    event_template_detail: '/dashboard/v2/event-template/templates/{id}/',

    photographer_manager: '/dashboard/v2/event-template/photographer-manager/',
    photographer_manager_by_owner: '/dashboard/v2/event-template/photographer-manager/for-event-owner/',
}

export default {
    async fetchTemplateList(filters, pagination, sorting) {
        let result = await client.get(endpoints.event_template_list, {
            ...filters,
            page_size: pagination.page_size ?? 20,
            page: pagination.page ?? 1,
            ordering: sorting.asc ? `-${sorting.column}` : sorting.column ?? ''
        })
        return {
            results: result.results,
            pagination: {
                num_pages: result.pagination.num_pages,
                page_size: result.pagination.page_size,
                page: result.pagination.page_number,
                total: result.pagination.total,
            }
        }
    },
    async deleteTemplate(id) {
        await client.delete(endpoints.event_template_detail.replace('{id}',id))
    },
    async fetchTemplate(id) {
        return await client.get(endpoints.event_template_detail.replace('{id}',id))
    },
    async updateTemplate(id, data) {
        return await client.patch(endpoints.event_template_detail.replace('{id}',id), data)
    },
    async createTemplate(data) {
        return await client.post(endpoints.event_template_list,data)
    },
}
