export default [
    [1],
    [0.6, 1],
    [0.45, 0.75, 1],
    [0.33, 0.6, 0.82, 1],
    [0.3, 0.55, 0.75, 0.9, 1],
    [0.3, 0.5, 0.67, 0.82, 0.92, 1],
    [0.25, 0.47, 0.65, 0.8, 0.9, 0.96, 1],
    [0.25, 0.45, 0.6, 0.72, 0.82, 0.9, 0.96, 1],
    [0.2, 0.38, 0.53, 0.65, 0.75, 0.83, 0.9, 0.96, 1],
    [0.2, 0.38, 0.52, 0.64, 0.74, 0.82, 0.88, 0.93, 0.97, 1]
]