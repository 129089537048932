import ApiClient from "@/domain/core/utils/apiClient";
import client_core from "@/services/clients/client_core";

const client = new ApiClient(client_core)

const endpoints = {
    list: '/dashboard/v2/contract_manager/',
    detail: '/dashboard/v2/contract_manager/{id}/',
    contracts: '/dashboard/v2/contract_manager/{contract_manager}/contracts/',
    contract_detail: '/dashboard/v2/contract_manager/{contract_manager}/contracts/{id}/',
    contract_create_event: '/dashboard/v2/contract_manager/{contract_manager}/contracts/{id}/create_event/',
    invitations: '/dashboard/v2/contract_manager/{contract_manager}/invitations/',
    invitation_detail: '/dashboard/v2/contract_manager/{contract_manager}/invitations/{id}/',
    invitation_resend: '/dashboard/v2/contract_manager/{contract_manager}/invitations/{id}/resend/',
    invitation_send: '/dashboard/v2/contract_manager/{contract_manager}/invitations/invite/',
    invitation_available_roles: '/dashboard/v2/contract_manager/{contract_manager}/invitations/roles/',
    search_event_owner: '/dashboard/v2/contract_manager/{contract_manager}/event_owner/',
    search_plan: '/dashboard/v2/contract_manager/{contract_manager}/plan/',
    search_template: '/dashboard/v2/contract_manager/{contract_manager}/template/',
    search_vertical: '/dashboard/v2/contract_manager/{contract_manager}/vertical/',
    roles: '/dashboard/v2/contract_manager/{contract_manager}/role/',
    role_detail: '/dashboard/v2/contract_manager/{contract_manager}/role/{id}/',
}

export default {
    async fetchContractManagerList(filters, pagination, sorting) {
        if (!filters) filters = {}
        if (!pagination) pagination = {}
        if (!sorting) sorting = {}

        let result = await client.get(endpoints.list, {
            ...filters,
            page_size: pagination.page_size ?? 20,
            page: pagination.page ?? 1,
            ordering: sorting.asc ? `-${sorting.column}` : sorting.column ?? ''
        })
        return {
            results: result.results,
            pagination: {
                num_pages: result.pagination.num_pages,
                page_size: result.pagination.page_size,
                page: result.pagination.page_number,
                total: result.pagination.total,
            }
        }
    },
    async deleteContractManager(id) {
        await client.delete(endpoints.detail.replace('{id}', id))
    },
    async fetchContractManager(id) {
        return await client.get(endpoints.detail.replace('{id}', id))
    },
    async updateContractManager(id, data) {
        return await client.patch(endpoints.detail.replace('{id}', id), data)
    },
    async createContractManager(data) {
        return await client.post(endpoints.list, data)
    },
    async searchPlan(contract_manager, {id, query}) {
        const params = {}
        if (id) params.id = id
        if (query) params.search = query
        const result = await client.get(endpoints.search_plan.replace('{contract_manager}', contract_manager), params)
        return result.results
    },
    async searchEventOwner(contract_manager, {id, query}) {
        const params = {}
        if (id) params.id = id
        if (query) params.search = query
        const result = await client.get(endpoints.search_event_owner.replace('{contract_manager}', contract_manager), params)
        return result.results
    },
    async searchTemplate(contract_manager, {id, query}) {
        const params = {}
        if (id) params.id = id
        if (query) params.search = query
        const result = await client.get(endpoints.search_template.replace('{contract_manager}', contract_manager), params)
        return result.results
    },
    async searchVertical(contract_manager, {id, query}) {
        const params = {}
        if (id) params.id = id
        if (query) params.search = query
        const result = await client.get(endpoints.search_vertical.replace('{contract_manager}', contract_manager), params)
        return result.results
    },
    async fetchContracts(id) {
        const result = await client.get(endpoints.contracts.replace('{contract_manager}', id))
        return result.results
    },
    async createContract(contract_manager, data) {
        return await client.post(endpoints.contracts.replace('{contract_manager}', contract_manager), data)
    },
    async deleteContract(contract_manager, id) {
        await client.delete(endpoints.contract_detail.replace('{contract_manager}', contract_manager).replace('{id}', id))
    },
    async fetchContract(contract_manager, id) {
        return await client.get(endpoints.contract_detail.replace('{contract_manager}', contract_manager).replace('{id}', id))
    },
    async updateContract(contract_manager, contract_id, data) {
        return await client.patch(
            endpoints.contract_detail.replace('{contract_manager}', contract_manager).replace('{id}', contract_id),
            data
        )
    },
    async createEvent(contract_manager, contract_id, data) {
        return await client.post(
            endpoints.contract_create_event.replace('{contract_manager}', contract_manager).replace('{id}', contract_id),
            data
        )
    },
    async fetchAvailableRoles(id) {
        return await client.get(endpoints.invitation_available_roles.replace('{contract_manager}', id))
    },
    async fetchInvitations(id) {
        const result = await client.get(endpoints.invitations.replace('{contract_manager}', id))
        return result.results
    },
    async deleteInvitation(contract_manager, id) {
        await client.delete(endpoints.invitation_detail.replace('{contract_manager}', contract_manager).replace('{id}', id))
    },
    async sendInvitation(id, params) {
        return await client.post(endpoints.invitation_send.replace('{contract_manager}', id), params)
    },
    async resendInvitation(contract_manager, id) {
        await client.post(endpoints.invitation_resend.replace('{contract_manager}', contract_manager).replace('{id}', id))
    },
    async fetchRoles(id, filters, pagination, sorting) {
        if (!filters) filters = {}
        if (!pagination) pagination = {}
        if (!sorting) sorting = {}

        let result = await client.get(endpoints.roles.replace('{contract_manager}', id), {
            ...filters,
            page_size: pagination.page_size ?? 20,
            page: pagination.page ?? 1,
            ordering: sorting.asc ? `-${sorting.column}` : sorting.column ?? ''
        })
        return {
            results: result.results,
            pagination: {
                num_pages: result.pagination.num_pages,
                page_size: result.pagination.page_size,
                page: result.pagination.page_number,
                total: result.pagination.total,
            }
        }
    },
    async fetchRole(contract_manager, id) {
        return await client.get(endpoints.role_detail.replace('{contract_manager}', contract_manager).replace('{id}',id))
    },
    async createRole(contract_manager, data) {
        return await client.post(endpoints.roles.replace('{contract_manager}', contract_manager), data)
    },
    async deleteRole(contract_manager, id) {
        await client.delete(endpoints.role_detail.replace('{contract_manager}', contract_manager).replace('{id}', id))
    },
    async updateRole(contract_manager, id, data) {
        return await client.patch(endpoints.role_detail.replace('{contract_manager}', contract_manager).replace('{id}', id), data)
    },
}
