<template>
    <CInput type="number"
            :min="min"
            :step="step"
            :class="['currency-input',{'disabled': disabled}]"
            :value="value"
            :prepend="currencySymbol(currency)"
            :append="currency"
            :readonly="readonly"
            :disabled="disabled"
            @input="onInput"
            @change="onChange"
            />
</template>
<script>
export default {
  name: "PCurrencyInput",
  props: {
    value: {
      type: [String, Number, null],
      default: null
    },
    step: {
      type: Number,
      default: 0.01
    },
    min: {
      type: Number,
      default: 0
    },
    currency: {
      type: String,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onInput(value) {
      this.$emit('update:value', value)
      this.$emit('input', value)
    },
    onChange(value) {
      if (!value) return
      this.$emit('change', value)
    },
  }
}
</script>


<style scoped>

</style>