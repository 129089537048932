<template>
  <CModal :show.sync="show" :title="$pgettext('refund.modal.edit_reason.title', 'Edit Refund Reason')" centered size="lg">
    <PFRow :label="$pgettext('refund.label','Reason')">
      <CTextarea v-model="form.reason"/>
    </PFRow>
    <template #footer>
      <CButton color="secondary" @click="show = false" v-translate>Close</CButton>
      <PButton
          color="primary"
          v-translate translate-context="refund.modal.edit_reason.actions"
          class="mr-3"
          :loading="saving"
          @click="update"
      >Update
      </PButton>
    </template>
  </CModal>
</template>

<script>
import {mapActions} from "vuex";
import PFRow from "@/domain/core/components/PFRow.vue";
import PButton from "@/components/PButton.vue";

export default {
  name: "EditReasonModal",
  components: {PButton, PFRow},
  props: {
    refund: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      saving: false,
      form: {
        reason: null
      },
    }
  },
  methods: {
    ...mapActions('refund', [
      'updateReason',
    ]),
    async open() {
      this.saving = false
      this.form.reason = this.refund.reason
      this.show = true
      this.loading = false
    },
    async update() {
      this.saving = true
      await this.updateReason({
        reason: this.form.reason,
      })
      this.show = false
    }
  }
}
</script>