import YearlyOrdersConsolidation from "@/domain/ordersConsolidation/YearlyOrdersConsolidation.vue";

const Analytics = () => import('@/domain/stats/Analytics.vue')

export default [
    {
        path: 'stats',
        name: 'stats',
        component: Analytics,
        meta: {
            title: 'Analytics',
            label: 'Analytics',
            // perms: ['stats.analytics']
        },
    },
    {
        path: 'yearly-consolidation',
        name: 'yearlyConsolidation',
        component: YearlyOrdersConsolidation,
        meta: {
            title: 'Closing balance',
            label: 'Closing balance',
            perms: ['dashboard.view_feature_order_consolidation']
        }
    },
]