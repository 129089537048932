<template>
  <div>
    <div v-for="suggest in suggestions" :key="suggest.step">
      <!-- <h3>{{ suggest.step }}</h3> -->
      <template v-if="suggest.mandatory">
        <PAlert icon="cipAlertTriangle"
                :text="info.value"
                v-for="info in suggest.mandatory"
                :key="info.field"
                class="mb-2"
                color="danger" />
      </template>
      <template v-if="suggest.suggested">
        <PAlert icon="cipInfo"
                :text="info.value"
                v-for="info in suggest.suggested"
                :key="info.field"
                class="mb-2"
                color="info" />
      </template>

    </div>
  </div>
</template>

<script>
import PAlert from "@/components/PAlert.vue";

export default {
  name: "Suggestions",
  components: {PAlert},
  props: {
    suggestions: Array
  },
}
</script>