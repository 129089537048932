<template>
  <div class="status-item d-flex justify-content-between align-items-center" @mouseover="showTooltip = true"
       @mouseleave="showTooltip = false">
    <div class="session-progress flex-grow-1">
      <CLink class="name" @click="routeToGallery">
        <CIcon size="custom-size" :height="14" name="cipFolderBlue" class="mr-1" style="vertical-align: baseline" />
        {{ session.gallery.name }}
      </CLink>
      <SessionProgress :processed_count="session.processed_count"
                       :received_count="session.received_count"
                       :uploaded_count="session.uploaded_count"
                       :intents_count="session.intents_count"
                       :uploaded_size="session.uploaded_size"
                       :total_size="session.total_size"
      />
      <div class="info">
        <slot></slot>
      </div>
    </div>
    <div class="ml-2">
      <CButtonClose @click="onClose" class="list-item-closer" v-if="showCloseButton">
        <CIcon name="cipClose" size="custom-size" width="15"/>
      </CButtonClose>
      <CButtonClose @click="onConfirm" class="list-item-closer text-success" v-if="showConfirmButton">
        <CIcon name="cipCheck" size="custom-size" width="15"/>
      </CButtonClose>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import SessionProgress from "@/domain/uploader/components/SessionProgress.vue";
import uploadSessionStatus from "@/domain/core/constant/uploadSessionStatus";

export default {
  name: "UploadSession",
  components: {SessionProgress},
  emits: ['close','confirm'],
  props: {
    session: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      showTooltip: false
    }
  },
  computed: {
    ...mapGetters('uploader', ['picaServiceId']),
    showCloseButton() {
      return [uploadSessionStatus.inProgress, uploadSessionStatus.empty, uploadSessionStatus.pending].includes(this.session.status)
    },
    showConfirmButton() {
      return [uploadSessionStatus.uploaded, uploadSessionStatus.received, uploadSessionStatus.processed].includes(this.session.status)
    }
  },
  methods: {
    async routeToGallery() {
      await this.$router.push({
        name: `eventWizard.gallery.browse_photo`, params: {
          galleryId: this.session.gallery.id,
          picaServiceId: this.picaServiceId
        }
      })
    },
    onClose() {
      this.$emit('close')
    },
    onConfirm() {
      this.$emit('confirm')
    }
  }
}
</script>
