<template>
  <CDataTable
      :hover="false"
      :bordered="true"
      :items="rows"
      :fields="fields"
      striped
      class="custom-table custom-table--clean"
  >
    <template #no-items-view>
      <div class="text-center">
        <h5 v-translate>No users</h5>
      </div>
    </template>
    <template #actions="{item}">
      <td>
        <div class="list-group list-group-horizontal mb-0"
             v-if="item.status == invitationStatus.PENDING || item.status == invitationStatus.ACCEPTED">
          <CButton @click="onDelete(item.id)" class="list-inline-item list-group-item-light"
                   v-c-tooltip="{content: $gettext('Cancel invite'), appendToBody: true}"
                   v-if="hasPerm('contract_manager.invitation.delete')"
          >
            <CIcon name="cipClose" size="custom-size" :height="24"/>
          </CButton>
          <CButton @click="onResend(item.id)" class="list-inline-item list-group-item-light"
                   v-c-tooltip="{content: $gettext('Resend invite'), appendToBody: true}"
                   v-if="item.status == invitationStatus.PENDING && hasPerm('contract_manager.invitation.invite')"
          >
            <CIcon name="cipSend" size="custom-size" :height="24"/>
          </CButton>
        </div>
      </td>
    </template>
  </CDataTable>
</template>

<script>
import {mapActions} from "vuex";
import {INVITATION_STATUS} from "@/domain/invitations/const";

export default {
  name: "Invitations",
  props: {
    rows: {
      type: Array,
      required: true
    },
  },
  computed: {
    invitationStatus(){
      return INVITATION_STATUS
    },
  },
  data() {
    return {
      fields: [
        {key: 'email', label: 'Email'},
        {key: 'role', label: 'Role'},
        {key: 'actions', label: 'Actions', sorter: false, filter: false, _style: 'text-align: center;'}
      ],
    }
  },
  emits: ['save'],
  methods: {
    ...mapActions('contractManager', [
      'deleteInvitation',
      'resendInvitation'
    ]),
    async onDelete(id) {
      await this.deleteInvitation(id)
    },
    async onResend(id) {
      await this.resendInvitation(id)
      await this.notifySuccess(this.$pgettext('invitations.notification', 'Invitation resent'))
    }
  }
}
</script>

<style scoped>

</style>