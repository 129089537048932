import {translate} from 'vue-gettext'

const {gettext: $gettext} = translate

export default [
    {
        _name: 'CSidebarNavTitle',
        _children: [$gettext('Events')],
        perms: ['events.list']
    },
    {
        _name: 'CSidebarNavItem',
        name: $gettext('Events'),
        to: '/events',
        icon: 'cip-calendar',
        perms: ['events.list']
    },
]