import {ApiException} from "@/domain/core/exception/exceptions";
import Downloader from "@/domain/core/utils/downloader";
import {emptyListResponse} from "@/domain/core/utils/django";


const state = {
    refunds: {...emptyListResponse},
    refund: null
}

export const getters = {
    refunds: state => state.refunds,
    refund: state => state.refund
}


export const mutations = {
    refunds(state, refunds) {
        state.refunds = refunds
    },
    refund(state, refund) {
        state.refund = refund
    },
    deleteRefund(state, id) {
        state.refunds.results = state.refunds.results.filter(refund => refund.id !== id)
    },
}


export const actions = refundService => ({
    async fetchRefundList({commit}, {filters, pagination, sorting}) {
        try {
            const templates = await refundService.fetchRefundList(filters, pagination, sorting)
            commit('refunds', templates)
        } catch (e) {
            if (e instanceof ApiException)
                commit('refunds', emptyListResponse)
            throw e
        }
    },
    async fetchRefund({commit}, id) {
        const refund = await refundService.fetchRefund(id)
        commit('refund', refund)
    },
    async deleteRefund({commit}, id) {
        await refundService.deleteRefund(id)
        commit('deleteRefund', id)
    },
    // eslint-disable-next-line no-unused-vars
    async createRefund({commit}, data) {
        await refundService.createRefund(data)
    },
    // eslint-disable-next-line no-unused-vars
    async searchOrderItem({commit}, params) {
        return await refundService.searchOrderItem(params)
    },
    // eslint-disable-next-line no-unused-vars
    async exportRefunds({commit}, filters) {
        const data = await refundService.exportRefunds(filters)
        Downloader.downloadFile(data, 'refunds.csv')
    },
    async updateReason({commit, getters}, data) {
        const refund = await refundService.updateReason(getters['refund'].id, data)
        commit('refund', refund)

    }
})

export default refundService => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(refundService)
})

