<template>
  <div>
    <h1 class="main-header" v-translate translate-context="form'">Create</h1>
    <div>
      <template v-if="search">
        <SearchOrderItem @select="onSelect"/>
      </template>
      <template v-else>
        <div v-if="found">
          <CRow>
            <span @click="openOrderPage(found)">
              Order: {{ found.order_id }} | {{ found.order_number }}
            </span><br>
            OrderItemID: {{ found.id }}<br>
            Name: {{ found.event_name}}<br>
            User: {{ found.user_email }}<br>
            PicaCode:{{ found.picacode }}<br>
            Amount:{{ found.price }} {{ found.currency }}
          </CRow>
        </div>
        <CButton @click="search = true" v-translate translate-context="form.actions">Search</CButton>
      </template>
      <CCard>
        <CCardHeader>
          <div class="card-header-actions">
            <CButton size="sm" color="primary" @click="onCreate">Create</CButton>
          </div>
        </CCardHeader>
        <CCardBody class="p-0">
          <CContainer fluid class="grid-stripped">
            <CRow>
              <CCol><h5 v-translate translate-context="refund.section">Photo</h5></CCol>
            </CRow>
            <PFRow :label="$pgettext('refund.label','Order Item ID')">
              <CInput v-model="form.order_item"/>
            </PFRow>
            <PFRow :label="$pgettext('refund.label','Amount')">
              <CInput v-model="form.amount"/>
            </PFRow>
            <PFRow :label="$pgettext('refund.label','Reason')">
              <CTextarea v-model="form.reason"/>
            </PFRow>
          </CContainer>
        </CCardBody>
      </CCard>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {SuccessNotification} from "@/domain/core/exception/exceptions";
import PFRow from "@/domain/core/components/PFRow.vue";
import SearchOrderItem from "@/domain/refund/components/SearchOrderItem.vue";
import {refundMethods} from "@/domain/refund/methods";

export default {
  name: 'NewRefund',
  components: {SearchOrderItem, PFRow},
  data() {
    return {
      form: {
        order_item: '',
        amount: 0,
        reason: '',
      },
      search: true
    }
  },
  methods: {
    ...mapActions('refund', [
      'createRefund',
    ]),
    async onCreate() {
      await this.createRefund({
        order_item: this.form.order_item,
        amount: this.form.amount,
        reason: this.form.reason
      })
      await this.$router.push({name: 'refund.list'})
      throw new SuccessNotification(this.$pgettext('refund', 'Refund created!'))
    },
    onSelect(item) {
      this.found = item
      this.form.order_item = item.id
      if (this.form.amount <= 0) this.form.amount = item.price
      this.search = false
    },
    ...refundMethods
  }
}
</script>

<style scoped>

</style>