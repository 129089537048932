<template>
  <div class="session-progress__track small my-2">
    <div class="session-progress__bar uploaded" :style="{ width: uploadSizePerc + '%' }" v-if="total_size && total_size > 0"></div>
    <div class="session-progress__bar uploaded" :style="{ width: uploadedPerc + '%' }" v-else></div>
<!--    <div class="session-progress__bar received" :style="{ width: receivedWidth + '%' }"></div>
    <div class="session-progress__bar processed" :style="{ width: processedWidth + '%' }"></div>-->
  </div>
</template>


<script>
export default {
  name: "SessionProgress",
  props: {
    intents_count: {
      type: Number,
      required: true
    },
    uploaded_count: {
      type: Number,
      required: true
    },
    received_count: {
      type: Number,
      required: true
    },
    processed_count: {
      type: Number,
      required: true
    },
    uploaded_size: {
      type: Number,
      required: false
    },
    total_size: {
      type: Number,
      required: false
    },
  },
  computed: {
    uploadedPerc() {
      return (this.uploaded_count / this.intents_count) * 100
    },
    uploadSizePerc() {
      return (this.uploaded_size / this.total_size) * 100
    },
    receivedPerc() {
      return (this.received_count / this.uploaded_count) * 100
    },
    processedPerc() {
      return (this.processed_count / this.uploaded_count) * 100
    }
  }
}
</script>


<style scoped>

</style>