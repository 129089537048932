<template>
  <div>
    <PFRow :label="$pgettext('event_wizard.gallery.modal.label','Name')" size="12x12">
      <CInput v-model="form.name" :placeholder="$pgettext('event_wizard.gallery.modal.placeholder','Gallery name')"
              @input="onChange"/>
    </PFRow>
    <PFRow :label="$pgettext('event_wizard.gallery.modal.label','Parent')" size="12x12"
           v-if="galleryOptions.length">
      <PSelectKeyValue
          allowEmpty
          :options="galleryOptions"
          :value.sync="form.parent_gallery"
          :placeholder="$pgettext('event_wizard.gallery.modal.placeholder','Select parent gallery')"
          @input="onChange"
      />
    </PFRow>
  </div>
</template>

<script>

import PFRow from "@/domain/core/components/PFRow.vue";
import PSelectKeyValue from "@/domain/core/components/PSelectKeyValue.vue";
import {mapGetters} from "vuex";

export default {
  name: "GalleryCustom",
  components: {PSelectKeyValue, PFRow},
  emits: ['save'],
  data() {
    return {
      form: {
        name: null,
        parent_gallery: null,
      },
    }
  },
  computed: {
    ...mapGetters('eventGallery', [
      'galleries',
    ]),
    galleryOptions() {
      return this.galleries.map(g => ({id: g.id, name: g.name}))
    },
  },
  methods: {
    onChange() {
      this.$emit('save', this.form);
    }
  }
}
</script>