<template>
  <div>
    <PFRow :full-width="!form_in_line" :half-width="form_in_line" :separator="form_in_line" :label-class="form_in_line ? 'big':''" :label="$pgettext('priceList.linear.label','Complete set photo')">
      <CSelect v-model="configuration.complete_set_num" :options="setNums" @input="onUpdate"/>
    </PFRow>
    <PFRow :full-width="!form_in_line" :half-width="form_in_line" :separator="form_in_line" :label-class="form_in_line ? 'big':''" :label="$pgettext('priceList.linear.label','Complete set price')">
      <PCurrencyInput v-model="configuration.complete_set_price" :currency="currency" @input="onUpdate"/>
    </PFRow>
  </div>
</template>

<script>

import PFRow from "@/domain/core/components/PFRow.vue";
import priceListRanges from "@/domain/core/constant/priceListRanges";
import PCurrencyInput from "@/domain/core/components/PCurrencyInput.vue";
import {mapGetters} from "vuex";

export default {
  name: "PackPriceList",
  components: {PCurrencyInput, PFRow},
  emits: ['update'],
  props: {
    currency: {
      type: String,
      required: true
    },
    form_in_line: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      items: [],
      configuration: {
        complete_set_num: 10,
        complete_set_price: 20,
      }
    }
  },
  computed: {
    ...mapGetters('event', ['eventCurrency']),
    setNums() {
      const result = [];
      for (let i = 1; i <= 10; i++) {
        result.push({label: i, value: i});
      }
      return result;
    }
  },
  methods: {
    init({items, configuration}) {
      this.items = items
      this.configuration = {
        ...this.configuration,
        ...configuration
      }
      if (this.items.length <= 0)
        this.onUpdate()
    },
    getPriceList() {
      return {
        items: this.items,
        configuration: this.configuration
      }
    },
    generateRows() {
      this.items = []
      const multiplier = priceListRanges.find(multiplier => multiplier.length === parseInt(this.configuration.complete_set_num))
      if (!multiplier)
        return false
      this.items = multiplier.map((multy, index) => {
        let amount = this.configuration.complete_set_price * multy
        if (multy !== 1)
          amount = this.roundPrice(amount, this.configuration.complete_set_price)
        return {
          qty: index + 1,
          price: amount,
        }
      })
      return true
    },
    roundPrice(value, maxAmount) {
      // Step 1: Obtain the rounding factor
      // 24.5 -> 0.245 -> 1,
      // 245 -> 2.45 -> 1,
      // 2450 -> 24.5 -> 10
      const rounding = Math.pow(10, Math.floor(Math.log10(maxAmount / 100)))

      // Step 2: Divide the value by the rounding factor and round the result
      let roundedValue = Math.round(value / rounding)

      // Step 3: Multiply the result by rounding to get the rounded value
      return roundedValue * rounding
    },
    onUpdate() {
      if (!this.generateRows())
        return

      this.$emit('update', {
        items: this.items,
        configuration: this.configuration
      })
    }
  }
}
</script>