<template>
  <div>
    <div v-for="item in perms.perms" :key="item.full_key" class="pl-2">
      <CButton @click="onClickPermission(item.full_key)" class="photo-browser__grouper__bt-check"  :disabled="!item.editable">
        <CIcon :name="item.icon.icon"
               :class="[item.icon.class, 'mr-2']"
               size="custom-size"
               :width="24"/>
        <span class="photo-browser__grouper__tile">{{ item.label }}</span>
      </CButton>
    </div>
    <div v-for="item in perms.groups" :key="item.full_key" class="pl-2">
      <CButton @click="onClickGroup(item.full_key)" class="photo-browser__grouper__bt-check" :disabled="!item.editable">
        <CIcon :name="item.icon.icon"
               :class="[item.icon.class, 'mr-2']"
               size="custom-size"
               :width="24"/>
        <span class="photo-browser__grouper__tile">{{ item.label }}</span>
      </CButton>
      <div class="pl-2">
        <Permission :permissions="item.perms" :active="item.active" :prefix="item.prefix" @click="onClickSub($event)"/>
      </div>
    </div>
  </div>
</template>

<script>
import Permission from "@/domain/roles/components/Permission.vue";

export default {
  name: "Permission",
  emits: ['click'],
  components: {
    Permission
  },
  props: {
    permissions: {
      type: Object,
      required: true
    },
    active: {
      type: [Object, String],
      required: false
    },
    prefix: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    perms() {
      const keys = Object.keys(this.permissions)
      return keys.reduce((acc, key) => {
        const full_key = `${this.prefix}${key}`
        if (this.permissions[key] === true) {
          acc.perms.push({
            key: key,
            full_key: full_key,
            icon: this.getIcon(key),
            label: this.getLabel(key),
            editable: this.active !== '*'
          })
        } else {
          let groupActive = null
          if (this.active === '*')
            groupActive = '*'
          else if (this.active && this.active[key])
            groupActive = this.active[key]

          acc.groups.push({
            key: key,
            full_key: full_key,
            icon: this.getIcon(key),
            label: this.getLabel(key),
            active: groupActive,
            perms: this.permissions[key],
            prefix: `${this.prefix}${key}.`,
            editable: this.active !== '*'
          })
        }
        return acc
      }, {perms: [], groups: []})
    }
  },
  methods: {
    onClickGroup(key) {
      if (this.active === '*') return
      this.$emit('click', {key:key,value:'*'})
    },
    onClickPermission(key) {
      if (this.active === '*') return
      this.$emit('click', {key:key,value:true})
    },
    onClickSub(event) {
      if (this.active === '*') return
      this.$emit('click', event)
    },
    getLabel(key) {
      const full_key = `${this.prefix}${key}`
      const label = this.$pgettext('permissions.permission', full_key)
      if (label !== full_key)
        return label
      return key.replace(/_/g, ' ').replace(/\b\w/, l => l.toUpperCase())
    },
    getIcon(key) {
      if (!this.active)
        return {icon: 'cipCircle', class: 'off'}
      if (this.active === '*')
        return {icon: 'cipCheck', class: 'partial'}
      if (this.active[key] === true || this.active[key] === '*')
        return {icon: 'cipCheck', class: 'on'}
      return {icon: 'cipCircle', class: 'off'}
    }
  }
}
</script>

<style scoped>

</style>