<template>
  <div>
    <PFRow full-width :label="$pgettext('event_wizard.price_list.form', 'Price list type')">
      <PSelectKeyValue :options="priceListOptions" :value="form.configuration.type" @input="onChangeLayoutType"/>
    </PFRow>
    <PackPriceList
        :form_in_line="false"
        :currency="eventCurrency"
        @update="onUpdatePrice"
        ref="prices"
        v-if="form.configuration.type === priceListType.pack"
    />
    <UnitPriceList
        :form_in_line="false"
        :currency="eventCurrency"
        @update="onUpdatePrice"
        ref="prices"
        v-if="form.configuration.type === priceListType.unit"
    />
    <CustomPriceList
        :currency="eventCurrency"
        @update="onUpdatePrice"
        ref="prices"
        v-if="form.configuration.type === priceListType.custom"
    />
    <TemplatePriceList
        ref="prices"
        @update="onUpdatePrice"
        v-if="form.configuration.type === priceListType.template"
    />
    <template v-if="form.configuration.type === priceListType.empty">
      Set the price later in the gallery
    </template>
  </div>
</template>

<script>

import 'vue2-timepicker/dist/VueTimepicker.css'
import PSelectKeyValue from "@/domain/core/components/PSelectKeyValue.vue";
import priceListType from "@/domain/core/constant/priceListType";
import PackPriceList from "@/domain/eventGallery/tab/priceList/PackPriceList.vue";
import UnitPriceList from "@/domain/eventGallery/tab/priceList/UnitPriceList.vue";
import PFRow from "@/domain/core/components/PFRow.vue";
import {mapGetters} from "vuex";
import TemplatePriceList from "@/domain/eventGallery/tab/priceList/TemplatePriceList.vue";
import CustomPriceList from "@/domain/eventGallery/tab/priceList/CustomPriceList.vue";

export default {
  name: "CompactPriceSelector",
  emits: ['update'],
  components: {
    CustomPriceList,
    TemplatePriceList,
    PFRow,
    UnitPriceList,
    PackPriceList,
    PSelectKeyValue
  },
  props: {
    release: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        items: [],
        configuration: {},
      },
    }
  },
  mounted() {
    this.onChangeData()
  },
  computed: {
    ...mapGetters('event', ['eventCurrency']),
    priceListType() {
      return priceListType
    },
    priceListOptions() {
      return priceListType.options(this.release, false, this.release, true)
    }
  },
  methods: {
    async onChangeData() {
      this.form = {
        ...this.data,
      }
      this.form.configuration = {type: priceListType.empty}
      await this.initPrices()
    },
    onUpdatePrice({items, configuration}) {
      this.form.items = items
      this.form.configuration = {
        ...configuration,
        type: this.form.configuration.type
      }
      this.onUpdate()
    },
    async onUpdate() {
      this.$emit('update', {
        ...this.form,
      })
    },
    async onChangeLayoutType(newTypeObj) {
      this.form = {
        items: [],
        configuration: {
          type: newTypeObj.value
        },
      }
      return await this.initPrices(true)
    },
    async initPrices(onChange = false) {
      await this.$nextTick()
      await this.delay(100)
      if (!this.$refs.prices) return
      this.$refs.prices.init({
        items: this.form.items,
        configuration: this.form.configuration,
        onChange: onChange
      })
    }
  }
}
</script>