import ApiClient from "@/domain/core/utils/apiClient";
import client_core from "@/services/clients/client_core";
const client = new ApiClient(client_core)

const endpoints = {
    logo_set_create: '/dashboard/v2/event-wizard/{picaServiceId}/logo_set/',
    logo_set_get: '/dashboard/v2/event-wizard/{picaServiceId}/logo_set/{id}/',
    logo_set_update: '/dashboard/v2/event-wizard/{picaServiceId}/logo_set/{id}/',
    logo_set_delete: '/dashboard/v2/event-wizard/{picaServiceId}/logo_set/{id}/',
    logo_preview: '/dashboard/v2/event-wizard/{picaServiceId}/preview/logo/',
}

export default {
    async fetchLogoSet(picaServiceId, id) {
        return await client.get(endpoints.logo_set_get.replace('{picaServiceId}', picaServiceId).replace('{id}', id))
    }, async updateLogoSet(picaServiceId, logoSet) {
        return await client.patch(
            endpoints.logo_set_get.replace('{picaServiceId}', picaServiceId).replace('{id}', logoSet.id),
            logoSet
        )
    }, async createLogoSet(picaServiceId, logoSet) {
        return await client.post(
            endpoints.logo_set_create.replace('{picaServiceId}', picaServiceId),
            logoSet
        )
    },async deleteLogoSet(picaServiceId, id) {
        await client.delete(endpoints.logo_set_delete.replace('{picaServiceId}', picaServiceId).replace('{id}', id))
    },async getPreviewUrl(picaServiceId, data) {
        return await client.post(endpoints.logo_preview.replace('{picaServiceId}', picaServiceId),data)
    }
}