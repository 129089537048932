import Vue from "vue";
import moment from "moment";
import MediaCluster from "@/domain/photoSearch/mediaCluster";
import TimeLine from "@/domain/photoSearch/timeLine";
import GallerySorting from "@/domain/core/constant/gallerySorting";
import Downloader from "@/domain/core/utils/downloader";

const maxMediaInFilter = 50 // this is duplicated in core PhotoSearchViewSet.PAGINATED_FILTER_NUM, the numbers MUST coincide

const state = {
    media: {
        received: {},
        storage: {},
        clusters: {},
        clusterBy: MediaCluster.BY_DAY,
        first: null,
        last: null,
        hasBefore: true,
        hasAfter: true,
        sorting: GallerySorting.shot_at_asc,
        defaultSorting: GallerySorting.shot_at_asc,
        hasTimeline: true,
        hideTimeline: false,
        lastCreatedAt: null,
        lastProcessedAt: null,
    },
    timeline: {
        storage: {},
        clusters: [],
        sorted: [],
    },
    filter: {
        options: {},
        value: {
            date_range: {}
        },
    },
    search: {
        query: {
            file_name: null
        },
        storage: null,
        results: null,
        result: null,
    },
    availableTags: null,
    galleries: null,
    pica_service_id: null,
    gallery_id: null,
    timeZone: null,
    permissions: {
        canUpdateTags: false,
        canFilterByPhotographers: false,
        canFilterByTags: false,
        canFilterByPicaCode: false,
        canFilterByRaceNumber: false,
        canFilterByShotAt: false,
        canFilterByDeliveryStatus: false,
        canDownload: false,
        canSetGallery: false,
        canCopy: false,
        canDelete: false,
        canSortMedia: false,
        canUpload: false,
        canSearchByFileName: false,
        canSoftDelete: false,
        canRestore: false,
        canSeeId: false,
        canEditMediaUploadedByOther: false,
        canExportMediaPicaCode: false,
        canSeeDelivered: false,
        canSeeDeliverable: false,
        canSeeCheckin: false,
    },
    configLoading: true,
    tabLoading: true,
    detail: null,
}

function updateMediaState(state) {
    const clusterManager = new MediaCluster(state.media.clusterBy, state.media.sorting)
    state.media.sorted = clusterManager.getSortedMedia(state.media.storage)
    state.media.clusters = clusterManager.getClusters(state.media.sorted, state.timeline.clusters)
    state.media.first = state.media.sorted[0]
    state.media.last = state.media.sorted[state.media.sorted.length - 1]
}

function getCursor(getters, media) {
    if ([GallerySorting.shot_at_asc, GallerySorting.shot_at_desc].includes(getters.sorting))
        return {date: media.shot_at}
    if ([GallerySorting.upload_asc, GallerySorting.upload_desc].includes(getters.sorting))
        return {date: media.id}
    if (getters.sorting === GallerySorting.custom)
        return {cursor: media.order}
    throw new Error('Invalid sorting')
}

export const getters = {
    configLoading: state => state.configLoading,
    tabLoading: state => state.tabLoading,
    hasTimeline: state => state.media.hasTimeline,
    hideTimeline: state => state.media.hideTimeline,
    lastCreatedAt: state => state.media.lastCreatedAt,
    lastProcessedAt: state => state.media.lastProcessedAt,
    hasMedia: state => Object.keys(state.media.storage).length > 0,
    numMedia: state => Object.keys(state.media.storage).length,
    hasActiveFilters: (state, getters) => getters.activeFilters.length > 0,
    singleMedia: state => id => state.media.storage[id],
    sorting: state => {
        if (state.media.sorting) return state.media.sorting
        return localStorage.getItem('photoSearch-sort-' + state.pica_service_id + '-' + state.gallery_id) ?? null
    },
    defaultSorting: state => state.media.defaultSorting,
    clusters: state => state.media.clusters,
    media: state => state.media.sorted,
    timeline: state => state.timeline.clusters,
    clusterBy: state => state.media.clusterBy,
    timeZone: state => state.timeZone,
    hasBefore: state => state.media.hasBefore,
    hasAfter: state => state.media.hasAfter,
    firstMedia: state => state.media.first,
    lastMedia: state => state.media.last,
    pica_service_id: state => state.pica_service_id,
    gallery_id: state => state.gallery_id,
    route_param: state => {
        return {
            pica_service_id: state.pica_service_id,
            gallery_id: state.gallery_id
        }
    },
    filters: state => state.filter.value,
    options: state => state.filter.options,
    permissions: state => state.permissions,
    searchResults: state => state.search.results,
    searchResult: state => state.search.result,
    searchQuery: state => state.search.query,
    availableTags: state => state.availableTags,
    galleries: state => state.galleries,
    targetGalleries: state => {
        if (!state.galleries) return []
        return state.galleries.map(gallery => {
            const sub = gallery.sub.filter(sub => sub.id !== state.gallery_id && !sub.passive)

            if (gallery.passive || gallery.id === state.gallery_id) {
                if (sub.length === 0) return null
                return {
                    ...gallery,
                    sub: sub
                }
            }

            sub.unshift({
                id: gallery.id,
                name: gallery.name,
                is_public: gallery.is_public,
            })
            return {
                ...gallery,
                sub: sub
            }
        }).filter(gallery => gallery !== null)
    },
    mediaTags: state => tags => {
        if (state.gallery_id !== null)
            tags = tags.filter(tag => !tag.startsWith('g:'))
        return tags.map(tag => {
            if (tag.startsWith('g:')) {
                if (state.galleries === null) return tag
                const idGallery = parseInt(tag.substring(2))
                const gallery = state.galleries.find(gallery => gallery.id === idGallery)
                if (gallery) return gallery.name
                return tag
            }
            return tag
        })
    },
    activeFilters: state => {
        return Object.keys(state.filter.value).map(key => {
            const value = state.filter.value[key]
            if (key === 'date_range') {
                if (value.from && value.to)
                    return {type: key, value: `${value.from.format('YYYY-MM-DD')} - ${value.to.format('YYYY-MM-DD')}`}
                if (value.from)
                    return {type: key, value: `From ${value.from.format('YYYY-MM-DD')}`}
                if (value.to)
                    return {type: key, value: `To ${value.to.format('YYYY-MM-DD')}`}
                return null
            } else
                return {type: key, value: value}
        }).filter(filter => filter !== null)
    },
    stats: (state, getters, rootState, rootGetters) => {
        // gallery stats non si aggiorna anche se carico le foto, le riceve etc
        // inoltre c'è da considerare che questi dati sono in differetiva, mentre la funzione remainingUpload ha dati più aggiornati
        if (state.gallery_id > 0)
            return rootGetters['uploader/galleryStats']
        return rootGetters['uploader/eventStats']
    },
    detail: state => state.detail,
}

export const mutations = {
    pica_service_id(state, pica_service_id) {
        state.pica_service_id = pica_service_id
    },
    gallery_id(state, gallery_id) {
        state.gallery_id = gallery_id > 0 ? gallery_id : null
        state.media.sorting = null
    },
    configLoading(state, loading) {
        state.configLoading = loading
        if (loading) state.tabLoading = true
    },
    timeZone(state, timezone) {
        state.timeZone = timezone
    },
    filters(state, filters) {
        if (!filters)
            filters = {date_range: {}}
        state.filter.value = filters
    },
    addMedia(state, photos) {
        state.tabLoading = false
        if (photos.length === 0) return 0

        photos.map(photo => {
            if (state.media.storage[photo.id]) return null
            photo.shot_at = moment.tz(photo.shot_at, state.timeZone)
            Vue.set(state.media.storage, photo.id, photo)
        })
        updateMediaState(state)
    },
    newMediaUploaded(state, photos) {
        if (photos.length === 0) return

        let lastCreatedAt = state.media.lastCreatedAt

        const cluster = new MediaCluster(state.media.clusterBy, state.media.sorting)


        photos = photos.map(photo => {
            // Check if already present in list
            if (state.media.storage[photo.id]) return null

            // We need to add another layer of check, if we skip the media ( out of viewport or similar )
            // this could already be counted, but not added to the media storage
            // if we don't acknowledge this, we will count it again in timeline or in other position
            if (state.media.received[photo.id]) return null
            state.media.received[photo.id] = true

            // Normalize field with moment for consistency with media.storage
            photo.shot_at = moment.tz(photo.shot_at, state.timeZone)
            photo.created_at = moment.tz(photo.created_at, state.timeZone)

            // Update the lastCreatedAt data to avoid asking again for the same media
            if (!lastCreatedAt || photo.created_at > lastCreatedAt)
                lastCreatedAt = photo.created_at

            // If the new media are out of the viewport, we won't add it to the current visibile media
            if (state.media.hasBefore && cluster.isMediaBefore(photo, state.media.first)) return photo
            if (state.media.hasAfter && cluster.isMediaAfter(photo, state.media.last)) return photo

            // Add the media to the storage
            Vue.set(state.media.storage, photo.id, photo)
            return photo
        }).filter(photo => photo !== null)


        if (photos.length === 0) return

        Vue.set(state.media, 'lastCreatedAt', lastCreatedAt)
        updateMediaState(state)

        if (state.media.hasTimeline) {
            const timeLineManager = new TimeLine(state.timeline.storage, state.media.sorting)
            state.timeline.storage = timeLineManager.incrementCounters(photos, state.media.clusterBy)
            state.timeline.clusters = timeLineManager.getCluster(state.media.clusterBy)
        }
    },
    newMediaProcessed(state, photos) {
        if (photos.length === 0) return

        let lastProcessedAt = state.media.lastProcessedAt

        photos.map(photo => {
            if (!lastProcessedAt || photo.processed_at > lastProcessedAt)
                lastProcessedAt = photo.processed_at

            let oldMedia = state.media.storage[photo.id]
            if (!oldMedia) return

            oldMedia.processed_at = photo.processed_at
            Vue.set(state.media.storage, oldMedia.id, oldMedia)
        })

        Vue.set(state.media, 'lastProcessedAt', lastProcessedAt)
    },
    resetMedia(state) {
        state.tabLoading = true
        state.media = {
            storage: {},
            received: {},
            clusters: [],
            sorted: [],
            first: null,
            last: null,
            hasBefore: true,
            hasAfter: true,
            clusterBy: state.media.clusterBy,
            sorting: state.media.sorting,
            hasTimeline: state.media.hasTimeline,
            hideTimeline: state.media.hideTimeline,
            lastCreatedAt: state.media.lastCreatedAt,
            lastProcessedAt: state.media.lastProcessedAt,
            defaultSorting: state.media.defaultSorting,
        }
    },
    timeline(state, timeline) {
        state.timeline.storage = timeline.map(item => {
            item.date = moment.tz(item.date, state.timeZone)
            item.key = item.date.format('YYYYMMDDHHmm')
            return item
        })

        const timeLineManager = new TimeLine(state.timeline.storage, state.media.sorting)

        if (state.media.hideTimeline) {
            state.media.clusterBy = MediaCluster.BY_DAY
            state.timeline.clusters = timeLineManager.getCluster(state.media.clusterBy)
        } else {
            const optimal = timeLineManager.getOptimalClusterization()
            state.timeline.clusters = optimal.clusters
            state.media.clusterBy = optimal.clusterBy
        }
    },
    resetTimeline(state) {
        state.timeline = {
            storage: {},
            clusters: []
        }
    },
    setClusterBy(state, clusterBy) {
        state.media.clusterBy = clusterBy
        if (state.media.hasTimeline) {
            const timeLineManager = new TimeLine(state.timeline.storage, state.media.sorting)
            state.timeline.clusters = timeLineManager.getCluster(clusterBy)
        } else {
            state.timeline.clusters = []
        }

        const clusterManager = new MediaCluster(clusterBy, state.media.sorting)
        state.media.clusters = clusterManager.getClusters(state.media.sorted, state.timeline.clusters)
    },
    hasBefore(state, hasBefore) {
        state.media.hasBefore = hasBefore
    },
    hasAfter(state, hasAfter) {
        state.media.hasAfter = hasAfter
    },
    config(state, config) {
        state.permissions = {
            ...state.permissions,
            canUpdateTags: 'can_update_tags' in config,
            canFilterByPhotographers: 'can_filter_by_photographers' in config,
            canFilterByTags: 'can_filter_by_tags' in config,
            canFilterByPicaCode: 'can_filter_by_pica_code' in config,
            canFilterByRaceNumber: 'can_filter_by_race_number' in config,
            canFilterByShotAt: 'can_filter_by_shot_at' in config,
            canFilterByDeliveryStatus: 'can_filter_by_delivery_status' in config,
            canDownload: 'can_download' in config,
            canSetGallery: 'can_set_gallery' in config,
            canCopy: 'can_copy' in config,
            canDelete: 'can_delete' in config,
            canSortMedia: 'can_sort_media' in config,
            canUpload: 'can_upload' in config,
            canSearchByFileName: 'can_search_by_file_name' in config,
            canSoftDelete: 'can_soft_delete' in config,
            canRestore: 'can_restore' in config,
            canSeeId: 'can_see_id' in config,
            canEditMediaUploadedByOther: 'can_edit_media_uploaded_by_other' in config,
            canExportMediaPicaCode: 'can_export_media_pica_code' in config,
            canSeeDeliverable: 'can_see_deliverable' in config,
            canSeeDelivered: 'can_see_delivered' in config,
            canSeeCheckin: 'can_see_checkin' in config,
        }

        let clusterBy = MediaCluster.BY_DAY
        if (!config.timeline || state.media.sorting === GallerySorting.custom)
            clusterBy = MediaCluster.NO_CLUSTER

        state.media = {
            ...state.media,
            sorting: config.sorting,
            defaultSorting: config.default_sorting,
            lastCreatedAt: config.last_created_at ? moment.tz(config.last_created_at, state.timeZone) : null,
            lastProcessedAt: config.last_processed_at ? moment.tz(config.last_processed_at, state.timeZone) : null,
            hasTimeline: config.timeline,
            hideTimeline: config.hide_timeline,
            clusterBy: clusterBy
        }
        state.configLoading = false
    },
    sorting(state, sorting) {
        localStorage.setItem('photoSearch-sort-' + state.pica_service_id + '-' + state.gallery_id, sorting)
        state.media.sorting = sorting
    },
    filtersValue(state, filters) {
        if (!filters) {
            state.filter.options = null
            return
        }
        state.filter.options = {
            photographers: 'photographers' in filters ? filters.photographers : [],
            tags: 'tags' in filters ? filters.tags : []
        }
    },
    setSearchQuery(state, query) {
        if (query === null)
            query = {}
        state.search.query = query
    },
    searchResult(state, media) {
        if (!media || media.length <= 0) {
            state.search.storage = []
            state.search.results = null
            state.search.result = null
            return
        }

        const mediaCluster = new MediaCluster(state.media.clusterBy, state.media.sorting)
        const results = media.map(item => {
            item.shot_at = moment.tz(item.shot_at, state.timeZone)
            return item
        })
        state.search.storage = mediaCluster.getSortedMedia(results)
        state.search.results = state.search.storage.reduce((acc, photo) => {
            acc[photo.id] = photo
            return acc
        }, {})
    },
    setActiveSearchResult(state, index) {
        const result = state.search.storage[index]
        if (!result) {
            state.search.result = null
            return
        }

        state.search.result = {
            id: result.id,
            shot_at: result.shot_at,
            actual: index + 1,
            index: index,
            total: state.search.storage.length,
            hasPrev: index > 0,
            hasNext: index < state.search.storage.length - 1
        }
    },
    setGallery(state, {ids, gallery}) {
        if (state.gallery_id) {
            // If we have a filtered-by-gallery media list, then if we change the gallery
            // the media will not be visible anymore
            ids.map(id => {
                Vue.delete(state.media.storage, id)
            })
        } else {
            // In case we have a global list, then the gallery is just a tag, so we can add it
            // Since changing the gallery imply that we will remove other galleries tag, we will remove all tags relating 'g:*'
            ids.map(id => {
                let photo = state.media.storage[id]
                photo.tags = photo.tags.filter(tag => !tag.startsWith('g:'))
                photo.tags.push(`g:${gallery}`)
                Vue.set(state.media.storage, photo.id, photo)
            })
        }
        updateMediaState(state)
    },
    addTags(state, {ids, tags}) {
        ids.map(id => {
            let photo = state.media.storage[id]
            if (!photo.tags) photo.tags = []
            for (let tag of tags) {
                if (!photo.tags.includes(tag))
                    photo.tags.push(tag)
            }
            Vue.set(state.media.storage, photo.id, photo)
        })
        updateMediaState(state)
    },
    removeTags(state, {ids}) {
        ids.map(id => {
            let photo = state.media.storage[id]
            photo.tags = []
            Vue.set(state.media.storage, photo.id, photo)
        })
        updateMediaState(state)
    },
    moveTop(state, {ids}) {
        // If we don't have the first media, we can't move the media to the top
        // ( we don't have the first order, nor we can show it until we are at the beginning )
        if (state.media.hasBefore) {
            ids.map(id => {
                Vue.delete(state.media.storage, id)
            })
        } else {
            let firstOrder = state.media.sorted[0].order
            ids.map(id => {
                let photo = state.media.storage[id]
                photo.order = --firstOrder
                Vue.set(state.media.storage, photo.id, photo)
            })
        }
        updateMediaState(state)
    },
    moveBottom(state, {ids}) {
        // If we don't have the last media, we can't move the media to the bottom
        // ( we don't have the last order, nor we can show it until we are at the end )
        if (state.media.hasAfter) {
            ids.map(id => {
                Vue.delete(state.media.storage, id)
            })
        } else {
            let lastOrder = state.media.sorted[state.media.sorted.length - 1].order
            ids.map(id => {
                let photo = state.media.storage[id]
                photo.order = ++lastOrder
                Vue.set(state.media.storage, photo.id, photo)
            })
        }
        updateMediaState(state)
    },
    reorder(state, {ids, before, after}) {
        const media = state.media.sorted
        ids = ids.map(id => parseInt(id))
        const destination = before ?? after

        let orders = media.filter(item => ids.includes(item.id) || item.id === destination).reduce((acc, item) => {
            if (acc.first === null || item.order < acc.first) acc.first = item.order
            if (acc.last === null || item.order > acc.last) acc.last = item.order
            if (item.id === destination) acc.dest = item.order
            return acc
        }, {first: null, last: null, dest: null})

        let toReorder = media.filter(item => item.order >= orders.first && item.order <= orders.last).reduce((acc, item) => {
            if (ids.includes(item.id)) acc.selected.push(item)
            else if (item.id === destination) acc.dest.push(item)
            else acc.other.push(item)
            return acc
        }, {selected: [], other: [], dest: []})

        const reorderFunct = (media) => {
            media.order = orders.first++
            Vue.set(state.media.storage, media.id, media)
        }

        if (orders.dest === orders.last) {
            if (before > 0) {
                toReorder.other.map(reorderFunct)
                toReorder.selected.map(reorderFunct)
                toReorder.dest.map(reorderFunct)
            } else {
                toReorder.other.map(reorderFunct)
                toReorder.dest.map(reorderFunct)
                toReorder.selected.map(reorderFunct)
            }
        } else {
            if (before > 0) {
                toReorder.selected.map(reorderFunct)
                toReorder.dest.map(reorderFunct)
                toReorder.other.map(reorderFunct)
            } else {
                toReorder.dest.map(reorderFunct)
                toReorder.selected.map(reorderFunct)
                toReorder.other.map(reorderFunct)
            }
        }

        updateMediaState(state)
    },
    replaceMedia(state, {ids, media}) {
        media.map(photo => {
            photo.shot_at = moment.tz(photo.shot_at, state.timeZone)
            Vue.set(state.media.storage, photo.id, photo)
        })
        ids.filter(id => !media.find(photo => photo.id === parseInt(id))).map(id => {
            Vue.delete(state.media.storage, id)
        })
        updateMediaState(state)
    },
    availableTags(state, tags) {
        state.availableTags = tags
    },
    galleries(state, galleries) {
        state.galleries = galleries
    },
    detail(state, detail) {
        state.detail = detail
    }
}


export const actions = service => ({
    setPicaServiceId({commit}, {picaServiceId, galleryId}) {
        service.abort()
        commit('pica_service_id', picaServiceId)
        commit('gallery_id', galleryId)
        commit('resetMedia')
        commit('resetTimeline')
        commit('setSearchQuery', null)
        commit('searchResult', null)
        commit('filters', null)
        commit('filtersValue', null)
        commit('availableTags', null)
        commit('galleries', null)
    }, setTimezone({commit}, timezone) {
        commit('timeZone', timezone)
    }, setFilters({commit}, filters) {
        service.abort()
        commit('resetMedia')
        commit('resetTimeline')
        commit('filters', filters)
    }, setSorting({commit}, sorting) {
        service.abort()
        commit('resetMedia')
        commit('resetTimeline')
        commit('sorting', sorting)
    }, setClusterBy({commit}, clusterBy) {
        commit('setClusterBy', clusterBy)
    }, async fetchBefore({commit, getters}) {
        const firstMedia = getters['firstMedia']
        if (!firstMedia) return
        if (!getters['hasBefore']) return

        const media = await service.fetchPhotos(getters['route_param'], {
            ...getters['filters'],
            ...getCursor(getters, firstMedia),
            cursor_before_id: firstMedia.id,
        }, getters.sorting)
        commit('addMedia', media)
        if (media.length < maxMediaInFilter) commit('hasBefore', false)
    }, async fetchAfter({commit, getters}) {
        const lastMedia = getters['lastMedia']
        if (!lastMedia) return
        if (!getters['hasAfter']) return
        const media = await service.fetchPhotos(getters['route_param'], {
            ...getters['filters'],
            ...getCursor(getters, lastMedia),
            cursor_after_id: lastMedia.id,
        }, getters.sorting)
        commit('addMedia', media)
        if (media.length < maxMediaInFilter) commit('hasAfter', false)
    }, async fetchFirstMedia({commit, getters}) {
        commit('resetMedia')
        const media = await service.fetchPhotos(getters['route_param'], getters['filters'], getters.sorting)
        commit('addMedia', media)
        if (media.length < maxMediaInFilter) commit('hasBefore', false)
        if (media.length < maxMediaInFilter) commit('hasAfter', false)
    }, async fetchForKey({commit, getters}, key) {
        const mediaCluster = new MediaCluster(getters['clusterBy'])
        const range = mediaCluster.getDateRangeForKey(key, getters['timeZone'])
        commit('resetMedia')
        const media = await service.fetchPhotos(getters['route_param'], {
            ...getters['filters'],
            date: getters.sorting === GallerySorting.shot_at_desc ? range.to : range.from,
        }, getters.sorting)
        commit('addMedia', media)
    }, async fetchFromId({commit, getters}, id) {
        commit('resetMedia')
        const media = await service.fetchPhotos(getters['route_param'], {
            ...getters['filters'],
            id: id
        }, getters.sorting)
        commit('addMedia', media)
    }, async downloadPhotos({getters}, {ids, email, appyLogo}) {
        const url = await service.downloadPhotos(
            getters['route_param'],
            ids,
            email,
            appyLogo
        )

        if (!email) {
            const date = moment().format('YYYY-MM-DD-HH-mm-ss')
            const fileName = `${this.currentEventName}-${date}.zip`
            Downloader.downloadFromUrl(url, fileName)
        }
    }, async fetchConfig({commit, getters}) {
        commit('configLoading', true)
        commit('config', await service.fetchConfig(getters['route_param'], getters.sorting))
        commit('configLoading', false)
    }, async fetchTimeline({commit, getters}) {
        if (!getters['hasTimeline']) return
        const timeline = await service.fetchTimeline(getters['route_param'], getters['filters'], getters['sorting'])
        commit('timeline', timeline)
    }, async fetchFilterOptions({commit, getters}) {
        const filters = await service.fetchFilterValues(getters['route_param'])
        commit('filtersValue', filters)
    }, async fetchTags({commit, getters}) {
        if (getters['availableTags']) return
        commit('availableTags', await service.fetchTags(getters['route_param']))
    }, async fetchGalleries({commit, getters}) {
        if (getters['galleries']) return
        commit('galleries', await service.fetchGalleries(getters['route_param']))
    }, async fetchIdsCluster({getters}, key) {
        const mediaCluster = new MediaCluster(getters['clusterBy'])
        const range = mediaCluster.getDateRangeForKey(key, getters['timeZone'])
        return await service.fetchIdsForDate(getters['route_param'], range.from, range.to, getters['filters'])
    }, async setSearchQuery({commit, getters}, query) {
        commit('setSearchQuery', query)
        commit('searchResult', null)
        if (!query) return

        const results = await service.fetchSearch(getters['route_param'], query)
        commit('searchResult', results)
        commit('setActiveSearchResult', 0)
    }, prevSearchResult({commit, getters}) {
        if (!getters['searchResults']) return
        const index = getters['searchResult'].index
        commit('setActiveSearchResult', index - 1)
    }, nextSearchResult({commit, getters}) {
        if (!getters['searchResults']) return
        const index = getters['searchResult'].index
        commit('setActiveSearchResult', index + 1)
    }, async addTags({commit, getters}, {ids, tags}) {
        await service.addTags(getters['route_param'], ids, tags)
        commit('addTags', {ids, tags})
    }, async removeTags({commit, getters}, {ids}) {
        await service.removeTags(getters['route_param'], ids)
        commit('removeTags', {ids})
    }, async setGallery({commit, getters}, {ids, gallery}) {
        await service.setGallery(getters['route_param'], ids, gallery)
        commit('setGallery', {ids, gallery})
    }, async copyToGallery({getters}, {ids, gallery}) {
        await service.copyToGallery(getters['route_param'], ids, gallery)
    }, async deleteMedia({commit, getters}, ids) {
        const updatedMedia = await service.deleteMedia(getters['route_param'], ids, getters['filters'])
        commit('replaceMedia', {ids, media: updatedMedia})
    }, async moveTop({commit, getters}, {ids}) {
        await service.moveTop(getters['route_param'], ids)
        commit('moveTop', {ids})
    }, async moveBottom({commit, getters}, {ids}) {
        await service.moveBottom(getters['route_param'], ids)
        commit('moveBottom', {ids})
    }, async newMediaUploaded({commit, getters}, taskId) {
        const newMedia = await service.fetchNewMedia(getters['route_param'], getters['lastCreatedAt'], taskId)
        if (newMedia.length <= 0) return
        commit('newMediaUploaded', newMedia)
    }, async newMediaProcessed({commit, getters}, taskId) {
        const idsInProcessing = getters['media'].filter(media => !media.is_processed).map(media => media.id)
        if (idsInProcessing.length === 0) return

        const processedMedia = await service.fetchNewProcessed(getters['route_param'], {
            processed_at: getters['lastProcessedAt'],
            ids: idsInProcessing,
            taskId: taskId
        })
        commit('newMediaProcessed', processedMedia)
    }, async reorder({commit, getters}, {ids, before, after}) {
        // for immediate feedback during drag and drop we commit the mutation before the request
        commit('reorder', {ids, before, after})
        await service.reorder(getters['route_param'], ids, before, after)
    }, async softDelete({commit, getters}, ids) {
        const updatedMedia = await service.softDelete(getters['route_param'], ids, getters['filters'])
        commit('replaceMedia', {ids, media: updatedMedia})
    }, async restore({commit, getters}, ids) {
        const updatedMedia = await service.restore(getters['route_param'], ids, getters['filters'])
        commit('replaceMedia', {ids, media: updatedMedia})
    }, async exportMediaPicaCode({getters}) {
        await service.exportMediaPicaCode(getters['route_param'])
    }, async fetchMediaDetail({getters, commit}, id) {
        if (getters.detail && getters.detail.id === id) return
        commit('detail', null)
        commit('detail',await service.fetchMediaDetail(getters['route_param'], id))
    }
})

export default service => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(service)
})

