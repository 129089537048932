import {ApiException} from "@/domain/core/exception/exceptions";
import {emptyListResponse} from "@/domain/core/utils/django";

const state = {
    tags: {...emptyListResponse},
    tag: {},
    categories: {...emptyListResponse},
    category: {},
}

export const getters = {
    tags: state => state.tags,
    tag: state => state.tag,
    categories: state => state.categories,
    category: state => state.category,
}


export const mutations = {
    tags(state, tags) {
        state.tags = tags
    },
    tag(state, tag) {
        state.tag = tag
    },
    deleteTag(state, id) {
        state.tags.results = state.tags.results.filter(tag => tag.id !== id)
    },
    categories(state, categories) {
        state.categories = categories
    },
    category(state, category) {
        state.category = category
    },
    deleteCategory(state, id) {
        state.categories.results = state.categories.results.filter(tag => tag.id !== id)
    }
}


export const actions = tagService => ({
    async fetchTagList({commit}, {category, filters, pagination, sorting}) {
        try {
            const tags = await tagService.fetchTagList(category, filters, pagination, sorting)
            commit('tags', tags)
        } catch (e) {
            if (e instanceof ApiException) {
                commit('tags', emptyListResponse)
            }
            throw e
        }
    },
    async deleteTag({commit}, id) {
        await tagService.deleteTag(id)
        commit('deleteTag', id)
    },
    async fetchTag({commit}, id) {
        const tag = await tagService.fetchTag(id)
        commit('tag', tag)
    },
    async createTag({commit}, data) {
        const tag = await tagService.createTag(data)
        commit('tag', tag)
    },
    async updateTag({commit}, {id, data}) {
        const tag = await tagService.updateTag(id, data)
        commit('tag', tag)
    },
    async fetchCategoryList({commit}, {filters, pagination, sorting}) {
        try {
            const categories = await tagService.fetchCategoryList(filters, pagination, sorting)
            commit('categories', categories)
        } catch (e) {
            if (e instanceof ApiException) {
                commit('categories', emptyListResponse)
            }
            throw e
        }
    },
    async deleteCategory({commit}, id) {
        await tagService.deleteCategory(id)
        commit('deleteCategory', id)
    },
    async fetchCategory({commit}, id) {
        const category = await tagService.fetchCategory(id)
        commit('category', category)
    },
    async createCategory({commit}, data) {
        const category = await tagService.createCategory(data)
        commit('category', category)
    },
    async updateCategory({commit}, {id, data}) {
        const category = await tagService.updateCategory(id, data)
        commit('category', category)
    }
})

export default tagService => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(tagService)
})

