import axiosRetry from 'axios-retry'
import store from '../../store'
import axios from "axios";


const instance = axios.create({
    baseURL: process.env.VUE_APP_PICA_CORE_API_BASE_URL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest'
    }
})

// Exponential back-off retry delay between requests, 2^N secs
axiosRetry(instance, {
    retries: 4,
    retryDelay: (retryNumber) => Math.pow(2, retryNumber) * 1000,
    retryCondition: axiosRetry.isNetworkOrIdempotentRequestError
})

const token = localStorage.getItem('token')
if (token) {
    instance.defaults.headers.common['Authorization'] = `Token ${token}`
}


instance.interceptors.response.use(null, function(error) {
    const errorResponseStatus = error.response ? error.response.status : error
    if (401 === errorResponseStatus) {
        console.warn('axios interceptor: 401 error')
        store.dispatch('login/logout', {onlyLocal: true})
            .then(() => Promise.reject(error))
    }

    return Promise.reject(error)
})

export default instance


export function getFileAndDownload(options, filename=null, retryDelay=4000) {
    return instance({
        method: options.method === undefined ? 'get' : options.method,
        url: options.url,
        params: options.params || {},
        responseType: 'blob',
        // override retry policy as download needs more time before retry
        'axios-retry': {
            retries: 3,
            retryDelay: (retryNumber) => Math.pow(2, retryNumber) * retryDelay,
            retryCondition: axiosRetry.isNetworkOrIdempotentRequestError
        }
    }).then(function(response) {
        let blob = new Blob([response.data], { type: options.fileType })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)

        if (filename === null) {
            const headerLine = response.headers['content-disposition']
            filename = headerLine.split('filename=')[1].replace(/"/g, '')
        }
        link.download = filename

        document.body.appendChild(link)
        link.click()
    })
}
