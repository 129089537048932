const ListPriceList = () => import('./ListPriceList.vue')
const EditPriceList = () => import('./EditPriceList.vue')
const NewPriceList = () => import('./NewPriceList.vue')
const TheContainer = () => import('@/containers/TheContainer')

export default [
    {
        path: '/priceListTemplate',
        name: 'priceListTemplate',
        component: TheContainer,
        meta: {perms: ['admin.price_list.list']},
        children: [
            {
                path: 'list',
                name: 'priceListTemplate.list',
                component: ListPriceList,
                meta: {label: 'List Price List Template',},
            },
            {
                path: 'new',
                name: 'priceListTemplate.new',
                component: NewPriceList,
                meta: {label: 'Create newPrice List Template',},
            },
            {
                path: ':id',
                name: 'priceListTemplate.edit',
                component: EditPriceList,
                meta: {label: 'Edit Price List Template',},
            },
        ]
    }
]