<template>
  <PFRow :full-width="!form_in_line" :half-width="form_in_line" :separator="form_in_line" :label-class="form_in_line ? 'big':''" :label="$pgettext('priceList.unit.label','Unit price')">
    <PCurrencyInput v-model="configuration.unit_price" :currency="currency" @input="onUpdate"/>
  </PFRow>
</template>

<script>

import PFRow from "@/domain/core/components/PFRow.vue";
import {mapGetters} from "vuex";
import PCurrencyInput from "@/domain/core/components/PCurrencyInput.vue";

export default {
  name: "UnitPriceList",
  components: {PCurrencyInput, PFRow},
  emits: ['update'],
  data() {
    return {
      items: [],
      configuration: {
        unit_price: null
      }
    }
  },
  props: {
    currency: {
      type: String,
      required: true
    },
    form_in_line: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters('event', ['eventCurrency'])
  },
  methods: {
    init({items, configuration}) {
      this.items = items
      this.configuration = {
        unit_price: configuration.unit_price ?? 5
      }
      if (this.items.length <= 0)
        this.onUpdate()
    },
    getPriceList() {
      return {
        items: this.items,
        configuration: this.configuration
      }
    },
    generateRows() {
      this.items = [
        {
          from_qty: 1,
          to_qty: 9999,
          unit_price: parseFloat(this.configuration.unit_price)
        }
      ]
      return true
    },
    onUpdate() {
      if (!this.generateRows())
        return
      this.$emit('update', {
        items: this.items,
        configuration: this.configuration
      })
    }
  }
}
</script>