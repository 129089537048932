const TheContainer = () => import('@/containers/TheContainer')

const Login = () => import('./Login.vue')
const Logout = () => import('./Logout.vue')
const Register = () => import('./Register.vue')
const UserProfile = () => import('./UserProfile.vue')
const Accept = () => import('./Accept.vue')
const Validate = () => import('./Validate.vue')

export default [
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            title: 'Login',
            label: 'Login',
            requiresNotAuth: true
        },
        props: {allowRegister: false},
    },
     {
        path: '/logout',
        name: 'logout',
        component: Logout,
        meta: {allowsUnAuth: true},
    },
    {
        path: '/login_or_register',
        name: 'login_or_register',
        component: Login,
        meta: {
            title: 'Login',
            label: 'Login',
            requiresNotAuth: true
        },
        props: {allowRegister: true},
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            title: 'Signup',
            label: 'Signup',
            requiresNotAuth: true
        },
    },
    {
        path: '/accept',
        name: 'login.accept',
        component: Accept,
        meta: {
            title: 'Accept Invitation',
            label: 'Accept Invitation',
            allowsUnAuth: true
        },
    },
    {
        path: '/validate/:email',
        name: 'login.validate',
        component: Validate,
        meta: {
            title: 'E-Mail validation',
            label: 'E-Mail validation',
            requiresNotAuth: true
        },
    },
    {
        path: '/settings',
        redirect: '/settings/user',
        name: 'settings',
        meta: {
            title: 'Settings',
            label: 'Settings',
        },
        component: TheContainer,
        children: [
            {
                path: 'user',
                name: 'user',
                component: UserProfile,
                meta: {
                    title: 'User profile', // $pgettext('routes', 'User profile'),
                    label: 'User profile', // $pgettext('routes', 'User profile')
                }
            },
        ]
    },

]