const Invitations = () => import('./Invitations.vue')

export default [
    {
        path: 'invitations',
        name: 'event.invitations.list',
        component: Invitations,
        meta: {
            label: 'Invitations',
        },
    }
]