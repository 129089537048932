const OrderList = () => import('@/views_pica/orders/OrderList.vue')
const OrderDetail = () => import('@/views_pica/orders/OrderDetail.vue')


export default [
    {
        path: 'orders',
        redirect: 'orders',
        name: 'eventOrders',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        meta: {
            label: 'Orders',
            title: 'Orders'
        },
        children: [
            {
                path: '',
                name: 'eventOrderList',
                component: OrderList,
                meta: {
                    label: 'Orders',
                    title: 'Event Orders',
                    perms: ['event_wizard.see_orders']
                },
            },
            {
                path: ':orderId([0-9]+)',
                name: 'eventOrderDetail',
                component: OrderDetail,
                meta: {
                    label: 'Order detail',
                    perms: ['event_wizard.see_orders']
                },
            },

        ]
    },
]
