import report from './nav/report'
import administration from './nav/administration'
import configuration from './nav/configuration'
import events from './nav/events'
import photo_service from './nav/photo_service'
import payments from './nav/payments'
import customerSuccess from "@/containers/nav/customerSuccess";


export default [
    ...events,
    ...customerSuccess,
    ...photo_service,
    ...administration,
    ...configuration,
    ...report,
    ...payments,
]
