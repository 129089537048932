<template>
  <CHeader fixed :with-subheader="showSubheader" :class="{'impersonating': isImpersonating}">
    <CToggler
        in-header
        class="ml-3 d-lg-none"
        @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
        in-header
        class="ml-3 d-md-down-none"
        @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <CIcon name="logoMinimized" height="30" alt="Logo" viewBox="0 0 69 68"/>
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">

    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <ImpersonateBadge/>
      <TheHeaderDropdownAccount/>
    </CHeaderNav>

    <CSubheader class="px-md-3" v-if="showSubheader">
      <PBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccount from './TheHeaderDropdownAccount.vue'
import PBreadcrumbRouter from "../components/PBreadcrumbRouter"
import ImpersonateBadge from "@/domain/impersonate/ImpersonateBadge.vue";
import {mapGetters} from "vuex";

export default {
  name: 'TheHeader',
  components: {
    ImpersonateBadge,
    TheHeaderDropdownAccount,
    PBreadcrumbRouter
  },
  props: {
    showSubheader: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters('impersonate', [
      'isImpersonating',
    ])
  },
}
</script>
