import ApiClient from "@/domain/core/utils/apiClient";
import client_core from "@/services/clients/client_core";

const client = new ApiClient(client_core)

const endpoints = {
    region_list: '/dashboard/v2/region/',
    region_detail: '/dashboard/v2/region/{id}/',
    category_list: '/dashboard/v2/region/{region}/category/',
    category_detail: '/dashboard/v2/region/{region}/category/{id}/',
    base_plan_list: '/dashboard/v2/region/{region}/category/{category}/base_plan/',
    base_plan_detail: '/dashboard/v2/region/{region}/category/{category}/base_plan/{id}/',
    search_plan: '/dashboard/v2/region/{region}/plan/',
    search_template: '/dashboard/v2/region/template/',
    search_vertical: '/dashboard/v2/region/vertical/',
}

export default {
    async fetchRegionList(filters, pagination, sorting) {
        let result = await client.get(endpoints.region_list, {
            ...filters,
            page_size: pagination.page_size ?? 20,
            page: pagination.page ?? 1,
            ordering: sorting.asc ? `-${sorting.column}` : sorting.column ?? ''
        })
        return {
            results: result.results,
            pagination: {
                num_pages: result.pagination.num_pages,
                page_size: result.pagination.page_size,
                page: result.pagination.page_number,
                total: result.pagination.total,
            }
        }
    },
    async deleteRegion(id) {
        await client.delete(endpoints.region_detail.replace('{id}', id))
    },
    async fetchRegion(id) {
        return await client.get(endpoints.region_detail.replace('{id}', id))
    },
    async updateRegion(id, data) {
        return await client.patch(endpoints.region_detail.replace('{id}', id), data)
    },
    async createRegion(data) {
        return await client.post(endpoints.region_list, data)
    },
    async fetchCategoryList(region, filters, pagination, sorting) {
        let result = await client.get(endpoints.category_list.replace('{region}', region), {
            ...filters,
            page_size: pagination.page_size ?? 20,
            page: pagination.page ?? 1,
            ordering: sorting.asc ? `-${sorting.column}` : sorting.column ?? ''
        })
        return {
            results: result.results,
            pagination: {
                num_pages: result.pagination.num_pages,
                page_size: result.pagination.page_size,
                page: result.pagination.page_number,
                total: result.pagination.total,
            }
        }
    },
    async deleteCategory(region, id) {
        await client.delete(endpoints.category_detail.replace('{region}', region).replace('{id}', id))
    },
    async fetchCategory(region, id) {
        return await client.get(endpoints.category_detail.replace('{region}', region).replace('{id}', id))
    },
    async updateCategory(region, id, data) {
        return await client.patch(endpoints.category_detail.replace('{region}', region).replace('{id}', id), data)
    },
    async createCategory(region, data) {
        return await client.post(endpoints.category_list.replace('{region}', region), data)
    },
    async fetchBasePlanList(region, category, filters, pagination, sorting) {
        let result = await client.get(endpoints.base_plan_list.replace('{region}', region).replace('{category}', category), {
            ...filters,
            page_size: pagination.page_size ?? 20,
            page: pagination.page ?? 1,
            ordering: sorting.asc ? `-${sorting.column}` : sorting.column ?? ''
        })
        return {
            results: result.results,
            pagination: {
                num_pages: result.pagination.num_pages,
                page_size: result.pagination.page_size,
                page: result.pagination.page_number,
                total: result.pagination.total,
            }
        }
    },
    async deleteBasePlan(region, category, id) {
        await client.delete(endpoints.base_plan_detail.replace('{region}', region).replace('{category}', category).replace('{id}', id))
    },
    async fetchBasePlan(region, category, id) {
        return await client.get(endpoints.base_plan_detail.replace('{region}', region).replace('{category}', category).replace('{id}', id))
    },
    async updateBasePlan(region, category, id, data) {
        return await client.patch(endpoints.base_plan_detail.replace('{region}', region).replace('{category}', category).replace('{id}', id), data)
    },
    async createBasePlan(region, category, data) {
        return await client.post(endpoints.base_plan_list.replace('{region}', region).replace('{category}', category), data)
    },
    async searchTemplate({id, query}) {
        const params = {}
        if (id) params.id = id
        if (query) params.search = query
        const result = await client.get(endpoints.search_template, params)
        return result.results
    },
    async searchVertical({id, query}) {
        const params = {}
        if (id) params.id = id
        if (query) params.search = query
        const result = await client.get(endpoints.search_vertical, params)
        return result.results
    },
}
