<template>
  <CCard>
    <CCardHeader>
      <div class="card-header-actions">
        <CButton variant="outline" color="success" @click="onReset" ref="reset-button" v-translate
                 translate-context="form" class="mr-2">Reset
        </CButton>
        <CButton color="primary" @click="onSave" ref="save-button">{{ save_text }}</CButton>
      </div>
    </CCardHeader>
    <CCardBody class="p-0">
      <CContainer fluid class="grid-stripped">
        <PFRow :label="$pgettext('role.label', 'Role Name')">
          <CInput v-model="form.name" :placeholder="$pgettext('role.placeholder','Name')"/>
        </PFRow>
        <PFRow :label="$pgettext('role.label', 'Permissions')">
          <Permission :active="activePermissions" :permissions="permissions" @click="onClickPermission"/>
        </PFRow>
        <PFRow :label="$pgettext('role.label', 'Visible By')">
          <div v-for="role in roles.results" :key="role.key">
            <CInputCheckbox :checked="form.visible_by.includes(role.key)" :label="role.name" @update:checked="updateVisibleBy(role.key,$event)"/>
          </div>
        </PFRow>
        <PFRow :label="$pgettext('role.label', 'Description')">
          <CTextarea v-model="form.description" :placeholder="$pgettext('role.placeholder','Description')"/>
        </PFRow>
      </CContainer>
    </CCardBody>
  </CCard>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PFRow from "@/domain/core/components/PFRow.vue";
import Permission from "@/domain/roles/components/Permission.vue";

export default {
  name: "Form",
  components: {Permission, PFRow},
  props: {
    data: {
      type: Object,
      required: true
    },
    save_text: {
      type: String,
      required: true
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        name: '',
        permissions: {},
        visible_by: [],
        description: '',
      },
      dot_permissions: {}
    }
  },
  async mounted() {
    await this.fetchPermissions()
    await this.fetchRoleList({pagination: {page_size: 200}})
    this.onReset()
  },
  emits: ['save'],
  computed: {
    ...mapGetters('roles', [
      'permissions',
      'roles'
    ]),
    activePermissions() {
      return this.fromDotPermission(this.dot_permissions)
    }
  },
  methods: {
    ...mapActions('roles', [
      'fetchPermissions',
      'fetchRoleList',
    ]),
    onReset() {
      this.form = {...this.data}
      this.dot_permissions = this.toDotPermission(this.form.permissions)
    },
    onClickPermission({key, value}) {
      if (value === '*') {
        if (this.dot_permissions[key]) {
          this.$delete(this.dot_permissions, key)
          return
        } else {
          this.$set(this.dot_permissions, key, '*')
          this.removeSubKeys(key)
          return
        }
      }
      if (this.dot_permissions[key])
        this.$delete(this.dot_permissions, key)
      else
        this.$set(this.dot_permissions, key, true)
    },
    removeSubKeys(key) {
      Object.keys(this.dot_permissions).forEach(k => {
        if (k === key) return
        if (k.indexOf(key) === 0)
          this.$delete(this.dot_permissions, k)
      })
    },
    fromDotPermission(dot_permissions) {
      return Object.keys(dot_permissions).reduce((acc, key) => {
        const keys = key.split('.');
        let obj = acc
        for (let i = 0; i < keys.length; i++) {
          const k = keys[i];
          if (i === keys.length - 1)
            obj[k] = dot_permissions[key]
          else {
            if (!obj[k])
              obj[k] = {}
            obj = obj[k]
          }
        }
        return acc
      }, {})
    },
    toDotPermission(obj, prefix = '') {
      return Object.keys(obj).reduce((acc, key) => {
        const newKey = prefix ? `${prefix}.${key}` : key;
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          Object.assign(acc, this.toDotPermission(obj[key], newKey));
        } else {
          acc[newKey] = obj[key];
        }
        return acc;
      }, {});
    },
    updateVisibleBy(key, value) {
      if (value) {
        if (!this.form.visible_by.includes(key))
          this.form.visible_by.push(key)
      } else {
        const index = this.form.visible_by.indexOf(key)
        if (index !== -1)
          this.form.visible_by.splice(index, 1)
      }
    },
    async onSave() {
      this.form.permissions = this.activePermissions
      this.$emit('save', this.form)
    },
  }
}
</script>

<style scoped>

</style>