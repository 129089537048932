var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[_c('h1',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"main-header",attrs:{"translate-context":"invoice"}},[_vm._v(" Credit Notes ")]),_c('CCard',{staticClass:"no-borders"},[_c('CCardBody',{staticClass:"px-0"},[_c('CDataTable',{staticClass:"custom-table",attrs:{"hover":false,"bordered":true,"items":_vm.creditNotes.results,"fields":_vm.fields,"column-filter":{external: true,lazy:true},"column-filter-value":_vm.filters,"sorter":{external: true, resetable:true},"loading":_vm.loading,"sorter-value":_vm.sorting,"striped":""},on:{"update:column-filter-value":_vm.updateFilters,"update:sorter-value":_vm.updateSorting},scopedSlots:_vm._u([{key:"no-items-view",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('h6',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("No items found")])])]},proxy:true},{key:"sorting-icon",fn:function(ref){
var state = ref.state;
var classes = ref.classes;
return [_c('div',{class:classes},[(state === 'asc' || state === 0)?_c('CIcon',{attrs:{"name":"cipCaretUp","size":"lg"}}):_c('CIcon',{attrs:{"name":"cipCaretUp","size":"lg"}})],1)]}},{key:"order_number",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('a',{staticClass:"font-weight-semi-bold",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.openOrderPage(item)}}},[_vm._v(_vm._s(item.order_number))])])]}},{key:"amount",fn:function(ref){
var item = ref.item;
return [_c('td',{},[_vm._v(" "+_vm._s(_vm._f("currency")(item.amount,item.currency))+" ")])]}},{key:"number",fn:function(ref){
var item = ref.item;
return [_c('td',{},[(item.number)?[_vm._v(" "+_vm._s(item.number)+" ")]:_vm._e()],2)]}},{key:"vat_number",fn:function(ref){
var item = ref.item;
return [_c('td',{},[_c('div',{staticClass:"d-flex align-items-center text-nowrap"},[_vm._v(" "+_vm._s(item.vat_number)+" "),_c('CLink',{staticClass:"ml-2",on:{"click":function($event){_vm.copyInClipboard(item.vat_number, _vm.$gettext('VAT/Fiscal code'))}}},[_c('CIcon',{attrs:{"name":"cipCopy","size":"custom-size","height":18}})],1)],1)])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',{},[_c('CBadge',{staticClass:"badge-outline",attrs:{"color":"light"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('CIcon',{staticClass:"mr-2",attrs:{"name":item.sent_at ? 'cipDotGreen' : 'cipDotOrange',"size":"custom-size","height":8}}),(item.sent_at)?[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"font-weight-normal",attrs:{"translate-context":"invoice.status"}},[_vm._v("Completed")])]:[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"font-weight-normal",attrs:{"translate-context":"invoice.status"}},[_vm._v("Pending")])]],2)])],1)]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-right"},[(_vm.hasPerm('orders.invoices.confirm'))?_c('CButton',{staticClass:"btn-circle btn-circle--sm",attrs:{"color":"dark","variant":"outline","size":"sm"},on:{"click":function($event){return _vm.openDetail(item.id)}}},[_c('CIcon',{attrs:{"name":"cipPencilLine","size":"sm"}})],1):_vm._e()],1)]}},{key:"under-table",fn:function(){return [_c('CPagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.creditNotes.pagination.num_pages > 1),expression:"creditNotes.pagination.num_pages > 1"}],attrs:{"activePage":_vm.creditNotes.pagination.page,"pages":_vm.creditNotes.pagination.num_pages},on:{"update:activePage":_vm.updatePage}})]},proxy:true}])})],1)],1)],1)],1),_c('CreditNoteDetailModal',{ref:"creditNoteDetailModal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }