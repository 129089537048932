import Constants from "@/constants";
const PhotoServiceList = () => import('@/views_pica/photo_services/PhotoServiceList')
const PhotoServiceDetail = () => import('@/views_pica/photo_services/PhotoServiceDetail')
// const PhotographerList = () => import('@/views_pica/photo_services/PhotographerList')
const PhotoServiceUsersManager = () => import('@/views_pica/photo_services/PhotoServiceUsersManager')

// Views
const EventList = () => import('@/domain/events/EventList.vue')
const EventDataEntry = () => import('@/views_pica/events/EventDataEntry')

const OrderList = () => import('@/views_pica/orders/OrderList')
const OrderDetail = () => import('@/views_pica/orders/OrderDetail')

const TheContainer = () => import('@/containers/TheContainer')

export default [
    {
        path: '/',
        redirect: '/events',
        name: 'index',
        component: TheContainer,
        meta: {
            title: 'Dashboard',
            label: 'Dashboard'
        },
        children: [
            {
                path: 'events',
                name: 'eventList',
                component: EventList,
                meta: {
                    title: 'Eventi',
                    label: 'Eventi',
                    perms: ['events.list']
                }
            },
            {
              path: "/manage/events_data_entry",
              component: EventDataEntry,
              props: { isDataEntry: true },
              meta: {
                label: "Events Data Entry",
                title: "Events Data Entry",
                perms: ['dashboard.change_event_turnover']
              },
            },
      
            {
                path: 'manage/orders',
                // redirect: '/manage/orders',
                //name: 'orders',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                meta: {
                    label: 'Orders',
                    title: 'Orders'
                },
                children: [
                    {
                        path: '',
                        name: 'orderList',
                        component: OrderList,
                        meta: {
                            label: 'Orders',
                            title: 'Orders',
                            perms: ['orders.list']
                        },
                    },
                    {
                        path: ':orderId([0-9]+)',
                        name: 'orderDetail',
                        component: OrderDetail,
                        meta: {
                            label: 'Order detail'
                        },
                    },
                ]
            },


            {
                path: 'manage/photo-services',
                // name: 'photoServicesIndex',
                component: {
                    render(c) {
                        return c('router-view')
                    }
                },
                meta: {
                    title: 'Photo services',
                    label: 'Photo services',
                },
                children: [
                    {
                        path: '',
                        name: 'photoServiceList',
                        component: PhotoServiceList,
                        meta: {
                            title: 'Photo services',
                            label: 'Photo services',
                            perms: ['pica.view_photoservice']
                        },
                    },

                    {
                        path: 'new',
                        name: 'photoServiceNew',
                        component: PhotoServiceDetail,
                        meta: {
                            title: 'New photo service',
                            label: 'New photo service',
                            perms: ['pica.add_photoservice']
                        }
                    },

                    {
                        path: '',
                        redirect: ':photoServiceId([0-9]+)/change',
                        // name: 'photoServiceDetail',
                        meta: {
                            title: 'Edit photo service',
                            label: 'Edit photo service'
                        },
                        component: {
                            render(c) {
                                return c('router-view')
                            }
                        },
                        children: [
                            {
                                path: ':photoServiceId([0-9]+)/change',
                                name: 'photoServiceDetail',
                                component: PhotoServiceDetail,
                                meta: {
                                    title: 'Edit',
                                    label: 'Edit',
                                    perms: ['pica.view_photoservice']
                                }
                            },
                            {
                                path: ':photoServiceId([0-9]+)/photographers',
                                name: 'PhotoServiceUsersManagerPhotographers',
                                component: PhotoServiceUsersManager,
                                props: {userGroup: Constants.USER_GROUP_PHOTOGRAPHERS},
                                meta: {
                                    title: 'Photographers',
                                    label: 'Photographers',
                                    perms: ['pica.view_photoservice']
                                }
                            },
                            {
                                path: ':photoServiceId([0-9]+)/photographer-managers',
                                name: 'PhotoServiceUsersManagerPhotographerManagers',
                                component: PhotoServiceUsersManager,
                                props: {userGroup: Constants.USER_GROUP_PHOTOGRAPHERS_MANAGERS},
                                meta: {
                                    title: 'Photographer managers',
                                    label: 'Photographer managers',
                                    perms: ['pica.view_photoservice']
                                }
                            },
                            {
                                path: ':photoServiceId([0-9]+)/legal-representative',
                                name: 'PhotoServiceUsersManagerLegalRepresentative',
                                component: PhotoServiceUsersManager,
                                props: {userGroup: Constants.USER_GROUP_LEGAL_REPRESENTATIVES},
                                meta: {
                                    title: 'Legal representative',
                                    label: 'Legal representative',
                                    perms: ['pica.view_photoservice']
                                }
                            },
                        ]
                    },
                ]
            },

            {
                path: 'my-photo-service/photographers',
                name: 'photographerList',
                component: PhotoServiceUsersManager,
                props: {userGroup: Constants.USER_GROUP_PHOTOGRAPHERS},
                // component: PhotographerList,
                meta: {
                    title: 'Photographers',
                    label: 'Photographers',
                    perms: ['dashboard.view_feature_photoservice_photographers']
                }
            },
        ]
    },
]