import {translate} from 'vue-gettext'

const {pgettext: $pgettext} = translate

export const tooltipColor = '#A9A8C2'
export const tooltipEventWizard = {
    mediaKit: {
        content: $pgettext('overview.tooltip', 'Send this link via email, whatsapp or text message and guests will be able to access their photos in just one click.'),
        placement: 'top',
    },
    qrCode: {
        content: $pgettext('overview.tooltip', 'Display the QR code and guests will be able to access their photos by scanning it.'),
        placement: 'top',
    },
    galleryUpload: {
        content: $pgettext('overview.tooltip', 'Select the folder and upload all the photos of the event.'),
        placement: 'top',
    },
    galleryPricelist: {
        content: $pgettext('overview.tooltip', 'Set your price list to sell your photos'),
        placement: 'top',
    },
    activateEvent: {
        header: $pgettext('event.tooltip', 'Activate event') + '<span class="p-closer j-activate-event">&times;</span>',
        content: $pgettext('event.tooltip', 'Confirm the chosen package and after activating the event you will be able to upload all the photos. In the overview section you will find the direct link and the QR code to communicate to participants to access their photo album.'),
        placement: 'top',
    },
    newEvent: {
        header: $pgettext('event.tooltip', 'Create your first event') + '<span class="p-closer">&times;</span>',
        content: $pgettext('event.tooltip', ''),
        placement: 'bottom',
    },
     shareEvent: {
        header: $pgettext('event.tooltip', 'Share the event') + '<span class="p-closer">&times;</span>',
        content: $pgettext('event.tooltip', 'View the access tools and share them with attendees to invite them to access their album'),
        placement: 'top-start',
    }
}




