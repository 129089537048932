import ApiClient from "@/domain/core/utils/apiClient";
import client_core from "@/services/clients/client_core";
const client = new ApiClient(client_core)

const endpoints = {
    list: '/dashboard/v2/checkins/{picaServiceId}/checkins/', // GET
    export: '/dashboard/v2/checkins/{picaServiceId}/checkins/export-csv/', // GET
    detail: '/dashboard/v2/checkins/{picaServiceId}/checkin/{id}/', // GET
    updateDateOfStay: '/dashboard/v2/checkins/{picaServiceId}/checkin/{id}/date_of_stay/', // PUT
}

export default {
    async fetchCheckins(pica_service_id, params) {
        return await client.get(endpoints.list.replace('{picaServiceId}', pica_service_id), params)
    },
    async exportCheckins(pica_service_id, params) {
        return await client.download(endpoints.export.replace('{picaServiceId}', pica_service_id), null,params, 'POST')
    },
     async fetchCheckin(pica_service_id, id) {
        return await client.get(endpoints.detail.replace('{picaServiceId}', pica_service_id).replace('{id}',id))
    },
    async updateDateOfStay(pica_service_id, id, {start_of_stay, end_of_stay}) {
        return await client.put(endpoints.updateDateOfStay.replace('{picaServiceId}', pica_service_id).replace('{id}',id), {
            start_of_stay,
            end_of_stay
        })
    }
}

