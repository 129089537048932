<template>
  <div>
    <div>
      <multiselect v-model="inputVal"
                   :options="userTimezones"
                   :placeholder="$pgettext('core.label','Select a timezone')"
                   class="tz-select"
                   @input="onInput">
      </multiselect>
    </div>
    <div class="mt-1 ml-2" v-if="showCheckbox">
      <CInputCheckbox custom :checked.sync="checked"
                      @click="setDefaultToBrowserTimezone($event.target.checked)"
                      :label="$pgettext('core.label', 'Default to browser timezone')"/>
    </div>

  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import Multiselect from "vue-multiselect";
import timeZone from "@/domain/core/utils/timeZone";

export default {
  name: "TimezoneSelect",
  components: {Multiselect},
  data() {
    return {
      loading: true,
      checked: false,
    }
  },
  props: {
    value: {
      type: String,
      required: false,
    },
    defaultToBrowserTimezone: {
      type: Boolean,
      default: false,
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['update:value'],
  async mounted() {
    await this.fetchTimeZones()
    this.loading = false

    if (this.value === null && this.defaultToBrowserTimezone) {
      this.setDefaultToBrowserTimezone(true)
    }
  },

  computed: {
    ...mapGetters('core', [
      'timeZones'
    ]),
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    userTimezones() {
      return [this.getBrowserTimeZone(), ...this.timeZones.filter(tz => tz !== this.getBrowserTimeZone())]
    }
  },

  methods: {
    ...mapActions('core', [
      'fetchTimeZones',
    ]),
    setDefaultToBrowserTimezone(val) {
      if (val) {
        const userTimezone = this.getBrowserTimeZone()
        if (userTimezone) this.$emit('input', userTimezone)
      }
    },
    getBrowserTimeZone() {

      const timezone = timeZone.getBrowserDefault()
      if (!this.isValidTimezone(timezone)) return null
      return timezone
    }, isValidTimezone(timezone) {
      return this.timeZones.includes(timezone)
    },
    onInput(val) {
      this.checked = false
      this.$emit('input', val)
    }
  },

}
</script>