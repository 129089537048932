
const FileSystemExtractor = {
    isSupported() {
        return 'getAsFileSystemHandle' in DataTransferItem.prototype
    },
    async extract(items) {
        const files = []
        const handles = items
            .filter((item) => item.kind === 'file') // directory are marked as file ( ... )
            .map((item) => item.getAsFileSystemHandle())

        for await (const handle of handles) {
            if (handle.kind === 'directory' || handle.isDirectory)
                files.push(...await this.readDirectory(handle))
            else
                files.push(await handle.getFile())
        }
        return files
    },
    async readDirectory(directory) {
        const ret = []
        for await(let handle of directory.values()){
            if (handle.kind === 'directory' || handle.isDirectory)
                 ret.push(...await this.readDirectory(handle))
            else
                ret.push(await handle.getFile())
          }
        return ret
    }
}

export default FileSystemExtractor