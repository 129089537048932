<template>
  <div v-if="contract_manager">
    <h1 class="main-header">
      <translate translate-context="contract_managers"
                 :translate-params="{id: contract_manager.id, name: contract_manager.name}">Contract Manager #%{id} -
        %{name}
      </translate>
    </h1>

    <PTabs :tabs="tabs" :selected="tab" @select="goToTab" pills class="mb-4"/>


    <template v-if="hasPerm('contract_manager.contract.list') && tab === 'contracts'">
      <CCard>
        <CCardHeader>
          <h4 v-translate translate-context="contract_manager">Contracts</h4>
          <div class="card-header-actions">
            <CButton color="primary" @click="onCreateNewContract" v-translate
                     v-if="hasPerm('contract_manager.contract.create')">Create Contract
            </CButton>
          </div>
        </CCardHeader>
        <CCardBody class="p-0">
          <Contracts :contract_manager="contract_manager" :rows="contract_manager.contracts" v-if="contract_manager.contracts" @createEvent="onCreateEvent"/>
        </CCardBody>
      </CCard>
      <CreateContractModal ref="create_contract_modal"/>
      <CreateEventModal ref="create_event_modal"/>
    </template>
    <template v-if="hasPerm('contract_manager.invitation.list') && tab === 'users'">
      <CCard>
        <CCardHeader>
          <h4 v-translate translate-context="contract_manager">Users</h4>
          <div class="card-header-actions">
            <CButton color="primary" @click="onInviteNewUser" v-translate
                     v-if="hasPerm('contract_manager.invitation.invite')">Invite User
            </CButton>
          </div>
        </CCardHeader>
        <CCardBody class="p-0">
          <Invitations :rows="contract_manager.invitations" v-if="contract_manager.invitations"/>
        </CCardBody>
      </CCard>
      <InviteUserModal ref="invite_user_modal" @sent="onInvitationSent"/>
    </template>
    <template v-if="hasPerm('contract_manager.role.list') && tab === 'roles'">
      <CCard>
        <CCardHeader>
          <h4 v-translate translate-context="contract_manager">Contracts</h4>
          <div class="card-header-actions">
            <CButton color="primary" @click="onCreateNewRole" v-translate
                     v-if="hasPerm('contract_manager.role.create')">Create Role
            </CButton>
          </div>
        </CCardHeader>
        <CCardBody class="p-0">
          <Roles @edit="onEditRoleModal"/>
        </CCardBody>
      </CCard>
      <CreateRoleModal ref="create_role_modal"/>
      <EditRoleModal ref="edit_role_modal"/>
    </template>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {RecordNotFound} from "@/domain/core/exception/exceptions";
import CreateContractModal from "@/domain/contract_manager/components/CreateContractModal.vue";
import Contracts from "@/domain/contract_manager/components/Contracts.vue";
import Invitations from "@/domain/contract_manager/components/Invitations.vue";
import InviteUserModal from "@/domain/contract_manager/components/InviteUserModal.vue";
import PTabs from "@/domain/core/components/PTabs.vue";
import CreateRoleModal from "@/domain/contract_manager/components/CreateRoleModal.vue";
import EditRoleModal from "@/domain/contract_manager/components/EditRoleModal.vue";
import Roles from "@/domain/contract_manager/components/Roles.vue";
import CreateEventModal from "@/domain/contract_manager/components/CreateEventModal.vue";

export default {
  name: 'DetailContractManager',
  components: {
    CreateEventModal,
    Roles,
    EditRoleModal, CreateRoleModal, PTabs, InviteUserModal, Invitations, Contracts, CreateContractModal},
  data() {
    return {
      tab: 'contracts',
    }
  },
  async mounted() {
    try {
      await this.fetchContractManager(this.$route.params.id)
      let promises = []
      if (this.hasPerm('contract_manager.contract.list'))
        promises.push(this.fetchContracts())
      if (this.hasPerm('contract_manager.invitation.list'))
        promises.push(this.fetchInvitations())
      await Promise.all(promises)

    } catch (e) {
      if (e instanceof RecordNotFound) {
        await this.$router.push({name: 'contract_manager.list'})
      }
    }
    this.loading = false
  },
  computed: {
    ...mapGetters('contractManager', [
      'contract_manager',
    ]),
    tabs() {
      let tabs = []
      if (this.hasPerm('contract_manager.contract.list'))
        tabs.push({name: 'contracts', label: 'Contracts'})
      if (this.hasPerm('contract_manager.invitation.list'))
        tabs.push({name: 'users', label: 'Users'})
      if (this.hasPerm('contract_manager.role.list'))
        tabs.push({name: 'roles', label: 'Roles'})
      return tabs
    }
  },
  methods: {
    ...mapActions('contractManager', [
      'fetchContractManager',
      'fetchContracts',
      'fetchInvitations',
    ]),
    onCreateNewContract() {
      this.$refs.create_contract_modal.open()
    },
    onInviteNewUser() {
      this.$refs.invite_user_modal.open()
    },
    onCreateNewRole() {
      this.$refs.create_role_modal.open()
    },
    onEditRoleModal(role) {
      this.$refs.edit_role_modal.open(role)
    },
    onCreateEvent(contract) {
      this.$refs.create_event_modal.open(contract)
    },
    async onInvitationSent() {
      await this.fetchInvitations()
    },
    goToTab(tab) {
      this.tab = tab
    }
  }
}
</script>