import DetailContractManager from "@/domain/contract_manager/DetailContractManager.vue";

const Invitations = () => import('./Invitations.vue')
const TheContainer = () => import('@/containers/TheContainer')

export default [
    {
        path: '/invitations',
        name: 'invitations',
        component: TheContainer,
        children: [
            {
                path: 'list',
                name: 'invitations.list',
                component: Invitations,
                meta: {label: 'List Global Invitations',},
            },
        ]
    },
]

