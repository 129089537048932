<template>
  <CModal :show.sync="show" :title="$pgettext('invoice.modal.detail.title', 'Invoice detail')" centered>
    <template v-if="creditNote">
      <PFRow :label="$pgettext('invoice.modal.detail.label','Name')" size="12x12">
        <CInput readonly size="sm" :value="creditNote.name"/>
      </PFRow>
      <PFRow :label="$pgettext('invoice.modal.detail.label','Address')" size="12x12">
        <CInput readonly size="sm" :value="completeAddress"/>
      </PFRow>
      <PFRow :label="$pgettext('invoice.modal.detail.label','VAT/Fiscal code')" size="12x12">
        <CInput readonly size="sm" :value="creditNote.vat_number"/>
      </PFRow>
      <PFRow :label="$pgettext('invoice.modal.detail.label','Refund Amount')" size="12x12">
        <CInput readonly size="sm" :value="creditNote.amount|currency(creditNote.currency)"/>
      </PFRow>
      <PFRow :label="$pgettext('invoice.modal.detail.label','Invoice Number')" size="12x12">
        <CInput readonly size="sm" :value="creditNote.invoice_number"/>
      </PFRow>
      <PFRow :label="$pgettext('invoice.modal.detail.label','Invoice Emission Date')" size="12x12">
        <CInput readonly size="sm" :value="creditNote.invoice_sent_at|formatDate"/>
      </PFRow>
      <PFRow :label="$pgettext('invoice.modal.detail.label','Invoice Creation Date')" size="12x12">
        <CInput readonly size="sm" :value="creditNote.invoice_created_at|formatDate"/>
      </PFRow>
    </template>
    <PFRow :label="$pgettext('invoice.modal.detail.label','Credit note number')" size="12x12">
      <CInput size="sm" v-model="form.number"/>
    </PFRow>
    <PFRow :label="$pgettext('invoice.modal.detail.label','Sent at')" size="12x12">
      <VueCtkDateTimePicker
          no-label
          v-model="form.sent_at"
          format="YYYY-MM-DD"
          formatted="LL"
          :input-size="`sm`"
      />
    </PFRow>
    <PFRow :label="$pgettext('invoice.modal.detail.label','Created at')" size="12x12">
      <VueCtkDateTimePicker
          no-label
          v-model="form.created_at"
          format="YYYY-MM-DD"
          formatted="LL"
          :input-size="`sm`"
      />
    </PFRow>
    <template #footer>
      <CButton color="secondary" @click="show = false" v-translate>Close</CButton>
      <PButton
          v-if="hasPerm('orders.invoices.confirm')"
          color="primary"
          v-translate translate-context="invoice.modal.detail.actions"
          :loading="saving"
          @click="confirm">Confirm
      </PButton>
    </template>
  </CModal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PFRow from "@/domain/core/components/PFRow.vue";
import PButton from "@/components/PButton.vue";

export default {
  name: "CreditNoteDetailModal",
  components: {PButton, PFRow},
  data() {
    return {
      show: false,
      saving: false,
      form: {
        number: null,
        sent_at: null,
        created_at: null,
      },
    }
  },
  computed: {
    ...mapGetters('invoice', [
      'creditNote',
    ]),
    completeAddress() {
      return `${this.creditNote.street}, ${this.creditNote.zip_code}, ${this.creditNote.city}, ${this.creditNote.country}`
    }
  },
  methods: {
    ...mapActions('invoice', [
      'fetchCreditNote',
      'confirmCreditNote',
    ]),
    async open(id) {
      this.saving = false
      this.loading = true
      await this.fetchCreditNote(id)
      this.form.number = this.creditNote.number
      this.form.sent_at = this.creditNote.sent_at
      this.form.created_at = this.creditNote.created_at
      this.show = true
      this.loading = false
    },
    async confirm() {
      this.saving = true
      await this.confirmCreditNote({
        number: this.form.number,
        sent_at: this.form.sent_at,
        created_at: this.form.created_at,
      })
      this.show = false
    }
  }
}
</script>