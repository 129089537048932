import ApiClient from "@/domain/core/utils/apiClient";
import client_core from "@/services/clients/client_core";

const client = new ApiClient(client_core)

const endpoints = {
    invoice_list: '/dashboard/v2/invoices/', // GET
    invoice_detail: '/dashboard/v2/invoices/{id}/', // GET
    invoice_confirm: '/dashboard/v2/invoices/{id}/confirm/', // PATCH

    credit_note_list: '/dashboard/v2/credit_notes/', // GET
    credit_note_detail: '/dashboard/v2/credit_notes/{id}/', // GET
    credit_note_confirm: '/dashboard/v2/credit_notes/{id}/confirm/', // PATCH
}

export default {
    async fetchInvoiceList(filters, pagination, sorting) {
        if (filters.number)
            filters.invoice_number = filters.number
        else
            delete filters.invoice_number

        let result = await client.get(endpoints.invoice_list, {
            ...filters,
            page_size: pagination.page_size ?? 20,
            page: pagination.page ?? 1,
            ordering: sorting.asc ? `-${sorting.column}` : sorting.column ?? ''
        })
        return {
            results: result.results,
            pagination: {
                num_pages: result.pagination.num_pages,
                page_size: result.pagination.page_size,
                page: result.pagination.page_number,
                total: result.pagination.total,
            }
        }
    },
    async fetchInvoice(id) {
        return await client.get(endpoints.invoice_detail.replace('{id}', id))
    },
    async confirmInvoice(id, data) {
        return await client.patch(endpoints.invoice_confirm.replace('{id}', id),  {
            number: data.number,
            sent_at: data.sent_at,
            created_at: data.created_at,
        })
    },
    async fetchCreditNoteList(filters, pagination, sorting) {
        if (filters.number)
            filters.credit_note_number = filters.number
        else
            delete filters.credit_note_number

        let result = await client.get(endpoints.credit_note_list, {
            ...filters,
            page_size: pagination.page_size ?? 20,
            page: pagination.page ?? 1,
            ordering: sorting.asc ? `-${sorting.column}` : sorting.column ?? ''
        })
        return {
            results: result.results,
            pagination: {
                num_pages: result.pagination.num_pages,
                page_size: result.pagination.page_size,
                page: result.pagination.page_number,
                total: result.pagination.total,
            }
        }
    },
    async fetchCreditNote(id) {
        return await client.get(endpoints.credit_note_detail.replace('{id}', id))
    },
    async confirmCreditNote(id, data) {
        return await client.patch(endpoints.credit_note_confirm.replace('{id}', id),  {
            number: data.number,
            sent_at: data.sent_at,
            created_at: data.created_at,
        })
    }
}
