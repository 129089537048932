<template>
  <multiselect
      class="mb-2"
      :options="selectOptions"
      label="label"
      track-by="value"
      :value="selected"
      :searchable="searchable"
      @input="onChange"
      @search-change="fetchOptions"
      :allow-empty="allowEmpty"
      :show-labels="allowEmpty"
      :close-on-select="!multiple"
      :multiple="multiple"
      :placeholder="placeholder"
      :showNoOptions="!isAjax"
  >
    <template v-slot:option="{ option }">
      <div class="d-flex align-items-center">
        <div v-if="option.original.image" class="multiselect__image">
          <img :src="option.original.image"/>
        </div>
        {{ option.label }}
      </div>
    </template>
  </multiselect>

</template>

<script>
import Multiselect from "vue-multiselect"

export default {
  name: "PSelectKeyValue",
  components: {Multiselect},
  watch: {
    value: {
      async handler(value) {
        if (value === this.selected) return
        this.searchFirstValue()
      },
      immediate: true
    }
  },
  props: {
    options: {
      type: [Array, Function],
      required: true,
      default: () => []
    },
    value: {
      type: [Number, String, Array],
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: 'name'
    },
    image: {
      type: String,
      required: false,
    },
    trackBy: {
      type: String,
      required: false,
      default: 'id'
    },
    allowEmpty: {
      type: Boolean,
      required: false,
      default: false
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  emits: ['update:value', 'input'],
  computed: {
    searchable() {
      return this.isAjax || this.options.length > 10
    },
    placeholder() {
      return this.isAjax ? this.$pgettext('core.form.select', 'Type to search') : this.$pgettext('core.form.select', 'Select an option')
    },
    selectOptions() {
      let options = this.isAjax ? this.fetchedOptions : this.options
      if (!options)
        return []

      return options.map(option => {
        return {
          label: option[this.label],
          value: option[this.trackBy],
          original: option
        }
      })
    },
    selected() {
      if (this.multiple) {
        if (!this.value) return []

        return this.selectOptions.filter(option => this.value.includes(option.value))
      }
      return this.selectOptions.find(option => option.value === this.value)
    },
    isAjax() {
      return this.options instanceof Function
    }
  },
  data() {
    return {
      fetchedOptions: null,
    }
  },
  async mounted() {
    this.searchFirstValue()
  },
  methods: {
    searchFirstValue() {
      let params = {}
      if (this.isAjax) {
        // This should not be needed, since the value should already be in
        // for some reason, sometime it fails to detect the value, this solve the problem
        setTimeout(async () => {
          if (this.value) {
            params.id = this.value
            this.fetchedOptions = await this.options(params)
          } else {
            this.fetchedOptions = await this.options(params)
          }
        }, 100)
      }
    },
    onChange(option) {
      if (this.multiple) {
        if (!option) {
          this.$emit('update:value', [])
          this.$emit('input', [])
        } else {
          this.$emit('update:value', option.map(o => o.value))
          this.$emit('input', option)
        }
        return
      }

      if (!option) {
        this.$emit('update:value', null)
        this.$emit('input', null)
      } else {
        this.$emit('update:value', option.value)
        this.$emit('input', option)
      }
    },
    async fetchOptions(query) {
      if (!query || typeof this.options !== 'function') return
      this.fetchedOptions = await this.options({
        query: query,
      })
    }
  }
}
</script>