<template>
  <div v-if="canSeeGallery">
    <CSidebarNavTitle style="padding-right: 10px">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <span>{{ $gettext('Galleries') }}</span>
          <CIcon name="cipRefreshCw04" size="custom-size"
                 width="16" class="ml-2 rotating"
                 v-if="hasGlobalProcessingMedia"/>
        </div>
        <CLink @click="$emit('createGallery')" v-if="hasPerm('gallery.create')" class="p-0">
          <CIcon name="cip-plus" size="custom" :height="16" class="add-gallery"/>
        </CLink>
      </div>
    </CSidebarNavTitle>

    <draggable v-model="sortedGalleries" @end="updateGallerySorting" handle=".gallery-sort" ghost-class="ghost">
      <transition-group type="transition" :name="'flip-list'">
        <div v-for="gallery in sortedGalleries" :key="gallery.id">
          <div class="position-relative">
            <CSidebarNavItem
                :title="gallery.name"
                :name="gallery.name"
                :class="['blue-folder', {'has-sorter': sortedGalleries.length > 1 && canSort}]"
                :to="{name: `eventWizard.gallery.${tab}`, params: {picaServiceId: picaServiceId, galleryId: gallery.id}}"
                :icon="gallery.is_public ? 'cipFolderBlueStar' : 'cipFolderBlue'"
            />
            <div class="d-flex folder-actions">
              <CIcon name="cipDot" :width="8" class="mr-2 text-danger" v-if="gallery.missing_price_list"/>
              <div class="gallery-sort mr-1" v-if="sortedGalleries.length > 1 && canSort">
                <CIcon size="lg" name="cipMenu01"/>
              </div>
            </div>

          </div>

          <template v-if="gallery.sub.length">
            <draggable v-model="gallery.sub" @end="updateSubGallerySorting(gallery.id)" handle=".gallery-sort-sub"
                       ghost-class="ghost">
              <transition-group type="transition" :name="'flip-list'">
                <div v-for="(subGallery, index) in gallery.sub" :key="subGallery.id">
                  <div class="position-relative">
                    <CSidebarNavItem
                        :title="subGallery.name"
                        :class="['c-sidebar-nav-item__sub','blue-folder', {'has-sorter': sortedGalleries.length > 1 && canSort}, {'is-last': gallery.sub.length === index + 1}]"
                        :name="`${subGallery.name}`"
                        :to="{name: `eventWizard.gallery.${tab}`, params: {picaServiceId: picaServiceId, galleryId: subGallery.id}}"
                        :icon="subGallery.is_public ? 'cipFolderBlueStar' : 'cipFolderBlue'"
                    />
                    <div class="d-flex folder-actions">
                      <CIcon name="cipDot" :width="8" class="mr-2 text-danger"
                             v-if="subGallery.missing_price_list"/>
                      <div class="gallery-sort-sub mr-1" v-if="gallery.sub.length > 1 && canSort">
                        <CIcon size="lg" name="cipMenu01"/>
                      </div>
                    </div>
                  </div>
                </div>
              </transition-group>
            </draggable>
          </template>
        </div>
      </transition-group>
    </draggable>
    <template v-if="canSeePhotoSearch && requireAggregateMediaView">
      <CSidebarNavItem
          :class="['border-top', 'mt-2', 'pt-1']"
          :name="$gettext('Browse Photos')"
          :to="{name: 'photoSearch.search', params: {picaServiceId: picaServiceId}}"
          icon="cip-search"
      />
    </template>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import draggable from 'vuedraggable'

export default {
  name: 'GalleryNav',
  emits: ['createGallery'],
  components: {
    draggable,
  },
  data() {
    return {
      sortedGalleries: []
    }
  },
  watch: {
    galleries: {
      handler() {
        this.sortedGalleries = this.galleries
      },
      deep: true
    }
  },
  mounted() {
    this.sortedGalleries = this.galleries
  },
  computed: {
    ...mapGetters('eventWizard', [
      'canSeePhotoSearch',
      'requireAggregateMediaView'
    ]),
    ...mapGetters('event', [
      'picaServiceId',
    ]),
    ...mapGetters('eventGallery', [
      'galleries',
    ]),
    ...mapGetters('uploader', [
      'hasGlobalProcessingMedia'
    ]),
    canSort() {
      return this.hasPerm('gallery.sort')
    },
    canSeeGallery() {
      return this.galleries.length > 0 || this.hasPerm('gallery.create')
    },
    tab() {
      let route = this.$route.matched.find(m => m.props?.default?.tab)
      if (route) return route.props.default.tab
      return "detail"
    },
  },
  methods: {
    ...mapActions('eventGallery', [
      'updateSorting',
      'updateSubSorting'
    ]),
    updateGallerySorting() {
      this.updateSorting(this.sortedGalleries.map(gallery => gallery.id))
    },
    updateSubGallerySorting(galleryId) {
      let gallery = this.sortedGalleries.find(gallery => gallery.id === galleryId)
      this.updateSubSorting({galleryId: galleryId, ids: gallery.sub.map(sub => sub.id)})
    }
  }
}
</script>
  