import {ApiException} from "@/domain/core/exception/exceptions";
import {emptyListResponse} from "@/domain/core/utils/django";


const state = {
    serviceOrders: {...emptyListResponse},
}

export const getters = {
    serviceOrders: state => state.serviceOrders,
}


export const mutations = {
    serviceOrders(state, serviceOrders) {
        state.serviceOrders = serviceOrders
    }
}


export const actions = service => ({
    async fetchServiceOrderList({commit}, {filters, pagination, sorting}) {
        try {
            const serviceOrders = await service.fetchServiceOrderList(filters, pagination, sorting)
            commit('serviceOrders', serviceOrders)
        } catch (e) {
            if (e instanceof ApiException)
                commit('serviceOrders', emptyListResponse)
            throw e
        }
    },
    // eslint-disable-next-line no-unused-vars
    async downloadInvoice({commit}, id) {
        await service.downloadInvoice(id)
    },
})

export default service => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(service)
})

