import ApiClient from "@/domain/core/utils/apiClient";
import client_core from "@/services/clients/client_core";

const client = new ApiClient(client_core)

const endpoints = {
    create: '/dashboard/v2/event-wizard/{picaService}/gallery/',    // POST
    detail: '/dashboard/v2/event-wizard/{picaService}/gallery/{id}/',   // GET
    delete: '/dashboard/v2/event-wizard/{picaService}/gallery/{id}/',   // DELETE
    sort: '/dashboard/v2/event-wizard/{picaService}/gallery/sort/',   // PATCH
    tags: '/dashboard/v2/event-wizard/{picaService}/gallery/{id}/tags/',   // GET
    getter: '/dashboard/v2/event-wizard/{picaService}/gallery/{id}/get/{step}/',   // PATCH
    updater: '/dashboard/v2/event-wizard/{picaService}/gallery/{id}/update/{step}/',   // PATCH
    priceList_search: '/dashboard/v2/event-wizard/{picaService}/gallery/price_list/',   // GET
}

export default {
    async fetchGallery(picaService, id) {
        return await client.get(endpoints.detail.replace('{picaService}', picaService).replace('{id}', id))
    },
    async deleteGallery(picaService, id) {
        await client.delete(endpoints.delete.replace('{picaService}', picaService).replace('{id}', id))
    },
    async createGallery(picaService, data) {
        return await client.post(endpoints.create.replace('{picaService}', picaService), data)
    },
    async updateSorting(picaService, ids) {
        await client.patch(endpoints.sort.replace('{picaService}', picaService), {ids: ids})
    },
    async fetchTags(picaService, id) {
        return await client.get(endpoints.tags.replace('{picaService}', picaService).replace('{id}', id))
    },
    async updateData(picaService, id, tab, data) {
        return await client.post(endpoints.updater
            .replace('{picaService}', picaService)
            .replace('{id}', id)
            .replace('{step}', tab), data)
    },
    async fetchData(picaService, id, tab) {
        return await client.get(endpoints.getter
            .replace('{picaService}', picaService)
            .replace('{id}', id)
            .replace('{step}', tab))
    },
    async searchPriceList(picaService, {id, query}) {
        const params = {}
        if (id) params.id = id
        if (query) params.search = query
        return await client.get(endpoints.priceList_search
            .replace('{picaService}', picaService), params)
    },
}
