import MediaCluster from "@/domain/photoSearch/mediaCluster";
import GallerySorting from "@/domain/core/constant/gallerySorting";
import moment from "moment";

class TimeLine {
    MAX_ITEM_FOR_CLUSTER = 100
    CLUSTER_BY_ORDERED = [
        'minute',
        '10minute',
        'hour',
        'day'
    ]

    constructor(timeline, sorting) {
        this.timeline = timeline
        this.sorting = sorting
    }

    getOptimalClusterization() {
        let clusters = []
        for (let clusterBy of this.CLUSTER_BY_ORDERED) {
            clusters = this.getCluster(clusterBy)
            if (clusters.length <= this.MAX_ITEM_FOR_CLUSTER) {
                return {
                    clusterBy: clusterBy,
                    clusters: clusters
                }
            }
        }

        return {
            clusterBy: this.CLUSTER_BY_ORDERED[this.CLUSTER_BY_ORDERED.length - 1],
            clusters: clusters
        }
    }

    getCluster(clusterBy) {
        if (!this.timeline || this.timeline.length === 0) return []
        const mediaCluster = new MediaCluster(clusterBy, this.sorting)
        let keys = []
        const clustered = this.timeline.reduce((acc, item) => {
            let key = mediaCluster.getClusterKey(item.date)
            if (!acc[key]) {
                acc[key] = {
                    date: moment(item.date),
                    key: key,
                    num: 0
                }
                keys.push(key)
            }
            acc[key].num += item.num
            return acc
        }, {})
        if (this.sorting === GallerySorting.shot_at_asc)
            keys = keys.sort()
        else if (this.sorting === GallerySorting.shot_at_desc)
            keys = keys.sort().reverse()
        else
            throw new Error('Unsupported sorting')
        return keys.map(key => clustered[key])
    }

    incrementCounters(photos) {
        if (photos.length === 0) return

        let newMediaClusters = {}
        photos.map(photo => {
            let key = photo.shot_at.format('YYYYMMDDHHmm')
            if (!newMediaClusters[key]) newMediaClusters[key] = {num: 0, date: photo.shot_at, key: key}
            newMediaClusters[key].num++
        })

        Object.values(newMediaClusters).forEach(newMediaCluster => {
            let existingCluster = this.timeline.find(cluster => cluster.key === newMediaCluster.key)
            if (existingCluster) existingCluster.num += newMediaCluster.num
            else this.timeline.push(newMediaCluster)
        })
        return this.timeline
    }
}

export default TimeLine;