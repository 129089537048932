<template>
  <div class="position-relative">
    <div class="session-progress__track">
      <div class="session-progress__bar limit" :style="{ width: receivedProcessLimitPerc + '%' }"
           v-if="showProcessLimit"></div>
      <div class="session-progress__bar uploaded" :style="{ width: uploadedPerc + '%' }"></div>
      <div class="session-progress__bar received" :style="{ width: receivedPerc + '%' }"></div>
      <!--<div class="session-progress__bar processed" :style="{ width: processedPerc + '%' }"></div>-->
    </div>
    <template v-if="showProcessLimit">
      <div class="process-limit" :style="{ width: processLimitPerc + '%' }">
      </div>
      <div class="process-limit__labels">
        &nbsp;
        <div class="included" v-translate translate-context="upload">
          Included
        </div>
        <div class="extra" v-translate translate-context="upload">
          Extra
        </div>
      </div>
    </template>
  </div>
</template>


<script>
import {stateWidgetUpload} from "@/constants";

export default {
  name: "UploadsProgress",
  props: {
    process_limit: {
      type: Number,
      default: 0
    },
    upload_limit: {
      type: Number,
      default: 0
    },
    uploaded_count: {
      type: Number,
      default: 0
    },
    received_count: {
      type: Number,
      default: 0
    },
    processed_count: {
      type: Number,
      default: 0
    },
    intents_count: {
      type: Number,
      default: 0
    },
    currentState: {
      type: String,
      required: true
    },
  },
  computed: {
    showProcessLimit() {
      return this.currentState === stateWidgetUpload.payAsYouGo
    },
    base_value() {
      if ([stateWidgetUpload.filesUploaded].includes(this.currentState)) return this.process_limit
      if ([stateWidgetUpload.payAsYouGo].includes(this.currentState)) return this.intents_count
      if (this.upload_limit > 0) return this.upload_limit
      return this.intents_count
    },
    receivedProcessLimitPerc() {
      if (this.pasAsYouGoPerc <= 0) return 0
      return this.processLimitPerc + this.pasAsYouGoPerc
    },
    uploadedPerc() {
      return this.calculatePercWithLimit(this.uploaded_count)
    },
    receivedPerc() {
      return this.calculatePercWithLimit(this.received_count)
    },
    processedPerc() {
      return this.calculatePercWithLimit(this.processed_count)
    },
    processLimitPerc() {
      return this.calculatePerc(this.process_limit)
    },
    pasAsYouGoPerc() {
      return this.calculatePerc(this.received_count - this.process_limit)
    }
  },
  methods: {
    calculatePerc(count) {
      return (count / this.base_value) * 100
    },
    calculatePercWithLimit(count) {
      const perc = this.calculatePerc(count)
      if (!this.processLimitPerc) return perc
      return perc > this.processLimitPerc ? this.processLimitPerc : perc
    }
  }
}
</script>


<style scoped>

</style>