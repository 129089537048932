<template>
  <div>
    <CDropdown v-if="hasMoreThanOneItem"
               :size="size"
               :offset="offset"
               :placement="placement"
               :caret="false"
               :add-menu-classes="'styled-dd-menu'"
               class="styled-dd">
      <template #toggler>
        <CButton :size="size" :color="color" :class="[fontWeight]" :disabled="disabled">
          <CIcon :name="selectedItem.icon" v-if="selectedItem.icon && !resetOnSelect" size="custom-size" width="18" class="item-icon"
                 style="vertical-align: sub"/>
          {{ selectedItemLabel }}
          <CIcon name="cipCaretDown" size="lg" />
        </CButton>
      </template>

      <template v-for="(item, index) in items">
        <CDropdownDivider :key="index" v-if="item.separator || item.divider"/>
        <CDropdownItem :active="item.value === selectedItem.value" :key="item.value" @click="onClick(item)" v-else>
          <div class="d-flex justify-content-between w-100">
            <div>
              <CIcon :name="item.icon" v-if="item.icon" size="custom-size" width="18" class="item-icon"
                     style="vertical-align: sub"/>
              {{ item.label }}
            </div>
            <div v-if="item.value===selectedItem.value && showItemSelector" class="ml-2">
              <CIcon name="cip-check" class="text-primary" width="16"/>
            </div>
          </div>
        </CDropdownItem>
      </template>

    </CDropdown>
    <CButton v-else :size="size" :color="color" class="border" :class="[fontWeight]" :disabled="disabled"
             @click="onClick(items[0])">
      {{ items[0].label }}
    </CButton>
  </div>

</template>

<script>
export default {
  name: "PDropDown",
  emits: ['change'],
  data() {
    return {
      selectedItem: {},
    }
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    offset: {
      type: Array,
      default: () => [0, 4]
    },
    emptyLabel: {
      type: String,
      required: false,
    },
    showItemSelector: {
      type: Boolean,
      default: false
    },
    resetOnSelect: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: 'bottom-start'
    },
    color: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      default: ''
    },
    fontWeight: {
      type: String,
      default: 'font-weight-medium'
    },
    buttonIfSingleItem: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      required: false
    }
  },
  watch: {
    value: {
      handler(newValue) {
        this.selectedItem = this.items.find(item => item.value === newValue) || {};
      },
      immediate: true
    }
  },
  mounted() {
    if (!this.emptyLabel) {
      if (this.value)
        this.selectedItem = this.items.find(item => item.value === this.value) || {}
      else
        this.selectedItem = this.items[0] || {}
    }
  },
  computed: {
    selectedItemLabel() {
      if (this.resetOnSelect) return this.emptyLabel
      return this.selectedItem.label || this.emptyLabel
    },
    hasMoreThanOneItem() {
      return this.items.length > 1
    }
  },
  methods: {
    onClick(item) {
      this.selectedItem = item
      this.$emit('change', this.selectedItem.value)
      if (this.resetOnSelect) this.selectedItem = {}
    },
  }
}
</script>
