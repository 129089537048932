const Page404 = () => import('@/domain/core/pages/Page404.vue')
const Page403 = () => import('@/domain/core/pages/Page403.vue')
const Maintenance = () => import('@/domain/core/pages/Maintenance.vue')
const IconsList = () => import('@/domain/core/pages/IconsList.vue')

export default [
    {
        path: '/forbidden',
        name: 'Page403',
        component: Page403
    },
    {
        path: '/maintenance',
        name: 'Maintenance',
        component: Maintenance,
        meta: {
            title: 'Service under maintenance',
            label: 'Maintenance',
            requiresNotAuth: true
        },
    },
    {
        path: '/icons',
        name: 'Icons',
        component: IconsList,
        requiresNotAuth: true
    },
    {
        path: "*",
        component: Page404
    }
]