<template>
  <CModal :show.sync="show">
    <h4 v-translate translate-context="consolidate.create_new_consolidation">Create New Consolidation</h4>
    <div>
      <CInput v-model="from" :label="$pgettext('consolidate.date_form', 'From')" placeholder="From"
              :disabled="!canEditFrom" type="datetime-local"/>
    </div>
    <div>
      <CInput v-model="to" :label="$pgettext('consolidate.date_form', 'To')" placeholder="To" type="datetime-local"/>
    </div>
    <template #footer>
      <CButton @click="show = false" color="secondary" v-translate>Close</CButton>
      <PButton :loading="loading"
               @click="onCreate"
               color="primary"
               translate-context="consolidate.create_new_consolidation" v-translate>
        Generate
      </PButton>
    </template>
  </CModal>
</template>

<script>

import PButton from "@/components/PButton.vue";
import moment from "moment";

export default {
  name: "CreateNewConsolidation",
  components: {PButton},
  props: {
    timezone: {
      type: String,
      default: 'UTC'
    }
  },
  data() {
    return {
      show: false,
      from: null,
      to: null,
      canEditFrom: false,
      loading: false,
      picaCode: ''
    }
  },
  computed: {},
  emits: ['make'],
  methods: {
    open(from) {
      this.show = true
      if (from) {
        this.canEditFrom = false
        // This is already localized in the event timezone, so no need to localize it again
        this.from = moment(from).tz(this.timezone).format('YYYY-MM-DDTHH:mm:ss')
        this.to = moment.tz(this.timezone).format('YYYY-MM-DDTHH:mm:ss')
      } else {
        this.canEditFrom = true
        this.from = moment.tz(this.timezone).startOf('month').format('YYYY-MM-DDTHH:mm:ss')
        this.to = moment.tz(this.timezone).format('YYYY-MM-DDTHH:mm:ss')
      }
    },
    async onCreate() {
      this.loading = true
      try {
        await this.emitPromised('make', {
          from: this.from,
          to: this.to
        })
        this.loading = false
        this.show = false
      } catch (e) {
        this.loading = false
      }
    },
  }
}
</script>

<style scoped>

</style>