import cipMore from '@/assets/icons/cipIcons/cip-more.js'
import cipMoney from '@/assets/icons/cipIcons/cip-money.js'
import cipEye from '@/assets/icons/cipIcons/cip-eye.js'
import cipEyeOff from "@/assets/icons/cipIcons/cip-eye-off";
import cipCalendar from '@/assets/icons/cipIcons/cip-calendar.js'
import cipCircleCheck from '@/assets/icons/cipIcons/cip-circle-check.js'
import cipDashboard from '@/assets/icons/cipIcons/cip-dashboard.js'
import cipPhotographer from '@/assets/icons/cipIcons/cip-photographer.js'
import cipOrder from '@/assets/icons/cipIcons/cip-order.js'
import cipAddUser from '@/assets/icons/cipIcons/cip-add-user'
import cipPicture from '@/assets/icons/cipIcons/cip-picture'
import cipStats from '@/assets/icons/cipIcons/cip-stats'
import cipTag from '@/assets/icons/cipIcons/cip-tag'
import cipTag2 from '@/assets/icons/cipIcons/cip-tag2'
import cipCode from '@/assets/icons/cipIcons/cip-code'
import cipCodeClean from '@/assets/icons/cipIcons/cip-code-clean'
import cipSearch from  '@/assets/icons/cipIcons/cip-search'
import cipSearch2 from  '@/assets/icons/cipIcons/cip-search2'
import cipPromo from  '@/assets/icons/cipIcons/cip-promo'
import cipOverview from  '@/assets/icons/cipIcons/cip-overview'
import cipCheck from  '@/assets/icons/cipIcons/cip-check'
import cipCompass from  '@/assets/icons/cipIcons/cip-compass'
import cipCog from  '@/assets/icons/cipIcons/cip-cog'
import cipChevronLeft from  '@/assets/icons/cipIcons/cip-chevron-left'
import cipArrowLeft from  '@/assets/icons/cipIcons/cip-arrow-left'
import cipArrowRight from  '@/assets/icons/cipIcons/cip-arrow-right'
import cipUpload from  '@/assets/icons/cipIcons/cip-upload'
import cipDotGreen from  '@/assets/icons/cipIcons/cip-dot-green'
import cipDotOrange from  '@/assets/icons/cipIcons/cip-dot-orange'
import cipDotGray from  '@/assets/icons/cipIcons/cip-dot-gray'
import cipLock from  '@/assets/icons/cipIcons/cip-lock'
import cipUsers from  '@/assets/icons/cipIcons/cip-users'
import cipVoice from  '@/assets/icons/cipIcons/cip-voice'
import cipSlider from  '@/assets/icons/cipIcons/cip-slider'
import cipExit from  '@/assets/icons/cipIcons/cip-exit'
import cipUser from  '@/assets/icons/cipIcons/cip-user'
import cipCopy from  '@/assets/icons/cipIcons/cip-copy'
import cipZip from  '@/assets/icons/cipIcons/cip-zip'
import cipDoc from  '@/assets/icons/cipIcons/cip-doc'
import cipGrid from  '@/assets/icons/cipIcons/cip-grid'
import cipLines from  '@/assets/icons/cipIcons/cip-lines'
import cipVerified from  '@/assets/icons/cipIcons/cip-verified'
import cipEnter from  '@/assets/icons/cipIcons/cip-enter'
import cipDot from  '@/assets/icons/cipIcons/cip-dot'
import cipEqualizer from  '@/assets/icons/cipIcons/cip-equalizer'
import cipHouseCheck from '@/assets/icons/cipIcons/cip-house-check'
import cipDownloadCircle from '@/assets/icons/cipIcons/cip-download-circle'
import cipCaretUp from "@/assets/icons/cipIcons/cip-caret-up";
import cipCaretDown from "@/assets/icons/cipIcons/cip-caret-down";
import cipSlideRight from "@/assets/icons/cipIcons/cip-slide-right";
import cipSquare from "@/assets/icons/cipIcons/cip-square";
import cipAlignCenter from "@/assets/icons/cipIcons/cip-align-center";
import cipAlignLeft from "@/assets/icons/cipIcons/cip-align-left";
import cipAlignRight from "@/assets/icons/cipIcons/cip-align-right";
import cipSend from "@/assets/icons/cipIcons/cip-send";
import cipPlusCircle from "@/assets/icons/cipIcons/cip-plus-circle";
import cipClose from "@/assets/icons/cipIcons/cip-close";
import cipCircle from "@/assets/icons/cipIcons/cip-circle";
import cipLink from "@/assets/icons/cipIcons/cip-link";
import cipNotebook from "@/assets/icons/cipIcons/cip-notebook";
import cipLayer from "@/assets/icons/cipIcons/cip-layer";
import cipSettingsFuture from "@/assets/icons/cipIcons/cip-settings-future";
import cipSwitchLeft from "@/assets/icons/cipIcons/cip-switch-left";
import cipHouse02 from "@/assets/icons/cipIcons/cip-house-02";
import cipWindowCode from "@/assets/icons/cipIcons/cip-window-code";
import cipHamburger from "@/assets/icons/cipIcons/cip-hamburger";
import cipRound0 from "@/assets/icons/cipIcons/cip-round-0";
import cipRound1 from "@/assets/icons/cipIcons/cip-round-1";
import cipRound2 from "@/assets/icons/cipIcons/cip-round-2";
import cipInfo from "@/assets/icons/cipIcons/cip-info";
import cipText from "@/assets/icons/cipIcons/cip-text";
import cipPin from "@/assets/icons/cipIcons/cip-pin";
import cipMarker from "@/assets/icons/cipIcons/cip-marker";
import cipClock from "@/assets/icons/cipIcons/cip-clock";
import cipUserSquare from "@/assets/icons/cipIcons/cip-user-square";
import cipChatConversation from "@/assets/icons/cipIcons/cip-chat-conversation";
import cipLinkHorizontal from "@/assets/icons/cipIcons/cip-link-horizontal";
import cipTl from "@/assets/icons/cipIcons/cip-tl";
import cipTc from "@/assets/icons/cipIcons/cip-tc";
import cipTr from "@/assets/icons/cipIcons/cip-tr";
import cipBr from "@/assets/icons/cipIcons/cip-br";
import cipBl from "@/assets/icons/cipIcons/cip-bl";
import cipBc from "@/assets/icons/cipIcons/cip-bc";
import cipList from "@/assets/icons/cipIcons/cip-list";
import cipCookie from "@/assets/icons/cipIcons/cip-cookie";
import cipData from "@/assets/icons/cipIcons/cip-data";
import cipShieldCheck from "@/assets/icons/cipIcons/cip-shield-check";
import cipReload from "@/assets/icons/cipIcons/cip-reload";
import cipTrashFull from "@/assets/icons/cipIcons/cip-trash-full";
import cipPortrait from "@/assets/icons/cipIcons/cip-portrait";
import cipLandscape from "@/assets/icons/cipIcons/cip-landscape";
import cipClockCheck from "@/assets/icons/cipIcons/cip-clock-check";
import cipTool from "@/assets/icons/cipIcons/cip-tool";
import cipSettings from "@/assets/icons/cipIcons/cip-settings";
import cipExternalLink from "@/assets/icons/cipIcons/cip-external-link";
import cipImagePlus from "@/assets/icons/cipIcons/cip-image-plus";
import cipFileLock from "@/assets/icons/cipIcons/cip-file-lock";
import cipAnnouncement from "@/assets/icons/cipIcons/cip-announcement";
import cipPencil from "@/assets/icons/cipIcons/cip-pencil";
import cipUpload2 from "@/assets/icons/cipIcons/cip-upload2";
import cipPlus from "@/assets/icons/cipIcons/cip-plus";
import cipMinus from "@/assets/icons/cipIcons/cip-minus";
import cipDownload from "@/assets/icons/cipIcons/cip-download";
import cipUploadCloud from "@/assets/icons/cipIcons/cip-upload-cloud";
import cipTriangleWarning from "@/assets/icons/cipIcons/cip-triangle-warning";
import cipCircleHelp from "@/assets/icons/cipIcons/cip-circle-help";
import cipResize from "@/assets/icons/cipIcons/cip-resize";
import cipMagnifierPlus from "@/assets/icons/cipIcons/cip-magnifier-plus";
import cipMagnifierMinus from "@/assets/icons/cipIcons/cip-magnifier-minus";
import cipNotificationMessage from "@/assets/icons/cipIcons/cip-notification-message";
import cipDocBars from "@/assets/icons/cipIcons/cip-doc-bars";
import cipPalettes from "@/assets/icons/cipIcons/cip-palettes";
import cipEmail from "@/assets/icons/cipIcons/cip-email";
import cipCreditCardEdit from "@/assets/icons/cipIcons/cip-credit-card-edit";
import cipGlasses from "@/assets/icons/cipIcons/cip-glasses";
import cipCoinsSwap from "@/assets/icons/cipIcons/cip-coins-swap";
import cipFolderEdit from "@/assets/icons/cipIcons/cip-folder-edit";
import cipImage5 from "@/assets/icons/cipIcons/cip-image-5";
import cipFilePlus from "@/assets/icons/cipIcons/cip-file-plus";
import cipPencilLine from "@/assets/icons/cipIcons/cip-pencil-line";
import cipFolderBlue from "@/assets/icons/cipIcons/cip-folder-blue";
import cipPictureCircle from "@/assets/icons/cipIcons/cip-picture-circle";
import cipLogin from "@/assets/icons/cipIcons/cip-login";
import cipCalendarDay from "@/assets/icons/cipIcons/cip-calendar-day";
import cipCreditCardPlus from "@/assets/icons/cipIcons/cip-credit-card-plus";
import cipSignal from "@/assets/icons/cipIcons/cip-signal";
import cipHome from "@/assets/icons/cipIcons/cip-home";
import cipGlobe from "@/assets/icons/cipIcons/cip-globe";
import cipBuilding from "@/assets/icons/cipIcons/cip-building";
import cipUsersPlus from "@/assets/icons/cipIcons/cip-users-plus";
import cipCart from "@/assets/icons/cipIcons/cip-cart";
import cipAnnotationQuestion from "@/assets/icons/cipIcons/cip-annotation-question";
import cipFile03 from "@/assets/icons/cipIcons/cip-file-03";
import cipTextInput from "@/assets/icons/cipIcons/cip-text-input";
import cipHeartHand from "@/assets/icons/cipIcons/cip-heart-hand";
import cipWallet02 from "@/assets/icons/cipIcons/cip-wallet-02";
import cipQrCode01 from "@/assets/icons/cipIcons/cip-qr-code-01";
import cipCreditCard02 from "@/assets/icons/cipIcons/cip-credit-card-02";
import cipFileDownload02 from "@/assets/icons/cipIcons/cip-file-download-02";
import cipCube01 from "@/assets/icons/cipIcons/cip-cube-01";
import cipLoading01 from "@/assets/icons/cipIcons/cip-loading-01";
import cipStars01 from "@/assets/icons/cipIcons/cip-stars-01";
import cipLogOut04 from "@/assets/icons/cipIcons/cip-log-out-04";
import cipAlertTriangle from "@/assets/icons/cipIcons/cip-alert-triangle";
import cipArrowNarrowUpRight from "@/assets/icons/cipIcons/cip-arrow-narrow-up-right";
import cipDownload03 from "@/assets/icons/cipIcons/cip-download-03";
import cipCircleCheckFill from "@/assets/icons/cipIcons/cip-circle-check-fill";
import cipBriefcase01 from "@/assets/icons/cipIcons/cip-briefcase-01";
import cipStars06 from "@/assets/icons/cipIcons/cip-stars-06";
import cipTagPlus from "@/assets/icons/cipIcons/cip-tag-plus";
import cipTagMinus from "@/assets/icons/cipIcons/cip-tag-minus";
import cipArrowNarrowUp from "@/assets/icons/cipIcons/cip-arrow-narrow-up";
import cipArrowNarrowDown from "@/assets/icons/cipIcons/cip-arrow-narrow-down";
import cipArrowNarrowRight from "@/assets/icons/cipIcons/cip-arrow-narrow-right";
import cipArrowNarrowLeft from "@/assets/icons/cipIcons/cip-arrow-narrow-left";
import cipFolderBlueStar from "@/assets/icons/cipIcons/cip-folder-blue-star";
import cipXCircle    from "@/assets/icons/cipIcons/cip-x-circle";
import cipRefreshCw01 from "@/assets/icons/cipIcons/cip-refresh-cw-01";
import cipRefreshCcw01 from "@/assets/icons/cipIcons/cip-refresh-ccw-01";
import cipDotsHorizontal from "@/assets/icons/cipIcons/cip-dots-horizontal";
import cipRefreshCw04 from "@/assets/icons/cipIcons/cip-refresh-cw-04";
import cipChevronRight from "@/assets/icons/cipIcons/cip-chevron-right";
import cipCornerDownRight from "@/assets/icons/cipIcons/cip-corner-down-right";
import cipSetting02 from "@/assets/icons/cipIcons/cip-setting-02";
import cipGridSm from "@/assets/icons/cipIcons/cip-grid-sm";
import cipGridMd from "@/assets/icons/cipIcons/cip-grid-md";
import cipGridLg from "@/assets/icons/cipIcons/cip-grid-lg";
import cipCircleLoader from "@/assets/icons/cipIcons/cip-circle-loader";
import cipCheckbox from "@/assets/icons/cipIcons/cip-checkbox";
import cipBuilding02 from "@/assets/icons/cipIcons/cip-building-02";
import cipMenu01 from "@/assets/icons/cipIcons/cip-menu-01";

export const cipIcons = {
    cipMenu01,
    cipBuilding02,
    cipCheckbox,
    cipCircleLoader,
    cipGridLg,
    cipGridMd,
    cipGridSm,
    cipSetting02,
    cipCornerDownRight,
    cipChevronRight,
    cipRefreshCw04,
    cipDotsHorizontal,
    cipRefreshCw01,
    cipRefreshCcw01,
    cipXCircle,
    cipFolderBlueStar,
    cipArrowNarrowLeft,
    cipArrowNarrowRight,
    cipArrowNarrowDown,
    cipArrowNarrowUp,
    cipTagMinus,
    cipTagPlus,
    cipStars06,
    cipEye,
    cipEyeOff,
    cipCalendar,
    cipMore,
    cipMoney,
    cipCircleCheck,
    cipDashboard,
    cipPhotographer,
    cipOrder,
    cipAddUser,
    cipPicture,
    cipStats,
    cipTag,
    cipTag2,
    cipCode,
    cipCodeClean,
    cipSearch,
    cipSearch2,
    cipPromo,
    cipOverview,
    cipCheck,
    cipCompass,
    cipCog,
    cipChevronLeft,
    cipArrowLeft,
    cipArrowRight,
    cipUpload,
    cipDotGreen,
    cipDotOrange,
    cipDotGray,
    cipLock,
    cipUsers,
    cipVoice,
    cipSlider,
    cipExit,
    cipUser,
    cipCopy,
    cipZip,
    cipDoc,
    cipGrid,
    cipLines,
    cipVerified,
    cipEnter,
    cipDot,
    cipSlideRight,
    cipSquare,
    cipAlignRight,
    cipAlignLeft,
    cipAlignCenter,
    cipSend,
    cipPlusCircle,
    cipClose,
    cipCircle,
    cipLink,
    cipNotebook,
    cipLayer,
    cipSettingsFuture,
    cipSwitchLeft,
    cipHouse02,
    cipWindowCode,
    cipHamburger,
    cipRound0,
    cipRound1,
    cipRound2,
    cipEqualizer,
    cipHouseCheck,
    cipDownloadCircle,
    cipCaretUp,
    cipCaretDown,
    cipInfo,
    cipText,
    cipPin,
    cipClock,
    cipUserSquare,
    cipChatConversation,
    cipLinkHorizontal,
    cipTl,
    cipTc,
    cipTr,
    cipBr,
    cipBl,
    cipBc,
    cipList,
    cipCookie,
    cipData,
    cipShieldCheck,
    cipReload,
    cipTrashFull,
    cipPortrait,
    cipLandscape,
    cipClockCheck,
    cipTool,
    cipSettings,
    cipExternalLink,
    cipImagePlus,
    cipFileLock,
    cipAnnouncement,
    cipPencil,
    cipPlus,
    cipUpload2,
    cipDownload,
    cipUploadCloud,
    cipTriangleWarning,
    cipCircleHelp,
    cipResize,
    cipMagnifierPlus,
    cipMagnifierMinus,
    cipNotificationMessage,
    cipDocBars,
    cipMarker,
    cipPalettes,
    cipEmail,
    cipCreditCardEdit,
    cipGlasses,
    cipCoinsSwap,
    cipFolderEdit,
    cipImage5,
    cipFilePlus,
    cipPencilLine,
    cipFolderBlue,
    cipPictureCircle,
    cipLogin,
    cipCalendarDay,
    cipCreditCardPlus,
    cipMinus,
    cipSignal,
    cipHome,
    cipGlobe,
    cipBuilding,
    cipUsersPlus,
    cipCart,
    cipAnnotationQuestion,
    cipFile03,
    cipTextInput,
    cipHeartHand,
    cipWallet02,
    cipQrCode01,
    cipCreditCard02,
    cipFileDownload02,
    cipCube01,
    cipLoading01,
    cipStars01,
    cipLogOut04,
    cipAlertTriangle,
    cipArrowNarrowUpRight,
    cipDownload03,
    cipCircleCheckFill,
    cipBriefcase01
}