import Paho from "paho-mqtt"
import { v4 as uuidv4 } from 'uuid';

// https://eclipse.dev/paho/files/jsdoc/Paho.MQTT.Client.html
class MqttClient {
    constructor(wsBroker, wsPort, username, password) {

        if (MqttClient.instance) {
            return MqttClient.instance
        }
        this.debugMode = process.env.VUE_APP_PICA_WSS_DEBUG > 0

        this.wsBroker = wsBroker
        this.wsPort = wsPort
        this.username = username
        this.password = password

        this.client = null
        this.userId = 'anonymous'
        this.onConnectCallback = null
        this.onConnectionFailureCallback = null
        this.onMessageCallback = null

        MqttClient.instance = this
    }

    setUserId(userId) {
        if (userId === null) userId = 'anonymous'
        if (this.userId === userId) return
        this.userId = userId
        if (this.client)
            this.init(this.onConnectCallback, this.onConnectionFailureCallback, this.onMessageCallback)
    }


    isInitialized() {
        return this.client !== null
    }

    init(onConnectCallback = null, onConnectionFailureCallback = null, onMessageCallback = null) {
        this.debug('INIT MQTT CLIENT')
        this.onConnectCallback = onConnectCallback
        this.onConnectionFailureCallback = onConnectionFailureCallback
        this.onMessageCallback = onMessageCallback

        if (this.client)
            this.client.disconnect()

        const truncatedUuid = uuidv4().substring(0, 10);
        const clientId = `dash-${this.userId}-${truncatedUuid}`;

        this.client = new Paho.Client(this.wsBroker, this.wsPort, "/ws", clientId)

        this.client.onConnectionLost = this.onConnectionLost.bind(this)
        this.client.onMessageArrived = this.onMessageArrived.bind(this)
        this.topics = {}

        this.connect()
    }


    onConnectionLost(responseObject) {
        this.debug("CONNECTION LOST - " + responseObject.errorMessage)
        this.topics = {}
    }
    onMessageArrived(message) {
        this.debug("RECEIVE ON " + message.destinationName + " PAYLOAD " + message.payloadString)
        if (this.onMessageCallback)
            this.onMessageCallback(message.destinationName, message.payloadString)
    }
    onConnectionSuccess() {
        this.debug("CONNECTED")
        this.topics = {}
        if (this.onConnectCallback) this.onConnectCallback()
    }
    onConnectionFailure(err) {
        this.debug("Connection failed: " + err.errorMessage)
        if (this.onConnectionFailureCallback) {
            this.onConnectionFailureCallback(err)
            this.topics = {}
        }
    }
    connect() {
        if (this.client.isConnected()) return

        this.client.connect({
            userName: this.username,
            password: this.password,
            keepAliveInterval: 60,
            timeout: 30,
            reconnect: true,
            useSSL: this.wsPort === 443,
            onSuccess: this.onConnectionSuccess.bind(this),
            onFailure: this.onConnectionFailure.bind(this)
        })
    }
    isConnected() {
        if (!this.client) return false
        return this.client.isConnected()
    }
    subscribe(topic) {
        if (this.client.isConnected()) {
            if (this.topics[topic]) {
                this.debug("Already subscribed to topic " + topic)
                return
            }
            this.topics[topic] = true
            this.client.subscribe(topic)
            this.debug("Subscribed to topic " + topic)
        } else {
            this.debug("Client is not connected. Cannot subscribe to topic.")
        }
    }
    unsubscribe(topic) {
        if (this.client && this.client.isConnected()) {
            this.client.unsubscribe(topic)
            delete this.topics[topic]
            this.debug("Unscribe from topic " + topic)
        } else {
            this.debug("Client is not connected. Cannot unsubscribe from topic.")
        }
    }
    disconnect() {
        if (this.client && this.client.isConnected()) {
            this.retryOnConnectionLost = false
            this.client.disconnect()
            this.client = null
            this.topics = {}
        } else {
            this.debug("Client is not connected. Cannot disconnect.")
        }
    }
    debug(message) {
        if (typeof console !== 'undefined' && this.debugMode) console.log('MQtt Client - '+message)
    }
}

const instance = new MqttClient(
    process.env.VUE_APP_PICA_WSS_URL,
    parseInt(process.env.VUE_APP_PICA_WSS_PORT),
    process.env.VUE_APP_PICA_WSS_USER,
    process.env.VUE_APP_PICA_WSS_PASS,
)

export default instance