import eventTemplate from '@/domain/eventTemplate/routes'
import eventManage from '@/domain/eventManage/routes'
import faq from '@/domain/faq/routes'
import tag from '@/domain/tag/routes'
import shortener from '@/domain/shortener/routes'
import impersonate from '@/domain/impersonate/routes'
import plan from '@/domain/plans/routes'
import refund from '@/domain/refund/routes'
import login from '@/domain/login/routes'
import role from '@/domain/roles/routes'
import contractManager from '@/domain/contract_manager/routes'
import invitation from '@/domain/invitations/routes'
import invoice from '@/domain/invoice/routes'
import verticalConfiguration from '@/domain/verticalConfiguration/routes'
import region from '@/domain/region/routes'
import priceListTemplate from '@/domain/priceListTemplate/routes'
import serviceOrders from '@/domain/serviceOrders/routes'
import nStats from '@/domain/stats/routes'
import customerSuccess from '@/domain/customerSuccess/routes'

import dashboard from './_dashboard'
import reports from './_reports'
import utility from './_utility'

export default [
    ...dashboard,
    ...reports,
    ...eventTemplate,
    ...eventManage,
    ...faq,
    ...tag,
    ...impersonate,
    ...shortener,
    ...plan,
    ...refund,
    ...login,
    ...role,
    ...contractManager,
    ...invitation,
    ...invoice,
    ...verticalConfiguration,
    ...region,
    ...priceListTemplate,
    ...nStats,
    ...serviceOrders,
    ...customerSuccess,
    ...utility, // utility must be last since it contains the wildcard 404 route
]