<template>
  <div class="upload-status">
    <UploadsProgressHeader :planName="planName"
                           :intents_count="intents_count"
                           :process_limit="process_limit"
                           :pay_as_you_go_count="pay_as_you_go_count"
                           :upload_limit="upload_limit"
                           :currentState="currentState"/>

    <UploadsProgress :process_limit="process_limit"
                     :intents_count="intents_count"
                     :upload_limit="upload_limit"
                     :uploaded_count="uploaded_count"
                     :received_count="received_count"
                     :processed_count="processed_count"
                     :currentState="currentState"/>

    <UploadsProgressFooter :pay_as_you_go_count="pay_as_you_go_count"
                           :intents_count="intents_count"
                           :process_limit="process_limit"
                           :currentState="currentState"
                           @upload="$emit('upload')"
                           @seePhotos="$emit('seePhotos')"
                           @deliver="$emit('deliver')"/>
  </div>
</template>

<script>
import UploadsProgress from "@/domain/uploader/components/UploadsProgress.vue";
import UploadsProgressFooter from "@/domain/uploader/components/UploadsProgressFooter.vue";
import UploadsProgressHeader from "@/domain/uploader/components/UploadsProgressHeader.vue";
import {stateWidgetUpload} from "@/constants";

export default {
  name: "UploadWidget",
  components: {UploadsProgressHeader, UploadsProgressFooter, UploadsProgress},
  emits: ['upload', 'seePhotos', 'deliver'],
  props: {
    planName: {
      type: String,
      default: ''
    },
    process_limit: {
      type: Number,
      default: 0
    },
    uploaded_count: {
      type: Number,
      default: 0
    },
    received_count: {
      type: Number,
      default: 0
    },
    processed_count: {
      type: Number,
      default: 0
    },
    intents_count: {
      type: Number,
      default: 0
    },
    pay_as_you_go_paid: {
      type: Boolean,
      default: false
    },
    pay_as_you_go_count: {
      type: Number,
      default: 0
    },
    upload_limit: {
      type: Number,
      default: 0
    },
  },
  computed: {
    currentState() {
      if (this.pay_as_you_go_count > 0) {
        return stateWidgetUpload.payAsYouGo
      } else if (this.intents_count === 0) {
        return stateWidgetUpload.noFilesUploaded
      } else if (this.intents_count > 0 && this.intents_count <= this.process_limit) {
        return stateWidgetUpload.filesUploaded
      } else if (this.pay_as_you_go_paid) {
        return stateWidgetUpload.payAsYouGoPaid
      } else {
        return false
      }
}
  }
}
</script>

<style scoped>

</style>