import {ApiException} from "@/domain/core/exception/exceptions";

const state = {
    users: [],
    user: null
}

export const getters = {
    users: state => state.users,
    user: state => state.user,
}


export const mutations = {
    users(state, users) {
        state.users = users
    },
    user(state, user) {
        state.user = user
    }
}


export const actions = service => ({
    async searchUsers({commit}, filters) {
        try {
            commit('users', await service.searchUsers(filters))
        } catch (e) {
            if (e instanceof ApiException)
                commit('users', [])
            throw e
        }
    },
    async fetchUser({commit}, id) {
        commit('user', null)
        commit('user', await  service.fetchUser(id))
    },
    async reactivate({commit, getters}) {
        commit('user', await service.reactivate(getters.user.id))
    },
    async changePassword({commit, getters}, password) {
        commit('user', await service.changePassword(getters.user.id, password))
    },
    async enableDashboard({commit, getters}) {
        commit('user', await service.enableDashboard(getters.user.id))
    },
})

export default service => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(service)
})

