import {translate} from 'vue-gettext'

const {gettext: $gettext} = translate;
const PhotoSearch = () => import('./PhotoSearch.vue')

export default [
    {
        path: 'photo-search',
        name: 'photoSearch.search',
        meta: {label: $gettext('Browse Photos'),},
        props: {galleryId: null},
        component: PhotoSearch,
    }
]