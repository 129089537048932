import {ApiException, PermissionDenied, RecordNotFound, ValidationError} from "@/domain/core/exception/exceptions"
import axiosRetry from "axios-retry"
import downloader from "@/domain/core/utils/downloader";

export default class ApiClient {

    constructor(axios) {
        this.axios = axios
        this.abortControllers = {}
    }

    abort(signalName = 'default') {
        if (!this.abortControllers[signalName]) return
        this.abortControllers[signalName].abort()
        delete this.abortControllers[signalName]
    }

    _getSignal(name) {
        if (!name) name = 'default'
        if (!this.abortControllers[name]) this.abortControllers[name] = new AbortController()
        return this.abortControllers[name].signal
    }

    async get(url, params, signalName) {
        const signal = this._getSignal(signalName)
        const result = await this.axios.get(url, {
            params: params,
            signal: signal
        }).catch(error => this.throw_exception(error))
        return result.data
    }

    async post(url, params, queryParams, signalName) {
        const signal = this._getSignal(signalName)
        const result = await this.axios.post(url, params, {
            params: queryParams,
            signal: signal
        }).catch(error => this.throw_exception(error));
        return result.data
    }

    async patch(url, params, signalName) {
        const signal = this._getSignal(signalName)
        const result = await this.axios.patch(url, params, {signal: signal}).catch(error => this.throw_exception(error))
        return result.data
    }

    async delete(url, queryParams, signalName) {
        const signal = this._getSignal(signalName)
        const result = await this.axios.delete(url, {
            params: queryParams,
            signal: signal
        }).catch(error => this.throw_exception(error))
        return result.data
    }

    async put(url, params, signalName) {
        const signal = this._getSignal(signalName)
        const result = await this.axios.put(url, params, {signal: signal}).catch(error => this.throw_exception(error))
        return result.data
    }

    async download(url, queryParams = null, params = null, method = 'GET', signalName) {
        const signal = this._getSignal(signalName)
        const response = await this.axios({
            method: method,
            url: url,
            data: params,
            params: queryParams,
            signal: signal,
            responseType: 'blob',
        }).catch(error => this.throw_exception(error))
        downloader.downloadFileFromResponse(response)
    }

    upload(url, form, onProgress, signal) {
        return this.axios.post(url, form, {
            headers: {'Content-Type': 'multipart/form-data'},
            'axios-retry': {
                // POST is NOT idempotent HTTP method, but this API is safe, so we override the axios-retry
                retries: 4,
                retryDelay: (retryNumber) => Math.pow(2, retryNumber) * 1500,
                retryCondition: axiosRetry.isRetryableError,
            },
            signal: signal,
            onUploadProgress: (progressEvent) => {
                onProgress({
                    loaded: progressEvent.loaded,
                    total: progressEvent.total
                })
            }
        }).catch(error => this.throw_exception(error))
    }

    throw_exception(error) {
        if (error.code === 'ERR_CANCELED') return
        if (error.name === 'CanceledError') return
        if (error.name === 'AbortError') return

        if (error.response) {
            if (error.response.status === 404)
                throw new RecordNotFound(error.response.config.url, error.response.status, error.response.data)
            if (error.response.status === 403)
                throw new PermissionDenied(error.response.config.url, error.response.status, error.response.data)
            if (error.response.status === 400)
                throw new ValidationError(error.response.config.url, error.response.status, error.response.data)
        }
        throw new ApiException(error.response.config.url, error.response.status, error.response.data)
    }
}
