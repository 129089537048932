import {ApiException} from "@/domain/core/exception/exceptions";
import {emptyListResponse} from "@/domain/core/utils/django";


const state = {
    invitations: {...emptyListResponse},
    roles: null,
}

export const getters = {
    invitations: state => state.invitations,
    roles: state => state.roles,
}

export const mutations = {
    invitations(state, invitations) {
        state.invitations = invitations
    },
    deleteInvitation(state, id) {
        state.invitations.results = state.invitations.results.filter(user => user.id !== id)
    },
    roles(state, roles) {
        state.roles = roles
    }
}

export const actions = service => ({
    async fetchInvitationList({commit}, {filters, pagination, sorting}) {
        try {
            commit('invitations', await service.fetchInvitationList(filters, pagination, sorting))
        } catch (e) {
            if (e instanceof ApiException)
                commit('invitations', emptyListResponse)
            throw e
        }
    },
    async deleteInvitation({commit}, id) {
        await service.deleteInvitation(id)
        commit('deleteInvitation', id)
    },
    // eslint-disable-next-line no-unused-vars
    async sendInvitation({commit}, params) {
        await service.sendInvitation(params)
    },
    // eslint-disable-next-line no-unused-vars
    async resendInvitation({commit}, id) {
        await service.resendInvitation(id)
    },
    async fetchRoles({commit}) {
        commit('roles', await service.fetchRoles())
    },
})

export default service => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(service)
})

