<template>
  <CModal :title="$pgettext('contract.create','Create Role')" :show="show" @update:show="close" size="lg"
          centered>

    <Form @save="create" :save_text="$pgettext('form','Create')" :data="empty" />
    <template #footer>
      <CButton @click="close" color="secondary" v-translate>Close</CButton>
    </template>
  </CModal>
</template>

<script>
import {mapActions} from "vuex";
import Form from "@/domain/roles/components/Form.vue";

export default {
  name: "CreateRoleModal",
  components: {Form},
  emits: ['sent'],
  data() {
    return {
      empty: {
        name: '',
        permissions: {},
        visible_by: [],
        description: '',
      },
      show: false,
      loading: false,
    }
  },
  methods: {
    ...mapActions('contractManager', [
      'createRole',
    ]),
    open() {
      this.form = {...this.empty}
      this.show = true
    },
    async create(data) {
      this.loading = true
      await this.createRole(data)
      await this.notifySuccess(this.$pgettext('role.create.notification', 'Role created'))
      this.loading = false
      this.close()
    },
    close() {
      this.show = false
    },
  }
}
</script>


<style scoped>

</style>