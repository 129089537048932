export default [
    "20 20",
    '<g clip-path="url(#clip0_1568_1529)">\n' +
    '<path d="M6.66602 6.66699H6.67435M1.66602 4.33366L1.66602 8.06242C1.66602 8.47007 1.66602 8.6739 1.71207 8.86571C1.75289 9.03577 1.82023 9.19835 1.91162 9.34747C2.01468 9.51566 2.15881 9.65979 2.44706 9.94804L8.83759 16.3386C9.82763 17.3286 10.3226 17.8236 10.8935 18.0091C11.3956 18.1722 11.9364 18.1722 12.4386 18.0091C13.0094 17.8236 13.5044 17.3286 14.4944 16.3386L16.3376 14.4954C17.3276 13.5054 17.8226 13.0104 18.0081 12.4395C18.1713 11.9374 18.1713 11.3966 18.0081 10.8944C17.8226 10.3236 17.3276 9.82861 16.3376 8.83856L9.94706 2.44804C9.65881 2.15979 9.51468 2.01566 9.34649 1.91259C9.19737 1.82121 9.03479 1.75387 8.86473 1.71304C8.67292 1.66699 8.4691 1.66699 8.06144 1.66699L4.33268 1.66699C3.39926 1.66699 2.93255 1.66699 2.57603 1.84865C2.26243 2.00844 2.00746 2.2634 1.84767 2.57701C1.66602 2.93353 1.66602 3.40024 1.66602 4.33366ZM7.08268 6.66699C7.08268 6.89711 6.89613 7.08366 6.66602 7.08366C6.4359 7.08366 6.24935 6.89711 6.24935 6.66699C6.24935 6.43687 6.4359 6.25033 6.66602 6.25033C6.89613 6.25033 7.08268 6.43687 7.08268 6.66699Z" fill="none" class="stroke-over" stroke="var(--ci-primary-color, currentColor)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '</g>\n' +
    '<defs>\n' +
    '<clipPath id="clip0_1568_1529">\n' +
    '<rect width="20" height="20" fill="white"/>\n' +
    '</clipPath>\n' +
    '</defs>']

