<template>
  <div class="upload-errors">
    <PAlert :color="color" :icon="icon" :icon-size="24" class="mb-2">
      <template>
        {{ files.length }} {{ message }}
      </template>
      <template #cta>
        <CLink @click="show = !show">
          <CIcon :name="show ? 'cipCaretUp':'cipCaretDown'" size="custom-size" height="24"/>
        </CLink>
      </template>
    </PAlert>
    <transition name="slide">
      <div v-if="show" class="mb-2 px-1 pt-2 upload-errors__list">
        <div v-for="file in files" :key="file.md5">
          <div class="text-ellipsis name mb-1">{{ file.name }}</div>
          <div v-if="file.errorDetail" class="text-ellipsis detail">{{ file.errorDetail }}</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import PAlert from "@/components/PAlert.vue";

export default {
  name: 'UploadErrorAlert',
  components: {PAlert},
  props: {
    files: {
      type: Array,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      show: false
    }
  }
}
</script>