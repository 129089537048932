<template>
  <div>
    <PFRow half-width separator label-class="big" :label="$pgettext('priceList.linear.label','Complete set photo')">
      <PSelectKeyValue :options="searchPriceList" v-model="configuration.template" @input="onTemplateSelect"/>
    </PFRow>
  </div>
</template>

<script>
import PFRow from "@/domain/core/components/PFRow.vue";
import PSelectKeyValue from "@/domain/core/components/PSelectKeyValue.vue";
import {mapActions} from "vuex";

export default {
  name: "TemplatePriceList",
  components: {PSelectKeyValue, PFRow},
  emits: ['update'],
  data() {
    return {
      items: [],
      configuration: {
        template: null
      }
    }
  },
  methods: {
    ...mapActions('eventGallery', ['searchPriceList']),
    getPriceList() {
      return {
        items: this.items,
        configuration: this.configuration
      }
    },
    init({items, configuration, onChange}) {
      this.items = items
      this.configuration = configuration

      if (!onChange) return
      this.onUpdate()
    },
    onTemplateSelect(value) {
      const template = value.original
      this.configuration.template = template.id
      this.items = template.items
      this.onUpdate()
    },
    onUpdate() {
      this.$emit('update', {
        items: this.items,
        configuration: this.configuration
      })
    }
  }
}
</script>