import DetailContractManager from "@/domain/contract_manager/DetailContractManager.vue";

const ListContractManager = () => import('./ListContractManager.vue')
const EditContractManager = () => import('./EditContractManager.vue')
const EditContract = () => import('./EditContract.vue')
const NewContractManager = () => import('./NewContractManager.vue')
const TheContainer = () => import('@/containers/TheContainer')

export default [
    {
        path: '/contract_manager',
        name: 'contract_manager',
        component: TheContainer,
        children: [
            {
                path: 'list',
                name: 'contract_manager.list',
                component: ListContractManager,
                meta: {label: 'List Contract Managers',},
            },
            {
                path: 'new',
                name: 'contract_manager.new',
                component: NewContractManager,
                meta: {label: 'Create new Contract Manager',},
            },
            {
                path: ':id/edit',
                name: 'contract_manager.edit',
                component: EditContractManager,
                meta: {label: 'Edit Contract Manager',},
            },
            {
                path: ':id/detail',
                name: 'contract_manager.detail',
                component: DetailContractManager,
                meta: {label: 'Contract Manager',},
            },
            {
                path: ':contract_manager/contract/:id/edit',
                name: 'contract.edit',
                component: EditContract,
                meta: {label: 'Edit Contract',},
            },
        ]
    }
]