import {ApiException} from "@/domain/core/exception/exceptions";
import {emptyListResponse} from "@/domain/core/utils/django";

const emptyRows = {
    results: [],
    pagination: {
        num_per_page: 20,
        next: null,
    }
}


const state = {
    shorts: {...emptyListResponse},
    short: {},
}

export const getters = {
    shorts: state => state.shorts,
    short: state => state.short,
    hasMoreShorts: state => state.shorts.pagination.next !== null,
    next: state => state.shorts.pagination.next,
}


export const mutations = {
    shorts(state, shorts) {
        state.shorts = shorts
    },
    addShorts(state, shorts) {
        state.shorts.pagination.next = shorts.pagination.next
        state.shorts.results = state.shorts.results.concat(shorts.results)
    },
    short(state, short) {
        state.short = short
    },
    deleteShort(state, id) {
        state.shorts.results = state.shorts.results.filter(tag => tag.id !== id)
    }
}


export const actions = shortService => ({
    async fetchShortList({commit}, {filters}) {
        try {
            const shorts = await shortService.fetchList(filters)
            commit('shorts', shorts)
        } catch (e) {
            if (e instanceof ApiException) {
                commit('shorts', emptyRows)
            }
            throw e
        }
    },
    async fetchMoreShorts({commit, getters},{filters}){
        try {
            const shorts = await shortService.fetchList(filters, getters.next)
            commit('addShorts', shorts)
        } catch (e) {
            if (e instanceof ApiException) {
                commit('shorts', emptyRows)
            }
            throw e
        }
    },
    async deleteShort({commit}, id) {
        await shortService.delete(id)
        commit('deleteShort', id)
    },
    async fetchShort({commit}, id) {
        const short = await shortService.get(id)
        commit('short', short)
    },
    async createShort({commit}, data) {
        const short = await shortService.create(data)
        commit('short', short)
    },
    async updateShort({commit}, {id, data}) {
        const short = await shortService.update(id, data)
        commit('short', short)
    },
    // eslint-disable-next-line no-unused-vars
    async getFreeUrl({commit}) {
        return await shortService.getFreeUrl()
    }
})

export default shortService => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(shortService)
})

