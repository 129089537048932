<template>
  <div>

    <CRow>
      <CCol lg="12">
        <h1 class="main-header" v-translate translate-context="refund">
          Refund List
        </h1>
        <CCard class="">
          <CCardHeader>
            <div class="card-header-actions">
              <router-link :to="{name: 'refund.new'}" v-if="hasPerm('orders.refunds.create')">
                <CButton block color="primary" v-translate translate-context="list.actions">Create</CButton>
              </router-link>
              <PButton class="btn btn-primary" style="margin-right: 5px"
                       @click="onExportRefunds"
                       :loading="exporting"
                       :loading-text="$gettext('Generating file...')"
                       spinner-color="secondary"
                       :disabled="!refunds.results || refunds.results.length <= 0"
                       v-if="hasPerm('orders.refunds.export')"
              >
                <translate>Export</translate>
              </PButton>
            </div>
          </CCardHeader>
          <CCardBody class="px-0">
            <CDataTable
                :hover="false"
                :bordered="true"
                :items="refunds.results"
                :fields="fields"
                :column-filter="{external: true,lazy:true}"
                :column-filter-value="filters"
                :sorter="{external: true, resetable:true}"
                :loading="loading"
                :sorter-value="sorting"
                @update:column-filter-value="updateFilters"
                @update:sorter-value="updateSorting"
                class="custom-table custom-table--clean custom-table--has-filters"
            >
              <template #no-items-view>
                <div class="text-center">
                  <h5 v-translate>No items found</h5>
                </div>
              </template>
              <template #sorting-icon="{ state, classes }">
                <div :class="classes">
                  <CIcon name="cipCaretUp" size="lg" v-if="state === 'asc' || state === 0"></CIcon>
                  <CIcon name="cipCaretUp" size="lg" v-else></CIcon>
                </div>
              </template>
              <template #event_name="{item}">
                <td>
                  {{ item.pica_service }} - {{ item.event_name }}
                </td>
              </template>
              <template #amount="{item}">
                <td>
                  {{ item.amount }} {{ item.currency }}
                </td>
              </template>
              <template #order="{item}">
                <td @click="openOrderPage(item)">
                  {{ item.order }}
                </td>
              </template>
              <template #actions="{item}">
                <td class="text-right">
                  <CButton
                      @click="$router.push({name: 'refund.detail', params: {id: item.id}})"
                      size="sm" variant="outline" color="primary"
                      v-translate translate-context="list.actions"
                      v-if="hasPerm('orders.refunds.detail')"
                  >
                    Edit
                  </CButton>

                  <CButton
                      @click="onDelete(item.id)"
                      size="sm" variant="outline" color="danger"
                      v-translate translate-context="list.actions"
                      v-if="hasPerm('orders.refunds.delete')"
                  >
                    Delete
                  </CButton>
                </td>
              </template>
              <template #under-table>
                <CPagination
                    v-show="refunds.pagination.num_pages > 1"
                    :activePage="refunds.pagination.page"
                    @update:activePage="updatePage"
                    :pages="refunds.pagination.num_pages"
                />
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>


    </CRow>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {refundMethods} from "@/domain/refund/methods";
import PButton from "@/components/PButton.vue";

export default {
  name: 'ListRefund',
  components: {PButton},

  data() {
    return {
      loading: true,
      exporting: false,
      pagination: {
        page_size: 20,
        page: 1,
      },
      sorting: {
        column: null,
        asc: false
      },
      filters: {},
    }
  },
  async mounted() {
    await this.fetchConfig({type: 'refund'})
    await this.fetchList()
  },
  computed: {
    ...mapGetters('refund', [
      'refunds',
    ]),
    fields() {
      let fields = []
      if (this.hasPerm('orders.refunds.create'))
        fields.push({key: 'id', label: this.$pgettext('refund.list', 'ID'), _style: 'width:100px;'})
      fields.push(
          {key: 'event_name', label: this.$pgettext('refund.list', 'Event Name'), sorter: false},
          {key: 'order', label: this.$pgettext('refund.list', 'Order'), sorter: false},
          {key: 'amount', label: this.$pgettext('refund.list', 'Amount'), sorter: false, filter: false},
          {key: 'picacode', label: this.$pgettext('refund.list', 'PicaCode'), sorter: false},
          {key: 'reason', label: this.$pgettext('refund.list', 'Reason'), sorter: false, filter: false},
      )
      if (this.hasPerm('orders.refunds.create'))
        fields.push({key: 'created_by', label: this.$pgettext('refund.list', 'Admin'), sorter: false, filter: false})
      if (this.hasAnyPerms(['orders.refunds.detail', 'orders.refunds.delete']))
        fields.push({key: 'actions', label: this.$pgettext('refund.list', 'Actions'), sorter: false, filter: false})
      return fields
    }
  },
  methods: {
    ...mapActions('refund', [
      'fetchRefundList',
      'deleteRefund',
      'exportRefunds'
    ]),
    ...mapActions('login', [
      'fetchConfig'
    ]),
    async fetchList() {
      this.loading = true
      await this.fetchRefundList({
        filters: this.filters,
        pagination: this.pagination,
        sorting: this.sorting
      })
      this.pagination.page = this.refunds.pagination.page
      this.loading = false
    },
    updateFilters(filters) {
      this.filters = filters
      this.pagination.page = 1
      this.fetchList()
    },
    updateSorting(sorting) {
      this.sorting = sorting
      this.fetchList()
    },
    updatePage(page) {
      this.pagination.page = page
      this.fetchList()
    },
    async onDelete(id) {
      // TODO: Trovare un componente per fare i confirm
      if (!confirm('Are you sure?')) {
        return
      }
      await this.deleteRefund(id)
      await this.fetchList()
    },
    onExportRefunds() {
      this.exportRefunds(this.filters)
    },
    ...refundMethods
  }
}
</script>

<style scoped>

</style>