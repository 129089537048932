import {MD5} from "crypto-js";

const state = {
    logoSet: null,
    picaServiceId: null,
    oldPreviewUrl: {}
}

export const getters = {
    picaServiceId: state => state.picaServiceId,
    previewUrl: state => (params) => {
        const key = MD5(JSON.stringify(params))
        return state.oldPreviewUrl[key]
    },
}


export const mutations = {
    setPicaServiceId(state, picaServiceId) {
        state.picaServiceId = picaServiceId
    },
    setPreviewUrl(state, {params, url}) {
        const key = MD5(JSON.stringify(params))
        state.oldPreviewUrl[key] = url
    },
}


export const actions = (logoSetService) => ({
    setPicaServiceId({commit}, picaServiceId) {
        commit('setPicaServiceId', picaServiceId)
    },
    async fetchLogoSet({getters}, id) {
        return await logoSetService.fetchLogoSet(getters.picaServiceId, id)
    },
    async createLogoSet({getters}, logoSet) {
        return await logoSetService.createLogoSet(getters.picaServiceId, logoSet)
    },
    async updateLogoSet({getters}, logoSet) {
        return await logoSetService.updateLogoSet(getters.picaServiceId, logoSet)
    },
    async deleteLogoSet({getters}, id) {
        await logoSetService.deleteLogoSet(getters.picaServiceId, id)
    },
    async getPreviewUrl({commit, getters}, data) {
        let url = getters.previewUrl(data)
        if (url) return url
        url = await logoSetService.getPreviewUrl(getters.picaServiceId, data)
        commit('setPreviewUrl', {params: data, url})
        return url
    },
})

export default (logoSetService) => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(logoSetService)
})
