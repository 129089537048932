import {translate} from 'vue-gettext';

const {gettext: $gettext} = translate;


class StepSummary {
    constructor() {
        this.mandatory = []
        this.suggested = []
    }

    addMandatory(field, value) {
        this.mandatory.push({field, value})
    }

    addSuggested(field, value) {
        this.suggested.push({field, value})
    }

    getSuggestions() {
        if (this.mandatory.length === 0 && this.suggested.length === 0)
            return false
        let suggestion = {}
        if (this.mandatory.length > 0) suggestion.mandatory = this.mandatory
        if (this.suggested.length > 0) suggestion.suggested = this.suggested
        return suggestion
    }

    isDone() {
        return this.mandatory.length === 0 && this.suggested.length === 0
    }
    isMandatoryOk() {
        return this.mandatory.length === 0
    }

}

const baseStepData = {
    done: false,
    mandatory_ok: false,
    active: false,
    suggestion: false,
}

const template = {
    ...baseStepData,
    key: 'template',
    done: true,
    mandatory_ok: true,
}

const planSettings = {
    ...baseStepData,
    name: $gettext('Plan'),
    key: 'plan_settings',
    done: true,
    mandatory_ok: true,
}

const vertical = {
    ...baseStepData,
    name: $gettext('Vertical'),
    key: 'vertical',
    done: true,
    mandatory_ok: true,
}

const eventSettings = {
    ...baseStepData,
    key: 'event_settings',
    done: true,
    mandatory_ok: true,
}

const dateAndName = {
    ...baseStepData,
    name: $gettext('Event date and name'),
    key: 'date_and_name',
    getSummary(form, configuration) {
        let summary = new StepSummary()
        if (!form.name) summary.addMandatory('name', $gettext('Event name'))
        if (!form.start_at) summary.addMandatory('start_at', $gettext('Event starting date'))
        if (!form.timezone) summary.addMandatory('timezone', $gettext('Event official timezone'))
        // if (!form.welcome_code) summary.addMandatory('name', $gettext('Event welcome code'))
        if (configuration.allows_profile_event_photo) {
            if (!form.logo) summary.addSuggested('logo', $gettext('Event logo'))
        }
        return summary
    }
}

const customizeLogoOnPhoto = {
    ...baseStepData,
    name: $gettext('Photo branding'),
    key: 'customize_logo_on_photo',
    getSummary(form) {
        let summary = new StepSummary()
        if (!form.logo) summary.addSuggested('logo', $gettext('Watermark logo on photo'))
        return summary
    }
}

const bannerAdvertisement = {
    ...baseStepData,
    name: $gettext('Banner ADV + link'),
    key: 'banner_advertisement',
    show_in_summary: true,
    getSummary(form) {
        let summary = new StepSummary()
        if (!form.banner && !form.link) summary.addSuggested('logo', $gettext('Watermark logo on photo'))
        else if (!form.banner) summary.addMandatory('banner', $gettext('You can\'t have a link without a banner'))
        else if (!form.link) summary.addMandatory('link', $gettext('You can\'t have a banner without a link'))
        return summary
    }
}

const form = {
    ...baseStepData,
    name: $gettext('Data collection'),
    key: 'form',
    done: true,
    mandatory_ok: true,
}

const customization = {
    ...baseStepData,
    name: $gettext('Customization'),
    key: 'customization',
    done: true,
    mandatory_ok: true,
}

const checkinOption = {
    ...baseStepData,
    name: $gettext('Checkin Options'),
    key: 'checkin_options',
    done: true,
    mandatory_ok: true,
}


const emailScheduler = {
    ...baseStepData,
    name: $gettext('E-Mail Scheduler'),
    key: 'email_scheduler',
    done: true,
    mandatory_ok: true,
}

const popup = {
    ...baseStepData,
    name: $gettext('PopUp'),
    key: 'popup',
    done: true,
    mandatory_ok: true,
}

const folderMapping = {
    ...baseStepData,
    name: $gettext('Folder Mapping'),
    key: 'folder_mapping',
    done: true,
    mandatory_ok: true,
}

const picaCodes = {
    ...baseStepData,
    name: $gettext('Pica Codes'),
    key: 'pica_codes',
    done: true,
    mandatory_ok: true,
}

const overview = {
    ...baseStepData,
    name: $gettext('Overview'),
    key: 'overview',
    done: true,
    mandatory_ok: true,
}

export const steps = {
    // Tab configuration
    'template': template,
    'plan_settings': planSettings,
    'vertical': vertical,
    'event_settings': eventSettings,

    // Normal steps
    'date_and_name': dateAndName,
    'customize_logo_on_photo': customizeLogoOnPhoto,
    'banner_advertisement': bannerAdvertisement,
    'customization': customization,
    'form': form,
    'checkin_options': checkinOption,
    'email_scheduler': emailScheduler,
    'popup': popup,
    'folder_mapping': folderMapping,
    'pica_codes': picaCodes,
    'overview': overview,
}