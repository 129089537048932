import {steps} from "@/domain/eventWizard/steps";
import Vue from "vue";
import Translate from "@/domain/core/utils/translation";

const state = {
    eventId: null,
    configuration: {
        template: false,
        plan_settings: false,
        vertical: false,
        event_settings: false,
        allows_upload_from_mobile: false,
        published: false,
        max_gallery_files: 0,
        event_started: false,
        event_ended: false,
        show_photo_map: false,
        allows_profile_event_photo: false,
        allows_custom_welcome_code: false,
        requires_welcome_code: false,
        auto_generate_welcome_code: false,
        manage_date_and_name: false,
        manage_gallery_photo_and_video: false,
        customize_logo_on_photo: false,
        manage_banner_advertisement: false,
        manage_form: false,
        manage_checkin_options: false,
        manage_customization: false,
        manage_popup: false,
        is_uploader_available: false,
        see_stats: false,
        manage_invitations: false,
        manage_photographer_managers: false,
        see_photo_search: false,
        manage_email_scheduler: false,
        manage_folder_mapping: false,
        manage_pica_codes: false,
        manage_advanced: false,
        is_paid: false,
        require_aggregate_media_view: true,
    },
    eventNote: null,
    steps: [],
    form: null,
    advanced: {},
    publicIntentUrl: {},
    publicFormS3Url: {},
    validationErrors: {},
    loading: [],
    checkinOptionsTags: null,
    logoOnPhotoTags: null,
    planOptions: null,
    paymentCurrency: null,
    unlockInfo: null,
    order: null,
}

export const getters = {
    eventId: state => state.eventId,
    eventNote: state => state.eventNote,
    configuration: state => state.configuration,
    steps: state => state.steps,
    form: state => state.form,
    isPublished: state => state.configuration.published,
    actualStep: state => {
        if (state.steps.length === 0) return null
        let step = state.steps.find(step => step.active)
        if (step) return step.key
        return state.steps[0].key
    },
    nextStep: state => {
        if (state.steps.length === 0) return null
        let nextStep = state.steps.findIndex(s => s.active === true) + 1
        return nextStep >= state.steps.length ? state.steps[nextStep - 1].key : state.steps[nextStep].key
    },
    isStepDone: state => step => {
        if (state.steps.length === 0) return false
        return state.steps.find(s => s.key === step).done
    },
    isStepMandatoryOk: state => step => {
        if (state.steps.length === 0) return false
        return state.steps.find(s => s.key === step).mandatory_ok
    },
    isStepVisible: state => step => {
        if (state.steps.length === 0) return false
        return state.steps.find(s => s.key === step)
    },
    isStepLoading: state => step => {
        if (state.loading.length === 0) return false
        return state.loading.includes(step)
    },
    allMandatoryIsPresent: state => {
        return state.validationErrors.length === 0
    },
    suggestions: state => {
        let suggestions = []
        // for (const i in state.steps) {
        //     const suggestion = state.steps[i].suggestion
        //     if (!suggestion) continue
        //     suggestions.push({
        //         step: state.steps[i].name,
        //         mandatory: suggestion.mandatory,
        //         suggested: suggestion.suggested
        //     })
        // }
        if (Object.keys(state.validationErrors).length > 0) {
            let errors = []
            for (const key in state.validationErrors)
                errors.push({
                    field: key,
                    value: Translate.context('wizard.publish.validation',key,state.validationErrors[key])
                })
            suggestions.push({
                step: Translate.context('wizard.publish.validation','step','Event'),
                mandatory: errors,
            })
        }
        return suggestions
    },
    publicIntentUrl: state => id => {
        if (!state.publicIntentUrl[id])
            return null
        return state.publicIntentUrl[id]
    },
    publicFormS3Url: state => id => {
        if (!state.publicFormS3Url[id])
            return null
        return state.publicFormS3Url[id]
    },
    advanced: state => type => {
        if (!state.advanced || !state.advanced[type]) return false
        return state.advanced[type]
    },
    showPhotoMap: state => state?.configuration?.show_photo_map || false,
    isUploaderAvailable: state => state?.configuration?.is_uploader_available || false,
    canChoosePhotographerManager: state => state?.configuration?.manage_photographer_managers || false,
    canSeePhotoSearch: state => state?.configuration?.see_photo_search || false,
    checkinOptionsTags: state => state.checkinOptionsTags,
    logoOnPhotoTags: state => state.logoOnPhotoTags,
    planOptions: state => state.planOptions,
    paymentCurrency: state => state.paymentCurrency,
    unlockInfo: state => state.unlockInfo,
    requireAggregateMediaView: state => state.configuration.require_aggregate_media_view,
    order: state => state.order,
}


export const mutations = {
    updateStatusBasedOnData(state) {
        for (let i in state.steps) {
            const step = state.steps[i]
            const form = state.form[step.key]

            if (step.getSummary) {
                const summary = step.getSummary(form, state.configuration)
                state.steps[i].mandatory_ok = summary.isMandatoryOk()
                state.steps[i].done = summary.isDone()
                state.steps[i].suggestion = summary.getSuggestions()
            }
        }
    },
    setStepsBasedOnConfiguration(state, {configuration}) {
        state.steps = []
        if (configuration.published) state.steps.push(steps['overview'])
        if (configuration.template) state.steps.push(steps['template'])
        if (configuration.plan_settings) state.steps.push(steps['plan_settings'])
        if (configuration.vertical) state.steps.push(steps['vertical'])
        if (configuration.event_settings) state.steps.push(steps['event_settings'])
        if (configuration.manage_date_and_name) state.steps.push(steps['date_and_name'])
        if (configuration.customize_logo_on_photo) state.steps.push(steps['customize_logo_on_photo'])
        if (configuration.manage_banner_advertisement) state.steps.push(steps['banner_advertisement'])
        if (configuration.manage_form) state.steps.push(steps['form'])
        if (configuration.manage_checkin_options) state.steps.push(steps['checkin_options'])
        if (configuration.manage_customization) state.steps.push(steps['customization'])
        if (configuration.manage_popup) state.steps.push(steps['popup'])
        if (configuration.manage_email_scheduler) state.steps.push(steps['email_scheduler'])
        if (configuration.manage_folder_mapping) state.steps.push(steps['folder_mapping'])
        if (configuration.manage_pica_codes) state.steps.push(steps['pica_codes'])
    },
    setEventId(state, eventId) {
        state.eventId = eventId
        state.logoOnPhotoTags = null
        state.checkinOptionsTags = null
        state.eventNote = null
        state.logoOnPhotoTags = null
        state.planOptions = null
        state.paymentCurrency = null
        state.unlockInfo = null
    },
    setStepAsDone(state, step) {
        state.steps.find(s => s.key === step).done = true
    },
    setStepAsActive(state, step) {
        state.steps.forEach(s => s.active = false)
        let activeStep = state.steps.find(s => s.key === step)
        if (activeStep) activeStep.active = true
    },
    setFormData(state, {step, data}) {
        if (!state.form) state.form = {}
        Vue.set(state.form, step, data)
    },
    setEventConfiguration(state, configuration) {
        state.configuration = configuration
    },
    setPublicIntentUrl(state, {id, url}) {
        state.publicIntentUrl[id] = url
    },
    setFormS3PublicUrl(state, {id, url}) {
        state.publicFormS3Url[id] = url
    },
    setAdvanced(state, {type, value}) {
        if (!state.advanced) state.advanced = {}
        state.advanced[type] = value
        localStorage.setItem('wizard_advanced', JSON.stringify(state.advanced))
    },
    setEventNote(state, note) {
        state.eventNote = note
    },
    setStepAsLoading(state, step) {
        if (!state.loading.includes(step))
            state.loading.push(step)
    },
    setStepAsLoaded(state, step) {
        state.loading = state.loading.filter((loadingStep) => loadingStep !== step)
    },
    setCheckinOptionsTags(state, tags) {
        state.checkinOptionsTags = tags
    },
    setLogoOnPhotoTags(state, tags) {
        state.logoOnPhotoTags = tags
    },
    rememberFromStorage(state) {
        const advanced = localStorage.getItem('wizard_advanced')
        if (advanced) state.advanced = JSON.parse(advanced)
    },
    planOptions(state, options) {
        state.planOptions = options
    },
    paymentCurrency(state, currency) {
        state.paymentCurrency = currency
    },
    setUnlockInfo(state, unlockInfo) {
        state.unlockInfo = unlockInfo
    },
    setOrder(state, order) {
        state.order = order
    },
    validation(state, validation) {
        if (!validation) validation = []
        state.validationErrors = validation
    }
}


export const actions = (service, picaServiceService) => ({
    setEventId({commit}, eventId) {
        commit('setEventId', eventId)
    },

    async fetchFormData({commit, getters}) {
        getters.steps.forEach((step) => commit('setStepAsLoading', step))
        const response = await service.getData(getters.eventId, getters.steps.map(s => s.key))
        response.forEach(step => {
            commit('setFormData', {
                step: step.key,
                data: step.data
            })
            commit('setStepAsLoaded', step.key)
        })
        commit('updateStatusBasedOnData')
    },
    async fetchStepData({commit, getters}, step) {
        commit('setStepAsLoading', step)
        const response = await service.getStepData(getters.eventId, step)
        commit('setFormData', {
            step: response.key,
            data: response.data
        })
        commit('setStepAsLoaded', step)
        commit('updateStatusBasedOnData')
    },
    async fetchEventConfiguration({commit, getters, rootGetters}) {
        const configuration = await service.getConfiguration(getters.eventId)
        const canUpdateConfigration = rootGetters['login/hasPerm']('dashboard.view_dashboard_event_template')
        const {galleries, ...config} = configuration
        commit('eventGallery/galleries', galleries, {root: true})
        commit('setEventConfiguration', config)
        commit('setStepsBasedOnConfiguration', {configuration: config, canUpdateConfigration})
    },
    setStepAsDone({commit}, step) {
        commit('setStepAsDone', step)
    },
    setStepAsActive({commit}, step) {
        commit('setStepAsActive', step)
    },
    setNextStepAsActive({commit, getters}) {
        commit('setStepAsActive', getters.nextStep)
    },
    activateFirstStep({commit, getters}) {
        for (let i in state.steps) {
            const step = state.steps[i]
            if (!step.done) {
                commit('setStepAsActive', step.key)
                return
            }
        }
        commit('setStepAsActive', getters.actualStep)
    },
    async updateFormData({commit, getters}, {step, data}) {
        commit('setStepAsLoading', step)
        const updated_data = await service.updateData(getters.eventId, step, data)
        commit('setFormData', {step: step, data: updated_data})
        commit('setStepAsLoaded', step)
        commit('updateStatusBasedOnData')
    },
    async fetchFreePicaCodesForEventName({getters}, {name, date}) {
        return await service.getFreePicaCodesForName({name, date, eventId: getters.eventId})
    },
    async validateWelcomeCode({getters}, code) {
        return await service.validateWelcomeCode({code, eventId: getters.eventId})
    },
    async fetchEventNote({commit, getters}) {
        const response = await picaServiceService.getEventNotes({
            'pica_service': getters.eventId
        })
        commit('setEventNote', response.data.results)
    },
    async createNewEventNote({getters, dispatch}, note) {
        await picaServiceService.createEventNote({
            'pica_service': getters.eventId,
            'text': note
        })
        dispatch('fetchEventNote')
    },
    async updateEventNote({getters, dispatch}, params) {
        await picaServiceService.updateEventNote({
            'pica_service': getters.eventId,
            'text': params.text,
            'id': params.id
        })
        dispatch('fetchEventNote')
    },
    async deleteEventNote({getters, dispatch}, id) {
        await picaServiceService.deleteEventNote({
            'pica_service': getters.eventId,
            'id': id
        })
        dispatch('fetchEventNote')
    },
    async getWizardUploadIntent({getters}, {field, file}) {
        return await service.getWizardUploadIntent({
            eventId: getters.eventId,
            field: field,
            file: file
        })
    },
    async fetchPublishValidation({getters, commit}) {
        if (getters.isPublished) {
            commit('validation', [])
            return
        }
        commit('validation', await service.fetchPublishValidation(getters.eventId))
    },
    async publish({getters, commit}, {basePlanId, force}) {
        await service.publish(getters.eventId, basePlanId, force)
        commit('event/setAsPublished', {}, {root: true})
    },
    async paymentOnDemand({getters, commit}, {planId, createInvoice}) {
        commit('setOrder', await service.paymentOnDemand({
            pica_service_id: getters.eventId,
            base_plan_id: planId,
            create_invoice: createInvoice
        }));
    },
    async paymentPayAsYouGo({getters, commit}, {qty, createInvoice}) {
        commit('setOrder', await service.paymentPayAsYouGo(getters.eventId, qty, createInvoice))
    },
    async setOrderStatus({getters}, status) {
        return await service.setOrderStatus(getters.order.order_id, status)
    },
    async getOrderStatus({getters}) {
        return await service.getOrderStatus(getters.order.order_id)
    },
    async exportFormResults({getters}) {
        return await service.exportFormResults(getters.eventId)
    },
    async exportSharedData({getters}) {
        return await service.exportSharedData(getters.eventId)
    },
    // eslint-disable-next-line no-unused-vars
    async getUploadIntentPublicUrl({getters, commit}, id) {
        let url = getters.publicIntentUrl(id)
        if (!url) {
            url = await service.getUploadIntentPublicUrl(id)
            commit('setPublicIntentUrl', {id, url})
        }
        return url
    },
    async getFormS3PublicUrl({getters, commit}, fileName) {
        if (!fileName || fileName.length <= 0) return '';
        let url = getters.publicFormS3Url(fileName)
        if (!url) {
            url = await service.getFormS3PublicUrl(getters.eventId, fileName)
            commit('setFormS3PublicUrl', {id: fileName, url})
        }
        return url
    },
    setAdvanced({commit}, {type, value}) {
        commit('setAdvanced', {type, value})
    },
    async searchRelease({getters}, params) {
        return await service.searchRelease(getters.eventId, params)
    },
    async fetchCheckinOptionsTags({commit, getters}) {
        if (getters.checkinOptionsTags) return
        commit('setCheckinOptionsTags', await service.fetchCheckinOptionsTags(getters.eventId))
    },
    async fetchLogoOnPhotoTags({commit, getters}) {
        if (getters.logoOnPhotoTags) return
        commit('setLogoOnPhotoTags', await service.fetchLogoOnPhotoTags(getters.eventId))
    },
    async rememberFromStorage({commit}) {
        commit('rememberFromStorage')
    },
    async fetchPlanOptions({commit, getters}) {
        commit('planOptions', await service.fetchPlanOptions(getters.eventId))
    },
    async fetchPaymentCurrency({commit, getters}) {
        if (getters['paymentCurrency']) return
        commit('paymentCurrency', await service.fetchPaymentCurrency(getters.eventId))
    },
    async fetchUnlockInfo({commit, getters}) {
        commit('setUnlockInfo', await service.fetchUnlockInfo(getters.eventId))
    },
    async searchContractManager({getters}, query) {
        return await service.searchContractManager(getters.eventId, query)
    },
    async searchContract({getters}, {contractManager, query}) {
        return await service.searchContract(getters.eventId, contractManager,query)
    },
    async searchEventOwner({getters}, query) {
        return await service.searchEventOwner(getters.eventId, query)
    },
})

export default (eventWizardService, picaServiceService) => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(eventWizardService, picaServiceService)
})
