import Vue from "vue";
import Downloader from "@/domain/core/utils/downloader";

const state = {
    eventId: null,
    configuration: null,
    timeZone: null,
    groupByType: {},
    groupByShareType: {},
    shareLinkByShareType: {},
    mostSharedMedia: [],
    influencerMostViewed: [],
    activeUsers: {},
    date: {}
}

export const getters = {
    configuration: state => state.configuration,
    eventId: state => state.eventId,
    timeZone: state => state.timeZone,
    groupByType: state => state.groupByType,
    mostSharedMedia: state => state.mostSharedMedia,
    influencerMostViewed: state => state.influencerMostViewed,
    groupByShareType: state => state.groupByShareType,
    shareLinkByShareType: state => state.shareLinkByShareType,
    activeUsers: state => state.activeUsers,
    date: state => state.date
}


export const mutations = {
    setEventId(state, eventId) {
        state.eventId = eventId
    },
    setConfiguration(state, configuration) {
        if (!configuration) configuration = null
        state.configuration = configuration
    },
    setGroupByType(state, {data}) {
        Object.keys(data).forEach((type) => {
            Vue.set(state.groupByType, type, data[type])
        })
    },
    setMostSharedMedia(state, mostSharedMedia) {
        state.mostSharedMedia = mostSharedMedia
    },
    setInfluencerMostViewed(state, influencerMostViewed) {
        state.influencerMostViewed = influencerMostViewed
    },
    setGroupByShareType(state, {data}) {
        Object.keys(data).forEach((type) => {
            Vue.set(state.groupByShareType, type, data[type])
        })
    },
    setShareLinkByShareType(state, {data}) {
        Object.keys(data).forEach((type) => {
            Vue.set(state.shareLinkByShareType, type, data[type])
        })
    },
    setActiveUsers(state, activeUsers) {
        state.activeUsers = activeUsers
    },
    resetData(state) {
        state.groupByType = {}
        state.groupByShareType = {}
        state.shareLinkByShareType = {}
        state.mostSharedMedia = []
        state.influencerMostViewed = []
        state.activeUsers = {}
    },
    setTimezone(state, timeZone) {
        state.timeZone = timeZone
    },
    setDates(state, {from, to}) {
        state.date = {
            from: from.format('YYYY-MM-DDTHH:mm:ssZ'),
            to: to.format('YYYY-MM-DDTHH:mm:ssZ')
        }
    }
}


export const actions = (eventMetricsService) => ({
    setEventId({commit}, eventId) {
        commit('setEventId', eventId)
        commit('resetData')
    },
    resetData({commit}) {
        commit('resetData')
    },
    setDates({commit}, {from, to}) {
        commit('setDates', {from, to})
    },
    setTimezone({commit}, timeZone) {
        commit('setTimezone', timeZone)
    },
    async fetchConfiguration({commit, getters}) {
        const response = await eventMetricsService.getConfiguration(getters.eventId)
        commit('setConfiguration', response)
    },
    async fetchGropByType({commit, getters}, {countBy, types}) {
        const response = await eventMetricsService.getGroupByType(getters.eventId, getters.date.from, getters.date.to,getters.timeZone, countBy, types)
        commit('setGroupByType', {
            data: response,
            from: getters.date.from,
            to: getters.date.to,
            countBy: countBy
        })
    },
    async fetchMostSharedMedia({commit, getters}, {num}) {
        const response = await eventMetricsService.getMostSharedMedia(getters.eventId, getters.date.from, getters.date.to,getters.timeZone, num)
        commit('setMostSharedMedia', response)
    },
    async fetchInfluencerMostViewed({commit, getters}, { num}) {
        const response = await eventMetricsService.getInfluencerMostViewed(getters.eventId, getters.date.from, getters.date.to,getters.timeZone, num)
        commit('setInfluencerMostViewed', response)
    },
    async fetchGroupByShareType({commit, getters}, {countBy}) {
        const response = await eventMetricsService.getGroupByShareType(getters.eventId, getters.date.from, getters.date.to,getters.timeZone, countBy)
        commit('setGroupByShareType', {
            data: response,
            from: getters.date.from,
            to: getters.date.to,
            countBy: countBy
        })
    },
    async fetchShareLinkByShareType({commit, getters}, {countBy}) {
        const response = await eventMetricsService.getShareLinkByShareType(getters.eventId, getters.date.from, getters.date.to,getters.timeZone, countBy)
        commit('setShareLinkByShareType', {
            data: response,
            from: getters.date.from,
            to: getters.date.to,
            countBy: countBy
        })
    },
    async fetchActiveUsers({commit, getters}) {
        const response = await eventMetricsService.getActiveUsers(getters.eventId, getters.date.from, getters.date.to, getters.timeZone)
        commit('setActiveUsers', response)
    },
    async downloadSharedMedia({getters}) {
        const data = await eventMetricsService.downloadSharedMedia(getters.eventId, getters.timeZone)
        Downloader.downloadFile(data, 'shared_media.csv')
    }
})

export default eventMetricsService => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(eventMetricsService)
})
