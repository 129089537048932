import {ApiException} from "@/domain/core/exception/exceptions";
import {emptyListResponse} from "@/domain/core/utils/django";

const state = {
    users: {...emptyListResponse},
    impersonator: JSON.parse(localStorage.getItem('impersonator')) || null,
}


export const getters = {
    users: state => state.users,
    isImpersonating: state => state.impersonator !== null,
    impersonator: state => state.impersonator,
}


export const mutations = {
    users(state, users) {
        state.users = users
    },
    resetImpersonator(state) {
        state.impersonator = null
        localStorage.removeItem('impersonator')
    },
    rememberImpersonator(state, {token, jwt}) {
        state.impersonator = {
            token: token,
            jwt: jwt,
        }
        localStorage.setItem('impersonator', JSON.stringify(state.impersonator))
    },
}


export const actions = impersonateService => ({
    async fetchUsersList({commit}, {filters, pagination, sorting}) {
        try {
            const users = await impersonateService.fetchUsersList(filters, pagination, sorting)
            commit('users', users)
        } catch (e) {
            if (e instanceof ApiException)
                commit('users', emptyListResponse)
            throw e
        }
    },
    async impersonate({commit, dispatch, rootGetters}, user) {
        const response = await impersonateService.impersonate(user.id)

        commit('rememberImpersonator', {
            token: rootGetters['login/token'],
            jwt: rootGetters['login/jwt'],
        })
        commit('login/setToken', {token: response.token, type: 'Token'}, {root: true})
        commit('login/setJwt', response.jwt, {root: true})
        commit('core/resetCache', null, {root: true})
        await dispatch('login/getCurrentUser',true , {root: true})
    },
    async returnYourself({commit, getters, dispatch}) {
        const impersonator = getters.impersonator

        commit('login/setToken', impersonator.token, {root: true})
        commit('login/setJwt', impersonator.jwt, {root: true})

        await dispatch('login/getCurrentUser',null, {root: true})
        commit('resetImpersonator')
    }
})

export default impersonateService => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(impersonateService)
})

