<template>
  <div class="plan-switcher">
    <div class="d-flex justify-content-between plan-switcher__head">
      <h6 class="plan-item__title">
        <CIcon name="cipCube01" size="custom" :height="20" class="mr-1" style="vertical-align: sub"/>
        {{ plan.name }}
      </h6>
    </div>
    <h4 class="plan-item__price">
        <span class="value">{{ plan.price|currency(currency) }}</span>
      /<translate translate-context="public_plan.base">event</translate>
    </h4>
    <div class="d-flex">
      <CButton size="sm" color="secondary" @click="select(-1)" :disabled="!hasPrev">
        <CIcon name="cipMinus" size="custom-size" width="8"/>
      </CButton>
      <div class="plan-switcher__value">{{ plan.media_included }} <span v-translate translate-context="public_plan.base">photos</span></div>
      <CButton size="sm" color="secondary" @click="select(1)" :disabled="!hasNext">
        <CIcon name="cipPlus" size="custom-size" width="8"/>
      </CButton>
    </div>
        <div class="plan-item__extra mt-3" v-if="plan.extra_photo_price">
      <translate translate-context="public_plan.base">Extra photos:</translate> <span class="font-weight-semi-bold">{{ plan.extra_photo_price|currency(currency) }}</span>
      / <translate translate-context="public_plan.base">photos</translate>
    </div>
  </div>
</template>
<script>
export default {
  name: "BasePlanSwitcher",
  emits: ['change'],
  props: {
    plan: {
      type: Object,
      required: true
    },
    currency: {
      type: String,
      required: true
    },
    hasNext: {
      type: Boolean,
      default: false
    },
    hasPrev: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    select(step) {
      this.$emit('change', this.plan.id, step)
    },
  }
}
</script>



