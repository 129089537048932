import Vue from "vue";

const state = {
    picaServiceId: null,
    availableYears: null,
    lastConsolidation: null,
    consolidationsForYear: null,
    deletableId: null,
    currency: null,
    year: null,
    permissions: {
        canConsolidateOrders: false,
        canDeleteConsolidation: false,
        canDownloadOrderExports: false,
        canDownloadSummary: false,
        canSeeRwValues: false,
        canSeeTotals: false,
        canDeleteConfirmedConsolidation: false,
    },

}

export const getters = {
    picaServiceId: state => state.picaServiceId,
    availableYears: state => state.availableYears,
    consolidationsForYear: state => state.consolidationsForYear,
    lastConsolidation: state => state.lastConsolidation,
    currency: state => state.currency,
    permissions: state => state.permissions,
    year: state => state.year,
    deletableId: state => state.deletableId,
}


export const mutations = {
    setPicaServiceId(state, id) {
        state.picaServiceId = id
    },
    setAvailableYears(state, years) {
        state.availableYears = years
    },
    setLastConsolidation(state, lastConsolidation) {
        state.lastConsolidation = lastConsolidation
    },
    setConsolidationsForYear(state, consolidations) {
        state.consolidationsForYear = consolidations
    },
    total_amount_eur(state, total_amount_eur) {
        state.total_amount_eur = total_amount_eur
    },
    setPermissions(state, permissions) {
        Vue.set(state.permissions, 'canConsolidateOrders', 'can_create_order_consolidation' in permissions)
        Vue.set(state.permissions, 'canDeleteConsolidation', 'can_delete_consolidation' in permissions)
        Vue.set(state.permissions, 'canDownloadOrderExports', 'can_download_order_consolidation' in permissions)
        Vue.set(state.permissions, 'canSeeGrossValues', 'can_see_gross_values' in permissions)
        Vue.set(state.permissions, 'canSeeTotals', 'can_see_totals' in permissions)
        Vue.set(state.permissions, 'canSeeRwValues', 'can_see_rw_values' in permissions)
        Vue.set(state.permissions, 'canDeleteConfirmedConsolidation', 'can_delete_confirmed_consolidation' in permissions)
        Vue.set(state.permissions, 'canDownloadSummary', 'can_download_summary' in permissions)
    },
    setCurrency(state, currency) {
        state.currency = currency
    },
    setYear(state, year) {
        if (year) state.year = year
        else if (state.availableYears.length === 0) state.year = null
        else if (state.year) {
            if (!state.availableYears.includes(state.year))
                state.year = state.availableYears[state.availableYears.length - 1]
        } else
           state.year = state.availableYears[state.availableYears.length - 1]

    },
    setDeletableId(state, id) {
        state.deletableId = id
    }
}


export const actions = consolidationService => ({
    setPicaServiceId({commit}, id) {
        commit('setPicaServiceId', id)
    },
    setYear({commit}, year) {
        commit('setYear', year)
    },
    async fetchConfiguration({commit, getters}) {
        const configuration = await consolidationService.fetchConfiguration(getters['picaServiceId'])

        commit('setPermissions', configuration)
        commit('setAvailableYears', configuration['years'])
        commit('setYear')
        commit('setLastConsolidation', configuration['last_consolidate'])
        commit('setCurrency', configuration['currency'])
        commit('setDeletableId', configuration['deletable_id'])
    },
    async downloadOrders({getters}, id) {
        await consolidationService.downloadOrders(getters['picaServiceId'], id)
    },
    async downloadRefunds({getters}, id) {
        await consolidationService.downloadRefunds(getters['picaServiceId'], id)
    },
    async downloadSummary({getters}, id) {
        await consolidationService.downloadSummary(getters['picaServiceId'], id)
    },
    async downloadMonthOrders({getters}, {month, year}) {
        await consolidationService.downloadMonthOrders(getters['picaServiceId'], month, year)
    },
    async downloadMonthRefunds({getters}, {month, year}) {
        await consolidationService.downloadMonthRefunds(getters['picaServiceId'], month, year)
    },
    async downloadMonthSummary({getters}, {month, year}) {
        await consolidationService.downloadMonthSummary(getters['picaServiceId'], month, year)
    },
    async fetchConsolidationsForYear({commit, getters}) {
        commit('setConsolidationsForYear', null)
        if (!getters['year']) return
        commit('setConsolidationsForYear', await consolidationService.fetchForYear(getters['picaServiceId'], getters['year']))
    },
    async consolidate({dispatch, getters}, {from, to}) {
        await consolidationService.consolidate(getters['picaServiceId'], from, to)
        await dispatch('fetchConfiguration')
        dispatch('fetchConsolidationsForYear')
    },
    async deleteConsolidation({dispatch, getters, commit}, id) {
        const result = await consolidationService.deleteConsolidation(getters['picaServiceId'], id)
        await dispatch('fetchConfiguration')
        dispatch('fetchConsolidationsForYear')
        commit('setDeletableId', result['deletable_id'])
    },
    async deleteLastConsolidation({dispatch, getters}) {
        await consolidationService.deleteLastConsolidation(getters['picaServiceId'])
        await dispatch('fetchConfiguration')
        dispatch('fetchConsolidationsForYear')
    },

})

export default consolidationService => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(consolidationService)
})

