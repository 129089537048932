import {translate} from 'vue-gettext'

const {gettext: $gettext} = translate

export default [
    {
        _name: 'CSidebarNavTitle',
        _children: [$gettext('Customer Success')],
        perms: [
            'admin.users.search',
        ]
    },
    {
        _name: 'CSidebarNavItem',
        name: $gettext('Search User'),
        to: {name: 'customer_success.search'},
        icon: 'cip-user',
        perms: ['admin.users.search']
    },
]