<template>
  <div>
  </div>
</template>

<script>
import eventBus from "@/domain/core/constant/eventBus";
import {mapGetters} from "vuex";

export default {
  name: "Chat",
  data() {
    return {
      contexts: [],
      widget_id: process.env.VUE_APP_FRESHWORKS_WIDGET_ID
    }
  },
  computed: {
    ...mapGetters('login', [
      'userFullName',
      'email'
    ]),
    enabled() {
      return this.contexts.length > 0
    }
  },
  created() {
    this.registerEvent(eventBus.chat.enable, this.onEnableChat)
    this.registerEvent(eventBus.chat.disable, this.onDisableChat)
  },
  beforeDestroy() {
    this.unregisterEvent(eventBus.chat.enable, this.onEnableChat)
    this.unregisterEvent(eventBus.chat.disable, this.onDisableChat)
  },
  methods: {
    onEnableChat(context) {
      if (!this.contexts.includes(context))
        this.contexts.push(context)

      !window.FreshworksWidget ? this.loadFreshworksWidget() : window.FreshworksWidget('show')
      this.prefillChat()
    },
    onDisableChat(context) {
      this.contexts = this.contexts.filter(c => c !== context)
      if (this.contexts.length > 0) return
      if (!window.FreshworksWidget) return
      window.FreshworksWidget('hide')
    },
    loadFreshworksWidget() {
      window.fwSettings = { 'widget_id': this.widget_id };
      (function() {
        if (typeof window.FreshworksWidget !== "function") {
          var n = function() { n.q.push(arguments) };
          n.q = [];
          window.FreshworksWidget = n;
        }
      })();
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://widget.freshworks.com/widgets/${this.widget_id}.js`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    },
    prefillChat() {
      window.FreshworksWidget('identify', 'ticketForm', {
        name: this.userFullName,
        email: this.email
      });
    }
  }
}
</script>