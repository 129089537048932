export const paymentAppearance = {
    appearance: {
        theme: 'stripe',
        variables: {
            fontFamily: 'Inter, sans-serif',
            fontSizeBase: '14px',
            borderRadius: '8px',
            colorBackground: '#ffffff',
            colorPrimary: '#1867FF',
            colorText: '#100F23',
            colorTextSecondary: '#6e6d6d',
            colorTextPlaceholder: '#8f8f9d',
            focusBoxShadow: '0 0 0 4px #E2F1FF',
        },
        rules: {
            '.Input': {
                borderColor: '#E1DFFB',
                fontWeight: '500',
                paddingTop: '12px',
                paddingBottom: '12px',
                paddingLeft: '16px',
                paddingRight: '16px',
            },
            '.Input:autofill': {}
        }
    }
}

export function getStripeArgs(clientSecret, elements) {
  return {
    clientSecret: clientSecret,
    elements: elements,
    redirect: 'if_required',
    confirmParams: {
      payment_method_data: {
        billing_details: {
          name: 'never'
        }
      }
    }
  }
}
