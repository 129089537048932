import {translate} from "vue-gettext";

const {gettext: $gettext} = translate;

export default {
    unit: 'unit',
    pack: 'pack',
    custom: 'custom',
    parent: 'parent',
    template: 'template',
    empty: 'empty',
    options(advanced = false, isSubGallery = false, template = false, empty = false) {
        let options = []
        if (empty) {
            options.push({
                id: this.empty,
                name: $gettext('Set price later')
            })
        }

        options.push({
            id: this.pack,
            name: $gettext('Pack Price'),
            img: 'pack'
        })
        options.push({
            id: this.unit,
            name: $gettext('Unit Price'),
            img: 'unit'
        })
        if (isSubGallery) {
            options.push({
                id: this.parent,
                name: $gettext('Parent'),
                img: 'custom'
            })
        }
        if (advanced) {
            options.push({
                id: this.custom,
                name: $gettext('Custom'),
                img: 'custom'
            })
        }
        if (template) {
            options.push({
                id: this.template,
                name: $gettext('Template'),
                img: 'template'
            })
        }
        return options
    },
    default(isSubGallery = false) {
        if (isSubGallery) return this.parent
        return this.pack
    }

}

export const customPriceListType = {
    unit: 'unit',
    range: 'range',
    options() {
        return [
            {
                id: this.unit,
                name: $gettext('Unit Price'),
                img: 'unit'
            },
            {
                id: this.range,
                name: $gettext('Range Price'),
                img: 'range'
            }
        ]
    },
    default() {
        return this.unit;
    }
}