export const INVITATION_STATUS = {
  PENDING: 'PENDING',   // invito appena inviato
  ACCEPTED: 'ACCEPTED', // invito accettato
  DISABLED: 'DISABLED', // invito accettato e poi disabilitato
  EXPIRED: 'EXPIRED',   // invito non accettato in tempo
  CANCELED: 'CANCELED', // invito annullato prima che venisse accettato
}

export const PHOTOGRAPHER_MANAGER = {
  EVENT_OWNER: 'event_owner',
  PHOTO_SERVICE: 'photo_service'
}

export const PHOTOGRAPHER_MANAGER_TYPE = [
  {
    label: 'Event Owner',
    value: PHOTOGRAPHER_MANAGER.EVENT_OWNER
  },
  {
    label: 'Photo Service',
    value: PHOTOGRAPHER_MANAGER.PHOTO_SERVICE
  },
]