<template>
  <div class="session-tooltip">
    <div>
      <div v-translate translate-context="globalUploader.sessions" class="font-weight-semi-bold mb-2">
        Deliverable photos
      </div>
      <p class="m-0">
        <translate translate-context="globalUploader.sessions" :translate-params="{num: pay_as_you_go_count}">
        You have uploaded %{num} more photos than the amount allowed by the selected plan: deliver them now!
        </translate>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadsTooltip",
  props: {
    pay_as_you_go_count: {
      type: Number,
      default: 0
    }
  }

}
</script>