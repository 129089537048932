<template>
  <div class="overflow-auto text-center d-flex" :class="[pills ? align : 'tabs--custom']">
    <ul class="nav flex-nowrap position-relative" :class="{'nav-pills chart-pills': pills}">
      <li class="nav-item" v-for="item in values" :key="item.name">
        <a @click.prevent="onclick(item.name)"
           class="nav-link"
           href="#"
           :class="{'active': selected===item.name}"
           v-show="!item.hidden">
          <div class="d-flex align-items-center">
            <CIcon v-if="item.icon" size="custom-size" :height="20" :name="item.icon" class="mr-1"/>
            <span v-html="item.label"></span>
          </div>
        </a>
      </li>
      <div ref="indicator" class="indicator" v-if="!pills"></div>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PTabs",
  props: {
    tabs: {
      type: [Object, Array],
      required: true
    },
    selected: {
      type: String,
      required: false
    },
    pills: {
      type: Boolean,
      default: false
    },
    alignRight: {
      type: Boolean,
      default: false
    },
    alignCenter: {
      type: Boolean,
      default: false
    },
    alignLeft: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    selected: {
      async handler() {
        await this.$nextTick()
        this.moveIndicator()
      },
      immediate: true
    }
  },
  emits: ['select'],
  computed: {
    align() {
      if (!this.alignCenter && !this.alignRight && !this.alignLeft)
        return 'justify-content-center'
      return {
        'justify-content-center': this.alignCenter,
        'justify-content-end': this.alignRight,
        'justify-content-start': this.alignLeft
      }
    },
    values() {
      if (Array.isArray(this.tabs))
        return this.tabs
      return Object.values(this.tabs)
    }
  },
  methods: {
    moveIndicator() {
      if (this.pills) return
      const el = this.$el.querySelector('.nav-link.active')
      if (el) {
        const indicator = this.$refs.indicator
        indicator.style.width = el.offsetWidth + 'px'
        indicator.style.left = el.offsetLeft + 'px'
        indicator.style.transition = 'left 0.3s, width 0.3s'
      }
    },
    onclick(name) {
      this.$emit('select', name)
    }
  }
}
</script>
