<template>
  <CDropdown
      inNav
      class="c-header-nav-items"
      placement="bottom-end"
      add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar" :style="avatarStyles">
          <div class="user-ideogram" :data-letters="userIdeogramName"></div>
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownItem class="title">
      <span v-translate>Administration</span>
    </CDropdownItem>
    <CDropdownItem @click="invoice" class="arrow" v-if="hasPerm('contract_manager.service_order.list')">
      <CIcon name="cip-order" size="custom-size"
             :height="20"/>
      <span v-translate>Invoices</span>
    </CDropdownItem>
    <CDropdownItem class="title">
      <span v-translate>Account</span>
    </CDropdownItem>
    <CDropdownItem @click="profile" class="arrow">
      <CIcon name="cip-user" size="custom-size"
             :height="20"/>
      <span v-translate>My profile</span>
    </CDropdownItem>
    <CDropdownItem class="title">
      <span v-translate>Disconnect</span>
    </CDropdownItem>
    <CDropdownItem @click="onLogout">
      <CIcon name="cip-exit" size="custom-size"
             :height="20"/>
      <translate>Logout</translate>
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ColorGenerator from "@/domain/core/utils/colorGenerator";

export default {
  name: 'TheHeaderDropdownAccount',
  data() {
    return {
      avatarStyles: {}
    }
  },
  mounted() {
    const generatedColors = new ColorGenerator(this.userIdeogramName)
    this.avatarStyles = {
      backgroundColor: generatedColors.colors.background,
      color: generatedColors.colors.text
    }
  },
  computed: {
    ...mapGetters('login', ['userIdeogramName', 'userCompactName'])
  },
  methods: {
    ...mapActions('login', ['logout']),
    async profile() {
      await this.$router.push({name: 'user'})
    },
    async invoice() {
      await this.$router.push({name: 'serviceOrders.list'})
    },
    async onLogout() {
      await this.logout()
      await this.$router.push({name: 'login'})
    }
  }
}
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>
