import {ApiException} from "@/domain/core/exception/exceptions";
import Vue from "vue";
import {emptyListResponse} from "@/domain/core/utils/django";


const state = {
    contract_managers: {...emptyListResponse},
    contract_manager: {},
    contract: {},
    permissions: {},
    availableRoles: null,
    roles: {...emptyListResponse},
    role: null,
}

export const getters = {
    contract_managers: state => state.contract_managers,
    contract_manager: state => state.contract_manager,
    contract: state => state.contract,
    permissions: state => state.permissions,
    roles: state => state.roles,
    role: state => state.role,
    availableRoles: state => state.availableRoles,
}


export const mutations = {
    contract_managers(state, contract_managers) {
        state.contract_managers = contract_managers
    },
    contract_manager(state, contract_manager) {
        state.contract_manager = contract_manager
    },
    contract(state, contract) {
        state.contract = contract
    },
    deleteContractManager(state, id) {
        Vue.set(state.contract_managers, 'results', state.contract_managers.results.filter(contract_manager => contract_manager.id !== id))
    },
    permissions(state, permissions) {
        state.permissions = permissions
    },
    contracts(state, contracts) {
        Vue.set(state.contract_manager, 'contracts', contracts)
    },
    addContract(state, contract) {
        state.contract_manager.contracts.push(contract)
    },
    deleteContract(state, id) {
        Vue.set(state.contract_manager, 'contracts', state.contract_manager.contracts.filter(contract => contract.id !== id))
    },
    invitations(state, invitations) {
        Vue.set(state.contract_manager, 'invitations', invitations)
    },
    addInvitation(state, invitation) {
        state.contract_manager.invitations.push(invitation)
    },
    deleteInvitation(state, id) {
        Vue.set(state.contract_manager, 'invitations', state.contract_manager.invitations.filter(invitation => invitation.id !== id))
    },
    availableRoles(state, roles) {
        state.availableRoles = roles
    },
    roles(state, roles) {
        state.roles = roles
    },
    role(state, role) {
        state.role = role
    },
    addRole(state, role) {
        state.roles.results.push(role)
    },
    updateRole(state, role) {
        state.roles.results = state.roles.results.map(r => r.id === role.id ? role : r)
    },
    deleteRole(state, id) {
        state.roles.results= state.roles.results.filter(role => role.id !== id)
    }
}


export const actions = service => ({
    async fetchContractManagerList({commit}, {filters, pagination, sorting}) {
        try {
            const contract_managers = await service.fetchContractManagerList(filters, pagination, sorting)
            commit('contract_managers', contract_managers)
        } catch (e) {
            if (e instanceof ApiException)
                commit('contract_managers', emptyListResponse)
            throw e
        }
    },
    async deleteContractManager({commit}, id) {
        await service.deleteContractManager(id)
        commit('deleteContractManager', id)
    },
    async fetchContractManager({commit}, id) {
        commit('contract_manager', null)
        const contract_manager = await service.fetchContractManager(id)
        commit('contract_manager', contract_manager)
    },
    async updateContractManager({commit}, {id, data}) {
        commit('contract_manager', await service.updateContractManager(id, data))
    },
    async createContractManager({commit}, data) {
        commit('contract_manager', await service.createContractManager(data))
    },
    async searchPlan({getters}, query) {
        return await service.searchPlan(getters['contract_manager'].id,query)
    },
    async searchEventOwner({getters}, query) {
        return await service.searchEventOwner(getters['contract_manager'].id,query)
    },
    async searchTemplate({getters}, query) {
        return await service.searchTemplate(getters['contract_manager'].id,query)
    },
    async searchVertical({getters}, query) {
        return await service.searchVertical(getters['contract_manager'].id,query)
    },
    async fetchContracts({commit, getters}) {
        commit('contracts', await service.fetchContracts(getters['contract_manager'].id))
    },
    async createContract({commit, getters}, data) {
        commit('addContract',await service.createContract(getters['contract_manager'].id, data))
    },
    async fetchContract({commit}, {id, contract_manager}) {
        commit('contract', null)
        const contract = await service.fetchContract(contract_manager, id)
        commit('contract', contract)
    },
    async deleteContract({commit, getters}, id) {
        await service.deleteContract(getters['contract_manager'].id, id)
        commit('deleteContract',id)
    },
    async updateContract({commit}, {id, contract_manager,  data}) {
        await service.updateContract(contract_manager, id, data)
        commit('contract',id)
    },
    async createEvent({getters}, {contractId, data}) {
        const picaService = await service.createEvent(getters['contract_manager'].id, contractId, data)
        return picaService.id
    },
    async fetchInvitations({commit, getters}) {
        commit('invitations', await service.fetchInvitations(getters['contract_manager'].id))
    },
    async deleteInvitation({commit, getters}, id) {
        await service.deleteInvitation(getters['contract_manager'].id, id)
        commit('deleteInvitation',id)
    },
    async resendInvitation({getters}, invitationId) {
        await service.resendInvitation(getters['contract_manager'].id, invitationId)
    },
    async sendInvitation({commit, getters}, {email, role, message, require_accept}) {
        commit('addInvitation',await service.sendInvitation(getters['contract_manager'].id, {email, role, message, require_accept}))
    },
    async fetchAvailableRoles({commit, getters}) {
        commit('availableRoles', await service.fetchAvailableRoles(getters['contract_manager'].id))
    },
    async fetchRoles({commit, getters}, {filters, pagination, sorting}) {
        commit('roles', await service.fetchRoles(getters['contract_manager'].id, filters, pagination, sorting))
    },
    async deleteRole({commit, getters}, id) {
        await service.deleteRole(getters['contract_manager'].id, id)
        commit('deleteRole',id)
    },
    async fetchRole({commit, getters}, id) {
        commit('role', await service.fetchRole(getters['contract_manager'].id, id))
    },
    async createRole({commit, getters}, data) {
        commit('addRole', await service.createRole(getters['contract_manager'].id, data))
    },
    async updateRole({commit, getters}, data) {
        commit('updateRole', await service.updateRole(getters['contract_manager'].id, getters['role'].id, data))
    },

})

export default contractManagerService => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(contractManagerService)
})

