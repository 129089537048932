import ApiClient from "@/domain/core/utils/apiClient";
import client_core from "@/services/clients/client_core";
import Downloader from "@/domain/core/utils/downloader";
import {ValidationError} from "@/domain/core/exception/exceptions";

const client = new ApiClient(client_core)

const endpoints = {
    propose_pica_code: '/dashboard/v2/event-wizard/{id}/welcome-code/propose_pica_code/',
    validate_welcome_code: '/dashboard/v2/event-wizard/{id}/welcome-code/validate_welcome_code/',
    update_step_data: '/dashboard/v2/event-wizard/{id}/step/update/{step}/',
    get_step_data: '/dashboard/v2/event-wizard/{id}/step/get/{step}/',
    configuration: '/dashboard/v2/event-wizard/{id}/wizard/configuration/',
    validate_publish: '/dashboard/v2/event-wizard/{id}/wizard/validate_publish/',
    publish: '/dashboard/v2/event-wizard/{id}/wizard/publish/',
    upload_intent: '/dashboard/v2/event-wizard/{id}/wizard/upload_intent/',
    export_form_results: '/dashboard/v2/event-wizard/{id}/wizard/export_form_results/',
    export_shared_data: '/dashboard/v2/event-wizard/{id}/wizard/export_shared_data/',
    upload_intent_public_url: '/dashboard/v2/utils/upload_intent/{id}/public_url',
    upload_form_s3_public_url: '/dashboard/v2/event-wizard/{id}/wizard/form_s3_public_path/?file={file}',
    search_release: '/dashboard/v2/event-wizard/{id}/release/',
    checkin_options_tags: '/dashboard/v2/event-wizard/{id}/wizard/checkin_options_tags/',
    logo_on_photo_tags: '/dashboard/v2/event-wizard/{id}/wizard/logo_on_photo_tags/',
    plan_options: '/dashboard/v2/event-wizard/{id}/wizard/plan_options/',
    payment_currency: '/dashboard/v2/event-wizard/{id}/wizard/payment_currency/',
    unlock_info: '/dashboard/v2/event-wizard/{id}/wizard/unlock_media_info/',
    search_contract_manager: '/dashboard/v2/event-wizard/{id}/search/contract_manager/',
    search_contract: '/dashboard/v2/event-wizard/{id}/search/contract_manager/{contract_manager}/contract/',
    search_event_owner: '/dashboard/v2/event-wizard/{id}/search/event_owner/',
    payment_on_demand: '/payment/orders/on-demand/',
    payment_pay_as_you_go: '/payment/orders/pay-as-you-go/',
    order_status: '/payment/orders/{id}/status/'
}

export default {
    async getData(eventId, steps) {
        let promises = steps.map(step => this.getStepData(eventId, step))
        return Promise.all(promises)
    }, async getStepData(eventId, step) {
        const response = await client.get(endpoints.get_step_data.replace('{id}', eventId).replace('{step}', step))
        return {
            key: step,
            data: response
        }
    },
    async updateData(eventId, step, data) {
        return await client.post(endpoints.update_step_data.replace('{id}', eventId).replace('{step}', step), data)
    }, async getConfiguration(eventId) {
        return await client.get(endpoints.configuration.replace('{id}', eventId))
    }, async getFreePicaCodesForName({name, date, eventId}) {
        return await client.get(endpoints.propose_pica_code.replace('{id}', eventId), {
            'name': name,
            'start_at': date.substr(0, 10),
            'qty': 10
        })
    }, async validateWelcomeCode({code, eventId}) {
        await client.get(endpoints.validate_welcome_code.replace('{id}', eventId), {
            'code': code,
        })
        return true
    }, async publish(eventId, basePlanId, force) {
        const params = {}
        if (basePlanId > 0) params.base_plan = basePlanId
        if (force) params.force = force
        await client.post(endpoints.publish.replace('{id}', eventId), params)
    }, async fetchPublishValidation(eventId) {
        try {
            await client.post(endpoints.validate_publish.replace('{id}', eventId))
            return []
        } catch (err) {
            if (err instanceof ValidationError) {
                return err.getAsDict()
            }
            else
                throw err
        }
    }, async getWizardUploadIntent({eventId, field, file}) {
        return await client.post(endpoints.upload_intent.replace('{id}', eventId), {
            field: field,
            file_name: file
        })
    }, async exportFormResults(eventId) {
        await Downloader.download(client, endpoints.export_form_results.replace('{id}', eventId), 'file.csv')
    }, async exportSharedData(eventId) {
        await Downloader.download(client, endpoints.export_shared_data.replace('{id}', eventId), 'file.csv')
    }, async getUploadIntentPublicUrl(uploadIntentId) {
        return await client.get(endpoints.upload_intent_public_url.replace('{id}', uploadIntentId))
    }, async getFormS3PublicUrl(eventId, s3file) {
        return await client.get(endpoints.upload_form_s3_public_url.replace('{id}', eventId).replace('{file}', s3file))
    }, async searchRelease(eventId, {id, query}) {
        const params = {}
        if (id) params.id = id
        if (query) params.search = query
        const result = await client.get(endpoints.search_release.replace('{id}', eventId), params)
        return result.results
    }, async fetchCheckinOptionsTags(eventId) {
        return await client.get(endpoints.checkin_options_tags.replace('{id}', eventId))
    }, async fetchLogoOnPhotoTags(eventId) {
        return await client.get(endpoints.logo_on_photo_tags.replace('{id}', eventId))
    }, async fetchPlanOptions(eventId) {
        return await client.get(endpoints.plan_options.replace('{id}', eventId))
    }, async fetchPaymentCurrency(eventId) {
        return await client.get(endpoints.payment_currency.replace('{id}', eventId))
    }, async fetchUnlockInfo(eventId) {
        return await client.get(endpoints.unlock_info.replace('{id}', eventId))
    }, async searchContractManager(eventId,{id, query}) {
        const params = {}
        if (id) params.id = id
        if (query) params.search = query
        const result = await client.get(endpoints.search_contract_manager.replace('{id}', eventId), params)
        return result.results
    },
    async searchContract(eventId, contractManagerId, {id, query}) {
        const params = {}
        if (id) params.id = id
        if (query) params.search = query
        const result = await client.get(endpoints.search_contract.replace('{id}', eventId).replace('{contract_manager}',contractManagerId), params)
        return result.results
    },
    async searchEventOwner(eventId, {id, query}) {
        const params = {}
        if (id) params.id = id
        if (query) params.search = query
        const result = await client.get(endpoints.search_event_owner.replace('{id}', eventId), params)
        return result.results
    },
    async paymentOnDemand(params) {
        return await client.post(endpoints.payment_on_demand, params)
    },
    async paymentPayAsYouGo(picaServiceId, qty = null, createInvoice = false) {
        let params = {
            pica_service_id: picaServiceId,
            create_invoice: createInvoice
        }
        if (qty !== null) params.unlock_qty = qty

        return await client.post(endpoints.payment_pay_as_you_go, params)
    },
    async setOrderStatus(id, status) {
        return await client.patch(endpoints.order_status.replace('{id}', id), status)
    },
    async getOrderStatus(id) {
        return await client.get(endpoints.order_status.replace('{id}', id))
    }
}
