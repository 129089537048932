const state = {
    ordersSummary: {},
    eventCurrency: '',
    ordersCountByDate: null,
    canViewStatsOrdersTransacted: false,

    boughtPhotos: null,
    ordersByCampaign: null,

    checkinsSummary: {},
    checkinsGroupBy: null,
    checkinsCountByDate: null,
    canViewStatsCheckins: false,
    checkinsTagsSummary: {},
    checkinsConversionRate: 0,

    photographicProductionSummary: {},
    canViewStatsPhotographicSummary: false,

    ordersAmountByPhotographer: null,
    canViewStatsPhotographicAmountProduction: false,
    uploadsCountByPhotographer: null,
    uploadsCountByUploadTask: null,

    releases: []
}

export const getters = {
    ordersSummary: state => state.ordersSummary,
    eventCurrency: state => state.eventCurrency || 'EUR',
    ordersCountByDate: state => state.ordersCountByDate,
    canViewStatsOrdersTransacted: state => state.canViewStatsOrdersTransacted,
    boughtPhotos: state => state.boughtPhotos,
    ordersByCampaign: state => state.ordersByCampaign,
    checkinsGroupBy: state => state.checkinsGroupBy,
    checkinsSummary: state => state.checkinsSummary,
    checkinsCountByDate: state => state.checkinsCountByDate,
    canViewStatsCheckins: state => state.canViewStatsCheckins,
    checkinsTagsSummary: state => state.checkinsTagsSummary,
    photographicProductionSummary: state => state.photographicProductionSummary,
    canViewStatsPhotographicSummary: state => state.canViewStatsPhotographicSummary,
    ordersAmountByPhotographer: state => state.ordersAmountByPhotographer,
    canViewStatsPhotographicAmountProduction: state => state.canViewStatsPhotographicAmountProduction,
    uploadsCountByPhotographer: state => state.uploadsCountByPhotographer,
    uploadsCountByUploadTask: state => state.uploadsCountByUploadTask,
    checkinsConversionRate: state =>  state.ordersSummary ? state.ordersSummary.conversion_rate * 100 : 0,
    releases: state => state.releases
}


export const mutations = {
    setOrdersSummary(state, ordersSummary) {
        state.ordersSummary = ordersSummary
        state.eventCurrency = ordersSummary.currency
    },
    setOrdersCountByDate(state, ordersCountByDate) {
        state.ordersCountByDate = ordersCountByDate
    },
    setCanViewStatsOrdersTransacted(state, canViewStatsOrdersTransacted) {
        state.canViewStatsOrdersTransacted = canViewStatsOrdersTransacted
    },
    setOrdersByCampaign(state, ordersByCampaign) {
        state.ordersByCampaign = ordersByCampaign
    },
    setBoughtPhotos(state, boughtPhotos) {
        state.boughtPhotos = boughtPhotos
    },
    setCheckinsCountByDate(state, checkinsCountByDate) {
        state.checkinsCountByDate = checkinsCountByDate
    },
    setCanViewStatsCheckins(state, canViewStatsCheckins) {
        state.canViewStatsCheckins = canViewStatsCheckins
    },
    setCheckinsSummary(state, checkinsSummary) {
        state.checkinsSummary = checkinsSummary
    },
    setCheckinsGroupBy(state, checkinsGroupBy) {
        state.checkinsGroupBy = checkinsGroupBy
    },
    setCheckinsTagsSummary(state, checkinsTagsSummary) {
        state.checkinsTagsSummary = checkinsTagsSummary
    },
    setPhotographicProductionSummary(state, photographicProductionSummary) {
        state.photographicProductionSummary = photographicProductionSummary
    },
    setCanViewStatsPhotographicSummary(state, canViewStatsPhotographicSummary) {
        state.canViewStatsPhotographicSummary = canViewStatsPhotographicSummary
    },
    setOrdersAmountByPhotographer(state, ordersAmountByPhotographer) {
        state.ordersAmountByPhotographer = ordersAmountByPhotographer
    },
    setCanViewStatsPhotographicAmountProduction(state, canViewStatsPhotographicAmountProduction) {
        state.canViewStatsPhotographicAmountProduction = canViewStatsPhotographicAmountProduction
    },
    setUploadsCountByPhotographer(state, uploadsCountByPhotographer) {
        state.uploadsCountByPhotographer = uploadsCountByPhotographer
    },
    setUploadsCountByUploadTask(state, uploadsCountByUploadTask) {
        state.uploadsCountByUploadTask = uploadsCountByUploadTask
    },
    setCheckinsConversionRate(state, checkinsConversionRate) {
        state.checkinsConversionRate = checkinsConversionRate
    },
    setReleases(state, releases) {
        state.releases = releases
    }
}

export const actions = (statsService) => ({
    async getOrdersSummary({commit}, {picaServiceId, params, bodyParams}) {
        const ordersSummary = await statsService.getOrdersSummary(picaServiceId, params, bodyParams)
        commit('setOrdersSummary', ordersSummary)
    },
    async getOrdersCountByDate({commit}, {picaServiceId, params, bodyParams}) {
        try {
            const ordersCountByDate = await statsService.getOrdersCountByDate(picaServiceId, params, bodyParams)
            commit('setOrdersCountByDate', ordersCountByDate)
            commit('setCanViewStatsOrdersTransacted', true)
        } catch (error) {
            commit('setCanViewStatsOrdersTransacted', error.response.status !== 403)
        }
    },
    async getBoughtPhotos({commit}, {picaServiceId, params, bodyParams}) {
        const boughtPhotos = await statsService.getBoughtPhotos(picaServiceId, params, bodyParams)
        commit('setBoughtPhotos', boughtPhotos)
    },
    async getOrdersCampaigns({commit}, {picaServiceId, params}) {
        const ordersByCampaign = await statsService.getOrdersCampaigns(picaServiceId, params)
        commit('setOrdersByCampaign', ordersByCampaign)
    },
    async getCheckinsSummary({commit}, {picaServiceId, params, bodyParams}) {
        const checkinsSummary = await statsService.getCheckinsSummary(picaServiceId, params, bodyParams)
        commit('setCheckinsSummary', checkinsSummary)
    },
    async getCheckinsCountByDate({commit}, {picaServiceId, params, bodyParams}) {
        try {
            const checkinsCountByDate = await statsService.getCheckinsCountByDate(picaServiceId, params, bodyParams)
            commit('setCheckinsCountByDate', checkinsCountByDate)
            commit('setCanViewStatsCheckins', true)
        } catch (error) {
            commit('setCanViewStatsCheckins', error.response.status !== 403)
        }
    },
    async getCheckinsGroupBy({commit}, {picaServiceId, params, bodyParams}) {
        const checkinsGroupBy = await statsService.getCheckinsGroupBy(picaServiceId, params, bodyParams)
        commit('setCheckinsGroupBy', checkinsGroupBy)
    },
    async getCheckinsTagsSummary({commit}, {picaServiceId, params}) {
        const checkinsTagsSummary = await statsService.getCheckinsTagsSummary(picaServiceId, params)
        commit('setCheckinsTagsSummary', checkinsTagsSummary)
    },
    async getPhotographicProductionSummary({commit}, {picaServiceId, params}) {
        try {
            const photographicProductionSummary = await statsService.getUploadsSummary(picaServiceId, params)
            commit('setPhotographicProductionSummary', photographicProductionSummary)
            commit('setCanViewStatsPhotographicSummary', true)
        } catch (error) {
            commit('setCanViewStatsPhotographicSummary', error.response.status !== 403)
        }
    },
    async getOrdersAmountByPhotographer({commit}, {picaServiceId, params, bodyParams}) {
        const ordersAmountByPhotographer = await statsService.getOrdersAmountByPhotographer(picaServiceId, params, bodyParams)
        commit('setOrdersAmountByPhotographer', ordersAmountByPhotographer)
        commit('setCanViewStatsPhotographicAmountProduction', true)
    },
    async getUploadsCountByPhotographer({commit}, {picaServiceId, params, bodyParams}) {
        const uploadsCountByPhotographer = await statsService.getUploadsCountByPhotographer(picaServiceId, params, bodyParams)
        commit('setUploadsCountByPhotographer', uploadsCountByPhotographer)
    },
    async getUploadsCountByUploadTask({commit}, {picaServiceId, params, bodyParams}) {
        const uploadsCountByUploadTask = await statsService.getUploadsCountByUploadTask(picaServiceId, params, bodyParams)
        commit('setUploadsCountByUploadTask', uploadsCountByUploadTask)
        return uploadsCountByUploadTask
    },
    async getReleases({commit}, {picaServiceId, params}) {
        const releases = await statsService.getReleases(picaServiceId, params)
        commit('setReleases', releases)
    },
    cleanupStats({commit}) {
        commit('setOrdersSummary', {})
        commit('setOrdersCountByDate', null)
        commit('setCanViewStatsOrdersTransacted', false)
        commit('setBoughtPhotos', null)
        commit('setOrdersByCampaign', null)
        commit('setCheckinsSummary', {})
        commit('setCheckinsCountByDate', null)
        commit('setCanViewStatsCheckins', false)
        commit('setCheckinsGroupBy', null)
        commit('setCheckinsTagsSummary', {})
        commit('setPhotographicProductionSummary', {})
        commit('setCanViewStatsPhotographicSummary', false)
        commit('setOrdersAmountByPhotographer', null)
        commit('setCanViewStatsPhotographicAmountProduction', false)
        commit('setUploadsCountByPhotographer', null)
        commit('setUploadsCountByUploadTask', null)
        commit('setCheckinsConversionRate', 0)
        commit('setReleases', [])
    }
})

export default statsService => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(statsService)
})
