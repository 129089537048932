export default [
    "24 24",
    '<mask id="path-1-inside-1_803_873" fill="white">\n' +
    '<rect x="2" y="2" width="9.09091" height="9.09091" class="stroke-over" rx="1.81818"/>\n' +
    '</mask>\n' +
    '<rect x="2" y="2" width="9.09091" height="9.09091" rx="1.81818" class="stroke-over" stroke="var(--ci-primary-color, currentColor)" fill="none" stroke-width="4" mask="url(#path-1-inside-1_803_873)"/>\n' +
    '<mask id="path-2-inside-2_803_873" fill="white">\n' +
    '<rect x="12.9092" y="2" width="9.09091" height="9.09091" rx="1.81818"/>\n' +
    '</mask>\n' +
    '<rect x="12.9092" y="2" width="9.09091" height="9.09091" rx="1.81818" class="stroke-over" stroke="var(--ci-primary-color, currentColor)" fill="none" stroke-width="4" mask="url(#path-2-inside-2_803_873)"/>\n' +
    '<mask id="path-3-inside-3_803_873" fill="white">\n' +
    '<rect x="12.9092" y="12.9092" width="9.09091" height="9.09091" rx="1.81818"/>\n' +
    '</mask>\n' +
    '<rect x="12.9092" y="12.9092" width="9.09091" height="9.09091" rx="1.81818" class="stroke-over" stroke="var(--ci-primary-color, currentColor)" fill="none" stroke-width="4" mask="url(#path-3-inside-3_803_873)"/>\n' +
    '<mask id="path-4-inside-4_803_873" fill="white">\n' +
    '<rect x="2" y="12.9092" width="9.09091" height="9.09091" rx="1.81818"/>\n' +
    '</mask>\n' +
    '<rect x="2" y="12.9092" width="9.09091" height="9.09091" rx="1.81818" class="stroke-over" stroke="var(--ci-primary-color, currentColor)" fill="none" stroke-width="4" mask="url(#path-4-inside-4_803_873)"/>']

