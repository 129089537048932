<template>
  <CModal :show.sync="show" centered add-content-classes="modal-420">
    <template #header-wrapper>
      <div class="d-none"></div>
    </template>
    <div class="d-flex flex-column justify-content-center align-items-center published-event-modal">
      <div class="emoji">&#127881;</div>
      <div class="text-center">
        <div class="title" v-translate translate-context="event.modal.paid.title">
          Purchase successful
        </div>
        <p class="text" v-if="unlockInfo">
          <translate translate-context="event.modal.paid.description" :translate-params="{num: unlockInfo.to_pay}">
            The %{num} photos will now be processed and delivered
          </translate>
        </p>
      </div>
      <PConfetti ref="confetti" :origin="{'y': 1}" />
    </div>
    <template #footer>
      <div class="d-flex justify-content-center w-100">
        <CButton block color="primary" @click="onConfirm" v-translate>Continue</CButton>
      </div>
    </template>
  </CModal>
</template>

<script>

import PConfetti from "@/domain/core/components/PConfetti.vue";
import {mapGetters} from "vuex";

export default {
  name: "PaidEventModal",
  components: {PConfetti},
  emits: ['confirm'],
  data() {
    return {
      show: false,
      loading: true,
    }
  },
computed: {
    ...mapGetters('eventWizard', [
      'unlockInfo'
    ]),
  },
  methods: {
    async open() {
      this.show = true
      this.loading = false
      await this.$nextTick()
      await this.delay(500)
      this.$refs.confetti.start()
    },
    onConfirm() {
      this.show = false
      this.$emit('confirm')
    }
  }
}
</script>