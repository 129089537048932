const Users = () => import('./Users.vue')
const TheContainer = () => import('@/containers/TheContainer')

export default [
    {
        path: '/impersonate',
        name: 'impersonate',
        component: TheContainer,
        meta: {perms: ['dashboard.impersonate'], label: 'Impersonate'},
        children: [
            {
                path: 'users',
                name: 'impersonate.users',
                component: Users,
                meta: {label: 'List users',},
            },
        ]
    }
]