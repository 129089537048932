<template>
  <div>
    <PFRow :label="$pgettext('event_wizard.gallery.modal.label','Name')" size="12x12">
      <CInput v-model="form.name" :placeholder="$pgettext('event_wizard.gallery.modal.placeholder','Gallery name')" @input="onChange"/>
    </PFRow>
  </div>
</template>

<script>

import PFRow from "@/domain/core/components/PFRow.vue";

export default {
  name: "GalleryPresentation",
  components: {PFRow},
  emits: ['save'],
  data() {
    return {
      form: {
        name: null,
      },
    }
  },
  methods: {
    onChange() {
      this.$emit('save', this.form);
    }
  }
}
</script>
