import {ApiException} from "@/domain/core/exception/exceptions";
import {emptyListResponse} from "@/domain/core/utils/django";


const state = {
    pica_service_id: null,
    invitations: {...emptyListResponse},
    roles: null,
    photographer_manager_info: {
        photographer_manager: null,
        invitation_status: null
    },
    contractManagerUsers: []
}

export const getters = {
    pica_service_id: state => state.pica_service_id,
    invitations: state => state.invitations,
    roles: state => state.roles,
    photographer_manager: state => state.photographer_manager_info.photographer_manager,
    is_owner_as_photographer_manager: state => state.photographer_manager_info.photographer_manager == null,
    photographer_manager_invitation: state => state.photographer_manager_info.invitation_status,
    contractManagerUsers: state => state.contractManagerUsers,
}

export const mutations = {
    setPicaServiceId(state, pica_service_id) {
        state.pica_service_id = pica_service_id
    },
    invitations(state, invitations) {
        state.invitations = invitations
    },
    deleteInvitation(state, id) {
        state.invitations.results = state.invitations.results.filter(user => user.id !== id)
    },
    photographerManagerInfo(state, photographer_manager_info) {
        state.photographer_manager_info = photographer_manager_info
    },
    roles(state, roles) {
        state.roles = roles
    },
    contractManagerUsers(state, contractManagerUsers) {
        state.contractManagerUsers = contractManagerUsers
    }
}

export const actions = service => ({
    setPicaServiceId({commit}, pica_service_id) {
        commit('setPicaServiceId', pica_service_id)
        commit('invitations', emptyListResponse)
        commit('roles', null)
    },

    async fetchInvitationList({commit, getters}, {filters, pagination, sorting}) {
        try {
            commit('invitations', await service.fetchInvitationList(getters.pica_service_id, filters, pagination, sorting))
        } catch (e) {
            if (e instanceof ApiException)
                commit('invitations', emptyListResponse)
            throw e
        }
    },
    async deleteInvitation({commit, getters}, invitation) {
        await service.deleteInvitation(getters.pica_service_id, invitation)
        commit('deleteInvitation', invitation)
    },
    async sendInvitation({getters}, params) {
        await service.sendInvitation(getters.pica_service_id, params)
    },
    async resendInvitation({getters}, invitationId) {
        await service.resendInvitation(getters.pica_service_id, invitationId)
    },
    async fetchRoles({commit, getters}) {
        commit('roles', await service.fetchRoles(getters.pica_service_id))
    },
    async fetchPhotographerManagerInfo({commit, getters}) {
        const info = await service.fetchPhotographerManagerInfo(getters.pica_service_id)
        commit('photographerManagerInfo', info)
    },
    async setOwnerAsPhotographerManager({commit, getters}) {
        const info = await service.setOwnerAsPhotographerManager(getters.pica_service_id)
        commit('photographerManagerInfo', info)
    },
    async sendPhotographerManagerInvitation({commit, getters}, email) {
        const info = await service.sendPhotographerManagerInvitation(getters.pica_service_id, email)
        commit('photographerManagerInfo', info)
    },
    async fetchUserIdByEmail({getters}, email) {
        return await service.fetchUserIdByEmail(getters.pica_service_id, email)
    },
    async fetchContractManagerUsers({commit, getters}) {
        commit('contractManagerUsers', await service.fetchContractManagerUsers(getters.pica_service_id))
    },
    async sendMultipleInvitations({getters}, {userIds, role}) {
        await service.sendMultipleInvitations(getters.pica_service_id, userIds, role)
    },
    async changePassword({getters}, {userId, password}) {
        await service.changePassword(getters.pica_service_id, userId, password)
    }
})

export default service => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(service)
})

