const ListPlan = () => import('./ListPlan.vue')
const EditPlan = () => import('./EditPlan.vue')
const NewPlan = () => import('./NewPlan.vue')
const TheContainer = () => import('@/containers/TheContainer')

export default [
    {
        path: '/payments/plan',
        name: 'payments.plan',
        component: TheContainer,
        meta: {perms: ['admin.plan.list']},
        children: [
            {
                path: 'list',
                name: 'payments.plan.list',
                component: ListPlan,
                meta: {label: 'List Plans',},
            },
            {
                path: 'new',
                name: 'payments.plan.new',
                component: NewPlan,
                meta: {label: 'Create new Plan',},
            },
            {
                path: ':id',
                name: 'payments.plan.edit',
                component: EditPlan,
                meta: {label: 'Edit Plan',},
            },
        ]
    }
]