const Checkins = () => import('./Checkins.vue')
const Checkin = () => import('./Checkin.vue')

export default [
    {
        path: 'checkin',
        redirect: 'checkin',
        name: 'checkin',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        meta: {
            label: 'Check-ins',
            title: 'Check-ins'
        },
        children: [
            {
                path: '',
                name: 'checkin.list',
                meta: {
                    title: 'Check-ins',
                    label: 'Check-ins',
                },
                component: Checkins
            },
            {
                path: ':id',
                name: 'checkin.detail',
                meta: {
                    title: 'Check-in detail',
                    label: 'Check-in detail',
                },
                component: Checkin
            }
        ]
    }
]