import ApiClient from "@/domain/core/utils/apiClient";
import client_core from "@/services/clients/client_core";
const client = new ApiClient(client_core)

const endpoints = {
    invitation_list: '/dashboard/v2/invitation/global/',   // GET
    delete: '/dashboard/v2/invitation/global/{id}/',    // DELETE
    resend: '/dashboard/v2/invitation/global/{id}/resend/',    // POST
    send_invitation: '/dashboard/v2/invitation/global/invite/',  // POST
    roles: '/dashboard/v2/invitation/global/roles/',  // GET
}

export default {
    async fetchInvitationList(filters, pagination, sorting) {
        let result = await client.get(endpoints.invitation_list, {
            ...filters,
            page_size: pagination.page_size ?? 20,
            page: pagination.page ?? 1,
            ordering: sorting.asc ? '' : '-' + sorting.column ?? '',
        })
        return {
            results: result.results,
            pagination: {
                num_pages: result.pagination.num_pages,
                page_size: result.pagination.page_size,
                page: result.pagination.page_number,
                total: result.pagination.total,
            }
        }
    },
    async deleteInvitation(id) {
        await client.delete(endpoints.delete.replace('{id}', id))
    },
    async resendInvitation(id) {
        await client.post(endpoints.resend.replace('{id}',id))
    },
    async sendInvitation({email, role, message, require_accept, password}) {
        return await client.post(endpoints.send_invitation, {email, role, message, require_accept, password})
    },
    async fetchRoles() {
        return await client.get(endpoints.roles)
    }
}
