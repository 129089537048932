<template>
  <div ref="confettiContainer" class="confetti-container"></div>
</template>

<script>
import confetti from "canvas-confetti";
export default {
  name: "PConfetti",
  props: {
    duration: {
      type: Number,
      default: 15000
    },
    particleCount: {
      type: Number,
      default: 100
    },
    spread: {
      type: Number,
      default: 70
    },
    gravity: {
      type: Number,
      default: 1
    },
    origin: {
      type: Object,
      default: () => ({x: 0, y: 0})
    },
    colors: {
      type: Array,
      default: () => ["#FF4176", "#66FFD4", "#FFD966", "#ECB93A", "#A666FF"]
    }
  },
  data() {
    return {
      confetti: null,
      canvas: null
    }
  },
  methods: {
    createConfettiCanvas() {
      const confettiContainer = this.$refs.confettiContainer;
      this.canvas = document.createElement('canvas')
      this.canvas.style.position = 'absolute'
      this.canvas.style.top = 0
      this.canvas.style.left = 0
      this.canvas.style.width = '100%'
      this.canvas.style.height = '100%'
      confettiContainer.appendChild(this.canvas)
      this.confetti = confetti.create(this.canvas, { resize: true, useWorker: true })
    },
    start() {
      if (!this.canvas) {
        this.createConfettiCanvas()
      }
      this.frame()
    },
    frame() {
      this.confetti({
        scalar: 1.2,
        ticks: 300,
        gravity: this.gravity,
        particleCount: this.particleCount,
        spread: this.spread,
        origin: this.origin,
        colors: this.colors,
      })
    },
    stop() {
      if (this.confetti) {
        this.confetti.reset()
      }
    }
  },
  beforeDestroy() {
    this.stop()
  }
}
</script>