import {UploadIntent, UploadScheduler} from "@/domain/uploader/scheduler";
import Vue from "vue";
import fileUtils from "@/domain/core/utils/fileUtils";
import MD5 from "crypto-js/md5";
import uploadSessionStatus from "@/domain/core/constant/uploadSessionStatus";
import {cloneDeep} from 'lodash';
import uploadErrors from "@/domain/core/constant/uploadErrors";

const uploadScheduler = new UploadScheduler()


const fileStruct = {
    pending: [],
    inProgress: [],
    uploaded: [],
    failed: [],
}
const sessionStruct = {
    id: null,
    intents_count: 0,
    uploaded_count: 0,
    uploaded_ack_count: 0,
    received_count: 0,
    processed_count: 0,
    total_size: 0,
    uploaded_size: 0,
    status: uploadSessionStatus.empty,
    confirmed: true,
    gallery: {
        id: null,
        name: null
    },
}


function moveFile(session, md5, from, to) {
    if (!session.files) return false

    const index = session.files[from].findIndex(f => f.md5 === md5)
    if (index === -1) return false

    session.files[to].push(session.files[from][index])
    session.files[from].splice(index, 1)
    updateSessionFromFiles(session)
    return true
}

function generateDerivedMetrics(session) {
    if (session.uploaded_count > session.intents_count)
        session.uploaded_count = session.intents_count
    if (session.received_count > session.uploaded_count)
        session.received_count = session.uploaded_count
    if (session.processed_count > session.received_count)
        session.processed_count = session.received_count

    session.in_upload_count = session.intents_count - session.uploaded_count
    session.thumbnail_generation_count = session.uploaded_count - session.received_count
    session.in_processing_count = session.received_count - session.processed_count

    return session
}

function updateSessionFromFiles(session) {
    if (!session.files) return session

    session.uploaded_count = session.files.uploaded.length

    if (session.files.inProgress.length > 0)
        session.status = uploadSessionStatus.inProgress
    else if (session.files.pending.length > 0)
        session.status = uploadSessionStatus.pending
    else if (session.files.uploaded.length > 0)
        session.status = uploadSessionStatus.uploaded
    else if (session.received > 0)
        session.status = uploadSessionStatus.received
    else if (session.processed > 0)
        session.status = uploadSessionStatus.processed
    else
        session.status = uploadSessionStatus.empty

    session.uploaded_size = session.files.uploaded.reduce((acc, file) => acc + (file.size || 0), 0)
    session.uploaded_size += session.files.inProgress.reduce((acc, file) => {
        const fileSize = file.size || 0
        const fileProgress = file.progress > 0 ? file.progress : 0
        return acc + (fileSize * fileProgress / 100)
    }, 0)
    return generateDerivedMetrics(session)
}

function updateSessionFromCounts(session) {
    if (session.intents_count === 0) {
        session.status = uploadSessionStatus.empty
        return session
    }

    session = generateDerivedMetrics(session)

    session.status = uploadSessionStatus.empty
    if (session.in_upload_count > 0)
        session.status = uploadSessionStatus.inProgress
    else if (session.thumbnail_generation_count > 0)
        session.status = uploadSessionStatus.uploaded
    else if (session.in_processing_count > 0)
        session.status = uploadSessionStatus.received
    else if (session.processed_count > 0)
        session.status = uploadSessionStatus.processed

    return session
}

const state = {
    sessions: [],
    tasks: [],
    picaServiceId: null,
    galleryId: null,
    planName: null,
    event: {
        intents_count: 0,
        uploaded_count: 0,
        received_count: 0,
        processed_count: 0,
        status: uploadSessionStatus.empty,

        in_upload_count: 0,
        thumbnail_generation_count: 0,
        in_processing_count: 0,
        upload_limit: 0,
        process_limit: 0,
        allowed_extensions: ['jpg', 'jpeg'],
    },
    gallery: {
        id: null,
        intents_count: 0,
        uploaded_count: 0,
        received_count: 0,
        processed_count: 0,
        status: uploadSessionStatus.empty,

        in_upload_count: 0,
        thumbnail_generation_count: 0,
        in_processing_count: 0,
        upload_limit: 0,
        allowed_extensions: ['jpg', 'jpeg'],
    },
}

const visibleStates = [
    uploadSessionStatus.pending,
    uploadSessionStatus.inProgress,
    uploadSessionStatus.empty,
]


export const getters = {
    picaServiceId: state => state.picaServiceId,
    destination: (state) => {
        return {
            picaServiceId: state.picaServiceId,
            galleryId: state.galleryId
        }
    },
    planName: state => state.planName,
    eventStats: state => {
        let pay_as_you_go_count = 0
        if (state.event.process_limit > 0 && state.event.intents_count > state.event.process_limit)
            pay_as_you_go_count = state.event.intents_count - state.event.process_limit
        return {
            ...state.event,
            pay_as_you_go_count: pay_as_you_go_count,
            pay_as_you_go_paid: state.event.upload_limit >= state.event.process_limit && state.event.intents_count >= state.event.process_limit
        }
    },
    galleryStats: state => state.gallery,
    sessions: (state) => {
        return state.sessions
            .filter(s => {
                if (s.files && !s.confirmed) return true
                return visibleStates.includes(s.status);
            })
            .sort((a, b) => a.id - b.id);
    },
    sessionSummary: (state, getters) => {
        let session = getters.sessions.reduce((acc, session) => {
            acc.intents_count += session.intents_count
            acc.uploaded_count += session.uploaded_count
            acc.received_count += session.received_count
            acc.processed_count += session.processed_count
            acc.uploaded_size += session.uploaded_size || 0
            acc.total_size += session.total_size || 0
            return acc
        }, cloneDeep(sessionStruct))
        return updateSessionFromCounts(session)
    },
    fileToUpload: (state) => {
        for (let session of state.sessions) {
            if (!session.files) continue
            if (session.files.pending.length > 0) return session.files.pending[0]
        }
        return null
    },
    allowedExtensions: state => {
        if (state.galleryId)
            return state.gallery.allowed_extensions
        return state.event.allowed_extensions
    },
    processingLimitReached: state => state.event.process_limit > 0 && state.event.processed_count >= state.event.process_limit,
    numMediaInThumbCreation: state => {
        const uploaded_without_ack = state.sessions.reduce((acc, session) => {
            if (state.galleryId && session.gallery.id !== state.galleryId) return acc
            if (!session.files) return acc
            return acc + session.uploaded_count - session.uploaded_ack_count
        }, 0)
        if (state.galleryId)
            return state.gallery.uploaded_count - state.gallery.received_count + uploaded_without_ack
        else
            return state.event.uploaded_count - state.event.received_count + uploaded_without_ack
    },
    globalNumMediaInThumbCreation: state => {
        const uploaded_without_ack = state.sessions.reduce((acc, session) => {
            if (!session.files) return acc
            return acc + session.uploaded_count - session.uploaded_ack_count
        }, 0)
        return state.event.uploaded_count - state.event.received_count + uploaded_without_ack
    },
    hasProcessingMedia: (state, getters) => {
        if (getters.numMediaInThumbCreation > 0) return true
        if (state.event.process_limit > 0 && state.event.processed_count >= state.event.process_limit) return false
        if (state.galleryId)
            return state.gallery.intents_count > state.gallery.processed_count
        return state.event.intents_count > state.event.processed_count
    },
    hasGlobalProcessingMedia: (state, getters) => {
        if (getters.globalNumMediaInThumbCreation > 0) return true
        if (state.event.process_limit > 0 && state.event.processed_count >= state.event.process_limit) return false
        return state.event.intents_count > state.event.processed_count
    },
    unprocessable: (state, getters) => {
        if (!getters.processingLimitReached) return 0
        return state.event.received_count - state.event.process_limit
    },
    remainingUpload: state => {
        let eventLimit = state.event.upload_limit <= 0 ? 9999 : state.event.upload_limit - state.event.intents_count
        if (eventLimit < 0) return 0
        if (!state.galleryId) return eventLimit

        let galleryLimit = state.gallery.upload_limit <= 0 ? 9999 : state.gallery.upload_limit - state.gallery.intents_count
        return Math.min(eventLimit, galleryLimit)
    }
}

export const mutations = {
    setDestination(state, {picaServiceId, galleryId}) {
        state.picaServiceId = picaServiceId
        state.galleryId = galleryId > 0 ? galleryId : null
    },
    planName(state, name) {
        state.planName = name
    },
    createNewSession(state, {task, intents, files, url}) {
        let session = {
            ...cloneDeep(sessionStruct),
            ...cloneDeep(task),
            files: cloneDeep(fileStruct),
        }
        session.files.pending = files.map(file => {
            if (!intents[file.md5]) return null
            file.intent = new UploadIntent(url, intents[file.md5])
            return file
        }).filter(file => file !== null)
        session.confirmed = false
        session.total_size = session.files.pending.reduce((acc, file) => acc + (file.size || 0), 0)
        state.sessions.push(session)
        uploadScheduler.start()
    },
    fileInUpload(state, file) {
        for (let session of state.sessions) {
            if (!moveFile(session, file.md5, 'pending', 'inProgress')) continue
            Vue.set(state.sessions, state.sessions.indexOf(session), session)
            break
        }
    },
    uploadCompleted(state, file) {
        for (let session of state.sessions) {
            if (!moveFile(session, file.md5, 'inProgress', 'uploaded')) continue
            state.sessions.uploaded_count += 1
            Vue.set(state.sessions, state.sessions.indexOf(session), session)
            break
        }
    },
    uploadFailed(state, file) {
        for (let session of state.sessions) {
            if (!moveFile(session, file.md5, 'inProgress', 'failed')) continue
            Vue.set(state.sessions, state.sessions.indexOf(session), session)
            break
        }
    },
    updateProgress(state, {md5, percent, speed}) {
        for (let session of state.sessions) {
            if (!session.files) continue
            const index = session.files.inProgress.findIndex(f => f.md5 === md5)
            if (index === -1) continue
            session.files.inProgress[index].progress = percent || 0
            session.files.inProgress[index].speed = speed || 0
            updateSessionFromFiles(session)
            Vue.set(state.sessions, state.sessions.indexOf(session), session)
            break
        }
    },
    deleteSession(state, sessionId) {
        state.sessions = state.sessions.filter(s => s.id !== sessionId)
    },
    addSession(state, session) {
        if (state.sessions.filter(s => s.id === session.id).length > 0) return
        state.sessions.push(session)
    },
    sessionStatsUpdate(state, {id, intents, uploaded, received, processed}) {
        for (let i = 0; i < state.sessions.length; i++) {
            let session = state.sessions[i];
            if (session.id !== id) continue

            if (session.files) {
                // In this case we have a more updated version of the intents/uploaded data
                // so uploaded_count will be derived from files, we keep the uploaded data
                // in a separate field to understand what the server has acknowledged
                // this is used to in the browse photo page to signal how many media are missing
                if (uploaded !== null) session.uploaded_ack_count = parseInt(uploaded)

            } else {
                if (intents !== null) session.intents_count = parseInt(intents)
                if (uploaded !== null) session.uploaded_count = parseInt(uploaded)
            }

            if (received !== null) session.received_count = parseInt(received)
            if (processed !== null) session.processed_count = parseInt(processed)

            session = updateSessionFromCounts(session)
            Vue.set(state.sessions, i, updateSessionFromCounts(session))
            return
        }
    },
    eventStatsUpdate(state, {intents = null, uploaded = null, received = null, processed = null, upload_limit = null, process_limit = null, allowed_extensions = null}) {
        if (intents != null) state.event.intents_count = parseInt(intents)
        if (uploaded != null) state.event.uploaded_count = parseInt(uploaded)
        if (received != null) state.event.received_count = parseInt(received)
        if (processed != null) state.event.processed_count = parseInt(processed)
        if (upload_limit != null) state.event.upload_limit = parseInt(upload_limit)
        if (process_limit != null) state.event.process_limit = parseInt(process_limit)
        if (allowed_extensions != null) state.event.allowed_extensions = allowed_extensions
        state.event = updateSessionFromCounts(state.event)
    },
    galleryStatsUpdate(state, {id, intents = null, uploaded = null, received = null, processed = null, upload_limit = null, allowed_extensions = null}) {
        if (intents != null) state.gallery.intents_count = parseInt(intents)
        if (uploaded != null) state.gallery.uploaded_count = parseInt(uploaded)
        if (received != null) state.gallery.received_count = parseInt(received)
        if (processed != null) state.gallery.processed_count = parseInt(processed)
        if (upload_limit != null) state.gallery.upload_limit = parseInt(upload_limit)
        if (allowed_extensions != null) state.gallery.allowed_extensions = allowed_extensions
        if (id) state.gallery.id = id
        state.gallery = updateSessionFromCounts(state.gallery)
    },
    confirmSession(state, sessionId) {
        const session = state.sessions.find(session => session.id === sessionId)
        if (!session) return
        session.confirmed = true
        Vue.set(state.sessions, state.sessions.indexOf(session), session)
    }
}


export const actions = service => ({
    async setDestination({commit}, {picaServiceId, galleryId}) {
        commit('setDestination', {picaServiceId, galleryId})
    },
    async validateFiles({getters}, files) {
        const validationRules = fileUtils.getValidationRules({
            allowed_extensions: getters['allowedExtensions'],
            max_image_size: 100 * 1024 * 1024, // 100MB
            max_video_size: 300 * 1024 * 1024, // 300MB
        })

        if (files instanceof FileList)
            files = Array.from(files)

        // Validate files for common error ( wrong file name, file size, calcolate md5 etc )
        files = await Promise.all(files.map(async (file) => {
            file.md5 = MD5(`${file.size}_${file.name.replace(' ', '')}`).toString()
            try {
                await fileUtils.validate(file, validationRules)
            } catch (error) {
                file.error = uploadErrors.invalidFile
                file.errorDetail = error instanceof Error ? error.message : error
                return file
            }
            return file
        }))


        // Check if the file already exist on the server
        const existing = await service.existingFiles(
            getters['destination'],
            files.filter(file => file.error === undefined)
        )
        existing.forEach(md5 => {
            let file = files.find(f => f.md5 === md5)
            if (file) file.error = uploadErrors.alreadyPresent
        })

        // We now have all the valid file that can be uploaded, it's time to check if we exceed the limit
        let validFiles = files.filter(file => file.error === undefined)
        if (validFiles.length > getters['remainingUpload'])
            validFiles.slice(getters['remainingUpload']).forEach(file => file.error = uploadErrors.uploadLimit)

        return files
    },
    async createSession({getters, commit}, files) {
        const {task, intents, url} = await service.createTask(getters['destination'], files)
        commit('createNewSession', {task: task, files: files, intents: intents, url: url})
        return files
    },
    async stopSession({commit, getters}, sessionId) {
        const session = await service.cancelTask(getters['destination'], sessionId)
        commit('deleteSession', sessionId)
        commit('addSession', session)
    },
    async deleteSession({commit, getters}, sessionId) {
        await service.cancelTask(getters['destination'], sessionId)
        commit('deleteSession', sessionId)
    },
    async confirmSession({commit}, sessionId) {
        commit('confirmSession', sessionId)
    },
    async fetchEventStats({getters, commit}) {
        const stats = await service.fetchEventStats(getters['destination']['picaServiceId'], 0)
        commit('eventStatsUpdate', {
            intents: stats.intents,
            uploaded: stats.uploaded,
            received: stats.received,
            processed: stats.processed,
            upload_limit: stats.upload_limit,
            process_limit: stats.process_limit,
            allowed_extensions: stats.allowed_extensions,
        })
        commit('planName', stats.plan_name)
    },
    async fetchGalleryStats({getters, commit}) {
        if (getters.galleryStats.id === getters.destination.galleryId) return
        commit('galleryStatsUpdate', {
            intents: 0,
            uploaded: 0,
            received: 0,
            processed: 0,
            upload_limit: 0,
            id: getters.destination.galleryId,
            allowed_extensions: [],
        })
        if (!getters.destination.galleryId) return

        const stats = await service.fetchEventStats(getters.destination.picaServiceId, getters.destination.galleryId)
        commit('galleryStatsUpdate', {
            intents: stats.intents,
            uploaded: stats.uploaded,
            received: stats.received,
            processed: stats.processed,
            upload_limit: stats.upload_limit,
            allowed_extensions: stats.allowed_extensions,
            id: getters.destination.galleryId
        })
    },
    async updateGalleryStats({commit}, {intents, uploaded, received, processed}) {
        commit('galleryStatsUpdate', {
            intents: intents,
            uploaded: uploaded,
            received: received,
            processed: processed,
        })
    },
    async sessionUpdate({commit}, {id, intents, uploaded, received, processed}) {
        commit('sessionStatsUpdate', {id, intents, uploaded, received, processed})
    },
    async eventUpdate({commit}, {intents, uploaded, received, processed}) {
        commit('eventStatsUpdate', {intents, uploaded, received, processed})
    }
})

export default service => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(service)
})

