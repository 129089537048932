import Vue from 'vue'
import Vuex from 'vuex'
import event from './modules/event'
import notifications from './modules/notifications'
import tours from '../domain/tours/store'

import eventWizard from '../domain/eventWizard/store'
import eventWizardService from '../domain/eventWizard/service'

import eventGallery from '../domain/eventGallery/store'
import eventGalleryService from '../domain/eventGallery/service'

import eventTemplate from '../domain/eventTemplate/store'
import eventTemplateService from '../domain/eventTemplate/service'

import eventMetric from '../domain/eventMetric/store'
import eventMetricService from '../domain/eventMetric/service'

import faq from '../domain/faq/store'
import faqService from '../domain/faq/service'

import tag from '../domain/tag/store'
import tagService from '../domain/tag/service'

import shortener from '../domain/shortener/store'
import shortenerService from '../domain/shortener/service'

import photoSearch from '../domain/photoSearch/store'
import photoSearchService from '../domain/photoSearch/service'

import impersonate from '../domain/impersonate/store'
import impersonateService from '../domain/impersonate/service'

import plan from '../domain/plans/store'
import planService from '../domain/plans/service'

import photoMap from '../domain/photoMap/store'
import photoMapService from '../domain/photoMap/service'

import logoSet from '../domain/logoSet/store'
import logoSetService from '../domain/logoSet/service'

import invitations from '../domain/invitations/store'
import invitationsService from '../domain/invitations/service'

import eventInvitations from '../domain/eventInvitations/store'
import eventInvitationsService from '../domain/eventInvitations/service'

import login from '../domain/login/store'
import loginService from '../domain/login/service'

import ordersConsolidation from '../domain/ordersConsolidation/store'
import ordersConsolidationService from '../domain/ordersConsolidation/service'

import refund from '../domain/refund/store'
import refundService from '../domain/refund/service'

import invoice from '../domain/invoice/store'
import invoiceService from '../domain/invoice/service'

import checkin from '../domain/checkin/store'
import checkinService from '../domain/checkin/service'

import events from '../domain/events/store'
import eventsService from '../domain/events/service'

import roles from '../domain/roles/store'
import rolesService from '../domain/roles/service'

import contractManager from '../domain/contract_manager/store'
import contractManagerService from '../domain/contract_manager/service'

import verticalConfiguration from '../domain/verticalConfiguration/store'
import verticalConfigurationService from '../domain/verticalConfiguration/service'

import picaCode from '../domain/picaCode/store'
import picaCodeService from '../domain/picaCode/service'

import region from '../domain/region/store'
import regionService from '../domain/region/service'

import core from '../domain/core/store'
import coreService from '../domain/core/service'

import picaServiceService from '../services/pica_service.service'

import nStats from '../domain/stats/store'
import nStatsService from '../domain/stats/service'

import publicStore from '../domain/public/store'
import publicService from '../domain/public/service'

import priceListTemplate from '../domain/priceListTemplate/store'
import priceListTemplateService from '../domain/priceListTemplate/service'

import stats from '../views_pica/stats/store'
import statsService from '../views_pica/stats/service'

import orders from '../domain/orders/store'
import ordersService from '../domain/orders/service'

import uploader from '../domain/uploader/store'
import uploaderService from '../domain/uploader/service'

import serviceOrders from '../domain/serviceOrders/store'
import serviceOrdersService from '../domain/serviceOrders/service'

import customerSuccess from '../domain/customerSuccess/store'
import customerSuccessService from '../domain/customerSuccess/service'


import eventBus from '../domain/eventBus/store'

Vue.use(Vuex)

const state = {
    sidebarShow: 'responsive',
    sidebarMinimize: false
}

const mutations = {
    toggleSidebarDesktop(state) {
        const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarOpened ? false : 'responsive'
    },
    toggleSidebarMobile(state) {
        const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarClosed ? true : 'responsive'
    },
    set(state, [variable, value]) {
        state[variable] = value
    }
}

export default new Vuex.Store({
    state,
    mutations,

    modules: {
        event,
        notifications,
        tours,
        eventBus,
        eventWizard: eventWizard(eventWizardService, picaServiceService),
        eventGallery: eventGallery(eventGalleryService),
        eventTemplate: eventTemplate(eventTemplateService),
        eventMetric: eventMetric(eventMetricService),
        core: core(tagService, coreService),
        faq: faq(faqService),
        ordersConsolidation: ordersConsolidation(ordersConsolidationService),
        tag: tag(tagService),
        shortener: shortener(shortenerService),
        photoSearch: photoSearch(photoSearchService),
        photoMap: photoMap(photoMapService),
        impersonate: impersonate(impersonateService),
        plan: plan(planService),
        logoSet: logoSet(logoSetService),
        invitations: invitations(invitationsService),
        eventInvitations: eventInvitations(eventInvitationsService),
        login: login(loginService),
        refund: refund(refundService),
        checkin: checkin(checkinService),
        events: events(eventsService),
        orders: orders(ordersService),
        roles: roles(rolesService),
        contractManager: contractManager(contractManagerService),
        stats: stats(statsService),
        invoice: invoice(invoiceService),
        verticalConfiguration: verticalConfiguration(verticalConfigurationService),
        picaCode: picaCode(picaCodeService),
        region: region(regionService),
        public: publicStore(publicService),
        priceListTemplate: priceListTemplate(priceListTemplateService),
        nStats: nStats(nStatsService),
        uploader: uploader(uploaderService),
        serviceOrders: serviceOrders(serviceOrdersService),
        customerSuccess: customerSuccess(customerSuccessService),
    }
})
