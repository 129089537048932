<template>
  <CDataTable
      :hover="false"
      :bordered="true"
      :items="rows"
      :fields="fields"
      striped
      class="custom-table custom-table--clean"
  >
    <template #no-items-view>
      <div class="text-center">
        <h5 v-translate>No contracts</h5>
      </div>
    </template>
    <template #qty="{item}">
      <td>
        {{item.used}}/{{item.qty}}
      </td>
    </template>
    <template #actions="{item}">
      <td class="text-right">
        <CButton @click="onEdit(item.id)" v-if="hasPerm('contract_manager.contract.update')" variant="outline" color="primary"
                 v-translate translate-context="list.actions"
        >
          Edit
        </CButton>
        <CButton @click="onDelete(item.id)" v-if="item.used <= 0 && hasPerm('contract_manager.contract.delete')" variant="outline" color="danger"
                 v-translate translate-context="list.actions"
        >
          Delete
        </CButton>
        <CButton @click="onCreateEvent(item)" v-if="hasPerm('contract_manager.contract.create_event')" variant="outline" color="success"
                 v-translate translate-context="list.actions"
        >
          Create Event
        </CButton>
      </td>
    </template>
  </CDataTable>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Contracts",
  props: {
    rows: {
      type: Array,
      required: true
    },
    contract_manager: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      fields: [
        {key: 'id', label: this.$pgettext('contract_managers.list', 'ID'), _style: 'width:100px;'},
        {key: 'name', label: this.$pgettext('contract_managers.list', 'Name')},
        {key: 'qty', label: this.$pgettext('contract_managers.list', 'Qty')},
        {key: 'event_owner', label: this.$pgettext('contract_managers.list', 'Event Owner')},
        {key: 'template', label: this.$pgettext('contract_managers.list', 'Template')},
        {key: 'vertical', label: this.$pgettext('contract_managers.list', 'Vertical')},
        {key: 'is_subscription', label: this.$pgettext('contract_managers.list', 'Subscription')},
        {key: 'actions', label: this.$pgettext('contract_managers.list', 'Actions'), _style: 'text-align: center;'}
      ],
    }
  },
  emits: ['createEvent'],
  methods: {
    ...mapActions('contractManager', [
      'deleteContract'
    ]),
    async onDelete(id) {
      await this.deleteContract(id)
    },
    async onCreateEvent(contract) {
      this.$emit('createEvent', contract)
    },
    async onEdit(id) {
      await this.$router.push({name: 'contract.edit', params: {id: id, contract_manager: this.contract_manager.id}})
    }
  }
}
</script>