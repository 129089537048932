<template>
  <TheSidebar>
    <template v-slot:header-nav>
      <div class="sidebar__event-header">
        <div class="event-info">
          <router-link :to="{name: 'eventList'}" class="back">
            <CIcon name="cipChevronLeft" size="custom-size" style="margin-left: -5px"
                   :height="22"/>
            <span v-translate>Change event</span>
          </router-link>
          <h4>
            {{ currentEventName }}
            <CIcon name="cipGlobe" class="text-primary mb-1" style="" width="20" v-if="thirdLevelDomain"/>
          </h4>
          <div class="ml-0 d-flex align-items-center" v-if="currentEventStartDate">
            <CIcon name="cipCalendar" size="custom-size" :height="18" class="mr-2"/>
            <span class="font-weight-normal">{{ currentEventStartDate|formatDate }}</span>
          </div>
        </div>
        <UploadStatus
            @upload="routeToGallery"
            @seePhotos="routeToGallery"
            @deliver="onDeliver"
        />
      </div>
    </template>

    <CSidebarNav>
      <OverviewNav/>
      <SetupNav/>
      <GalleryNav @createGallery="$emit('createGallery')"/>
      <InvitationsNav/>
      <MediaNav/>
      <InsightNav/>
    </CSidebarNav>
    <CSidebarFooter v-if="hasPerm('event_wizard.publish')">
      <transition name="slide-up">
        <template v-if="showPublishButton">
          <div class="draft">
            <div class="draft__popover"
                 v-c-popover="popoverActivateEvent"
                 v-if="showActivateEvent"></div>
            <div class="d-flex align-items-center justify-content-between mb-2">
              <CIcon name="cipSignal" :width="24" class="mr-2 text-primary"/>
              <CBadge color="light" class="badge-outline px-2">
                <CIcon name="cipDot" :width="8" class="mr-2 text-warning"/>
                <translate>Draft</translate>
              </CBadge>
            </div>
            <div class="mb-3">
              <h5 class="m-0 font-weight-semi-bold" v-translate>Activate event</h5>
              <div v-translate>Go live and start selling</div>
            </div>
            <CButton block color="primary" @click="onPublish" v-translate>
              <template v-if="allMandatoryIsPresent">Activate</template>
              <template v-else>Force</template>
            </CButton>
          </div>
        </template>
      </transition>
      <template v-if="!isDraft">
        <div class="published">
          <div class="d-flex justify-content-between mb-2">
            <div>
              <h6 class="mb-0 font-weight-semi-bold" v-translate>Event active</h6>
              <span v-translate>From</span> {{ currentEventStartDate|formatDate('L') }}
            </div>
            <div class="d-flex align-items-center">
              <CBadge color="light" class="badge-outline px-2">
                <CIcon name="cipDot" :width="8" class="mr-2 text-success"/>
                <translate>Active</translate>
              </CBadge>

              <template v-if="hasPerm('feature.popup_upload_completed')">
                <CButton @click="routeToOverview"
                         @mouseenter="showShareEvent = true"
                         class="btn-circle btn-circle--sm ml-2 position-relative"
                         color="primary">
                  <CIcon name="cipUpload2"/>
                  <div class="position-absolute" style="top: -4px" v-c-popover="popoverShareEvent"
                       v-c-clickaway="closePopoverShare" v-if="showShareEvent"></div>
                </CButton>
              </template>
            </div>
          </div>
        </div>
      </template>
    </CSidebarFooter>
  </TheSidebar>
</template>
<script>


import {mapActions, mapGetters} from "vuex";
import TheSidebar from "@/containers/TheSidebar.vue";
import SetupNav from "@/domain/eventManage/nav/SetupNav.vue";
import OverviewNav from "@/domain/eventManage/nav/OverviewNav.vue";
import InsightNav from "@/domain/eventManage/nav/InsightNav.vue";
import MediaNav from "@/domain/eventManage/nav/MediaNav.vue";
import InvitationsNav from "@/domain/eventManage/nav/InvitationsNav.vue";
import GalleryNav from "@/domain/eventManage/nav/GalleryNav.vue";
import UploadStatus from "@/domain/uploader/components/UploadStatus.vue";
import {tooltipEventWizard} from "@/domain/eventWizard/components/overview/tooltip";

export default {
  name: 'EventManagerSidebar',
  emits: ['createGallery'],
  components: {
    UploadStatus,
    GalleryNav, InvitationsNav, MediaNav, InsightNav, OverviewNav, SetupNav, TheSidebar
  },
  data() {
    return {
      showActivateEvent: false,
      showShareEvent: false,
      barReady: false,
    }
  },
  watch: {
    allMandatoryIsPresent: {
      async handler(newVal) {
        if (!newVal) return
        await this.delay(1000)
        this.showActivateEvent = true
        await this.$nextTick()
        const elPopover = this.$el.querySelector('.j-activate-event')
        if (elPopover) elPopover.addEventListener('click', this.closePopoverActivate)
      },
      immediate: true
    }
  },
  beforeDestroy() {
    document.querySelector('.j-activate-event')?.removeEventListener('click', this.closePopoverActivate)
  },
  computed: {
    ...mapGetters('event', [
      'picaServiceId',
      'currentEventStartDate',
      'currentEventName',
      'thirdLevelDomain',
      'isDraft',
    ]),
    ...mapGetters('eventWizard', [
      'allMandatoryIsPresent',
    ]),
    ...mapGetters('eventGallery', [
      'galleries',
    ]),
    showPublishButton() {
      if (!this.barReady || !this.isDraft) return false
      if (this.allMandatoryIsPresent) return true
      return this.hasPerm('advanced.force_publish')
    },

    popoverActivateEvent() {
      return {
        header: this.$pgettext('event.tooltip', 'Activate event') + '<span class="p-closer j-activate-event">&times;</span>',
        content: this.$pgettext('event.tooltip', 'Confirm the chosen package and after activating the event you will be able to upload all the photos. In the overview section you will find the direct link and the QR code to communicate to participants to access their photo album.'),
        appendToBody: false,
        active: true,
        placement:'top-start',
      }
    },
    popoverShareEvent() {
      return {
        header: this.$pgettext('event.tooltip', 'Share the event') + '<span class="p-closer">&times;</span>',
        content: this.$pgettext('event.tooltip', 'View the access tools and share them with attendees to invite them to access their album'),
        appendToBody: true,
        active: true,
        placement: 'top-start',
      }
    },

  },
  async mounted() {
    await this.$nextTick()
    await this.fetchPublishValidation()
    this.barReady = true
  },
  methods: {
    ...mapActions('eventWizard', [
      'fetchPublishValidation',
    ]),
    ...mapActions('event', [
      'fetchPicaService'
    ]),
    closePopoverActivate() {
      this.showActivateEvent = false
    },
    closePopoverShare() {
      this.showShareEvent = false
    },
    onPublish() {
      const parent = this.getParentWithMethod('openPublishEvent')
      if (parent) parent.openPublishEvent()
    },
    onDeliver() {
      const parent = this.getParentWithMethod('openPayAsYouGo')
      if (parent) parent.openPayAsYouGo()
    },
    routeToGallery() {
      if (this.$route.name === 'eventWizard.gallery.browse_photo') return
      const galleryId = this.galleries[0].id
      this.$router.push({
        name: `eventWizard.gallery.browse_photo`,
        params: {picaServiceId: this.picaServiceId, galleryId: galleryId}
      })
    },
    async routeToOverview() {
      if (this.$route.name === 'eventWizard.overview') return
      await this.$router.push({name: 'eventWizard.overview', params: {picaServiceId: this.picaServiceId}})
    }
  }
}
</script>