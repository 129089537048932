import TheContainer from "@/containers/TheContainer.vue";

const EventWizard = () => import('@/domain/eventWizard/EventWizard')

export default [
    {
        path: 'settings',
        name: 'eventWizard.settings',
        redirect: 'settings/template',
        meta: {label: 'Settings'},
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: 'template',
                name: 'eventWizard.settings.template',
                component: EventWizard,
                meta: {label: 'Template'},
                props: {step: 'template'},
            },
            {
                path: 'plan_settings',
                name: 'eventWizard.settings.plan_settings',
                component: EventWizard,
                meta: {label: 'Plan'},
                props: {step: 'plan_settings'},
            },
            {
                path: 'vertical',
                name: 'eventWizard.settings.vertical',
                component: EventWizard,
                meta: {label: 'Vertical'},
                props: {step: 'vertical'},
            },
            {
                path: 'event_settings',
                name: 'eventWizard.settings.event_settings',
                component: EventWizard,
                meta: {label: 'Settings'},
                props: {step: 'event_settings'},
            },
        ]
    },
    {
        path: 'date_and_name',
        name: 'eventWizard.date_and_name',
        component: EventWizard,
        meta: {label: 'Date and Name'},
        props: {step: 'date_and_name'}
    },
    {
        path: 'customize_logo_on_photo',
        name: 'eventWizard.customize_logo_on_photo',
        component: EventWizard,
        meta: {label: 'Logo on Photo',},
        props: {step: 'customize_logo_on_photo'}
    },
    {
        path: 'banner_advertisement',
        name: 'eventWizard.banner_advertisement',
        component: EventWizard,
        meta: {label: 'Banner ADV',},
        props: {step: 'banner_advertisement'}
    },
    {
        path: 'form',
        name: 'eventWizard.form',
        component: EventWizard,
        meta: {label: 'Form data collection',},
        props: {step: 'form'}
    },
    {
        path: 'checkin_options',
        name: 'eventWizard.checkin_options',
        component: EventWizard,
        meta: {label: 'Checkin Options',},
        props: {step: 'checkin_options'}
    },
    {
        path: 'customization',
        name: 'eventWizard.customization',
        component: EventWizard,
        meta: {label: 'Customization',},
        props: {step: 'customization'}
    },
    {
        path: 'email_scheduler',
        name: 'eventWizard.email_scheduler',
        component: EventWizard,
        meta: {label: 'E-Mail Scheduler',},
        props: {step: 'email_scheduler'}
    },
    {
        path: 'popup',
        name: 'eventWizard.popup',
        component: EventWizard,
        meta: {label: 'PopUp',},
        props: {step: 'popup'}
    },
    {
        path: 'folder_mapping',
        name: 'eventWizard.folder_mapping',
        component: EventWizard,
        meta: {label: 'Folder Mapping',},
        props: {step: 'folder_mapping'}
    },
    {
        path: 'pica_codes',
        name: 'eventWizard.pica_codes',
        component: EventWizard,
        meta: {label: 'Pica Codes',},
        props: {step: 'pica_codes'}
    },
    {
        path: 'overview',
        name: 'eventWizard.overview',
        component: EventWizard,
        meta: {label: 'Overview',},
        props: {step: 'overview'}
    },
]




