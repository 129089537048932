const ListShortUrl = () => import('./ListShortUrl.vue')
const NewShortUrl = () => import('./NewShortUrl.vue')
const EditShortUrl = () => import('./EditShortUrl.vue')
const TheContainer = () => import('@/containers/TheContainer')

export default [
    {
        path: '/shortener',
        name: 'shortener',
        component: TheContainer,
        meta: {perms: ['dashboard.view_feature_shortener']},
        children: [
            {
                path: 'list',
                name: 'shortener.list',
                component: ListShortUrl,
                meta: {label: 'List Short Url',},
            },
            {
                path: 'new',
                name: 'shortener.new',
                component: NewShortUrl,
                meta: {label: 'Create new Short Url',},
            },
            {
                path: ':id',
                name: 'shortener.edit',
                component: EditShortUrl,
                meta: {label: 'Edit Short Url',},
            },
        ]
    }
]