export const period = {
    DAY: {
        suffix: 'day',
        format: 'L'
    },
    MONTH: {
        suffix: 'month',
        format: 'MMM-YYYY'
    },
    YEAR: {
        suffix: 'year',
        format: 'YYYY'
    }
}
export const METRIC_TYPES = {
    // Raw metrics
    MEDIA_DOWNLOADED: 'media_downloaded',
    MEDIA_DOWNLOADED_ZIP: 'media_downloaded_zip',
    TIME_IN_ALBUM: 'time_in_album',
    MEDIA_VIEWED: 'media_viewed',
    MEDIA_OPENED: 'media_opened',
    BANNER_CLICKED: 'banner_clicked',
    BANNER_VIEWED: 'banner_viewed',
    ANONYMIZED_VIEWED: 'anonymized_viewed',
    LEAD_ACQUIRED: 'lead_acquired',
    SHARED_LINK_OPENED: 'shared_link_opened',
    SHARED_LINK_CREATED: 'shared_link_created',

    // Derived metrics
    MEDIA_DOWNLOADED_VIDEO: 'media_downloaded_video',
    MEDIA_DOWNLOADED_PHOTO: 'media_download_photo',
    MEDIA_OPENED_VIDEO: 'media_opened_video',
    MEDIA_OPENED_PHOTO: 'media_opened_photo',
    MEDIA_VIEWED_VIDEO: 'media_viewed_video',
    MEDIA_VIEWED_PHOTO: 'media_viewed_photo',
    ALBUM_SESSION_NUMBER: 'album_session_number',

    UNIQUE_MEDIA_DOWNLOADED: 'unique_media_downloaded',

    UNIQUE_SHARE_INSTAGRAM: 'unique_share_instagram',
    UNIQUE_SHARE_FACEBOOK: 'unique_share_facebook',
    UNIQUE_SHARE_WHATSAPP: 'unique_share_whatsapp',
    UNIQUE_SHARE_TWITTER: 'unique_share_twitter',
    UNIQUE_SHARE_TELEGRAM: 'unique_share_telegram',
    UNIQUE_SHARE_LINKEDIN: 'unique_share_linkedin',
    UNIQUE_SHARE_EMAIL: 'unique_share_email',

}

export const SHARE_TYPES = {
    INSTAGRAM: 'instagram',
    FACEBOOK: 'facebook',
    WHATSAPP: 'whatsapp',
    TWITTER: 'twitter',
    TELEGRAM: 'telegram',
    EMAIL: 'email',
    LINKEDIN: 'linkedin',
}
