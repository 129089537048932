<template>
  <CModal :show.sync="show" centered>
    <template #header>
      <div>
        <div class="d-flex">
          <CIcon name="cipSignal" size="custom-size" width="36" height="36"
                 class="mr-3 icon-circle icon-circle--primary"/>
          <div class="align-self-center">
            <h5 class="modal-title" v-translate translate-context="event.modal.create_new_event.title">Pay now</h5>
            <div v-translate translate-context="event.modal.create_new_event.description">Proceed to payment
            </div>
          </div>
        </div>
      </div>
      <CButtonClose @click="show = false"/>
    </template>
    <PComponentLoader :loading="loading">
      <PAlert icon="cipAlertTriangle" :text="orderError" class="mb-3" color="danger" v-if="orderError"/>
      <StripeElements
          v-if="order"
          ref="stripe"
          @paid="onPaid"
          :client_secret="order.client_secret"
          :customer_secret="order.customer_session_client_secret"
          :price="planPrice"
          :currency="paymentCurrency">
        <div>
          <div class="mb-4 font-weight-semi-bold">
            <translate>Summary</translate>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <div class="font-weight-semi-bold">
              <translate>Total</translate>
              ({{ paymentCurrency }})
            </div>
            <div class="font-weight-bold" style="font-size: 18px">{{ planPrice|currency(paymentCurrency) }}</div>
          </div>
          <hr>
        </div>
      </StripeElements>
      <div>
        <div id="payment-element" class="mb-3"></div>
        <div id="address-element"></div>
      </div>
    </PComponentLoader>
    <template #footer>
      <CButton color="secondary" @click="show = false" v-translate>Close</CButton>
      <PButton
          color="primary" v-translate translate-context="event.modal.publish.actions" class="mr-3"
          :disabled="!planOptionId || orderError!==null"
          @click="onPay">
        <translate>Continue</translate>
      </PButton>
    </template>
  </CModal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PButton from "@/components/PButton.vue";
import PComponentLoader from "@/domain/core/components/PComponentLoader.vue";
import StripeElements from "@/domain/events/components/StripeElements.vue";
import PAlert from "@/components/PAlert.vue";

export default {
  name: "PayOnDemandModal",
  emits: ['published', 'edit'],

  components: {
    PAlert,
    StripeElements,
    PComponentLoader,
    PButton,
  },
  data() {
    return {
      show: false,
      loading: true,
      planOptionId: null,
      createInvoice: false,
      orderError: null,
    }
  },
  computed: {
    ...mapGetters('eventWizard', [
      'planOptions',
      'paymentCurrency',
      'order'
    ]),
    planPrice() {
      if (!this.planOptionId) return 0
      return this.planOptions.find(option => option.id === this.planOptionId)?.price
    },
  },
  methods: {
    ...mapActions('eventWizard', [
      'fetchPaymentCurrency',
      'paymentOnDemand',
      'setOrderStatus',
    ]),
    async open() {
      this.show = true
      this.loading = true
      await this.fetchPaymentCurrency()
      try {
        await this.paymentOnDemand({
          planId: this.planOptionId,
          createInvoice: this.createInvoice})
      } catch (e) {
        this.orderError = 'Error fetching payment details'
        this.loading = false
        return
      }
      this.loading = false
      await this.$nextTick()
      await this.$refs.stripe.setup()
    },
    async onPay() {
      await this.$refs.stripe.pay()
    },
    onPaid() {
      this.$emit('published')
      this.show = false
    }
  }
}
</script>