<template>
  <div v-if="options && options.length && paymentCurrency">
    <div class="font-weight-semi-bold mb-2">
      <CIcon name="cipCube01" size="custom-size" width="16" class="mr-1" style="vertical-align: sub"/>
      <translate translate-context="event.modal.create_new_event.plan_options_selector">
        Selected a plan
      </translate>
    </div>
    <BasePlanSwitcher :plan="chosenPlan"
                      :currency="paymentCurrency"
                      :has-next="chosenPlan.id !== options[options.length - 1].id"
                      :has-prev="chosenPlan.id !== options[0].id"
                      @change="selectPlan" class="mb-2" v-if="chosenPlan"/>
    <div class="mb-4 plan-expiration d-flex align-items-center">
      <translate translate-context="event.modal.create_new_event.info">
       The photos will be visible to your guests for two years
      </translate>
      <PTooltip class="ml-2"
                :content="$pgettext('plan.tooltip', 'The photos will be available on our servers for two years, except in cases of early deletion request.')"
                :color="tooltipColor"
                placement="right"/>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import BasePlanSwitcher from "@/domain/events/components/BasePlanSwitcher.vue";
import PTooltip from "@/domain/core/components/PTooltip.vue";
import {tooltipColor} from "@/domain/eventWizard/components/overview/tooltip";

export default {
  name: "PlanOptionSelector",
  components: {PTooltip, BasePlanSwitcher},
  data() {
    return {
      chosenPlan: null
    }
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Number,
      default: null
    }
  },
  async mounted() {
    await this.fetchPaymentCurrency()
    if (this.selected) {
      const index = this.options.findIndex(option => option.id === this.selected)
      this.chosenPlan = this.options[index]
    } else {
      this.chosenPlan = this.options[0]
    }
  },
  computed: {
    ...mapGetters('eventWizard', [
      'paymentCurrency'
    ]),
    tooltipColor() {
      return tooltipColor
    }
  },
  methods: {
    ...mapActions('eventWizard', [
      'fetchPaymentCurrency'
    ]),
    selectPlan(planId, step) {
      const index = this.options.findIndex(option => option.id === planId)
      this.chosenPlan = this.options[index + step]

      this.$emit('update:value', this.chosenPlan.id)
      this.$emit('input', this.chosenPlan)
    }
  }
}
</script>