import ApiClient from "@/domain/core/utils/apiClient";
import client_core from "@/services/clients/client_core";
import moment from "moment";

const client = new ApiClient(client_core)

const endpoints = {
    search: '/dashboard/v2/photo-search/{id}/search/?gallery_id={gallery}',
    newly_created: '/dashboard/v2/photo-search/{id}/newly_created/?gallery_id={gallery}',
    newly_processed: '/dashboard/v2/photo-search/{id}/newly_processed/?gallery_id={gallery}',
    download: '/dashboard/v2/photo-search/{id}/download/?gallery_id={gallery}',
    config: '/dashboard/v2/photo-search/{id}/config/?gallery_id={gallery}',
    timeline: '/dashboard/v2/photo-search/{id}/timeline/?gallery_id={gallery}',
    filter: '/dashboard/v2/photo-search/{id}/filter/?gallery_id={gallery}',
    filters: '/dashboard/v2/photo-search/{id}/filters/?gallery_id={gallery}',
    ids: '/dashboard/v2/photo-search/{id}/ids/?gallery_id={gallery}',
    tags: '/dashboard/v2/photo-search/{id}/tags/?gallery_id={gallery}',
    add_tags: '/dashboard/v2/photo-search/{id}/add_tags/?gallery_id={gallery}',
    remove_tags: '/dashboard/v2/photo-search/{id}/remove_tags/?gallery_id={gallery}',
    galleries: '/dashboard/v2/photo-search/{id}/galleries/?gallery_id={gallery}',
    set_gallery: '/dashboard/v2/photo-search/{id}/set_gallery/?gallery_id={gallery}',
    copy: '/dashboard/v2/photo-search/{id}/copy/?gallery_id={gallery}',
    move_top: '/dashboard/v2/photo-search/{id}/move_top/?gallery_id={gallery}',
    move_bottom: '/dashboard/v2/photo-search/{id}/move_bottom/?gallery_id={gallery}',
    reorder: '/dashboard/v2/photo-search/{id}/reorder/?gallery_id={gallery}',
    delete_media: '/dashboard/v2/photo-search/{id}/delete/?gallery_id={gallery}',
    soft_delete: '/dashboard/v2/photo-search/{id}/soft_delete/?gallery_id={gallery}',
    restore: '/dashboard/v2/photo-search/{id}/restore/?gallery_id={gallery}',
    export_media_pica_code: '/dashboard/v2/photo-search/{id}/export_media_pica_code/?gallery_id={gallery}',
    media_detail: '/dashboard/v2/photo-search/{id}/media/{media_id}/detail?gallery_id={gallery}',
}

export default {
    async fetchConfig({pica_service_id, gallery_id}, sorting = null) {
        return await client.get(endpoints.config.replace('{id}', pica_service_id).replace('{gallery}', gallery_id),{sorting})
    },
    async fetchTimeline({pica_service_id, gallery_id}, filters, sorting = null) {
        return await client.post(endpoints.timeline.replace('{id}', pica_service_id).replace('{gallery}', gallery_id), mapFilters(filters), {sorting})
    },
    async fetchPhotos({pica_service_id, gallery_id}, filters, sorting = null) {
        return await client.post(endpoints.filter.replace('{id}', pica_service_id).replace('{gallery}', gallery_id), mapFilters(filters), {sorting})
    },
    async fetchFilterValues({pica_service_id, gallery_id}) {
        return await client.get(endpoints.filters.replace('{id}', pica_service_id).replace('{gallery}', gallery_id))
    },
    async fetchSearch({pica_service_id, gallery_id}, query) {
        return await client.post(endpoints.search.replace('{id}', pica_service_id).replace('{gallery}', gallery_id), query)
    },
    async fetchNewMedia({pica_service_id, gallery_id}, created_at, taskId) {
        return await client.post(endpoints.newly_created.replace('{id}', pica_service_id).replace('{gallery}', gallery_id), {
            created_at: created_at ? created_at.toISOString() : null,
            upload_task: taskId
        })
    },
    async fetchNewProcessed({pica_service_id, gallery_id}, {processed_at = null, ids, taskId}) {
        let iso_processed_at = null
        if (processed_at)
            iso_processed_at = moment(processed_at).toISOString()

        return await client.post(endpoints.newly_processed.replace('{id}', pica_service_id).replace('{gallery}', gallery_id), {
            processed_at: iso_processed_at,
            visible_ids: ids??[],
            upload_task: taskId
        })
    },
    async downloadPhotos({pica_service_id, gallery_id}, ids, email, appyLogo) {
        return await client.post(endpoints.download.replace('{id}', pica_service_id).replace('{gallery}', gallery_id), {
            ids,
            email,
            logo: appyLogo
        }, {}, 'download')
    },
    async fetchIdsForDate({pica_service_id, gallery_id}, start, end, filters, sorting) {
        return await client.get(endpoints.ids.replace('{id}', pica_service_id).replace('{gallery}', gallery_id), {
            ...mapFilters(filters),
            date_start: start.toISOString(),
            date_end: end.toISOString(),
            sorting: sorting
        })
    },
    async fetchTags({pica_service_id, gallery_id}) {
        return await client.get(endpoints.tags.replace('{id}', pica_service_id).replace('{gallery}', gallery_id))
    },
    async addTags({pica_service_id, gallery_id}, ids, tags) {
        return await client.post(endpoints.add_tags.replace('{id}', pica_service_id).replace('{gallery}', gallery_id), {
            ids: ids,
            tags: tags
        })
    },
    async removeTags({pica_service_id, gallery_id}, ids) {
        return await client.post(endpoints.remove_tags.replace('{id}', pica_service_id).replace('{gallery}', gallery_id), {
            ids: ids,
        })
    },
    async fetchGalleries({pica_service_id, gallery_id}) {
        return await client.get(endpoints.galleries.replace('{id}', pica_service_id).replace('{gallery}', gallery_id))
    },
    async setGallery({pica_service_id, gallery_id}, ids, gallery) {
        return await client.post(endpoints.set_gallery.replace('{id}', pica_service_id).replace('{gallery}', gallery_id), {
            ids: ids,
            gallery: gallery
        })
    },
    async copyToGallery({pica_service_id, gallery_id}, ids, gallery) {
        return await client.post(endpoints.copy.replace('{id}', pica_service_id).replace('{gallery}', gallery_id), {
            ids: ids,
            gallery: gallery
        })
    },
    async deleteMedia({pica_service_id, gallery_id}, ids, filters) {
        return await client.post(endpoints.delete_media.replace('{id}', pica_service_id).replace('{gallery}', gallery_id), {
            ids: ids,
            filters: mapFilters(filters),
        })
    },
    async moveTop({pica_service_id, gallery_id}, ids) {
        return await client.post(endpoints.move_top.replace('{id}', pica_service_id).replace('{gallery}', gallery_id), {
            ids: ids,
        })
    },
    async moveBottom({pica_service_id, gallery_id}, ids) {
        return await client.post(endpoints.move_bottom.replace('{id}', pica_service_id).replace('{gallery}', gallery_id), {
            ids: ids,
        })
    },
    async reorder({pica_service_id, gallery_id}, ids, before, after) {
        return await client.post(endpoints.reorder.replace('{id}', pica_service_id).replace('{gallery}', gallery_id), {
            ids: ids,
            before: before > 0 ? before : null,
            after: after > 0 ? after : null,
        })
    },
    async softDelete({pica_service_id, gallery_id}, ids, filters) {
        return await client.post(endpoints.soft_delete.replace('{id}', pica_service_id).replace('{gallery}', gallery_id), {
            ids: ids,
            filters: mapFilters(filters),
        })
    },
    async restore({pica_service_id, gallery_id}, ids, filters) {
        return await client.post(endpoints.restore.replace('{id}', pica_service_id).replace('{gallery}', gallery_id), {
            ids: ids,
            filters: mapFilters(filters),
        })
    },
    async exportMediaPicaCode({pica_service_id, gallery_id}) {
        await client.download(endpoints.export_media_pica_code.replace('{id}', pica_service_id).replace('{gallery}', gallery_id),null,null,'POST')
    },
    async fetchMediaDetail({pica_service_id, gallery_id}, media_id) {
        return await client.get(endpoints.media_detail.replace('{id}', pica_service_id).replace('{gallery}', gallery_id).replace('{media_id}', media_id))
    },
    async abort() {
       client.abort()
    }
}

function mapFilters(filters) {
    return Object.keys(filters).reduce((acc, key) => {
        if (!filters[key] === null) return acc
        if (key === 'date')
            acc.date = moment(filters.date)
        else if (key === 'date_range') {
            if (filters.date_range.start)
                acc.date_start = moment(filters.date_range.start)
            if (filters.date_range.end)
                acc.date_end = moment(filters.date_range.end)
        } else {
            acc[key] = filters[key]
        }
        return acc
    }, {})
}
