import ApiClient from "@/domain/core/utils/apiClient";
import client_core from "@/services/clients/client_core";

const client = new ApiClient(client_core)

const endpoints = {
    region_list: '/dashboard/v2/payment/public/regions/',
    region_detail: '/dashboard/v2/payment/public/regions/{region}/',
    category_list: '/dashboard/v2/payment/public/regions/{region}/categories/',
}

export default {
    async fetchRegions() {
        return await client.get(endpoints.region_list)
    },
    async fetchRegion(idRegion) {
        return await client.get(endpoints.region_detail.replace('{region}', idRegion))
    },
    async fetchCategories(idRegion) {
        return await client.get(endpoints.category_list.replace('{region}', idRegion))
    },
}
