<template>
  <CModal :show.sync="show" centered>
    <template #header>
      <div>
        <div class="d-flex">
          <CIcon name="cipUpload2" size="custom-size" width="36" height="36"
                 class="mr-3 icon-circle icon-circle--primary"/>
          <div class="align-self-center">
            <h5 class="modal-title" v-translate translate-context="event.modal.pay_as_you_go.title">Deliver photos</h5>
            <div v-if="unlockInfo">
              <translate v-translate translate-context="event.modal.pay_as_you_go.description"
                         :translate-params="{num: unlockInfo.to_pay}">Deliver %{num} photos now to all participants
              </translate>
            </div>
          </div>
        </div>
      </div>
      <CButtonClose @click="show = false"/>
    </template>
    <PComponentLoader :loading="loading">
      <template v-if="!loading">
        <template v-if="unlockInfo.status === unlockStatus.already_unlocked">
          <div v-translate translate-context="event.modal.pay_as_you_go.content">
            You have already unlocked all the media you can.
          </div>
        </template>
        <template v-if="unlockInfo.status === unlockStatus.nothing_to_pay">
          <translate translate-context="event.modal.pay_as_you_go.content"
                     :translate-params="{limit: unlockInfo.limit, uploaded: unlockInfo.uploaded}">
            The upload limit is %{limit}. You have uploaded %{uploaded} media files.
          </translate>
          <translate translate-context="event.modal.pay_as_you_go.content">
            You don't need to pay to unlock more media files.
          </translate>
        </template>
        <template v-if="unlockInfo.status === unlockStatus.pay_to_unlock">
          <div v-if="unlockInfo && order">
            <PAlert icon="cipAlertTriangle" :text="orderError" class="mb-3" color="danger" v-if="orderError"/>
            <PAlert icon="cipAlertTriangle"
                    class="mb-3" color="warning"
                    v-if="unlockInfo.price > unlockInfo.real_price">
              <translate translate-context="event.modal.pay_as_you_go.content" :translate-params="{price: priceFormatted}">
                The minimum transaction is %{price}
              </translate>
            </PAlert>
            <StripeElements
                ref="stripe"
                @paid="onPaid"
                :client_secret="order.client_secret"
                :customer_secret="order.customer_session_client_secret"
                :price="unlockInfo.price"
                :currency="paymentCurrency">
              <div class="mb-3 font-weight-semi-bold">
                <translate>Summary</translate>
              </div>
              <div class="mb-4">
                <div class="mb-2">
                <span class="font-weight-bold"
                      style="font-size: 18px">{{ unlockInfo.extra_photo_price|currency(paymentCurrency) }}</span> /
                  <translate translate-context="event.modal.pay_as_you_go.content">photo</translate>
                  x {{ unlockInfo.to_pay }}
                  <translate translate-context="event.modal.pay_as_you_go.content">total photos</translate>
                  *
                </div>
                <div v-translate translate-context="event.modal.pay_as_you_go.content" class="text-help">
                  *The photos included in your piano will still be uploaded, you just pay the difference
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <div class="font-weight-semi-bold">
                  <translate>Total</translate>
                  ({{ paymentCurrency }})
                </div>
                <div class="font-weight-bold" style="font-size: 18px">{{ unlockInfo.price|currency(paymentCurrency) }}
                </div>
              </div>
              <hr>
              <div class="d-flex justify-content-between align-items-center mb-3">
                <div>
                  <CIcon name="cipBuilding02" size="custom-size" width="16" class="mr-1" style="vertical-align: sub"/>
                  <span class="font-weight-semi-bold" v-translate
                        translate-context="event.modal.create_new_event.plan_options_selector">
                  Are you a company?
                </span>&nbsp;
                  <span v-translate translate-context="event.modal.create_new_event.plan_options_selector">
                  Request an invoice
                </span>
                </div>
                <CSwitch class="mx-1" color="primary" variant="3d" size="sm"
                         :checked.sync="createInvoice"/>
              </div>
            </StripeElements>
          </div>
        </template>
      </template>
    </PComponentLoader>
    <template #footer>
      <CButton color="secondary" @click="show = false" v-translate>Close</CButton>
      <PButton
          color="primary" class="mr-3"
          v-if="requirePayment"
          :disabled="orderError"
          @click="onPay">
        <translate translate-context="event.modal.publish.actions">Deliver for</translate> {{ unlockInfo.price|currency(paymentCurrency) }}
      </PButton>
    </template>
  </CModal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PButton from "@/components/PButton.vue";
import unlockStatus from "@/domain/core/constant/unlockStatus";
import PComponentLoader from "@/domain/core/components/PComponentLoader.vue";
import PAlert from "@/components/PAlert.vue";
import StripeElements from "@/domain/events/components/StripeElements.vue";

export default {
  name: "PayAsYouGoModal",
  emits: ['payed'],
  components: {
    StripeElements,
    PAlert,
    PComponentLoader,
    PButton,
  },
  data() {
    return {
      show: false,
      loading: true,
      orderError: null,
      createInvoice: false
    }
  },
  computed: {
    ...mapGetters('eventWizard', [
      'unlockInfo',
      'paymentCurrency',
      'order'
    ]),
    requirePayment() {
      if (!this.unlockInfo) return false
      return unlockStatus.isPayable(this.unlockInfo.status)
    },
    unlockStatus() {
      return unlockStatus
    },
    priceFormatted() {
      if (!this.unlockInfo && !this.unlockInfo.price) return ''
      return this.$options.filters.currency(this.unlockInfo.price, this.paymentCurrency)
    }
  },
  methods: {
    ...mapActions('eventWizard', [
      'fetchUnlockInfo',
      'fetchPaymentCurrency',
      'paymentPayAsYouGo',
    ]),
    async open() {
      this.show = true
      this.loading = true
      await this.fetchUnlockInfo()
      await this.fetchPaymentCurrency()

      try {
        await this.paymentPayAsYouGo({
          quantity: this.unlockInfo.to_pay,
          createInvoice: this.createInvoice
        })
      } catch (e) {
        this.orderError = 'Error fetching payment details'
        this.loading = false
        return
      }
      this.loading = false
      await this.$nextTick()
      await this.$refs.stripe.setup()
    },
    async onPay() {
      await this.$refs.stripe.pay()
    },
    onPaid() {
      this.$emit('payed')
      this.show = false
    }
  }
}
</script>