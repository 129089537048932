<template>
  <CButton class="p-button" :block="block" :size="size" :color="color" @click.prevent="onClick" :disabled="disabled || isLoading">
    <template v-if="isLoading">
      <CSpinner :color="spinnerColor" :size="spinnerSize"/>
      <span class="ml-2" v-if="loadingText">{{ loadingText }}</span>
    </template>
    <slot v-else></slot>
  </CButton>
</template>


<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: null,
      optional: true,
    },
    spinnerColor: {
      type: String,
      default: 'success'
    },
    spinnerSize: {
      type: String,
      default: 'sm'
    },
    loadingText: {
      type: String,
    },
    block: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      required: false,
      default: ''
    },
    color: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      asyncLoading: false
    }
  },
  computed: {
    isLoading() {
      return this.loading || this.asyncLoading
    }
  },
  methods: {
    async onClick() {
      if (this.loading === null) {
        this.asyncLoading = true
        await this.emitPromised('click')
        this.asyncLoading = false
      } else {
        this.$emit('click')
      }
    }
  }
}
</script>