import 'core-js/stable'
import 'regenerator-runtime/runtime';
import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import {Vue as VueIntegration} from '@sentry/integrations'
import {VLazyImagePlugin} from 'v-lazy-image'
import * as VueGoogleMaps from 'vue2-google-maps'
import router from './router'
import CoreuiVue from '@coreui/vue'
import {iconsSet as icons} from './assets/icons/icons.js'
import store from './store'
import {mapGetters, mapActions} from 'vuex'
import VueCompositionAPI from '@vue/composition-api'
import {setLocale} from '@/utils/i18n'
import './utils/filters'
import './i18n'
import 'leaflet/dist/leaflet.css';
import {Cropper} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.use(VueCompositionAPI)
Vue.prototype.$log = console.log.bind(console)

// Sentry
// ---------------------------------------------------------------------------------------------------------------------
if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: 'https://e4b554547f734708929414e467e97084@o49465.ingest.sentry.io/5397372',
        integrations: [new VueIntegration({Vue, attachProps: true})],
    })
}

// v-lazy-image
// ---------------------------------------------------------------------------------------------------------------------
Vue.use(VLazyImagePlugin)

// Cropper
// ---------------------------------------------------------------------------------------------------------------------
Vue.component('Cropper', Cropper)

// Google Maps
// ---------------------------------------------------------------------------------------------------------------------
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAp9iIxYrZkmqzGbeprsGx8tSIBMuqTDnk',
        libraries: 'places' // This is required if you use the Autocomplete plugin
    },

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    // autobindAllEvents: false,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then set installComponents to 'false'.
    //// If you want to automatically install all the components this property must be set to 'true':
    installComponents: true
})


// Setting user locale
// ---------------------------------------------------------------------------------------------------------------------
setLocale(store.getters['login/userLanguage'], store.getters['login/userTimeZone'])

// Global mixin
// ---------------------------------------------------------------------------------------------------------------------
import {ValidationError} from "@/domain/core/exception/exceptions";

function log(...args) {
    if (process.env.NODE_ENV === 'development') {
        if (args.length === 1) {
            console.log(JSON.parse(JSON.stringify(args[0])))
        } else {
            if (typeof args[0] === 'string') console.group(args.shift())
            else console.group('Log')
            for (let arg of args) {
                console.log(JSON.parse(JSON.stringify(arg)))
            }
            console.groupEnd()
        }
    }
}

window.log = log

const mixin = {
    computed: {
        ...mapGetters({
            hasPerm: 'login/hasPerm',
            hasPerms: 'login/hasPerms',
            hasAnyPerms: 'login/hasAnyPerms',
            isInGroup: 'login/isInGroup',
            language: 'login/userLanguage',
        })
    },
    methods: {
        ...mapActions({
            notifyMessage: 'notifications/addMessage',
            notifySuccess: 'notifications/success',
            notifyError: 'notifications/error',
            confirm: 'notifications/confirm'
        }),
        log(...args) {
            log(...args)
        },
        delay(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async emitPromised(method, ...params) {
            let listener = this.$listeners[method] || this.$attrs[method] || this[method]
            if (listener) {
                let res = await listener(...params)
                return res === undefined || res
            }
            return false
        },
        getParentWithMethod(method) {
            let parent = this.$parent
            while (parent) {
                if (parent[method])
                    return parent
                parent = parent.$parent
            }
            return null
        },
        currencySymbol(currency) {
            return (0).toLocaleString(undefined, {
                style: 'currency',
                currency: currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            }).replace(/\d/g, '').trim();
        },
        copyInClipboard(text, description) {
            if (navigator && navigator.clipboard) {
                navigator.clipboard.writeText(text)
                this.notifyMessage({text: this.$gettext(description + ' copied!'), messageType: 'success'})
            } else {
                this.notifyMessage({text: this.$gettext('Clipboard not supported'), messageType: 'danger'})
            }
        },
        async try(funct, messages) {
            try {
                await funct()
            } catch (exception) {
                if (exception instanceof ValidationError) {
                    let code = exception.message.code
                    if (messages[code])
                        await this.notifyError(messages[code])
                    else if (exception.message.details)
                        await this.notifyError(exception.message.details)
                    else
                        throw exception
                } else throw exception
            }
        },
        registerEvent(event, callback) {
            this.$store.dispatch('eventBus/registerEvent', {event, callback});
        },
        unregisterEvent(event, callback) {
            this.$store.dispatch('eventBus/unregisterEvent', {event, callback});
        },
        async emitEvent(event, payload) {
            await this.$store.dispatch('eventBus/emitEvent', {event, payload});
        }
    }
}
Vue.mixin(mixin)

// If you want to use the default style (with webpack css-loader)
// import 'vuex-toast/dist/vuex-toast.css'

import 'vue-multiselect/dist/vue-multiselect.min.css'


// Date time picker
// ---------------------------------------------------------------------------------------------------------------------
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker)

// Vue Tour
// ---------------------------------------------------------------------------------------------------------------------
import VueTour from 'vue-tour'
import 'vue-tour/dist/vue-tour.css'

Vue.use(VueTour)

// Lodash
// We import lodash as global variable, refer to it as _
// ---------------------------------------------------------------------------------------------------------------------
window._ = require('lodash')

store.dispatch('login/rememberFromStorage')
store.dispatch('eventWizard/rememberFromStorage')

export {Vue, router, store, icons}
