export const refundMethods = {
    openOrderPage(item) {
        if (!this.hasPerm('pica_ecommerce.view_order_detail'))
            return

        const url = this.$router.resolve({
            name: 'eventOrderDetail',
            params: {
                orderId: item.order_id,
                picaServiceId: item.pica_service,
            }
        })
        window.open(url.href, '_blank')
    }
}

