const state = {
  events: {}
}

const mutations = {
  register(state, { event, callback }) {
    if (!state.events[event])
      state.events[event] = []
    state.events[event].push(callback)
  },
  unregister(state, { event, callback }) {
    if (state.events[event])
      state.events[event] = state.events[event].filter(cb => cb !== callback)
  }
}

const getters = {
  listeners: (state) => (event) => {
    return state.events[event] || []
  }
}

const actions = {
  async emitEvent({ getters }, { event, payload }) {
    const listeners = getters.listeners(event)
    if (listeners.length <= 0) return

    const promises = listeners.map(callback => callback(payload))
    await Promise.all(promises)
  },
  registerEvent({ commit }, { event, callback }) {
    commit('register', { event, callback })
  },
  unregisterEvent({ commit }, { event, callback }) {
    commit('unregister', { event, callback })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
