<template>
  <div v-if="enabled">
    <transition name="fade" :css="dismissible">
      <div class="p-alert" :class="[modifier, extraClass, {'disabled': disabled}]" v-if="visible">
        <div class="d-flex justify-content-between" :class="contentAlign">
          <div class="d-flex">
            <div class="p-alert__icon" v-if="icon">
              <CIcon :name="icon" size="custom-size" :width="iconSize" class="icon-circle" :class="iconClasses"/>
            </div>
            <div class="p-alert__text align-self-center">
              <slot>
                <h6 v-if="title">{{ title }}</h6>
                <p v-html="text" v-if="text"></p>
              </slot>
            </div>
          </div>
          <div class="p-alert__cta">
            <slot name="cta"></slot>
          </div>
          <CButtonClose class="p-alert__close" @click="onDismiss" v-if="dismissible"/>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "PAlert",
  emits: ['dismiss'],
  data() {
    return {
      enabled: true,
      visible: true
    }
  },
  props: {
    color: {
      type: String,
      default: "primary"
    },
    icon: {
      type: String,
      required: false
    },
    iconSize: {
      type: Number,
      default: 36
    },
    title: {
      type: String,
      required: false
    },
    text: {
      type: String,
      required: false
    },
    extraClass: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    iconClass: {
      type: String,
      required: false
    },
    dismissible: {
      type: Boolean,
      default: false
    },
    contentAlign: {
      type: String,
      default: "align-items-start"
    },
    sessionKey: {
      type: String,
      required: false
    }
  },
  computed: {
    modifier() {
      return `p-alert--${this.color}`;
    },
    iconClasses() {
      return {
        "icon-circle--sm": this.iconSize < 30,
        [`icon-circle--${this.color}`]: true,
        [this.iconClass]: this.iconClass
      }
    }
  },
  methods: {
    async onDismiss() {
      this.visible = false
      this.saveState()
      await this.delay(300)
      this.enabled = false
      this.$emit("dismiss")
    },
    saveState() {
      let alertState = JSON.parse(sessionStorage.getItem('p-alert')) || {}
      alertState[this.sessionKey] = 'dismissed'
      sessionStorage.setItem('p-alert', JSON.stringify(alertState))
    }
  },
  mounted() {
    const alertState = JSON.parse(sessionStorage.getItem('p-alert'))
    if (alertState && alertState[this.sessionKey] === 'dismissed') {
      this.enabled = false
    }
  }
}
</script>