<template>
  <CRow :class="rowClass" :form="form">
    <CCol col="12" v-if="separator">
      <hr class="mt-0 mb-4">
    </CCol>
    <CCol col="12" :lg="colSizeLg[0]">
      <slot name="title"><label :class="labelClass">{{ label }}</label></slot>

      <slot name="help-text">
        <p class="text-help-dark" v-if="helpText">
          {{ helpText }}
        </p>
      </slot>

    </CCol>
    <CCol col="12" :lg="colSizeLg[1]">
      <slot></slot>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: "PFRow",
  props: {
    label: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: '3x4'
    },
    helpText: {
      type: String,
      default: ''
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    halfWidth: {
      type: Boolean,
      default: false
    },
    rowClass: {
      type: String,
      default: 'py-3'
    },
    labelClass: {
      type: String,
      default: ''
    },
    separator: {
      type: Boolean,
      default: false
    },
    form: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    colSizeLg() {
      // short hands
      if (this.fullWidth) return ['12', '12']
      if (this.halfWidth) return ['6', '6']
      // default
      return this.size.split('x').map(Number)
    },
  }
}
</script>
