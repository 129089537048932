import fileUtils from "@/domain/core/utils/fileUtils";
import store from '@/store';

class UploadThread {

    constructor({onSuccess, onFailure, onProgress}) {
        this.cb = {
            onSuccess,
            onFailure,
            onProgress,
        }
        this.active = false
    }

    start(file) {
        this.file = file
        this.active = true
        this.abortController = null
        this.doUpload()
    }

    isUploadingFile(file) {
        if (!this.active) return false
        return this.file === file
    }

    abort() {
        this.active = false
        this.file.inProgress = false
        if (this.abortController && !this.abortController.signal.aborted)
            this.abortController.abort()
    }

    async doUpload() {
        try {
            const begin = new Date()
            this.file.inProgress = true
            this.updateOnProgress(0)
            this.abortController = new AbortController();
            await fileUtils.uploadToS3({
                file: this.file, intent: this.file.intent, onProgress: this.onProgress.bind(this, begin),
                signal: this.abortController.signal
            })
            this.cb.onSuccess(this)
            this.updateOnProgress(100)
        } catch (e) {
            this.cb.onFailure(this, e.message)
            this.updateOnProgress(-1)
        }
        this.active = false
    }

    onProgress(begin, uploaded) {
        const duration = (new Date() - begin) / 1000
        const speed = uploaded.loaded / duration
        this.updateOnProgress(Math.round(uploaded.loaded / uploaded.total * 100), speed)
    }

    updateOnProgress(percent, speed) {
        if (!speed) speed = 0
        this.cb.onProgress(this.file.md5, percent, speed)
    }

    isActive() {
        return this.active
    }
}

export class UploadScheduler {
    MAX_THREADS = 5

    constructor() {
        this.threads = []
        for (let i = 0; i < this.MAX_THREADS; i++)
            this.threads.push(new UploadThread({
                onSuccess: this.onThreadSuccess.bind(this),
                onFailure: this.onThreadFailure.bind(this),
                onProgress: this.onProgress.bind(this)
            }))
    }

    start() {
        while (this.numActiveThread() < this.MAX_THREADS) {

            const file = store.getters['uploader/fileToUpload']
            if (!file) return

            const thread = this.getFreeThread()
            thread.start(file)
            store.commit('uploader/fileInUpload', file)
        }
    }

    getFreeThread() {
        for (let i = 0; i < this.threads.length; i++) {
            if (!this.threads[i].isActive()) {
                return this.threads[i]
            }
        }
        return null
    }

    numActiveThread() {
        return this.threads.filter(thread => thread.isActive()).length
    }

    onThreadSuccess(thread) {
        store.commit('uploader/uploadCompleted', thread.file)
        this.start()
    }

    onThreadFailure(thread, error) {
        store.commit('uploader/uploadFailed', {file: thread.file, error: error})
        this.start()
    }

    abortUpload(file) {
        for (let i = 0; i < this.threads.length; i++) {
            if (this.threads[i].isUploadingFile(file)) {
                this.threads[i].abort()
                return
            }
        }
    }

    onProgress(md5, percent, speed) {
        store.commit('uploader/updateProgress', {md5, percent, speed})
    }
}

export class UploadIntent {
    constructor(url, fields) {
        this.url = url
        this.fields = fields
    }
}
