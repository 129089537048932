import ApiClient from "@/domain/core/utils/apiClient";
import client_core from "@/services/clients/client_core";
const client = new ApiClient(client_core)

const endpoints = {
    timezones: '/dashboard/v2/core/timezones/',
    currencies: '/dashboard/v2/core/currencies/',
    recognition_classes: '/dashboard/v2/core/recognition_class/',
    cluster_sizes: '/dashboard/v2/core/cluster_size/',
    categories: '/dashboard/v2/core/category/',
    codecs: '/dashboard/v2/core/codec/',
    checkin_stay_mode: '/dashboard/v2/core/checkin_stay_mode/',
    regions: '/dashboard/v2/core/region/',
    orderStatus: '/dashboard/v2/core/order_status/',
    paymentMethods: '/dashboard/v2/core/payment_methods/',
    anonymizeMode: '/dashboard/v2/core/anonymize_mode/',
    shareMode: '/dashboard/v2/core/share_mode/',
    experienceType: '/dashboard/v2/core/experience_type/',
    purchaseTime: '/dashboard/v2/core/purchase_time/',
    hdStorageTypes: '/dashboard/v2/core/hd_storage_type/',
    hdServingTypes: '/dashboard/v2/core/hd_serving_type/',
    sdServingTypes: '/dashboard/v2/core/sd_serving_type/',
}

export default {
    async fetchTimezones() {
        return await client.get(endpoints.timezones)
    },
    async fetchCurrencies() {
        return await client.get(endpoints.currencies)
    },
    async fetchRecognitionClasses() {
        return await client.get(endpoints.recognition_classes)
    },
    async fetchClusterSizes() {
        return await client.get(endpoints.cluster_sizes)
    },
    async fetchCategories() {
        return await client.get(endpoints.categories)
    },
    async fetchCodecs() {
        return await client.get(endpoints.codecs)
    },
    async fetchCheckinStayMode() {
        return await client.get(endpoints.checkin_stay_mode)
    },
    async fetchRegions() {
        return await client.get(endpoints.regions)
    },
    async fetchOrderStatus() {
        return await client.get(endpoints.orderStatus)
    },
    async fetchPaymentMethods() {
        return await client.get(endpoints.paymentMethods)
    },
    async fetchAnonymizeMode() {
        return await client.get(endpoints.anonymizeMode)
    },
    async fetchShareMode() {
        return await client.get(endpoints.shareMode)
    },
    async fetchExperienceType() {
        return await client.get(endpoints.experienceType)
    },
    async fetchPurchaseTime() {
        return await client.get(endpoints.purchaseTime)
    },
    async fetchHdStorageTypes() {
        return await client.get(endpoints.hdStorageTypes)
    },
    async fetchHdServingTypes() {
        return await client.get(endpoints.hdServingTypes)
    },
    async fetchSdServingTypes() {
        return await client.get(endpoints.sdServingTypes)
    },
}
