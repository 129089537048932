<template>
  <CToaster :autohide="autoHide" position="top-center">
    <CToast
        v-for="(message, index) in messages"
        :key="index"
        :color="message.messageType"
        :show="true"
        :fade="false"
        :class="[message.hide ? 'slide-out-up' : 'slide-in-down']"
        @update:show="onUpdate"
    >
      <div class="d-flex">
        <div class="mr-2">
          <CIcon :name="getIcon(message.messageType)" size="custom-size" width="32" class="icon-circle"
                 :class="'icon-circle--' + message.messageType"/>
        </div>
        <div class="align-self-center">
          <h6 class="font-weight-semi-bold mb-0" v-if="message.title">{{ message.title }}</h6>
          {{ message.text }}
        </div>
      </div>
    </CToast>
  </CToaster>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Notifier",
  computed: {
    ...mapGetters('notifications', ['messages', 'autoHide']),
  },
  methods: {
    ...mapActions('notifications', ['clearMessages']),
    onUpdate() {
      this.clearMessages()
    },
    getIcon(messageType) {
      switch (messageType) {
        case 'success':
          return 'cipCircleCheck'
        case 'warning':
          return 'cipTriangleWarning'
        case 'danger':
          return 'cipXCircle'
        case 'info':
          return 'cipInfo'
        case 'primary':
          return 'cipInfo'
      }
    }
  },
  watch: {
    messages: {
      async handler(newMessages) {
        const messageArray = Object.values(newMessages);
        for (const message of messageArray) {
          await this.delay(this.autoHide)
          this.$set(message, 'hide', !message.hide)
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
@keyframes slide-in-down {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-in-down {
  animation: slide-in-down 300ms ease-out;
}

@keyframes slide-out-up {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}
.slide-out-up {
  animation: slide-out-up 300ms ease-out;
}
</style>