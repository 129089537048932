<template>
  <div v-if="hasAnySteps">
    <CSidebarNavTitle v-translate>Setup</CSidebarNavTitle>
    <CSidebarNavItem
        :name="$gettext('Configuration')"
        :to="{name: 'eventWizard.settings', params: {picaServiceId: picaServiceId}}"
        icon="cip-settings"
        v-if="isVisible('template') || isVisible('plan_settings') || isVisible('event_settings') || isVisible('vertical')"
    />
    <CSidebarNavItem
        :name="$gettext('Event profile')"
        :to="{name: 'eventWizard.date_and_name', params: {picaServiceId: picaServiceId}}"
        icon="cip-house-02"
        :addLinkClasses="linkClasses('date_and_name')"
        v-if="isVisible('date_and_name')"
    />
    <CSidebarNavItem
        :name="$gettext('Photo branding')"
        :to="{name: 'eventWizard.customize_logo_on_photo', params: {picaServiceId: picaServiceId}}"
        icon="cip-layer"
        :addLinkClasses="linkClasses('customize_logo_on_photo')"
        v-if="isVisible('customize_logo_on_photo')"
    />
    <CSidebarNavItem
        :name="$gettext('Banner ADV + link')"
        :to="{name: 'eventWizard.banner_advertisement', params: {picaServiceId: picaServiceId}}"
        icon="cip-external-link"
        :addLinkClasses="linkClasses('banner_advertisement')"
        v-if="isVisible('banner_advertisement')"
    />
    <CSidebarNavItem
        :name="$gettext('Customization')"
        :to="{name: 'eventWizard.customization', params: {picaServiceId: picaServiceId}}"
        icon="cip-shield-check"
        :addLinkClasses="linkClasses('customization')"
        v-if="isVisible('customization')"
    />
    <CSidebarNavItem
        :name="$gettext('Data collection')"
        :to="{name: 'eventWizard.form', params: {picaServiceId: picaServiceId}}"
        icon="cip-file-lock"
        :addLinkClasses="linkClasses('form')"
        v-if="isVisible('form')"
    />
    <CSidebarNavItem
        :name="$gettext('Checkin Options')"
        :to="{name: 'eventWizard.checkin_options', params: {picaServiceId: picaServiceId}}"
        icon="cip-login"
        :addLinkClasses="linkClasses('checkin_options')"
        v-if="isVisible('checkin_options')"
    />
    <CSidebarNavItem
        :name="$gettext('Popup')"
        :to="{name: 'eventWizard.popup', params: {picaServiceId: picaServiceId}}"
        icon="cip-notification-message"
        :addLinkClasses="linkClasses('popup')"
        v-if="isVisible('popup')"
    />
    <CSidebarNavItem
        :name="$gettext('E-Mail Scheduler')"
        :to="{name: 'eventWizard.email_scheduler', params: {picaServiceId: picaServiceId}}"
        icon="cip-email"
        v-if="isVisible('email_scheduler')"
    />
    <CSidebarNavItem
        :name="$gettext('Price list')"
        :to="{name: 'eventWizard.price_list', params: {picaServiceId: picaServiceId}}"
        icon="cip-file-plus"
        v-if="isVisible('price_list')"
    />
    <CSidebarNavItem
        :name="$gettext('Folder Mapping')"
        :to="{name: 'eventWizard.folder_mapping', params: {picaServiceId: picaServiceId}}"
        icon="cip-folder-edit"
        v-if="isVisible('folder_mapping')"
    />
    <CSidebarNavItem
        :name="$gettext('Pica Codes')"
        :to="{name: 'eventWizard.pica_codes', params: {picaServiceId: picaServiceId}}"
        icon="cip-code"
        v-if="isVisible('pica_codes')"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'SetupNav',
  computed: {
    ...mapGetters('eventWizard', [
      'isStepVisible',
      'isStepDone',
      'isStepMandatoryOk'
    ]),
    ...mapGetters('event', [
      'picaServiceId',
    ]),
    hasAnySteps() {
      return this.isStepVisible('date_and_name') ||
          this.isStepVisible('customize_logo_on_photo') ||
          this.isStepVisible('banner_advertisement') ||
          this.isStepVisible('customization') ||
          this.isStepVisible('form') ||
          this.isStepVisible('checkin_options') ||
          this.isStepVisible('popup') ||
          this.isStepVisible('email_scheduler')
    },
  }, methods: {
    linkClasses(step) {
      return {
        'step-done': this.isStepDone(step),
        'step-mandatory-ko': !this.isStepMandatoryOk(step)
      }
    },
    isVisible(step) {
      return this.isStepVisible(step)
    },
  }
}
</script>
