<template>
  <div class="" :class="['p-tooltip d-flex', alignStyle]" v-if="show">
    <CButton
        :size="size"
        class="p-0 mx-1"
        v-c-tooltip="{ content: content, placement: placement, appendToBody: appendToBody}"
    >
      <CIcon :style="style" name="cipCircleHelp"/>
    </CButton>
  </div>
</template>

<script>
export default {
  name: "PTooltip",
  props: {
    content: {
      type: String,
      required: true
    },
    appendToBody: {
      type: Boolean,
      default: true
    },
    placement: {
      type: String,
      required: false,
      default: 'top'
    },
    text: {
      type: String,
      required: false
    },
    size: {
      type: String,
      default: 'sm'
    },
    color: {
      type: String,
      default: 'inherit'
    },
    align: {
      type: String,
      default: 'center',
      validator: function (value) {
        return ['top', 'center', 'bottom'].indexOf(value) !== -1
      }
    }
  },
  data() {
    return {
      show: false
    }
  },
  async mounted() {
    // need to wait for next tick to ensure the tooltip is shown after the button is rendered
    await this.$nextTick()
    this.show = true
  },
  computed: {
    style() {
      return {
        color: this.color
      }
    },
    alignStyle() {
      return this.align === 'top' ? 'align-items-start' : this.align === 'center' ? 'align-items-center' : 'align-items-end'
    }
  }
}
</script>