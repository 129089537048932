<template>
  <div>
    <router-view></router-view>
    <ConfirmModal ref="confirm-modal"/>
    <Chat/>
  </div>
</template>

<script>
import {ApiException, Notification, ValidationError} from "@/domain/core/exception/exceptions";
import ConfirmModal from "./components/ConfirmModal.vue";
import {mapActions} from 'vuex'
import Chat from "@/domain/chat/Chat.vue";

export default {
  name: 'App',
  components: {Chat, ConfirmModal},
  mounted (){
    this.setConfirmModal(this.$refs['confirm-modal'])
  },
  methods: {
    ...mapActions('notifications', ['setConfirmModal']),
  },
  errorCaptured(err, vm, info) {
    if (err instanceof Notification) {
      this.notifyMessage({text: err.message, messageType: err.type})
    } else if (err instanceof ValidationError) {
      this.notifyMessage({text: err.getPrettyMessage(), messageType: 'danger'})
    } else if (err instanceof ApiException) {
      this.notifyMessage({text: err.message, messageType: 'danger'})
    } else {
      console.log('errorCaptured', err, vm, info);
    }
    return false;
  }
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'assets/scss/style';
</style>
