<template>
    <CDataTable
        :hover="false"
        :bordered="true"
        :items="data.results"
        :fields="fields"
        :column-filter="{external: true,lazy:true}"
        :column-filter-value="filters"
        :sorter="{external: true, resetable:true}"
        :loading="loading"
        :sorter-value="sorting"
        @update:column-filter-value="updateFilters"
        @update:sorter-value="updateSorting"
        class="custom-table custom-table--clean custom-table--has-filters"
    >
      <template #no-items-view>
        <div class="text-center">
          <h5 v-translate>No items found</h5>
        </div>
      </template>
      <template #sorting-icon="{ state, classes }">
        <div :class="classes">
          <CIcon name="cipCaretUp" size="lg" v-if="state === 'asc' || state === 0"></CIcon>
          <CIcon name="cipCaretUp" size="lg" v-else></CIcon>
        </div>
      </template>
      <template #under-table>
        <CPagination
            v-show="data.pagination.num_pages > 1"
            :activePage="data.pagination.page"
            @update:activePage="updatePage"
            :pages="data.pagination.num_pages"
        />
      </template>
      <template v-for="(slot, name) in $scopedSlots" v-slot:[name]="slotData">
        <slot :name="name" v-bind="slotData"></slot>
      </template>
    </CDataTable>
</template>

<script>

export default {
  name: "AjaxTable",
  props: {
    fields: {type: Array},
    data: {type: Object},
    fetch: {type: Function}
  },
  emits: [],
  data() {
    return {
      pagination: {
        page_size: 20,
        page: 1,
      },
      sorting: {
        column: null,
        asc: false
      },
      filters: {},
      loading: true,
    }
  },
  mounted() {
    this.fetchList()
  },
  methods: {
    async fetchList() {
      this.loading = true
      await this.fetch({
        filters: this.filters,
        pagination: this.pagination,
        sorting: this.sorting
      })
      this.pagination.page = this.data.pagination.page
      this.loading = false
    },
    updateFilters(filters) {
      this.filters = filters
      this.pagination.page = 1
      this.fetchList()
    },
    updateSorting(sorting) {
      this.sorting = sorting
      this.fetchList()
    },
    updatePage(page) {
      this.pagination.page = page
      this.fetchList()
    },
  }
}
</script>