import {translate} from "vue-gettext";

const {gettext: $gettext} = translate;

export default {
    shot_at_asc: 's',
    shot_at_desc: '-s',
    custom: 'c',
    upload_desc: '-u',
    upload_asc: 'u',

    default() {
        return this.shot_at_asc
    },
    options() {
        return [
            {id: this.shot_at_asc, name: $gettext('Shot date (from oldest)')},
            {id: this.shot_at_desc, name: $gettext('Shot date (from newest)')},
            {id: this.custom, name: $gettext('Custom')},
            {id: this.upload_asc, name: $gettext('Upload date (from oldest)')},
            {id: this.upload_desc, name: $gettext('Upload date (from newest)')},
        ]
    },
    availableSorting(defaultSorting) {
        if ([this.shot_at_asc, this.shot_at_desc].includes(defaultSorting))
            return [
                {id: this.upload_desc, name: $gettext('Upload date (from newest)')},
                {id: this.upload_asc, name: $gettext('Upload date (from oldest)')},
                {id: this.shot_at_asc, name: $gettext('Shot date (from oldest)')},
                {id: this.shot_at_desc, name: $gettext('Shot date (from newest)')},
            ]
        if ([this.upload_asc, this.upload_desc].includes(defaultSorting))
            return [
                {id: this.upload_desc, name: $gettext('Upload date (from newest)')},
                {id: this.upload_asc, name: $gettext('Upload date (from oldest)')},
            ]
        if ([this.custom].includes(defaultSorting))
            return [
                {id: this.upload_desc, name: $gettext('Upload date (from newest)')},
                {id: this.upload_asc, name: $gettext('Upload date (from oldest)')},
                {id: this.custom, name: $gettext('Custom')},
            ]
        return []
    }
}