<template>
  <div>
    <UploadsTooltip :pay_as_you_go_count="pay_as_you_go_count" v-if="showTooltip"/>
    <div class="d-flex justify-content-between align-items-center mb-3 upload-header">
      <div class="d-flex">
        <CIcon name="cipCube01" size="custom-size" width="16" class="mr-2"/>
        <span class="font-weight-semi-bold" style="font-size: 12px">{{ planName }}</span>
      </div>
      <div class="upload-limit d-flex">
        <template v-if="upload_limit>0">{{ intents_count }}/<span
            class="value">{{ process_limit }}</span></template>
        <CLink @mouseenter="showTooltip=true" @mouseleave="showTooltip=false" v-if="hasTooltip">
          <CIcon name="cipCircleHelp" size="custom-size" width="16" style="vertical-align: sub"/>
        </CLink>
      </div>
    </div>
  </div>
</template>

<script>
import UploadsTooltip from "@/domain/uploader/components/UploadsTooltip.vue";
import {stateWidgetUpload} from "@/constants";

export default {
  name: "UploadsProgressHeader",
  components: {UploadsTooltip},
  data() {
    return {
      showTooltip: false
    }
  },
  props: {
    planName: {
      type: String,
      default: ""
    },
    upload_limit: {
      type: Number,
      default: 0
    },
    process_limit: {
      type: Number,
      default: 0
    },
    intents_count: {
      type: Number,
      default: 0
    },
    pay_as_you_go_count: {
      type: Number,
      default: 0
    },
    currentState: {
      type: String,
      required: true
    }
  },
  computed: {
    hasTooltip() {
      return this.currentState === stateWidgetUpload.payAsYouGo
    }
  }
}
</script>
