const ListServiceOrders = () => import('./ListServiceOrders.vue')
const TheContainer = () => import('@/containers/TheContainer')

export default [
    {
        path: '/serviceOrders',
        name: 'serviceOrders',
        component: TheContainer,
        children: [
            {
                path: 'list',
                name: 'serviceOrders.list',
                component: ListServiceOrders,
                meta: {label: 'List Roles',},
            },
        ]
    }
]