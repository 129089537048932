<template>
  <CModal :title="$pgettext('event_wizard.gallery.modal','Create Gallery')" :show="show" @update:show="close"
          centered>
    <template v-if="show">
      <CSelect :options="templates" v-model="form.template" :label="$pgettext('event_wizard.gallery.modal.label','Template')"/>
      <GalleryCustom v-if="form.template === galleryTemplates.custom" :params="form.params" @save="onSaveParam"/>
      <GalleryFreePrivate v-if="form.template === galleryTemplates.free_private" :params="form.params" @save="onSaveParam"/>
      <GalleryFreePublic v-if="form.template === galleryTemplates.free_public" :params="form.params" @save="onSaveParam"/>
      <GalleryPaid v-if="form.template === galleryTemplates.paid" :params="form.params" @save="onSaveParam"/>
      <GalleryPresentation v-if="form.template === galleryTemplates.presentation" :params="form.params" @save="onSaveParam"/>
      <GalleryRelease v-if="form.template === galleryTemplates.release" :params="form.params" @save="onSaveParam"/>
    </template>
    <template #footer>
      <CButton @click="close" color="secondary" v-translate>Close</CButton>
      <PButton class="btn btn-primary"
               :loading="loading"
               @click="create"
               color="primary"
               translate-context="event_wizard.gallery.modal" v-translate>
        Create
      </PButton>
    </template>
  </CModal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PButton from "@/components/PButton.vue";
import {ValidationError} from "@/domain/core/exception/exceptions";
import GalleryCustom from "@/domain/eventGallery/templates/GalleryCustom.vue";
import GalleryPaid from "@/domain/eventGallery/templates/GalleryPaid.vue";
import galleryTemplates from "@/domain/core/constant/galleryTemplates";
import GalleryFreePrivate from "@/domain/eventGallery/templates/GalleryFreePrivate.vue";
import GalleryFreePublic from "@/domain/eventGallery/templates/GalleryFreePublic.vue";
import GalleryPresentation from "@/domain/eventGallery/templates/GalleryPresentation.vue";
import GalleryRelease from "@/domain/eventGallery/templates/GalleryRelease.vue";

export default {
  name: "CreateNewGalleryModal",
  components: {
    GalleryRelease,
    GalleryPresentation,
    GalleryFreePublic,
    GalleryFreePrivate,
    GalleryPaid,
    GalleryCustom,
    PButton
  },
  emits: ['created'],
  data() {
    return {
      form: {
        params: {},
        template: galleryTemplates.default(),
      },
      show: false,
      loading: false,
      release: false,
    }
  },
  computed: {
    galleryTemplates() {
      return galleryTemplates
    },
    ...mapGetters('eventGallery', [
      'galleries',
    ]),
    ...mapGetters('event', [
      'picaServiceId',
    ]),
    templates() {
      return galleryTemplates.options(this.release !== null, this.hasPerm('advanced.gallery_configuration')).map(option => ({
        value: option.id,
        label: option.name
      }))
    }
  },
  methods: {
    ...mapActions('eventGallery', [
      'createGallery',
      'setPicaServiceId',
    ]),
    ...mapActions('eventWizard', [
      'fetchEventConfiguration',
    ]),
    open({release}) {
      this.setPicaServiceId(this.picaServiceId)
      if (release) {
        this.release = release
        this.form.template = galleryTemplates.release
        this.form.params = {
          name: this.release.description,
        }
      } else {
        this.release = null
        this.form.template = galleryTemplates.default()
        this.form.params = {}
      }
      this.show = true
    },
    async create() {
      this.loading = true
      try {
        await this.createGallery({
          template: this.form.template,
          params: {
            ...this.form.params,
            release: this.release ? this.release.id : null,
          }
        })
        await this.fetchEventConfiguration()
        await this.notifySuccess(this.$pgettext('event_wizard.gallery.modal.confirm', 'Gallery created'))
        this.$emit('created')
      } catch (e) {
        if (e instanceof ValidationError) {
          await this.notifyError(this.$pgettext('event_wizard.gallery.modal.confirm', 'Error creating gallery'))
          this.loading = false
          return
        } else
          throw e
      }

      this.loading = false
      this.close()
    },
    close() {
      this.show = false
    },
    onSaveParam(data) {
      this.form.params = data
    }
  }
}
</script>


<style scoped>

</style>