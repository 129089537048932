import moment from "moment";

import {translate} from "vue-gettext";
const {gettext: $gettext} = translate;


export default {
    getInfo(timezone) {
        if (!timezone) return null

        let date = null
        if (isNaN(timezone)) {
            date = moment(new Date()).tz(timezone)
        } else {
            date = moment(new Date()).utcOffset(timezone)
        }

        let tzOffset = date.utcOffset()
        const h = $gettext('hours')

        return {
            name: `${Math.sign(tzOffset) === 1 ? '+' : ''}${tzOffset / 60} ${h}`,
            offset: tzOffset
        }
    },
    getBrowserInfo() {
        const date = moment(new Date()).tz(moment.tz.guess())
        let tzOffset = date.utcOffset()
        const h = $gettext('hours')
        return {
            name: `${Math.sign(tzOffset) === 1 ? '+' : ''}${tzOffset / 60} ${h}`,
            offset: tzOffset
        }
    },
    getBrowserDefault() {
        return  Intl.DateTimeFormat().resolvedOptions().timeZone
    }
}