const state = {
    pica_service_id: null,
    checkins: {},
    checkin: null,
    id: null,
}


export const getters = {
    picaServiceId: state => state.pica_service_id,
    checkins: state => state.checkins,
    id: state => state.id,
    checkin: state => state.checkin,
}


export const mutations = {
    setPicaServiceId(state, pica_service_id) {
        state.pica_service_id = pica_service_id
    },
    setCheckins(state, checkins) {
        state.checkins = checkins
    },
    setCheckinId(state, id) {
        state.id = id
    },
    setCheckin(state, checkin) {
        state.checkin = checkin
    }
}


export const actions = service => ({
    setPicaServiceId({commit}, pica_service_id) {
        commit('setPicaServiceId', pica_service_id)
    },
    setCheckinId({commit}, checkin_id) {
        commit('setCheckinId', checkin_id)
    },
    async fetchCheckins({commit,getters}, params) {
        const checkins = await service.fetchCheckins(getters['picaServiceId'],params)
        commit('setCheckins', checkins)
    },
    async exportCheckins({getters}, params) {
        await service.exportCheckins(getters['picaServiceId'],params)
    },
    async fetchCheckin({commit, getters}) {
        const checkin = await service.fetchCheckin(getters['picaServiceId'], getters['id'])
        commit('setCheckin', checkin)
    },
    async updateDateOfStay({commit, getters}, {start_of_stay, end_of_stay}) {
        const checkin = await service.updateDateOfStay(getters['picaServiceId'], getters['id'], {
            start_of_stay,
            end_of_stay
        })
        commit('setCheckin', checkin)
    }
})

export default checkinService => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(checkinService)
})

