export default [
    "18 18",
    '<path d="M12.0883 1.5H16.5V5.91176H12.0883V1.5Z" fill="var(--ci-primary-color, currentColor)"  />\n' +
    '<path d="M6.79403 1.5H11.2058V5.91176H6.79403V1.5Z" fill="var(--ci-primary-color, currentColor)"  />\n' +
    '<path d="M1.5 1.5H5.91174V5.91176H1.5V1.5Z" fill="var(--ci-primary-color, currentColor)"  />\n' +
    '<path d="M1.5 6.79411H5.91174V11.2059H1.5V6.79411Z" fill="var(--ci-primary-color, currentColor)"  />\n' +
    '<path d="M6.79403 6.79411H11.2058V11.2059H6.79403V6.79411Z" fill="var(--ci-primary-color, currentColor)"  />\n' +
    '<path d="M6.79403 12.0882H11.2058V16.5H6.79403V12.0882Z" fill="var(--ci-primary-color, currentColor)"  />\n' +
    '<path d="M1.5 12.0882H5.91174V16.5H1.5V12.0882Z" fill="var(--ci-primary-color, currentColor)"  />\n' +
    '<path d="M12.0883 12.0882H16.5V16.5H12.0883V12.0882Z" fill="var(--ci-primary-color, currentColor)"  />\n' +
    '<path d="M12.0883 6.79411H16.5V11.2059H12.0883V6.79411Z" fill="var(--ci-primary-color, currentColor)"  />'
]

