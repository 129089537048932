<template>
  <div>
    <div v-if="months.length > 0">
      <div class="d-flex justify-content-end mb-3" v-if="canSeeGrossValues">
        <CNav variant="pills" class="chart-pills chart-pills--bordered chart-pills--sm">
          <CNavItem :active="dataType === dataTypes.RW" @click.native="dataType=dataTypes.RW" v-if="canSeeRwValues">RW
          </CNavItem>
          <CNavItem :active="dataType === dataTypes.NET" @click.native="dataType=dataTypes.NET">Net</CNavItem>
          <CNavItem :active="dataType === dataTypes.GROSS" @click.native="dataType=dataTypes.GROSS">Gross</CNavItem>
        </CNav>
      </div>
      <CCard class="chart-card--blue" v-for="month in months" :key="month.month">
        <CCardHeader class="border-bottom-0 py-2">
          <h5 class="my-2">
            {{ month.month }}
            <a href="#" @click.prevent="downloadMonthSummary(month.month_number)" class="ml-2 rounded-icon"
               v-if="canDownloadSummary">
              <CIcon size="custom" width="16" name="cipDownload"/>
            </a>
          </h5>
          <div class="card-header-actions">
            <div class="d-flex align-items-center">
              <template v-if="canSeeTotals">
                <div class="m-0 mr-4 d-flex align-items-center" style="font-size: 15px">
                  <div class="d-flex align-items-center">
                    Total Orders:
                    <span class="font-weight-semi-bold">
                    <template v-if="dataType===dataTypes.GROSS">{{
                        month.total_orders|currency(currencyCode)
                      }}</template>
                    <template v-else-if="dataType===dataTypes.RW">{{
                        month.total_rw_orders|currency(currencyCode)
                      }}</template>
                    <template v-else>{{ month.total_net_orders|currency(currencyCode) }}</template>
                    ({{ month.num_orders|number }})
                  </span>
                    <a href="#" @click.prevent="downloadMonthOrders(month.month_number)" class="ml-2 rounded-icon"
                       v-if="canDownloadOrderExports">
                      <CIcon size="custom" width="16" name="cipDownload"/>
                    </a>
                  </div>
                </div>
                <div class="m-0 mr-4 d-flex align-items-center" style="font-size: 15px">
                  <div class="d-flex align-items-center">Total Refunds:
                    <span class="font-weight-semi-bold">
                    <template v-if="dataType===dataTypes.GROSS">{{
                        month.total_refunds|currency(currencyCode)
                      }}</template>
                    <template v-else-if="dataType===dataTypes.RW">{{
                        month.total_rw_refunds|currency(currencyCode)
                      }}</template>
                    <template v-else>{{ month.total_net_refunds|currency(currencyCode) }}</template>
                    ({{ month.num_refunds|number }})
                  </span>

                    <a href="#" @click.prevent="downloadMonthRefunds(month.month_number)" class="ml-2 rounded-icon"
                       v-if="canDownloadOrderExports">
                      <CIcon size="custom" width="16" name="cipDownload"/>
                    </a>
                  </div>
                </div>
              </template>
              <div class="m-0 mr-3" style="font-size: 15px">
                <div class="d-flex align-items-center">
                  Total Net:
                  <span class="font-weight-semi-bold">
                  <template v-if="dataType===dataTypes.GROSS">{{
                      month.total_orders - month.total_refunds|currency(currencyCode)
                    }}</template>
                  <template v-else-if="dataType===dataTypes.RW">{{
                      month.total_rw_orders - month.total_rw_refunds|currency(currencyCode)
                    }}</template>
                  <template v-else>{{
                      month.total_net_orders - month.total_net_refunds|currency(currencyCode)
                    }}</template>
                </span>

                </div>
              </div>
              <CButton :class="['btn-grid-toggle', 'pr-0', {'on': month._toggled}]" @click="toggle(month)"/>
            </div>
          </div>
        </CCardHeader>
        <CCollapse :show="Boolean(month._toggled)" :duration="collapseDuration">
          <div style="min-height: 100px;border-top: 1px solid #376AE6FF">
            <CCardBody class="p-0">
              <div class="table-responsive" v-if="month.detail.length > 0">
                <table class="table table-striped">
                  <thead style="background-color: #ECF4FF">
                  <tr>
                    <th class="pl-4" v-translate translate-context="consolidate.detail">Created at</th>
                    <th class="pl-4" v-translate translate-context="consolidate.detail">Range date</th>
                    <template v-if="canSeeTotals">
                      <th class="text-right" style="min-width: 180px" v-translate
                          translate-context="consolidate.detail">
                        Orders
                      </th>
                      <th class="text-right" style="min-width: 180px" v-translate
                          translate-context="consolidate.detail">
                        Refund
                      </th>
                    </template>
                    <th class="text-right" style="min-width: 180px"
                        v-if="canSeeRwValues"
                        v-translate
                        translate-context="consolidate.detail">RW
                    </th>
                    <th>&nbsp;</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="detail in month.detail" :key="detail.id">
                    <td class="pl-4">{{ detail.created_at|formatDateTime('L LT', timezone) }}</td>
                    <td class="pl-4">
                      <span v-translate translate-context="consolidate.date_form">From</span>:
                      {{ detail.date_begin|formatDateTime('L LT', timezone) }} -
                      <span v-translate translate-context="consolidate.date_form">To</span>:
                      {{ detail.date_end|formatDateTime('L LT', timezone) }}
                    </td>
                    <template v-if="canSeeTotals">
                      <td class="font-weight-semi-bold text-right">
                        <div class="d-flex align-items-center justify-content-end">
                        <span>
                          <template v-if="dataType===dataTypes.GROSS">{{
                              detail.total_orders|currency(currencyCode)
                            }}</template>
                          <template v-else-if="dataType===dataTypes.RW">{{
                              detail.total_rw_orders|currency(currencyCode)
                            }}</template>
                          <template v-else>{{ detail.total_net_orders|currency(currencyCode) }}</template>
                          ({{ detail.num_orders|number }})
                        </span>
                          <a href="#" @click.prevent="downloadOrders(detail)" class="ml-2 rounded-icon"
                             v-if="canDownloadOrderExports">
                            <CIcon size="custom" width="16" name="cipDownload"/>
                          </a>
                        </div>
                      </td>
                      <td class="font-weight-semi-bold">
                        <div class="d-flex align-items-center justify-content-end">
                        <span>
                          <template v-if="dataType===dataTypes.GROSS">{{
                              detail.total_refunds|currency(currencyCode)
                            }}</template>
                          <template v-else-if="dataType===dataTypes.RW">{{
                              detail.total_rw_refunds|currency(currencyCode)
                            }}</template>
                          <template v-else>{{ detail.total_net_refunds|currency(currencyCode) }}</template>
                          ({{ detail.num_refunds|number }})</span>
                          <a href="#" @click.prevent="downloadRefunds(detail)" class="ml-2 rounded-icon"
                             v-if="canDownloadOrderExports">
                            <CIcon size="custom" width="16" name="cipDownload"/>
                          </a>
                        </div>
                      </td>
                    </template>
                    <td class="font-weight-semi-bold text-right">
                      <template v-if="dataType===dataTypes.GROSS">
                        {{ detail.total_orders - detail.total_refunds|currency(currencyCode) }}
                      </template>
                      <template v-else-if="dataType===dataTypes.RW">
                        {{ detail.total_rw_orders - detail.total_rw_refunds|currency(currencyCode) }}
                      </template>
                      <template v-else>{{
                          detail.total_net_orders - detail.total_net_refunds|currency(currencyCode)
                        }}
                      </template>
                    </td>
                    <td>
                      <a href="#" @click.prevent="downloadSummary(detail)" class="ml-2 rounded-icon"
                         v-if="canDownloadSummary">
                        <CIcon size="custom" width="16" name="cipDownload"/>
                      </a>
                      <template v-if="canDeleteConsolidation && detail.id == deletableId">
                        <a class="rounded-icon" href="#" style="color: #6d6d70"
                           @click.prevent="onDeleteConsolidation(detail)">
                          <CIcon name="cip-trash-full" size="custom" :width="18"/>
                        </a>
                      </template>
                      <template v-else>
                        <span>&nbsp;</span>
                      </template>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="font-weight-semi-bold text-center pt-4" v-else>
                No details available for this month.
              </div>
            </CCardBody>
          </div>
        </CCollapse>
      </CCard>
    </div>
    <div v-else>
      <div class="font-weight-semi-bold text-center pt-4">
        No details available for this year.
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

const dataTypes = {
  RW: 'rw',
  NET: 'net',
  GROSS: 'gross',
}
export default {
  name: "YearTable",
  data() {
    return {
      dataType: dataTypes.NET,
      collapseDuration: 0,
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    canDownloadOrderExports: {
      type: Boolean,
      required: true
    },
    canDeleteConsolidation: {
      type: Boolean,
      required: true
    },
    canSeeTotals: {
      type: Boolean,
      required: true
    },
    canSeeRwValues: {
      type: Boolean,
      required: true
    },
    canSeeGrossValues: {
      type: Boolean,
      required: true
    },
    canDownloadSummary: {
      type: Boolean,
      required: true
    },
    currencyCode: {
      type: String,
      required: true
    },
    timezone: {
      type: String,
      required: true
    },
    deletableId: {
      type: Number,
      default: null
    }
  },
  emits: [
    'download-orders',
    'download-refunds',
    'download-summary',
    'download-month-refunds',
    'download-month-orders',
    'download-month-summary',
    'delete-consolidation',
  ],
  computed: {
    dataTypes() {
      const types = {...dataTypes}
      if (!this.canSeeRwValues)
        delete types.RW
      return types
    },
    months() {
      let months = []
      for (let i = 1; i <= 12; i++) {
        const m = String(i).padStart(2, '0')
        let data = this.data[m] ?? []
        data = data.map(consolidation => ({
          ...consolidation,
          total_rw_orders: consolidation.total_net_orders * 1.16,
          total_rw_refunds: consolidation.total_net_refunds * 1.16
        }))
        months.push({
          month: this.getMonthName(i),
          month_number: i,
          detail: data,
          // gross values
          total_orders: data.reduce((acc, d) => parseFloat(acc) + parseFloat(d.total_orders), 0),
          total_refunds: data.reduce((acc, d) => parseFloat(acc) + parseFloat(d.total_refunds), 0),
          // net values
          total_net_orders: data.reduce((acc, d) => parseFloat(acc) + parseFloat(d.total_net_orders), 0),
          total_net_refunds: data.reduce((acc, d) => parseFloat(acc) + parseFloat(d.total_net_refunds), 0),
          // rw values
          total_rw_orders: data.reduce((acc, d) => parseFloat(acc) + parseFloat(d.total_rw_orders), 0),
          total_rw_refunds: data.reduce((acc, d) => parseFloat(acc) + parseFloat(d.total_rw_refunds), 0),

          num_orders: data.reduce((acc, d) => parseFloat(acc) + parseFloat(d.num_orders), 0),
          num_refunds: data.reduce((acc, d) => parseFloat(acc) + parseFloat(d.num_refunds), 0)
        })
      }
      //remove months with no data
      return months.filter(m => m.detail.length > 0)
    },
  },
  mounted() {
    this.dataType = Object.values(this.dataTypes)[0]
  },
  methods: {
    getMonthName(month) {
      month = moment().month(month - 1).format('MMMM')
      return month.charAt(0).toUpperCase() + month.slice(1);
    },
    downloadOrders(detail) {
      this.$emit('download-orders', detail.id)
    },
    downloadRefunds(detail) {
      this.$emit('download-refunds', detail.id)
    },
    downloadSummary(detail) {
      this.$emit('download-summary', detail.id)
    },
    downloadMonthOrders(month) {
      this.$emit('download-month-orders', month)
    },
    downloadMonthRefunds(month) {
      this.$emit('download-month-refunds', month)
    },
    downloadMonthSummary(month) {
      this.$emit('download-month-summary', month)
    },
    onDeleteConsolidation(detail) {
      this.$emit('delete-consolidation', detail.id)
    },
    async toggle(item) {
      this.$set(item, "_toggled", !item._toggled)

      this.collapseDuration = 300
      await this.$nextTick()
      this.collapseDuration = 0
    }
  }
}
</script>