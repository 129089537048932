import axiosRetry from 'axios-retry'

export default {
    async download(client, url, file_name = null, retryDelay = 4000) {
        // NOTE: This is NOT the way to do it, we are downloading the file in memory and then creating a link to it
        // Since the download is a stream, we should allow the browser to download it directly, the problem is that we need to create
        // a single-use jwt/token to allow for the authenticated download without requiring a header
        // for now we are accepting that the file will be relatively small and that this is not a big problem

        const response = await client.axios.get(url, {
            responseType: 'blob',
            // override retry policy as download needs more time before retry
            'axios-retry': {
                retries: 3,
                retryDelay: (retryNumber) => Math.pow(2, retryNumber) * retryDelay,
                retryCondition: axiosRetry.isNetworkOrIdempotentRequestError
            }
        })
        this.downloadFileFromResponse(response, file_name)
    },

    downloadFileFromResponse(response, file_name) {
        // NOTE: The request must have "{responseType: 'blob'}" in order to work
        const type = response.headers['content-type']
        const blob = new Blob([response.data], {type: type})
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)

        if (file_name === null || file_name === undefined) {
            const headerLine = response.headers['content-disposition']
            file_name = headerLine.split('filename=')[1].replace(/"/g, '')
        }

        link.download = file_name
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    },

    downloadFile(data, fileName) {
        const url = window.URL.createObjectURL(new Blob([data]))
        this.downloadFromUrl(url, fileName)
    },

    downloadFromUrl(url, fileName) {
        let link = document.createElement('a')

        link.href = url
        if (fileName)
            link.download = fileName
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}