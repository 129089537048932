import {translate} from 'vue-gettext'

const {gettext: $gettext} = translate

export default [
    {
        _name: 'CSidebarNavTitle',
        _children: [$gettext('Administration')],
        perms: [
            'orders.list',
            'pica.view_photoservice',
            'dashboard.change_event_turnover',
            'invitation.list_invitations',
            'contract_manager.list',
            'dashboard.impersonate',
        ]
    },
    {
        _name: 'CSidebarNavItem',
        name: $gettext('Orders'),
        to: '/manage/orders',
        icon: 'cip-cart',
        perms: ['orders.list']
    },
    {
        _name: 'CSidebarNavItem',
        name: $gettext('Photo services'),
        to: '/manage/photo-services',
        icon: 'cip-building',
        perms: ['pica.view_photoservice']
    },
    {
        _name: "CSidebarNavItem",
        name: $gettext("Events Data Entry"),
        to: "/manage/events_data_entry",
        icon: "cip-text-input",
        perms: ["dashboard.change_event_turnover"],
    },
    {
        _name: "CSidebarNavItem",
        name: $gettext("Invites"),
        to: {name: 'invitations.list'},
        icon: "cip-add-user",
        perms: ["invitation.list_invitations"],
    },
    {
        _name: "CSidebarNavItem",
        name: $gettext("Contract Manager"),
        to: {name: 'contract_manager.list'},
        icon: "cip-heart-hand",
        perms: ["contract_manager.list"],
    },
    {
        _name: "CSidebarNavItem",
        name: $gettext("Impersonate"),
        to: {name: 'impersonate.users'},
        icon: "cip-glasses",
        perms: ["dashboard.impersonate"],
    },
]