const state = {
    timeZones: [],
    currencies: [],
    tags: [],
    recognitionClasses: [],
    clusterSizes: [],
    categories: [],
    codecs: [],
    checkinStayMode: [],
    regions: [],
    orderStatus: [],
    paymentMethods: [],
    anonymizeMode: [],
    shareMode: [],
    experienceType: [],
    purchaseTime: [],
    hdStorageTypes: [],
    hdServingTypes: [],
    sdServingTypes: [],
}

const mapToLabelValue = (items) => items.map(item => ({
    label: item.label,
    value: item.id
}))


export const getters = {
    timeZones: state => state.timeZones,
    currencies: state => mapToLabelValue(state.currencies),
    clusterSizes: state => mapToLabelValue(state.clusterSizes),
    recognitionClasses: state => mapToLabelValue(state.recognitionClasses),
    categories: state => mapToLabelValue(state.categories),
    codecs: state => mapToLabelValue(state.codecs),
    checkinStayMode: state => mapToLabelValue(state.checkinStayMode),
    regions: state => mapToLabelValue(state.regions),
    orderStatus: state => state.orderStatus,
    paymentMethods: state => state.paymentMethods,
    anonymizeMode: state => mapToLabelValue(state.anonymizeMode),
    shareMode: state => mapToLabelValue(state.shareMode),
    experienceType: state => mapToLabelValue(state.experienceType),
    purchaseTime: state => mapToLabelValue(state.purchaseTime),
    hdStorageTypes: state => mapToLabelValue(state.hdStorageTypes),
    hdServingTypes: state => mapToLabelValue(state.hdServingTypes),
    sdServingTypes: state => mapToLabelValue(state.sdServingTypes),
}


export const mutations = {
    resetCache(state) {
        state.timeZones = []
        state.currencies = []
        state.tags = []
        state.recognitionClasses = []
        state.clusterSizes = []
        state.categories = []
        state.codecs = []
        state.checkinStayMode = []
        state.regions = []
        state.orderStatus = []
        state.paymentMethods = []
        state.anonymizeMode = []
        state.shareMode = []
        state.experienceType = []
        state.purchaseTime = []
        state.hdStorageTypes = []
        state.hdServingTypes = []
        state.sdServingTypes = []
    },
    setTimeZones(state, timeZones) {
        state.timeZones = timeZones
    },
    setCurrencies(state, currencies) {
        state.currencies = currencies
    },
    recognitionClasses(state, recognitionClasses) {
        state.recognitionClasses = recognitionClasses
    },
    clusterSizes(state, clusterSizes) {
        state.clusterSizes = clusterSizes
    },
    categories(state, categories) {
        state.categories = categories
    },
    codecs(state, codecs) {
        state.codecs = codecs
    },
    checkinStayMode(state, checkinStayMode) {
        state.checkinStayMode = checkinStayMode
    },
    regions(state, regions) {
        state.regions = regions
    },
    orderStatus(state, orderStatus) {
        state.orderStatus = orderStatus
    },
    paymentMethods(state, paymentMethods) {
        state.paymentMethods = paymentMethods
    },
    anonymizeMode(state, anonymizeMode) {
        state.anonymizeMode = anonymizeMode
    },
    shareMode(state, shareMode) {
        state.shareMode = shareMode
    },
    experienceType(state, experienceType) {
        state.experienceType = experienceType
    },
    purchaseTime(state, purchaseTime) {
        state.purchaseTime = purchaseTime
    },
    hdStorageTypes(state, hdStorageTypes) {
        state.hdStorageTypes = hdStorageTypes
    },
    hdServingTypes(state, hdServingTypes) {
        state.hdServingTypes = hdServingTypes
    },
    sdServingTypes(state, sdServingTypes) {
        state.sdServingTypes = sdServingTypes
    },
}


export const actions = (tagService, coreService) => ({
    fetchTimeZones: async function ({commit, getters}) {
        if (getters.timeZones.length) return
        commit('setTimeZones', await coreService.fetchTimezones())
    },
    fetchCurrencies: async function ({commit, getters}) {
        if (getters.currencies.length) return
        commit('setCurrencies', await coreService.fetchCurrencies())
    },
    // eslint-disable-next-line no-unused-vars
    autocompleteTags: async function ({commit}, query) {
        if (typeof query === 'object') {
            const {query: q, category} = query
            return await tagService.autocompleteTags(q, category)
        }
        return await tagService.autocompleteTags(query)
    },
    async fetchRecognitionClasses({commit, getters}) {
        if (getters['recognitionClasses'].length) return
        commit('recognitionClasses', await coreService.fetchRecognitionClasses())
    },
    async fetchClusterSizes({commit, getters}) {
        if (getters['clusterSizes'].length) return
        commit('clusterSizes', await coreService.fetchClusterSizes())
    },
    async fetchCategories({commit, getters}) {
        if (getters['categories'].length) return
        commit('categories', await coreService.fetchCategories())
    },
    async fetchCodecs({commit, getters}) {
        if (getters['codecs'].length) return
        commit('codecs', await coreService.fetchCodecs())
    },
    async fetchCheckinStayMode({commit, getters}) {
        if (getters['checkinStayMode'].length) return
        commit('checkinStayMode', await coreService.fetchCheckinStayMode())
    },
    async fetchRegions({commit, getters}) {
        if (getters['regions'].length) return
        commit('regions', await coreService.fetchRegions())
    },
    async fetchOrderStatus({commit, getters}) {
        if (getters['orderStatus'].length) return
        commit('orderStatus', await coreService.fetchOrderStatus())
    },
    async fetchPaymentMethods({commit, getters}) {
        if (getters['paymentMethods'].length) return
        commit('paymentMethods', await coreService.fetchPaymentMethods())
    },
    async fetchAnonymizeMode({commit, getters}) {
        if (getters['anonymizeMode'].length) return
        commit('anonymizeMode', await coreService.fetchAnonymizeMode())
    },
    async fetchShareMode({commit, getters}) {
        if (getters['shareMode'].length) return
        commit('shareMode', await coreService.fetchShareMode())
    },
    async fetchExperienceType({commit, getters}) {
        if (getters['experienceType'].length) return
        commit('experienceType', await coreService.fetchExperienceType())
    },
    async fetchPurchaseTime({commit, getters}) {
        if (getters['purchaseTime'].length) return
        commit('purchaseTime', await coreService.fetchPurchaseTime())
    },
    async fetchHdStorageTypes({commit, getters}) {
        if (getters['hdStorageTypes'].length) return
        commit('hdStorageTypes', await coreService.fetchHdStorageTypes())
    },
    async fetchHdServingTypes({commit, getters}) {
        if (getters['hdServingTypes'].length) return
        commit('hdServingTypes', await coreService.fetchHdServingTypes())
    },
    async fetchSDServingTypes({commit, getters}) {
        if (getters['sdServingTypes'].length) return
        commit('sdServingTypes', await coreService.fetchSdServingTypes())
    },
    resetCache({commit}) {
        commit('resetCache')
    }
})

export default (tagService, coreService) => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(tagService, coreService)
})