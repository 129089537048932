export default [
    "20 20",
    '<g clip-path="url(#clip0_1099_421)">\n' +
    '<path d="M9.99998 12.3297V7.32967M7.49998 9.82967H12.5M16.6666 10.2463C16.6666 14.3367 12.205 17.3117 10.5816 18.2587C10.3971 18.3664 10.3049 18.4202 10.1747 18.4481C10.0737 18.4698 9.92628 18.4698 9.82525 18.4481C9.69506 18.4202 9.60281 18.3664 9.41832 18.2587C7.79495 17.3117 3.33331 14.3367 3.33331 10.2463V6.261C3.33331 5.59474 3.33331 5.26161 3.44228 4.97525C3.53854 4.72228 3.69497 4.49656 3.89803 4.31761C4.12789 4.11503 4.43981 3.99806 5.06365 3.76412L9.53181 2.08856C9.70506 2.02359 9.79168 1.99111 9.8808 1.97823C9.95984 1.96681 10.0401 1.96681 10.1192 1.97823C10.2083 1.99111 10.2949 2.02359 10.4681 2.08856L14.9363 3.76412C15.5601 3.99806 15.8721 4.11503 16.1019 4.31761C16.305 4.49656 16.4614 4.72228 16.5577 4.97525C16.6666 5.26161 16.6666 5.59474 16.6666 6.261V10.2463Z" fill="none" class="stroke-over" stroke="var(--ci-primary-color, currentColor)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '</g>\n' +
    '<defs>\n' +
    '<clipPath id="clip0_1099_421">\n' +
    '<rect width="20" height="20"  fill="var(--ci-primary-color, currentColor)" transform="translate(0 0.246338)"/>\n' +
    '</clipPath>\n' +
    '</defs>']

