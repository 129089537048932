<template>
  <div>
    <CCard>
      <CCardHeader>
        Search Order Item
      </CCardHeader>
      <CCardBody class="p-0">
        <CContainer fluid class="grid-stripped">
          <PFRow :label="$pgettext('refund.label','E-Mail')">
            <CInput v-model="email" :placeholder="$pgettext('refund.placeholder','User E-Mail')" @keyup="onSearch"
                    @change="onSearch"/>
          </PFRow>
          <PFRow :label="$pgettext('refund.label','Order Number')">
            <CInput v-model="order_number" :placeholder="$pgettext('refund.placeholder','Order Number')"
                    @keyup="onSearch" @change="onSearch"/>
          </PFRow>
          <PFRow :label="$pgettext('refund.label','PicaCode')">
            <CInput v-model="picacode" :placeholder="$pgettext('refund.placeholder','Pica Code')" @keyup="onSearch"
                    @change="onSearch"/>
          </PFRow>
        </CContainer>
      </CCardBody>
    </CCard>
    <CCard v-if="results.length > 0">
      <CCardBody class="p-0">
        <CContainer fluid class="grid-stripped">
          <CRow v-for="item in results" :key="item.id">
            <span @click="openOrderPage(item)">
              Order: {{ item.order_id }} | {{ item.order_number }}
            </span><br>
            OrderItemID: {{ item.id }}<br>
            Name: {{ item.event_name }}<br>
            User: {{ item.user_email }}<br>
            PicaCode:{{ item.picacode }}<br>
            Amount:{{ item.price }} {{ item.currency }}
            <CButton @click="onSelect(item)">Select</CButton>
          </CRow>
        </CContainer>
      </CCardBody>
    </CCard>
  </div>

</template>

<script>
import PFRow from "@/domain/core/components/PFRow.vue";
import {mapActions} from "vuex";
import {refundMethods} from "@/domain/refund/methods";

export default {
  name: "SearchOrderItem",
  components: {PFRow},
  data() {
    return {
      email: '',
      order_number: '',
      picacode: '',
      results: []
    }
  },
  async mounted() {
    this.onReset()
  },
  emits: ['select'],
  methods: {
    ...mapActions('refund', [
      'searchOrderItem',
    ]),
    onReset() {
      this.email = ''
      this.order_number = ''
      this.picacode = ''
      this.results = []
    },
    async onSearch() {
      this.results = await this.searchOrderItem({
        email: this.email,
        order_number: this.order_number,
        picacode: this.picacode
      })
    },
    async onSelect(item) {
      this.$emit('select', item)
    },
    ...refundMethods
  }
}
</script>

<style scoped>

</style>