const state = {
    tours: JSON.parse(localStorage.getItem('tours')) || {},
}

export const getters = {
    isTourViewed: (state) => (name) => {
        return state.tours[name] || false
    },
}

const mutations = {
    setTourViewed(state, name) {
        state.tours[name] = true
        localStorage.setItem('tours', JSON.stringify(state.tours))
    },
}

export const actions = {
    setTourViewed({commit}, name) {
        commit('setTourViewed', name)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}