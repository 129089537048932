export class ApiException extends Error {

    constructor(url, status, message) {
        super(`${url} returned ${status} error with message ${message}`);
        this.url = url;
        this.status = status;
        this.message = message;
    }
}

export class RecordNotFound extends ApiException {
    constructor(url, status, message) {
        super(url, status, message);
    }
}

export class PermissionDenied extends ApiException {
    constructor(url, status, message) {
        super(url, status, message);
    }
}

export class ValidationError extends ApiException {
    constructor(url, status, message) {
        super(url, status, message)
    }

    getCode() {
        return this.message.code
    }

    getData() {
        return this.message.error_data
    }

    getPrettyMessage() {
        let errors = []
        for (const [key, value] of Object.entries(this.message)) {
            if (Array.isArray(value)) {
                value.forEach((item) => {
                    if (typeof item === 'object')
                        errors.push(`${key}: ${this.getPrettyMessage(item)}`)
                    else if (typeof item === 'string')
                        errors.push(`${key}: ${item}`)
                })
            } else if (typeof value === 'object')
                errors.push(`${key}: ${this.getPrettyMessage(value)}`)
            else if (typeof value === 'string')
                errors.push(`${key}: ${value}`)
        }
        return errors.join('<br>')
    }

    getAsDict(message) {
        if (message === undefined)
            message = this.message

        let errors = {}
        for (const [key, value] of Object.entries(message)) {
            if (Array.isArray(value)) {
                value.forEach((item) => {
                    if (typeof item === 'object') {
                        let subs = this.getAsDict(item)
                        for (const [subKey, value] of Object.entries(subs))
                            errors[key+'.'+subKey] = value
                    }
                    else if (typeof item === 'string')
                        errors[key] = item
                })
            } else if (typeof value === 'object') {
                let subs = this.getAsDict(value)
                for (const [subKey, value] of Object.entries(subs))
                    errors[key+'.'+subKey] = value
            }
            else if (typeof value === 'string')
                errors[key] = value
        }
        return errors
    }
}

export class Notification extends Error {

    constructor(message, notification_type) {
        super(message);
        this.type = notification_type;
        this.message = message;
    }
}


export class SuccessNotification extends Notification {
    constructor(message) {
        super(message, 'success')
    }
}

export class DangerNotification extends Notification {
    constructor(message) {
        super(message, 'danger')
    }
}

export class ErrorNotification extends Notification {
    constructor(message) {
        super(message, 'danger')
    }
}