import {ApiException} from "@/domain/core/exception/exceptions";
import {emptyListResponse} from "@/domain/core/utils/django";


const state = {
    categories: {...emptyListResponse},
    questions: {...emptyListResponse},
    category: {},
    question: {},
}

export const getters = {
    categories: state => state.categories,
    category: state => state.category,
    questions: state => state.questions,
    question: state => state.question
}


export const mutations = {
    categories(state, categories) {
        state.categories = categories
    },
    category(state, category) {
        state.category = category
    },
    deleteCategory(state, id) {
        state.categories.results = state.categories.results.filter(category => category.id !== id)
    },
    questions(state, questions) {
        state.questions = questions
    },
    question(state, question) {
        state.question = question
    },
    deleteQuestion(state, id) {
        state.questions.results = state.questions.results.filter(question => question.id !== id)
    }
}


export const actions = faqService => ({
    async fetchCategoryList({commit}, {filters, pagination, sorting}) {
        try {
            const categories = await faqService.fetchCategoryList(filters, pagination, sorting)
            commit('categories', categories)
        } catch (e) {
            if (e instanceof ApiException) {
                commit('categories', emptyListResponse)
            }
            throw e
        }
    },
    async deleteCategory({commit}, id) {
        await faqService.deleteCategory(id)
        commit('deleteCategory', id)
    },
    async fetchCategory({commit}, id) {
        const category = await faqService.fetchCategory(id)
        commit('category', category)
    },
    async createCategory({commit}, data) {
        const category = await faqService.createCategory(data)
        commit('category', category)
    },
    async updateCategory({commit}, {id, data}) {
        const category = await faqService.updateCategory(id, data)
        commit('category', category)
    },
    async fetchQuestionList({commit}, {category, filters, pagination, sorting}) {
        try {
            const questions = await faqService.fetchQuestionList(category, filters, pagination, sorting)
            commit('questions', questions)
        } catch (e) {
            if (e instanceof ApiException) {
                commit('questions', emptyListResponse)
            }
            throw e
        }
    },
    async deleteQuestion({commit}, id) {
        await faqService.deleteQuestion(id)
        commit('deleteQuestion', id)
    },
    async fetchQuestion({commit}, id) {
        const question = await faqService.fetchQuestion(id)
        commit('question', question)
    },
    async createQuestion({commit}, data) {
        const question = await faqService.createQuestion(data)
        commit('question', question)
    },
    async updateQuestion({commit}, {id, data}) {
        const question = await faqService.updateQuestion(id, data)
        commit('question', question)
    }
})

export default faqService => ({
    namespaced: true,
    state,
    getters,
    mutations,
    actions: actions(faqService)
})

