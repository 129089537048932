import ApiClient from "@/domain/core/utils/apiClient";
import client_core from "@/services/clients/client_core";

const client = new ApiClient(client_core)

const endpoints = {
    create: `/dashboard/v2/user/register/`,
    login: `/dashboard/v2/user/login/`,
    token_auth: '/dashboard/v1/auth/?include_jwt=true',
    convert_token: '/auth/convert-token/?include_jwt=true',
    jwt: '/v2/user/jwt/',
    user: '/dashboard/v1/users/me/?include_perms=true&include_groups=true',
    change_password: '/api/v1/password/change/',
    invitation_validate: '/dashboard/v2/invitation/validate_code/',
    invitation_accept: '/dashboard/v2/invitation/accept/',
    config: '/dashboard/v2/config/{type}/?id={id}',
    resend_registration_token: '/dashboard/v2/user/resend_registration_token/',
    validate_registration_code: '/dashboard/v2/user/validate_registration_token/',
}

export default {
    async createUser(email, password) {
        return await client.post(endpoints.create, {email, password})
    },
    async getAuthToken(username, password, allowRegister) {
        return await client.post(endpoints.login, {username, password, allow_register: allowRegister})
    },
    async convertAuthToken(payload) {
        return await client.post(endpoints.convert_token, payload)
    },
    async getUserData() {
        return await client.get(endpoints.user, {
            includePerms: true,
            includeGroups: true
        })
    },
    async changePassword(password, passwordRepeat) {
        await client.post(endpoints.change_password, {
            'new_password1': password,
            'new_password2': passwordRepeat,
        })
    },
    async changeUserData({first_name, last_name, language, timezone}) {
        await client.patch(endpoints.user, {
            first_name,
            last_name,
            language,
            timezone
        })
    },
    async validateInvitation(code, type) {
        return await client.get(endpoints.invitation_validate, {
            code:code,
            type: type
        })
    },
    async acceptInvitation(code, type) {
        return await client.post(endpoints.invitation_accept, {
            code: code,
            type: type,
        })
    },
    async getJwt() {
        const response = await client.get(endpoints.jwt)
        return response.jwt
    },
    async getConfig(type, id) {
        return await client.get(endpoints.config.replace('{type}', type).replace('{id}',id))
    },
    async resendValidationCode(email) {
        return await client.post(endpoints.resend_registration_token, {email})
    },
    async validateCode(email, code) {
        return await client.post(endpoints.validate_registration_code, {email, token: code})
    }
}
