import moment from 'moment-timezone'
import 'moment/locale/en-ca'
import 'moment/locale/it'
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/de'
import 'moment/locale/sl'
import Vue from 'vue'
import client_core from "@/services/clients/client_core";

const setLocale = function (language, timezone) {
    client_core.defaults.headers.common['Accept-Language'] = language
    moment.locale(language)
    moment.tz.setDefault(timezone)
    Vue.config.language = language
}

export { setLocale }