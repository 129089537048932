<template>
  <div
      v-if="!isDraft && hasAnyPerms(['dashboard.view_feature_search_photos','pica.upload_from_mobile','pica.upload_medias_from_videos','dashboard.view_feature_tag_photos'])">
    <CSidebarNavTitle v-translate>Media</CSidebarNavTitle>
    <CSidebarNavItem
        :name="$gettext('Search photos')"
        :to="{name: 'MediaSearch', params: {picaServiceId: picaServiceId}}"
        icon="cip-search"
        v-if="hasAnyPerms('dashboard.view_feature_search_photos')"
    />
    <CSidebarNavItem
        :name="$gettext('Manual tag')"
        :to="{name: 'EventTagging', params: {picaServiceId: picaServiceId}}"
        icon="cip-tag"
        v-if="hasAnyPerms('dashboard.view_feature_tag_photos')"
    />
    <CSidebarNavItem
        :name="$gettext('Photo Map')"
        :to="{name: 'photo-map', params: {picaServiceId: picaServiceId}}"
        icon="cip-upload"
        :badge="{'text': 'BETA', 'color': 'warning', 'shape': 'pill'}"
        v-if="hasPerms(['dashboard.view_feature_beta']) && showPhotoMap"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'MediaNav',
  computed: {
    ...mapGetters('event', [
      'picaServiceId',
      'allowsUploadFromMobile',
      'isDraft'
    ]),
    ...mapGetters('eventWizard', [
      'showPhotoMap',
      'isUploaderAvailable'
    ]),
  }
}
</script>
