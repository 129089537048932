import ApiClient from "@/domain/core/utils/apiClient";
import client_metrics from "@/services/clients/client_metrics";
import client_core from "@/services/clients/client_core";

const metrics = new ApiClient(client_metrics)
const core = new ApiClient(client_core)

const core_endpoints = {
    configuration: '/dashboard/v2/stats/pica-services/{id}/metrics/configuration/',
    get_potential_active_users: '/dashboard/v2/stats/pica-services/{id}/metrics/potential_active_users/',
}

const metrics_endpoints = {
    group_by_type: '/stats/{id}/groupByType',
    most_shared_media: '/stats/{id}/mostSharedMedia',
    influencer_most_viewed: '/stats/{id}/influencer/mostViewed',
    group_by_share_type: '/stats/{id}/groupByShareType',
    share_link_by_share_type: '/stats/{id}/shareLinkByShareType',
    get_active_users: '/stats/{id}/activeUsers',
    export_shared_media: '/exports/{id}/sharedMedia',
}

export default {
    async getConfiguration(eventId) {
        return await core.get(core_endpoints.configuration.replace('{id}', eventId))
    },
    async getGroupByType(eventId, from, to, timeZone, countBy, types) {
        return  await metrics.get(metrics_endpoints.group_by_type.replace('{id}', eventId), {
            from: from,
            to: to,
            tz: timeZone,
            countBy: countBy,
            types: types
        })
    },
    async getMostSharedMedia(eventId, from, to, timeZone, num) {
        return  await metrics.get(metrics_endpoints.most_shared_media.replace('{id}', eventId), {
            from: from,
            to: to,
            tz: timeZone,
            num: num
        })
    },
    async getInfluencerMostViewed(eventId, from, to, timeZone, num) {
        return  await metrics.get(metrics_endpoints.influencer_most_viewed.replace('{id}', eventId), {
            from: from,
            to: to,
            tz: timeZone,
            num: num
        })
    },
    async getGroupByShareType(eventId, from, to, timeZone, countBy) {
        return await metrics.get(metrics_endpoints.group_by_share_type.replace('{id}', eventId), {
            from: from,
            to: to,
            tz: timeZone,
            countBy: countBy,
        })
    },
    async getShareLinkByShareType(eventId, from, to, timeZone, countBy) {
        return await metrics.get(metrics_endpoints.share_link_by_share_type.replace('{id}', eventId), {
            from: from,
            to: to,
            tz: timeZone,
            countBy: countBy,
        })
    },
    async getActiveUsers(eventId, from, to, timeZone) {
        const potential = await core.get(core_endpoints.get_potential_active_users.replace('{id}', eventId))
        const actual = await metrics.get(metrics_endpoints.get_active_users.replace('{id}', eventId), {
            from: from,
            to: to,
            tz: timeZone,
        })
        return {
            potential: potential,
            actual: actual
        }
    },
    async downloadSharedMedia(eventId, timeZone) {
        return await metrics.get(metrics_endpoints.export_shared_media.replace('{id}', eventId), {
            tz: timeZone,
        })
    }
}