<template>
  <div>
    <PFRow :label="$pgettext('event_wizard.gallery.modal.label','Name')" size="12x12">
      <CInput v-model="form.name" :placeholder="$pgettext('event_wizard.gallery.modal.placeholder','Gallery name')"
              @input="onChange"/>
    </PFRow>
    <PFRow size="7x5" :label="$pgettext('event_gallery.label', 'Show media of other people?')">
      <CSwitch class="mx-1" color="primary" variant="3d" size="sm"
               :checked.sync="form.show_all" @input="onChange"/>
    </PFRow>
        <PFRow size="7x5" :label="$pgettext('event_gallery.label', 'Do you want to separate photo from video?')">
      <CSwitch class="mx-1" color="primary" variant="3d" size="sm"
               :checked.sync="form.separate_video" @input="onChange"/>
    </PFRow>
  </div>
</template>

<script>

import PFRow from "@/domain/core/components/PFRow.vue";

export default {
  name: "GalleryFreePrivate",
  components: {PFRow},
  emits: ['save'],
  data() {
    return {
      form: {
        name: null,
        show_all: false,
        separate_video: false,
      },
    }
  },
  methods: {
    onChange() {
      this.$emit('save', this.form);
    }
  }
}
</script>
