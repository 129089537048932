const SearchUsers = () => import('./SearchUser.vue')
const UserDetail = () => import('./UserDetail.vue')
const TheContainer = () => import('@/containers/TheContainer')

export default [
    {
        path: '/customer_success',
        name: 'customer_success',
        component: TheContainer,
        children: [
            {
                path: 'search',
                name: 'customer_success.search',
                component: SearchUsers,
                meta: {label: 'Search Users',},
            },
            {
                path: 'users/:id',
                name: 'customer_success.user',
                component: UserDetail,
                meta: {label: 'User Info',},
            },
        ]
    }
]