<template>
  <AjaxTable
      :fields="fields"
      :data="roles"
      :fetch="fetchRoles"
  >
    <template #actions="{item}">
      <td>
        <CButton variant="outline" color="primary" class="mr-2"
                 v-if="hasPerm('contract_manager.role.update')"
                 @click="onEdit(item.id)"
                 v-translate translate-context="list.actions"
        >
          Edit
        </CButton>
        <CButton @click="onDelete(item.id)" variant="outline" color="danger"
                 v-if="hasPerm('contract_manager.role.delete')"
                 v-translate translate-context="list.actions"
        >
          Delete
        </CButton>
      </td>
    </template>
  </AjaxTable>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AjaxTable from "@/domain/core/components/AjaxTable.vue";

export default {
  name: "Roles",
  components: {AjaxTable},
  data() {
    return {
      fields: [
        {key: 'name', label: 'Name'},
        {key: 'description', label: 'Description'},
        {key: 'actions', label: 'Actions', sorter: false, filter: false}
      ],
    }
  },
  computed: {
    ...mapGetters('contractManager', [
      'roles'
    ]),
  },
  emits: ['edit'],
  methods: {
    ...mapActions('contractManager', [
      'deleteRole',
      'fetchRoles'
    ]),
    async onDelete(id) {
      await this.deleteRole(id)
    },
    async onEdit(id) {
      this.$emit('edit', id)
    }
  }
}
</script>