<template>
  <CModal :show.sync="show" centered>
    <template #header>
      <div>
        <div class="d-flex">
          <CIcon name="cipSignal" size="custom-size" width="36" height="36"
                 class="mr-3 icon-circle icon-circle--primary"/>
          <div class="align-self-center">
            <h5 class="modal-title" v-translate translate-context="event.modal.create_new_event.title">
              Activate event</h5>
            <div v-translate translate-context="event.modal.create_new_event.description">
              Review your settings and enable the event
            </div>
          </div>
        </div>
      </div>
      <CButtonClose @click="show = false"/>
    </template>
    <PComponentLoader :loading="loading">
      <template v-if="!allMandatoryIsPresent">
        <Suggestions :suggestions="suggestions"/>
      </template>
      <template v-else-if="configuration.is_in_payment">
        <div v-translate translate-context="event.modal.publish.info">
          Processing Payment, please wait...
        </div>
      </template>
      <template v-else-if="configuration.require_payment">
        <PlanOptionsSelector
            :options="planOptionsSorted"
            :selected="planOptionId"
            @input="onSelectPlanOption"
            v-if="planOptionsSorted.length > 0"
        />
        <div class="font-weight-semi-bold mb-2">
          <CIcon name="cipSetting02" size="custom-size" width="16" class="mr-1" style="vertical-align: sub"/>
          <translate translate-context="event.modal.create_new_event.plan_options_selector">
            Review your settings
          </translate>
        </div>
        <div class="event-recap mb-3">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex">
              <div class="label mr-2" v-translate translate-context="event.modal.publish.start_date">Start date</div>
              <div class="value">{{ currentEventStartDate|formatDate('L') }}</div>
            </div>
            <div>
              <CButton color="secondary" size="sm" @click="onEdit">
                <CIcon name="cipPencilLine" size="custom-size" width="15"/>
                <translate>Edit</translate>
              </CButton>
            </div>
          </div>
          <hr>
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex">
              <div class="label mr-2" v-translate translate-context="event.modal.publish.timezone">Timezone</div>
              <div class="value">{{ timeZone }}</div>
            </div>
            <div>
              <CButton color="secondary" size="sm" @click="onEdit">
                <CIcon name="cipPencilLine" size="custom-size" width="15"/>
                <translate>Edit</translate>
              </CButton>
            </div>
          </div>
        </div>
        <PAlert icon="cipInfo" color="info" class="mb-4">
          <template>
            <div v-translate translate-context="event.modal.publish.info">
              After activation you will no longer be able to change the date and time zone of the event
            </div>
          </template>
        </PAlert>
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <CIcon name="cipBuilding02" size="custom-size" width="16" class="mr-1" style="vertical-align: sub"/>
            <span class="font-weight-semi-bold" v-translate
                  translate-context="event.modal.create_new_event.plan_options_selector">
            Are you a company?
          </span>&nbsp;
            <span v-translate translate-context="event.modal.create_new_event.plan_options_selector">
            Request an invoice
          </span>
          </div>
           <CSwitch class="mx-1" color="primary" variant="3d" size="sm"
                   :checked.sync="createInvoice"/>
        </div>
      </template>
      <template v-else>
        All ok, ready to publish!
        <Suggestions :suggestions="suggestions"/>
      </template>
    </PComponentLoader>
    <template #footer>
      <CButton color="secondary" @click="show = false" v-translate>Close</CButton>
      <template v-if="!allMandatoryIsPresent">
          <PButton
              color="primary" class="mr-3"
              @click="onEdit">
            <translate>Edit</translate>
          </PButton>
        <PButton
            v-if="hasPerm('advanced.force_publish')"
              color="danger" class="mr-3"
              @click="onPublish(true)">
            <translate>Force</translate>
          </PButton>
      </template>
      <template v-else-if="configuration.require_payment">
        <template v-if="planPrice > 0">
          <PButton
              color="primary" class="mr-3"
              :disabled="!planOptionId"
              v-if="!configuration.is_in_payment"
              @click="onPayment">
            <translate translate-context="event.modal.publish.info">Activate for</translate>
            {{ planPrice|currency(paymentCurrency) }}
          </PButton>

          <PButton
              color="secondary" v-translate translate-context="event.modal.publish.actions" class="mr-3"
              v-if="hasPerm('advanced.bypass_payment')"
              @click="onPublish">
            Publish - Wired Transfer
          </PButton>
        </template>
        <template v-if="planPrice <= 0">
          <PButton
              color="primary" class="mr-3"
              :disabled="!planOptionId"
              @click="onPublish">
            <translate translate-context="event.modal.publish.info">Activate for Free</translate>
          </PButton>
        </template>

      </template>
      <template v-else>
        <PButton
            v-if="allMandatoryIsPresent"
            color="primary" v-translate translate-context="event.modal.publish.actions" class="mr-3"
            @click="onPublish">
          Publish
        </PButton>
      </template>
    </template>
  </CModal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PButton from "@/components/PButton.vue";
import Suggestions from "@/domain/events/components/Suggestions.vue";
import PlanOptionsSelector from "@/domain/events/components/PlanOptionsSelector.vue";
import PAlert from "@/components/PAlert.vue";
import PComponentLoader from "@/domain/core/components/PComponentLoader.vue";

export default {
  name: "PublishEventModal",
  emits: ['published', 'edit', 'goToPayment'],
  components: {
    PComponentLoader,
    PAlert,
    PlanOptionsSelector,
    Suggestions,
    PButton,
  },
  data() {
    return {
      show: false,
      loading: true,
      planOptionId: null,
      createInvoice: false
    }
  },
  computed: {
    ...mapGetters('eventWizard', [
      'allMandatoryIsPresent',
      'suggestions',
      'planOptions',
      'configuration',
      'paymentCurrency'
    ]),
    ...mapGetters('event', [
      'currentEventStartDate',
      'timeZone'
    ]),
    planOptionsSorted() {
      if (!this.planOptions) return []
      return [...this.planOptions].sort((a, b) => a.price - b.price)
    },
    planPrice() {
      if (!this.planOptionId) return 0
      return this.planOptions.find(option => option.id === this.planOptionId)?.price
    }
  },

  methods: {
    ...mapActions('eventWizard', [
      'publish',
      'fetchPlanOptions',
      'fetchPublishValidation',
      'fetchFormData',
      'fetchEventConfiguration'
    ]),
    async open() {
      this.show = true
      this.loading = true
      await this.fetchPublishValidation()
      if (this.allMandatoryIsPresent && this.configuration.require_payment) {
        await this.fetchPlanOptions()
        if (this.planOptionsSorted.length) {
          this.planOptionId = this.planOptionsSorted.find(option => option.current)?.id
        }
      }

      this.loading = false
    },
    onSelectPlanOption(option) {
      this.planOptionId = option.id
    },
    onEdit() {
      this.$emit('edit')
      this.show = false
    },
    async onPublish(force = false) {
      await this.publish({basePlanId: this.planOptionId, force: force})
      this.$emit('published')
      this.show = false
    },
    async onPayment() {
      this.$emit('goToPayment', {
        planId: this.planOptionId,
        createInvoice: this.createInvoice })
      this.show = false
    },
  }
}
</script>